import { DateTime } from "luxon";
import { useMemo } from "react";

interface DayOfMonthDateProps {
  date: DateTime;
}

export function DayOfMonthDate({ date }: DayOfMonthDateProps) {
  const iso = useMemo(() => {
    return date.toISO();
  }, [date]);

  return <time dateTime={iso}>{date.day}</time>;
}
