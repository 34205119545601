import { Patient } from "@cur8/rich-entity";
import { BodyAreas } from "lib/smpl";
import { toCenter } from "lib/three";
import { useMemo } from "react";
import { LeftArmBloodPressure } from "render/fragments/avatar/overlay/LeftArmBloodPressure";
import { RightArmBloodpressure } from "render/fragments/avatar/overlay/RightArmBloodpressure";
import { Waist } from "render/fragments/avatar/overlay/Waist";
import { ScreenProjector } from "render/hooks/three/useProject";
import { useScreenAnchorsForObjects } from "render/hooks/three/useScreenAnchors";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import * as THREE from "three";
import { Overlay } from "../../components/Overlay";
import styles from "./styles.module.sass";

interface DiabetesLayerProps {
  layer: Layer;
  patient: Patient;
  areas: BodyAreas;
  projector: ScreenProjector;
}

export function DiabetesLayer({
  layer,
  patient,
  areas,
  projector,
}: DiabetesLayerProps) {
  const active = layer === Layer.Diabetes;

  const {
    metrics: { cardio },
    smpl: { metrics },
  } = usePatientData();

  const positions = useMemo(() => {
    const waist = new THREE.Vector3(
      areas.waist.max.x + 120,
      (areas.waist.min.y + areas.waist.max.y) / 2,
      (areas.waist.min.z + areas.waist.max.z) / 2
    );

    return {
      waist,
      leftUpperArm: toCenter(areas.leftUpperArm),
      rightUpperArm: toCenter(areas.rightUpperArm),
    };
  }, [areas]);

  const preferredWaist = useMemo(() => {
    if (!metrics) {
      return;
    }

    return metrics.at(0)?.unit.circumferences.waist;
  }, [metrics]);

  const anchors = useScreenAnchorsForObjects(projector, positions, active);

  return (
    <div className={styles.DiabetesLayer} data-active={active}>
      {anchors && (
        <>
          {cardio.brachialPressure.left && (
            <Overlay pos={anchors.leftUpperArm}>
              <div className={styles.item}>
                <LeftArmBloodPressure
                  patient={patient}
                  brachialPressure={cardio.brachialPressure.left}
                />
              </div>
            </Overlay>
          )}

          {cardio.brachialPressure.right && (
            <Overlay pos={anchors.rightUpperArm}>
              <div className={styles.item}>
                <RightArmBloodpressure
                  patient={patient}
                  brachialPressure={cardio.brachialPressure.right}
                />
              </div>
            </Overlay>
          )}

          {preferredWaist && (
            <Overlay pos={anchors.waist}>
              <div className={styles.item}>
                <Waist circumference={preferredWaist} />
              </div>
            </Overlay>
          )}
        </>
      )}
    </div>
  );
}
