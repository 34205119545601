import { ThermalImageURI } from "lib/api/thermal-uri";
import { ColorMap } from "lib/webgl/colorizer";
import { useGradientMap } from "render/hooks/api/image/useGradientMap";
import { useCycleState } from "render/hooks/useCycleState";
import { useThermalImage } from "render/hooks/useThermalImage";
import { BusyPlaceholder } from "render/ui/throbber/BusyPlaceholder";

const SIZE = { w: 600, h: 600 };

const Colormaps = [
  ColorMap.Inferno,
  ColorMap.Plasma,
  ColorMap.Magma,
  ColorMap.Viridis,
  ColorMap.None,
];

interface ThermalImageProps {
  uri: ThermalImageURI;
}

export function ThermalImage({ uri }: ThermalImageProps) {
  const [colormap, cycleColormap] = useCycleState(Colormaps);

  const image = useGradientMap(colormap, useThermalImage(uri, SIZE));

  if (!image) {
    return <BusyPlaceholder />;
  } else {
    return (
      <img
        onClick={cycleColormap}
        alt="Thermal Capture"
        width={SIZE.w}
        height={SIZE.h}
        src={image.src}
      />
    );
  }
}
