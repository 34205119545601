import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { Typography } from "render/ui/presentation/Typography";
import { Layer } from "../../../../types";
import styles from "./styles.module.sass";

const HIDE_MENU = new Set([
  Layer.Identity,
  Layer.Circulation,
  Layer.Cholesterol,
  Layer.Heart,
  Layer.Bloodwork,
  Layer.LesionLibrary,
  Layer.LesionMacro,
  Layer.Diabetes,
  Layer.ArterialHealth,
  Layer.PulseWaves,
]);

export function HealthAspectMenu() {
  const { ui } = useDashboardContext();

  const show = !HIDE_MENU.has(ui.layer);

  return (
    <div
      className={styles.HealthAspectMenu}
      data-active={ui.layer === Layer.Identity}
    >
      <nav className={styles.menu} data-active={show}>
        <div className={styles.item} data-active={ui.layer === Layer.Cardio}>
          <button
            onClick={() => {
              ui.set({ layer: Layer.Cardio });
            }}
          >
            <Typography variant="eyebrow-m">Heart</Typography>
          </button>
        </div>

        <div className={styles.item} data-active={ui.layer === Layer.Body}>
          <button
            onClick={() => {
              ui.set({ layer: Layer.Body });
            }}
          >
            <Typography variant="eyebrow-m">Body</Typography>
          </button>
        </div>

        <div className={styles.item} data-active={ui.layer === Layer.Skin}>
          <button
            onClick={() => {
              ui.set({ layer: Layer.Skin });
            }}
          >
            <Typography variant="eyebrow-m">Skin</Typography>
          </button>
        </div>
      </nav>
    </div>
  );
}
