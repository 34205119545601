import { classNames } from "@pomle/classnames";
import styles from "./styles.module.sass";

type Direction = "left" | "right";

export function Arrow({ direction = "left" }: { direction?: Direction }) {
  return (
    <svg
      className={classNames(styles.Arrow, {
        [styles.left]: direction === "left",
        [styles.right]: direction === "right",
      })}
      width="7"
      height="12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 6.75a.75.75 0 1 0 0-1.5v1.5ZM.47 5.47a.75.75 0 0 0 0 1.06l4.773 4.773a.75.75 0 0 0 1.06-1.06L2.061 6l4.242-4.243a.75.75 0 0 0-1.06-1.06L.47 5.47Zm1.03-.22H1v1.5h.5v-1.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
