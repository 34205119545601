import { useQueries } from "render/pages/TissuePages/hooks/useQueries";
import { ImageType } from "../../lib/types";
import styles from "./styles.module.sass";

interface ImageTogglesProps {
  autoflow?: "row" | "column";
  isPAD?: boolean;
  showBloodVessels?: boolean;
}

export function ImageToggles({
  autoflow = "row",
  isPAD = false,
  showBloodVessels = false,
}: ImageTogglesProps) {
  const {
    bloodVesselsMode,
    colormap,
    cycleBloodVesselsMode,
    cycleColormap,
    cycleImageType,
    imageType,
  } = useQueries();

  return (
    <div className={styles.ImageToggles} data-autoflow={autoflow}>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="ccm_viridis" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#440154" />
            <stop offset="5%" stopColor="#482777" />
            <stop offset="30%" stopColor="#3f51b5" />
            <stop offset="45%" stopColor="#2b83ba" />
            <stop offset="75%" stopColor="#77d377" />
            <stop offset="100%" stopColor="#cfe745" />
          </linearGradient>
          <linearGradient id="ccm_inferno" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#7f0000" />
            <stop offset="30%" stopColor="#ff0000" />
            <stop offset="42%" stopColor="#ff7f00" />
            <stop offset="60%" stopColor="#ffff00" />
            <stop offset="75%" stopColor="#00ff00" />
            <stop offset="100%" stopColor="#007f00" />
          </linearGradient>
          <linearGradient id="cit_normal" x1="0" x2="1" y1="0" y2="0">
            <stop offset="0%" stopColor="#4393c3" />
            <stop offset="25%" stopColor="#92c5de" />
            <stop offset="50%" stopColor="#d0f0f8" />
            <stop offset="75%" stopColor="#fee0d2" />
            <stop offset="100%" stopColor="#f08080" />
          </linearGradient>
          <linearGradient id="cit_highcontrast" x1="0" x2="1" y1="0" y2="0">
            <stop offset="0%" stopColor="#4393c3" />
            <stop offset="25%" stopColor="#4393c3" />
            <stop offset="25.01%" stopColor="#92c5de" />
            <stop offset="50%" stopColor="#92c5de" />
            <stop offset="50.01%" stopColor="#d0f0f8" />
            <stop offset="75%" stopColor="#d0f0f8" />
            <stop offset="75.01%" stopColor="#fee0d2" />
            <stop offset="100%" stopColor="#fee0d2" />
          </linearGradient>
          <linearGradient id="cit_rgb" x1="0" x2="1" y1="0" y2="0">
            <stop offset="0%" stopColor="red" />
            <stop offset="33%" stopColor="red" />
            <stop offset="33.01%" stopColor="green" />
            <stop offset="66%" stopColor="green" />
            <stop offset="66.01%" stopColor="blue" />
            <stop offset="100%" stopColor="blue" />
          </linearGradient>
        </defs>
      </svg>

      {showBloodVessels && (
        <div className={styles.btnlabel}>
          <label>Vessels</label>
          <button title={bloodVesselsMode} onClick={cycleBloodVesselsMode}>
            <svg
              viewBox="0 0 68 52"
              data-toggle={bloodVesselsMode}
              className={styles.bloodVessels}
            >
              <rect className={styles.hoverRect} />
              <g className={styles.opacity}>
                <rect
                  className={styles.gradientRect}
                  fill="url(#ccm_viridis)"
                />
                <path
                  className={styles.vessels}
                  d="M44.9 10.7c-1.3 1.5-4.8 5-7.8 7.8l-5.3 5-10.7.5c-9.8.5-10.6.7-10.6 2.5 0 1.7.8 2.1 6 2.5 4.6.4 6.9 1.2 9.7 3.3A36.3 36.3 0 0 0 42.9 39c1.5 0 4.5 1.1 6.5 2.5 2 1.3 3.6 2.1 3.6 1.6s-.7-1.1-1.5-1.5c-2.7-1-1.6-2.6 1.9-2.6 1.9 0 3.7-.3 3.9-.8.3-.4-3.2-.7-7.6-.7-9.4 0-13.7-1.3-19.9-5.8l-4.3-3.1 3.5-.9c1.9-.5 6.3-1.1 9.6-1.4 5.8-.4 6.4-.3 10 2.8l3.9 3.3-2.8-3.1-2.8-3 3-1.3c3.1-1.2 6.1-4 4.4-4a9 9 0 0 0-3 1.5 18.6 18.6 0 0 1-12.6 1.8l-2.9-.9 4.5-4.1c4.1-3.8 9.6-6.3 14-6.3.9 0 1.7-.5 1.7-1 0-1-4.4-.7-9.2.7-2.3.6-2.3-1.3-.1-2.6 1-.5 1.5-1.2 1.2-1.6-.4-.3-1.7.7-3 2.2z"
                />
              </g>
            </svg>
          </button>
        </div>
      )}

      <div className={styles.btnlabel}>
        <label>Image Type</label>
        <button title={imageType} onClick={() => cycleImageType(isPAD)}>
          {imageType === ImageType.normal && (
            <svg>
              <rect className={styles.hoverRect} />
              <g className={styles.opacity}>
                <rect className={styles.gradientRect} fill="url(#cit_normal)" />
              </g>
            </svg>
          )}
          {imageType === ImageType.highContrast && (
            <svg>
              <rect className={styles.hoverRect} />
              <g className={styles.opacity}>
                <rect
                  className={styles.gradientRect}
                  fill="url(#cit_highcontrast)"
                />
              </g>
            </svg>
          )}
          {imageType === ImageType.rgb && (
            <svg>
              <rect className={styles.hoverRect} />
              <g className={styles.opacity}>
                <rect className={styles.gradientRect} fill="url(#cit_rgb)" />
              </g>
            </svg>
          )}
        </button>
      </div>

      {!isPAD && (
        <div className={styles.btnlabel}>
          <label>Color map</label>
          <button
            title={`Color map: ${colormap}`}
            disabled={imageType === ImageType.rgb}
            onClick={cycleColormap}
          >
            <svg>
              <rect className={styles.hoverRect} />
              <g className={styles.opacity}>
                <rect
                  className={styles.gradientRect}
                  fill="url(#ccm_viridis)"
                />
                <path d="M4 48 L64 4 L64 48" fill="url(#ccm_inferno)" />
              </g>
            </svg>
          </button>
        </div>
      )}
    </div>
  );
}
