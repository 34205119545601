import { Risk } from "@cur8/health-risks-calc";
import { Highlight, Variant } from "render/ui/presentation/ChartMarker/Marker";

export const DEFAULT_POINT_COLOR = "#019dc9";
export const DEFAULT_RANGE_COLOR = "#C5D9DD";

export const RiskToHighlightMap: Record<Risk, Highlight> = {
  [Risk.Unknown]: "normal",
  [Risk.Optimal]: "normal",
  [Risk.Normal]: "normal",
  [Risk.Risk]: "warning",
  [Risk.HighRisk]: "danger",
  [Risk.ImmediateRisk]: "danger",
  [Risk.LowRisk]: "warning",
  [Risk.ModerateRisk]: "danger",
};

export const RiskToColorMap: Record<Risk, string> = {
  [Risk.Unknown]: "#59C4DD",
  [Risk.Optimal]: "#59C4DD",
  [Risk.Normal]: "#59C4DD",
  [Risk.Risk]: "#FFB647",
  [Risk.HighRisk]: "#F6A29D",
  [Risk.ImmediateRisk]: "#F6A29D",
  [Risk.LowRisk]: "#FFB647",
  [Risk.ModerateRisk]: "#F6A29D",
};

interface GetMarkerVariantProps {
  areValuesEqual: boolean;
  isLatestValue: boolean;
}

export function getMarkerVariant({
  areValuesEqual,
  isLatestValue,
}: GetMarkerVariantProps): Variant {
  if (areValuesEqual) {
    return "primary-outlined";
  }

  if (isLatestValue) {
    return "primary";
  }

  return "outlined";
}

interface GetHighlightProps {
  isUnbalanced: boolean;
  risk?: Risk;
}

export function getHighlight({
  isUnbalanced,
  risk,
}: GetHighlightProps): Highlight {
  if (isUnbalanced) {
    return "warning";
  }

  if (risk) {
    return RiskToHighlightMap[risk];
  }

  return "normal";
}
