import { APITypesV1 } from "@cur8/api-client";
import { fromAPI } from "@cur8/rich-entity";
import { useCallback, useEffect, useMemo } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useStore } from "render/context/StoreContext";
import { useEntries } from "render/hooks/store/refs";
import { useReporting } from "../useReporting";

export function usePatientPrivateJournalNoteQuery(
  options: APITypesV1.PrivateJournalNoteGetsParams,
  namespace: string
) {
  const { logError } = useReporting();

  const api = useAPIClient();

  const privateJournalNoteStore = useStore().privateJournalNote;

  const { getEntries, setEntries } = useEntries(
    privateJournalNoteStore,
    namespace
  );

  const fetch = useCallback(async () => {
    let currentToken: string | undefined = options.continuationToken;
    let allNotes: any[] = [];
    try {
      do {
        const result = await api.privateJournalNote.getByPatientId({
          ...options,
          continuationToken: currentToken,
        }).result;

        const privateJournalNotes = result.items.map(
          fromAPI.toPrivateJournalNote
        );

        allNotes = [...allNotes, ...privateJournalNotes];

        currentToken = result.nextPage;
      } while (currentToken);

      setEntries(allNotes);
    } catch (error) {
      logError(error);
    }
  }, [api, setEntries, options, logError]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return useMemo(() => {
    return {
      entries: getEntries(),
      fetch,
    };
  }, [getEntries, fetch]);
}
