import { Lesion } from "lib/lesion";
import styles from "./styles.module.sass";

interface LesionIdProps {
  lesion: Lesion;
}

export function LesionId({ lesion }: LesionIdProps) {
  return (
    <div className={styles.LesionId}>
      N
      <span className={styles.num}>
        °{lesion.fakeId.toString().padStart(3, "0")}
      </span>
    </div>
  );
}
