import { BoundingBoxAnnotation } from "@cur8/rich-entity";
import { toList } from "@pomle/react-flat-store";
import { MouseEvent, useMemo } from "react";
import { useLatestPhysicalArtefact } from "render/hooks/api/physical-artefact/useLatestPhysicalArtefact";
import { useRelatedAnnotations } from "render/hooks/api/useRelatedAnnotations";
import { ArtefactStateColor } from "render/pages/AtlasPage/markingColors";
import { PhysicalArtefactState } from "render/ui/format/PhysicalArtefactState";
import { BoundingBox } from "render/ui/presentation/BoundingBox";
import EyeIcon from "./assets/eye.svg?react";
import { RelatedImage } from "./components/RelatedImage";
import styles from "./styles.module.sass";

interface AnnotationAreaProps {
  annotation: BoundingBoxAnnotation;
  onClick: (event: MouseEvent<Element>) => void;
  onSelectedEvent: (selected: boolean) => void;
  highlight?: boolean;
}

export function AnnotationArea({
  annotation,
  onClick,
  onSelectedEvent,
  highlight,
}: AnnotationAreaProps) {
  const { entries: relatedAnnotationEntries } =
    useRelatedAnnotations(annotation);

  const { latestPhysicalArtefact: physicalArtefact } =
    useLatestPhysicalArtefact({
      patientId: annotation.patientId,
      physicalArtefactId: annotation.physicalArtefactId || "",
    });

  const state = physicalArtefact.data?.state;

  const color = useMemo(() => {
    if (!state) {
      return ArtefactStateColor.none;
    }

    return ArtefactStateColor[state];
  }, [state]);

  const annotations = useMemo(() => {
    return toList(relatedAnnotationEntries);
  }, [relatedAnnotationEntries]);

  return (
    <div className={styles.AnnotationArea}>
      <div className={styles.State}>
        {state ? <PhysicalArtefactState state={state} /> : "--"}
      </div>

      <BoundingBox
        color={color}
        onClick={onClick}
        onSelectedEvent={onSelectedEvent}
        bold={highlight}
      >
        <EyeIcon className={styles.icon} />

        {annotations.length > 0 && (
          <div className={styles.relatedImage}>
            <RelatedImage annotations={annotations} />
          </div>
        )}
      </BoundingBox>
    </div>
  );
}
