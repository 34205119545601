import { Sex } from "@cur8/rich-entity";
import { useCallback, useMemo } from "react";
import CheckSvg from "render/assets/svg/check.svg?react";
import { useAssessmentContext } from "render/pages/AssessmentPage/context/AssessmentContext";
import { CreatePulseWaveAssessmentRequestBox } from "render/pages/AssessmentPage/context/AssessmentContext/lib";
import { Tooltip } from "render/ui/presentation/Tooltip";
import { AssessmentHandler } from "../../../AssessmentHandler";
import {
  getLVETIrange,
  LVETI_RANGE_NORMAL_FEMALE,
  LVETI_RANGE_NORMAL_MALE,
  MAX_ASYMMETRY_DELAY,
  MAX_PULSEWAVEVELOCITY,
  RangeLabel,
} from "../../lib/calculations";
import styles from "./styles.module.sass";

export function AssessmentSidebox({ sex }: { sex: Sex }) {
  const { createAssessment } = useAssessmentContext();
  const pwa = createAssessment as CreatePulseWaveAssessmentRequestBox;

  const hasAllMeasurements = useCallback(() => {
    if (
      pwa.lveti &&
      pwa.pulseWaveVelocity &&
      pwa.footAsymmetry &&
      pwa.handAsymmetry
    ) {
      return true;
    }
    return false;
  }, [pwa.footAsymmetry, pwa.handAsymmetry, pwa.lveti, pwa.pulseWaveVelocity]);

  const boundryLabel = useMemo(() => {
    if (!sex || !pwa || !pwa.lveti || !pwa.lveti.average) {
      return;
    }
    return getLVETIrange(pwa.lveti.average, sex);
  }, [pwa, sex]);
  const normalRange =
    sex === Sex.Female ? LVETI_RANGE_NORMAL_FEMALE : LVETI_RANGE_NORMAL_MALE;

  const indicator = useMemo(() => {
    switch (boundryLabel) {
      case RangeLabel.above:
        return "↑";
      case RangeLabel.below:
        return "↓";
      default:
        return;
    }
  }, [boundryLabel]);

  const displayValues = useMemo(() => {
    return {
      lveti: pwa.lveti?.average ? Math.round(pwa.lveti.average) : "-",
      pwv: pwa.pulseWaveVelocity?.value
        ? Math.round(pwa.pulseWaveVelocity.value * 10) / 10
        : "-",
      pwvAbnormal:
        pwa.pulseWaveVelocity?.value &&
        pwa.pulseWaveVelocity?.value > MAX_PULSEWAVEVELOCITY
          ? true
          : false,
      handasy: pwa.handAsymmetry?.value
        ? Math.round(pwa.handAsymmetry.value)
        : "-",
      handAbnormal:
        pwa.handAsymmetry?.value &&
        pwa.handAsymmetry?.value > MAX_ASYMMETRY_DELAY
          ? true
          : false,
      footasy: pwa.footAsymmetry?.value
        ? Math.round(pwa.footAsymmetry.value)
        : "-",
      footAbnormal:
        pwa.footAsymmetry?.value &&
        pwa.footAsymmetry?.value > MAX_ASYMMETRY_DELAY
          ? true
          : false,
    };
  }, [
    pwa.footAsymmetry?.value,
    pwa.handAsymmetry?.value,
    pwa.lveti?.average,
    pwa.pulseWaveVelocity?.value,
  ]);

  return (
    <div className={styles.AssessmentSidebox}>
      <h3>Measurements</h3>
      <div className={styles.tabledata}>
        <div>{pwa.lveti && <CheckSvg />}</div>
        <label>LVETI</label>
        <Tooltip>
          <div className={styles.valueItem}>
            <span className={styles.value} data-boundry={boundryLabel}>
              {displayValues.lveti}
            </span>
            <span className={styles.unit}>{pwa.lveti && "ms"}</span>
            <i>{indicator}</i>
          </div>
          <div>
            {Sex[sex]} normal range is:
            <br />
            <span className={styles.value}>
              {normalRange.from} - {normalRange.to}
              <span className={styles.unit}> ms</span>
            </span>
          </div>
        </Tooltip>
        <div>{pwa.pulseWaveVelocity && <CheckSvg />}</div>
        <label>Pulse Wave Velocity</label>
        <Tooltip>
          <div className={styles.valueItem}>
            <span
              className={styles.value}
              data-abnormal={displayValues.pwvAbnormal}
            >
              {displayValues.pwv}
            </span>
            <span className={styles.unit}>
              {pwa.pulseWaveVelocity && "m/s"}
            </span>
          </div>
          <div>
            A PWV above <b>{MAX_PULSEWAVEVELOCITY} m/s</b> is abnormal.
          </div>
        </Tooltip>
        <div>{pwa.handAsymmetry && <CheckSvg />}</div>
        <label>Hand Asymmetry</label>
        <Tooltip>
          <div className={styles.valueItem}>
            <span
              className={styles.value}
              data-abnormal={displayValues.handAbnormal}
            >
              {displayValues.handasy}
            </span>
            <span className={styles.unit}>{pwa.handAsymmetry && "ms"}</span>
          </div>
          <div>
            Asymmetry (delay) above <b>{MAX_ASYMMETRY_DELAY} ms</b> is abnormal.
          </div>
        </Tooltip>
        <div>{pwa.footAsymmetry && <CheckSvg />}</div>
        <label>Foot Asymmetry</label>
        <Tooltip>
          <div className={styles.valueItem}>
            <span
              className={styles.value}
              data-abnormal={displayValues.footAbnormal}
            >
              {displayValues.footasy}
            </span>
            <span className={styles.unit}>{pwa.footAsymmetry && "ms"}</span>
          </div>
          <div>
            Asymmetry (delay) above <b>{MAX_ASYMMETRY_DELAY} ms</b> is abnormal.
          </div>
        </Tooltip>
      </div>

      <AssessmentHandler />
      {!hasAllMeasurements() && (
        <div className={styles.disclaimer}>
          You need to make all measurements before you can save the assessment.
        </div>
      )}
    </div>
  );
}
