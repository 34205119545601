import { BodyAreas } from "lib/smpl";
import { toCenter } from "lib/three";
import { useMemo } from "react";
import { HeartRateFloat as HeartRate } from "render/fragments/avatar/overlay/HeartRate";
import { PulseWaveLocation } from "render/fragments/avatar/overlay/PulseWaveLocation";
import { ScreenProjector } from "render/hooks/three/useProject";
import { useScreenAnchorsForObjects } from "render/hooks/three/useScreenAnchors";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import { Overlay } from "../../components/Overlay";
import styles from "./styles.module.sass";

const ACTIVE = new Set([Layer.Cardio, Layer.PulseWaves]);

interface PulseWaveLayerProps {
  layer: Layer;
  areas: BodyAreas;
  projector: ScreenProjector;
}

export function PulseWaveLayer({
  layer,
  areas,
  projector,
}: PulseWaveLayerProps) {
  const {
    metrics: { cardio },
  } = usePatientData();

  const { ui } = useDashboardContext();

  const positions = useMemo(() => {
    const aorta = toCenter(areas.heart);
    const pulse = toCenter(areas.heart);
    const femoral = toCenter(areas.rightHip);

    aorta.z += 50;

    return {
      aorta,
      pulse,
      femoral,
    };
  }, [areas]);

  const active = ACTIVE.has(layer);

  const anchors = useScreenAnchorsForObjects(projector, positions, active);

  return (
    <>
      <div className={styles.PulseWaveLayer} data-active={active}>
        {anchors && (
          <Overlay pos={anchors.femoral}>
            <div className={styles.item}>
              <PulseWaveLocation caption="Hip" />
            </div>
          </Overlay>
        )}
      </div>

      <div
        className={styles.PulseWaveLayer}
        data-active={active && ui.layer === Layer.Cardio}
      >
        {anchors && (
          <Overlay pos={anchors.aorta}>
            <div className={styles.item}>
              <PulseWaveLocation caption="Aorta" />
            </div>
          </Overlay>
        )}
      </div>

      <div
        className={styles.PulseWaveLayer}
        data-active={active && ui.layer === Layer.PulseWaves}
      >
        {anchors && (
          <Overlay pos={anchors.pulse}>
            <div className={styles.item}>
              {cardio?.heartRate && (
                <HeartRate heartRate={cardio.heartRate} width={220} />
              )}
            </div>
          </Overlay>
        )}
      </div>
    </>
  );
}
