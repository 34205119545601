import { DateTime } from "luxon";
import { toFormat } from "./toFormat";

interface FullDateProps {
  date: DateTime;
}

export function FullDate({ date }: FullDateProps) {
  return <time dateTime={date.toISO()}>{toFormat(date)}</time>;
}
