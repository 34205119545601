import { ReactNode, useCallback, useState } from "react";
import { HoverTextButton } from "../HoverTextButton";
import CloseIcon from "./assets/close-x.svg?react";
import styles from "./styles.module.sass";

interface Props {
  children: ReactNode;
  label?: string;
  header: string | undefined;
}

export function DropdownMenuButton({
  children,
  label = "···",
  header = undefined,
}: Props) {
  const [menuExpanded, setMenuExpanded] = useState<boolean>(false);
  const toggleDropdownMenu = useCallback(() => {
    setMenuExpanded((menuExpanded) => !menuExpanded);
  }, []);

  return (
    <div className={styles.DropdownMenuButton}>
      <HoverTextButton onClick={toggleDropdownMenu}>{label}</HoverTextButton>
      <div
        className={styles.DropDownMenu}
        data-expanded={menuExpanded}
        onClick={toggleDropdownMenu}
      >
        {header && (
          <div className={styles.header}>
            <h3>{header}</h3>
            <CloseIcon />
          </div>
        )}
        <div className={styles.menuItems}>{children}</div>
      </div>
    </div>
  );
}
