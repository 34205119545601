import { ValidScanTypes, parseScanType } from "lib/scanType";

interface ScanTypeProps {
  scanType: string;
}

export function ScanType({ scanType }: ScanTypeProps) {
  const pt = parseScanType(scanType);
  if (pt) {
    if (pt.type === ValidScanTypes.Skin) {
      return <>Skin Imaging</>;
    }

    if (pt.type === ValidScanTypes.Cardio) {
      return <>Cardio</>;
    }

    if (pt.type === ValidScanTypes.Tissue) {
      let label = "Tissue";
      if (pt.subtype === "mind") {
        label = "Tissue/MIND";
      } else if (pt.subtype === "pad") {
        label = "Tissue/PAD";
      }
      return <>{label}</>;
    }
  }

  return <>{scanType}</>;
}
