import {
  Lesion,
  SKIN_FAKE_COLORS,
  byLinkDate,
  isPreferredLink,
} from "lib/lesion";
import { useEffect, useMemo, useState } from "react";
import { LesionImage } from "./components/LesionImage";
import styles from "./styles.module.sass";

function pickColor() {
  const index = Math.floor(SKIN_FAKE_COLORS.length * Math.random());
  return SKIN_FAKE_COLORS[index];
}

interface LesionGridItemProps {
  active: boolean;
  delay: number;
  lesion: Lesion;
}

export function LesionGridItem({ active, delay, lesion }: LesionGridItemProps) {
  const [placeholderColor] = useState(pickColor);

  const [activated, setActivated] = useState(active);

  const link = useMemo(() => {
    return lesion.links.filter(isPreferredLink).sort(byLinkDate("desc")).at(0);
  }, [lesion]);

  const annotation = link?.annotation;

  useEffect(() => {
    setActivated(false);
  }, [annotation]);

  useEffect(() => {
    if (active) {
      setActivated(true);
    }
  }, [active]);

  const size = annotation?.classification.size;

  return (
    <div
      className={styles.LesionGridItem}
      data-active={active}
      style={{ transitionDelay: `${delay}s` }}
    >
      <div className={styles.image} style={{ background: placeholderColor }}>
        {annotation && (
          <LesionImage
            active={activated}
            lesion={lesion}
            annotation={annotation}
          />
        )}
      </div>

      <div className={styles.caption}>{size ? size.toFixed(1) : "--"}mm</div>
    </div>
  );
}
