import {
  AktiiaMonitorBloodPressureAssignmentData,
  Assignment,
} from "@cur8/rich-entity";
import { useCallback } from "react";
import { usePopupDialog } from "render/context/PopupDialogContext";
import { ReturnBloodMonitorDeviceDialog } from "render/fragments/assignments/ReturnBloodMonitorDeviceDialog";

export function useAssignmentReturnDevicePopup() {
  const { create } = usePopupDialog();

  const emitDialog = useCallback(
    (
      assignment: Assignment<AktiiaMonitorBloodPressureAssignmentData>,
      { onSuccess }: { onSuccess: (assignment: Assignment) => void }
    ) => {
      const { emit, close } = create();
      emit(
        <ReturnBloodMonitorDeviceDialog
          onSuccess={(updatedAssignment) => {
            onSuccess(updatedAssignment);
            close();
          }}
          assignment={assignment}
          onDone={close}
        />
      );
    },
    [create]
  );

  return {
    emitDialog,
  };
}
