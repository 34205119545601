import { CreatePadAssessmentRequestBox } from "render/pages/AssessmentPage/context/AssessmentContext";
import { DisplayLabel } from "./DisplayLabel";
import styles from "./styles.module.sass";

interface RegionTrackerProps {
  assessment: CreatePadAssessmentRequestBox;
  onRoiSelect: (roi: string) => void;
  pixelsPerCm: number | undefined;
  selectedRoi?: string;
}

export function RegionTracker({
  assessment,
  onRoiSelect,
  pixelsPerCm,
  selectedRoi,
}: RegionTrackerProps) {
  if (!assessment.regionsOfInterest) {
    return <></>;
  }
  return (
    <div className={styles.RegionTracker}>
      <h3>Regions of Interest</h3>
      <DisplayLabel
        box={assessment.regionsOfInterest.forearm}
        isSelected={selectedRoi === "forearm"}
        onRoiSelect={onRoiSelect}
        label="forearm"
        pixelsPerCm={pixelsPerCm}
      />
      <DisplayLabel
        box={assessment.regionsOfInterest.hypothenar}
        isSelected={selectedRoi === "hypothenar"}
        onRoiSelect={onRoiSelect}
        label="hypothenar"
        pixelsPerCm={pixelsPerCm}
      />
    </div>
  );
}
