import { VisitSummary } from "@cur8/rich-entity";
import { PrivateJournalNote } from "@cur8/rich-entity/dist/types/PrivateJournalNote";
import { PathLink, useNav } from "@pomle/react-router-paths";
import React, { useMemo, useState } from "react";
import { FaAngleRight, FaRegEdit, FaRegFileAlt } from "react-icons/fa";
import { paths } from "render/routes/paths";
import { DayDate } from "render/ui/format/DayDate";
import { Table } from "render/ui/layouts/Table";
import { DoctorName } from "../PreviousVisitSummary/components/Summary/components/DoctorName";
import styles from "./styles.module.sass";

interface RecordTableProps {
  visits: VisitSummary[];
  privateJournalNotes: PrivateJournalNote[];
  onViewPrivateJournalNote: (note: any) => void;
}

export default function RecordTable({
  visits,
  privateJournalNotes,
  onViewPrivateJournalNote,
}: RecordTableProps) {
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const nav = {
    edit: useNav(paths.patient.visit.edit),
  };

  // Combine and sort visits and private journal notes
  const sortedRecords = useMemo(() => {
    const combinedRecords = [
      ...visits.map((visit) => ({
        ...visit,
        type: "visitSummary",
        date: visit.visitDate,
        content: visit.summaryText,
        key: visit.id,
      })),
      ...privateJournalNotes.map((note) => ({
        ...note,
        type: "privateJournalNote",
        date: note.createdAt,
        content: note.note,
        key: note.id,
      })),
    ];

    return combinedRecords.sort(
      (a, b) => b.date.toMillis() - a.date.toMillis()
    );
  }, [visits, privateJournalNotes]);

  const toggleRow = (id: string) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  return (
    <div className={styles.RecordTable}>
      <Table>
        <thead>
          <tr>
            <th className={styles.arrowColumn}></th>
            <th className={styles.dateColumn}>Date</th>
            <th className={styles.noteColumn}>Note</th>
            <th className={styles.lastChangeColumn}>Last change by</th>
            <th className={styles.actionColumn}>Action</th>
          </tr>
        </thead>
        <tbody>
          {sortedRecords.map((record) => {
            const isExpanded = expandedRow === record.id;
            return (
              <React.Fragment key={record.key}>
                <tr
                  key={record.id}
                  onClick={() => toggleRow(record.id)}
                  className={`${styles.row} ${
                    record.type === "privateJournalNote"
                      ? styles.privateJournalNote
                      : ""
                  } ${
                    isExpanded && record.type === "visitSummary"
                      ? styles.highlighted
                      : ""
                  }`}
                >
                  <td className={styles.arrowCell}>
                    <span
                      className={`${styles.arrow} ${
                        isExpanded ? styles.expanded : ""
                      }`}
                    >
                      <FaAngleRight />
                    </span>
                  </td>
                  <td className={styles.dateCell}>
                    <DayDate date={record.date} />
                  </td>
                  <td className={styles.noteCell}>
                    {record.type === "visitSummary" && record.content ? (
                      <div className={styles.summaryCell}>
                        <FaRegFileAlt className={styles.icon} />
                        Member summary
                      </div>
                    ) : record.type === "privateJournalNote" &&
                      record.content ? (
                      <div
                        className={`${styles.summaryCell} ${styles.truncatedText}`}
                      >
                        <FaRegEdit className={styles.icon} />
                        {record.content}
                      </div>
                    ) : (
                      "No content"
                    )}
                  </td>
                  <td className={styles.lastChangeCell}>
                    {record.audit.created?.userUri && (
                      <DoctorName uri={record.audit.created?.userUri} />
                    )}
                  </td>
                  <td className={styles.actionCell}>
                    {record.type === "visitSummary" ? (
                      <PathLink
                        to={nav.edit.to({
                          patientId: record.patientId,
                          summaryId: record.id,
                        })}
                      >
                        <span className={styles.viewAction}>View</span>
                      </PathLink>
                    ) : (
                      <span
                        className={styles.viewAction}
                        onClick={() => onViewPrivateJournalNote(record)}
                      >
                        View
                      </span>
                    )}
                  </td>
                </tr>
                {isExpanded && (
                  <tr
                    key={`${record.id}-details`}
                    className={`${styles.expandedRow} ${
                      record.type === "visitSummary" ? styles.highlighted : ""
                    } ${
                      record.type === "privateJournalNote"
                        ? styles.privateJournalNote
                        : ""
                    }`}
                  >
                    <td colSpan={5}>{record.content}</td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
