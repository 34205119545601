import styles from "./styles.module.sass";

interface BackgroundStripesProps {
  labels: number[];
  toY: (n: number) => number;
}

export function BackgroundStripes({ labels, toY }: BackgroundStripesProps) {
  const stroke = "#E8E8E8";

  return (
    <svg
      className={styles.BackgroundStripes}
      overflow="visible"
      xmlSpace="preserve"
    >
      {labels.map((label) => (
        <line
          key={label}
          x1="0"
          y1={`${toY(label)}%`}
          x2="100%"
          y2={`${toY(label)}%`}
          stroke={stroke}
          strokeWidth="1px"
        />
      ))}
    </svg>
  );
}
