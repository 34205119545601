// Great test example for dev
// Moving around: http://localhost:3000/patient/P0i2txqql7kwgg/scans/99ceda89-dce4-59f2-bdbc-2388df9448ec/3e989ff4-445f-45ba-85a7-5a00f631ad8c/cardio
// Ken artificial: http://localhost:3000/patient/P0b2otgmah1i8t/scans/7b6f100f-e504-5a99-84d3-66b00ce8009b/a0bf876f-f0a8-4aee-a9ed-1b7ffa79f080/cardio

import { PathLink, useNav } from "@pomle/react-router-paths";
import { useState } from "react";
import BackIcon from "render/assets/nav/back.svg?react";
import { useImmutableScan } from "render/hooks/api/useImmutableScan";
import { usePatient } from "render/hooks/api/usePatient";
import { paths } from "render/routes/paths";
import { ContextHeader } from "render/ui/layouts/ContextHeader";
import { FramedPage } from "render/ui/layouts/FramedPage/FramedPage";
import { PatientSummary } from "../TissuePages/components/PatientSummary";
import { EjectionTime } from "./components/EjectionTime";
import { FootAsymmetry } from "./components/FootAsymmetry";
import { HandAsymmetry } from "./components/HandAsymmetry";
import { PWV } from "./components/PWV";
import { ButtonControl } from "./components/shared/ButtonControl";
import { Range } from "./components/shared/DataWindow";
import {
  INITIAL_SELECTED_RANGE,
  PlotType,
} from "./components/shared/constants";
import { useCardioSignals } from "./hooks/useCardioSignals";
import styles from "./styles.module.sass";

interface CardioScanPageProps {
  patientId: string;
  scanId: string;
  scanVersion: string;
}

export function CardioScanPage({
  patientId,
  scanId,
  scanVersion,
}: CardioScanPageProps) {
  const [activePlot, setActivePlot] = useState<PlotType>(
    PlotType.EJECTION_TIME
  );
  const patient = usePatient(patientId);
  const scan = useImmutableScan(patientId, scanId, scanVersion);
  const [range, setRange] = useState<Range>(INITIAL_SELECTED_RANGE);
  const signals = useCardioSignals(scan);

  const nav = {
    patient: useNav(paths.patient.detail),
  };

  return (
    <FramedPage>
      <div className={styles.CardioScanPage}>
        <header>
          <ContextHeader
            context={
              <PathLink
                to={nav.patient.to({
                  patientId,
                })}
              >
                <BackIcon />
              </PathLink>
            }
          >
            {patient && scan && (
              <PatientSummary patient={patient} scan={scan} />
            )}
          </ContextHeader>
        </header>

        <div className={styles.spaceRow}>
          <ButtonControl
            activePlot={activePlot}
            setActivePlot={setActivePlot}
          />
        </div>
        {activePlot === PlotType.EJECTION_TIME && (
          <div className={styles.plotComponent}>
            <EjectionTime
              patient={patient}
              signals={signals}
              range={range}
              setRange={setRange}
            />
          </div>
        )}
        {activePlot === PlotType.PWV && (
          <div className={styles.plotComponent}>
            <PWV
              patientId={patientId}
              signals={signals}
              range={range}
              setRange={setRange}
            />
          </div>
        )}
        {activePlot === PlotType.HAND_ASYMMETRY && (
          <div className={styles.plotComponent}>
            <HandAsymmetry
              signals={signals}
              range={range}
              setRange={setRange}
            />
          </div>
        )}
        {activePlot === PlotType.FOOT_ASYMMETRY && (
          <div className={styles.plotComponent}>
            <FootAsymmetry
              signals={signals}
              range={range}
              setRange={setRange}
            />
          </div>
        )}
      </div>
    </FramedPage>
  );
}
