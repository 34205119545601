import * as Risks from "@cur8/health-risks-calc";
import { Patient, Sex } from "@cur8/rich-entity";
import { useAge } from "render/hooks/patient/useAge";
import { PatientMetrics } from "render/hooks/patient/usePatientMetrics";
import { Table } from "render/ui/layouts/Table";
import { ResultRow } from "../ResultRow";

interface BloodworkTableProps {
  patient?: Patient;
  metrics: PatientMetrics;
}

export function BloodworkTable({ patient, metrics }: BloodworkTableProps) {
  const age = useAge(patient);
  const bloodwork = metrics.bloodwork;

  return (
    <Table>
      <thead>
        <tr>
          <th>Bloodwork</th>
          <th>Result</th>
          <th>Risk</th>
          <th>Units</th>
        </tr>
      </thead>
      <tbody>
        <ResultRow
          caption="CRP"
          value={bloodwork.crp?.at(0)?.unit}
          format={(value) => value["mg/L"].toFixed(2)}
          unit="mg/L"
          summarize={(unit) => {
            return {
              risk: Risks.CRP.rangesFor().findRisk(unit),
            };
          }}
        />
        <ResultRow
          caption="HbA1C"
          value={bloodwork.hba1c?.at(0)?.unit}
          format={(value) => value["mmol/mol"].toFixed(2)}
          unit="mmol/mol"
          summarize={(unit) => {
            return {
              risk: Risks.HbA1c.rangesFor({ age: age ?? NaN }).findRisk(unit),
            };
          }}
        />
        <ResultRow
          caption="Haemoglobin"
          value={bloodwork.haemoglobin?.at(0)?.unit}
          format={(value) => value["g/L"].toFixed(2)}
          unit="g/L"
          summarize={(unit) => {
            return {
              risk: Risks.Haemoglobin.rangesFor({
                sex: patient?.sex ?? Sex.Unknown,
              }).findRisk(unit),
            };
          }}
        />
        <ResultRow
          caption="Glucose"
          value={bloodwork.glucose?.at(0)?.unit}
          format={(value) => value["mmol/L"].toFixed(2)}
          unit="mmol/L"
          summarize={(unit) => {
            return {
              risk: Risks.Glucose.rangesFor().findRisk(unit),
            };
          }}
        />
      </tbody>
    </Table>
  );
}
