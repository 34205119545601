import { UserURI } from "@cur8/uri";
import { useUser } from "render/hooks/api/useUser";

interface DoctorNameProps {
  uri: UserURI;
}

export function DoctorName({ uri }: DoctorNameProps) {
  const user = useUser(uri);

  if (user.data) {
    return <>{user.data.displayName}</>;
  }

  return <>--/--</>;
}
