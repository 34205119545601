import { PrivateJournalNote as PrivateJournalNoteEntity } from "@cur8/rich-entity/dist/types/PrivateJournalNote";
import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import styles from "./styles.module.sass";
import ViewPrivateJournalNote from "./ViewPrivateJournalNote";
import WritePrivateJournalNote from "./WritePrivateJournalNote";

interface PrivateJournalNoteProps {
  active: boolean;
  onClose: () => void;
  patientId: string;
  onNoteAdded: (note: PrivateJournalNoteEntity) => void;
  onNoteEdited: (note: PrivateJournalNoteEntity) => void;
  selectedNote?: PrivateJournalNoteEntity;
}

export default function PrivateJournalNote({
  active,
  onClose,
  patientId,
  onNoteAdded,
  onNoteEdited,
  selectedNote: initialSelectedNote,
}: PrivateJournalNoteProps) {
  const [selectedNote, setSelectedNote] = useState(initialSelectedNote);

  return (
    <div data-active={active} className={styles.PrivateJournalNote}>
      <div className={styles.overlay} onClick={onClose} />
      <div className={styles.content}>
        <div className={styles.closeButton}>
          <FaTimes onClick={onClose} />
        </div>
        {selectedNote ? (
          <ViewPrivateJournalNote
            privateJournalNote={selectedNote}
            onNoteEdited={onNoteEdited}
            setSelectedNote={setSelectedNote}
          />
        ) : (
          <WritePrivateJournalNote
            patientId={patientId}
            onNoteAdded={onNoteAdded}
            setSelectedNote={setSelectedNote}
          />
        )}
      </div>
    </div>
  );
}
