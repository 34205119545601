import { clamp } from "lib/math";
import { PointerEvent, ReactNode, useCallback, useState } from "react";
import { useRangeContext } from "../../../../../context/RangeContext";
import { MINIMUM_SELECTION } from "../../../constants";
import styles from "./styles.module.sass";

enum Cursor {
  crosshair = "crosshair",
  grabbing = "grabbing",
  grab = "grab",
  resizeLeft = "resizeLeft",
  resizeRight = "resizeRight",
}

interface DataWindowProps {
  children: ReactNode;
}

export function DataWindow({ children }: DataWindowProps) {
  const {
    scalarToTime,
    signalRange,
    windowRange,
    windowToScalar,
    setWindowRange,
  } = useRangeContext();
  const [dragPoint, setDragPoint] = useState<number>();
  const [cursor, setCursor] = useState<Cursor>(Cursor.crosshair);

  const isInSelection = useCallback(
    (time: number) => {
      return time > windowRange.from && time < windowRange.to;
    },
    [windowRange]
  );

  const EDGE_MARGIN = 0.5;

  const isAtLeftEdge = useCallback(
    (time: number) => {
      return Math.abs(time - windowRange.from) < EDGE_MARGIN;
    },
    [windowRange]
  );

  const isAtRightEdge = useCallback(
    (time: number) => {
      return Math.abs(time - windowRange.to) < EDGE_MARGIN;
    },
    [windowRange]
  );

  const handlePointer = useCallback(
    (event: PointerEvent<HTMLDivElement>) => {
      const bounds = event.currentTarget.getBoundingClientRect();
      const scalar = clamp((event.clientX - bounds.left) / bounds.width, 0, 1);
      const time = scalarToTime(scalar);

      if (event.type === "pointerdown") {
        setDragPoint(undefined);
        if (isAtLeftEdge(time)) {
          setCursor(Cursor.resizeLeft);
        } else if (isAtRightEdge(time)) {
          setCursor(Cursor.resizeRight);
        } else if (isInSelection(time)) {
          setDragPoint(time - windowRange.from);
          setCursor(Cursor.grabbing);
        } else {
          setCursor(Cursor.crosshair);
          setWindowRange({ from: time, to: time });
        }
      } else if (event.type === "pointermove") {
        if (!!event.buttons) {
          if (cursor === Cursor.resizeLeft) {
            setWindowRange((cur) => {
              if (time < cur.to - MINIMUM_SELECTION) {
                return { from: time, to: cur.to };
              }
              return cur;
            });
          } else if (cursor === Cursor.resizeRight) {
            setWindowRange((cur) => {
              if (time > cur.from + MINIMUM_SELECTION) {
                return { from: cur.from, to: time };
              }
              return cur;
            });
          } else if (dragPoint === undefined) {
            setWindowRange((cur) => {
              return { from: cur.from, to: time };
            });
          } else {
            // just moving, ensure we don't move out
            setWindowRange((cur) => {
              const left = time - dragPoint;
              const right = time + Math.abs(cur.to - cur.from) - dragPoint;
              if (left > signalRange.from && right < signalRange.to) {
                return {
                  from: time - dragPoint,
                  to:
                    time +
                    Math.abs(windowRange.to - windowRange.from) -
                    dragPoint,
                };
              }
              return cur;
            });
          }
        } else {
          if (isAtLeftEdge(time) || isAtRightEdge(time)) {
            setCursor(Cursor.resizeLeft);
          } else if (isInSelection(time)) {
            setCursor(Cursor.grab);
          } else {
            setCursor(Cursor.crosshair);
          }
        }
      } else if (event.type === "pointerup") {
        setDragPoint(undefined);
        setCursor(isInSelection(time) ? Cursor.grab : Cursor.crosshair);
        const delta = Math.abs(windowRange.to - windowRange.from);
        const minDelta = MINIMUM_SELECTION;
        if (delta < minDelta) {
          setWindowRange({
            from: clamp(time - minDelta, 0, 1 - 2 * minDelta),
            to: clamp(time + minDelta, 2 * minDelta, 1),
          });
        }
      }
    },
    [
      scalarToTime,
      isAtLeftEdge,
      isAtRightEdge,
      isInSelection,
      windowRange,
      setWindowRange,
      cursor,
      dragPoint,
      signalRange,
    ]
  );

  const win = windowToScalar();

  return (
    <div className={styles.DataWindow} data-cursor={cursor}>
      {windowRange && (
        <div
          className={styles.window}
          style={{
            left: `${win.from * 100}%`,
            right: `${(1 - win.to) * 100}%`,
          }}
        />
      )}
      <div
        className={styles.content}
        onPointerDown={handlePointer}
        onPointerMove={handlePointer}
        onPointerUp={handlePointer}
      >
        {children}
      </div>
    </div>
  );
}
