import { toPolyline } from "lib/svg";
import { useMemo } from "react";

function smoothen(polygon: { x: number; y: number }[]) {
  const smooth: { x: number; y: number }[] = [];

  if (polygon.length === 0) {
    return smooth;
  }

  let peek = 2;
  for (let i = 0; i < polygon.length + 1; i++) {
    const samples: { x: number; y: number }[] = [];
    for (let j = i - peek; j <= i + peek; j++) {
      const sample = polygon[(polygon.length + j) % polygon.length];
      samples.push({
        x: sample.x,
        y: sample.y,
      });
    }

    const sum = samples.reduce(
      (sum, value) => {
        sum.x += value.x;
        sum.y += value.y;
        return sum;
      },
      { x: 0, y: 0 }
    );

    smooth.push({
      x: sum.x / samples.length,
      y: sum.y / samples.length,
    });
  }

  return smooth;
}

interface LesionOutlineProps {
  polygon: { x: number; y: number }[];
}

export function LesionOutline({ polygon }: LesionOutlineProps) {
  const path = useMemo(() => {
    return toPolyline(smoothen(polygon));
  }, [polygon]);

  return (
    <svg viewBox="0 0 100 100" width="100%" height="100%">
      <polyline
        points={path}
        strokeWidth="1"
        strokeDasharray="2"
        stroke="#008BB7"
        fill="#C5D9DD80"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
