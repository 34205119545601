import { APITypesV1 } from "@cur8/api-client";
import { SkinBookTracking } from "@cur8/rich-entity";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { useReporting } from "render/hooks/useReporting";
import { DropdownMenuButton } from "render/ui/trigger/DropdownMenuButton";
import { DropdownItem } from "render/ui/trigger/DropdownMenuButton/components/DropdownItem";
import { useBookSkinModal } from "../BookSkin/useBookSkinModal";

interface AppointmentManagementMenuProps {
  item: SkinBookTracking;
  onActionCompleted: () => void;
}

export function AppointmentManagementMenu({
  item,
  onActionCompleted,
}: AppointmentManagementMenuProps) {
  const api = useAPIClient();
  const { handleError } = useReporting();
  const { openBookSkinModal } = useBookSkinModal();

  const handleStatusChange = useAsyncHandle(
    useCallback(
      async (status: APITypesV1.SkinAppointmentStatus) => {
        if (!item.appointmentStartTime) {
          handleError(new Error("No appointment time for this member."));
          return;
        }

        return api.patient
          .sendSkinBookAppointment({
            appointmentStartTime: item.appointmentStartTime.toISO(),
            patientId: item.patientId,
            scanId: item.scanId,
            status,
          })
          .result.then(() => onActionCompleted())
          .catch(handleError);
      },
      [api.patient, handleError, item, onActionCompleted]
    )
  );

  const handleRebook = useCallback(() => {
    openBookSkinModal({
      patientId: item.patientId,
      patientName: item.patientDisplayName,
      scanId: item.scanId,
      isRebook: true,
      onClose: onActionCompleted,
    });
  }, [
    item.patientDisplayName,
    item.patientId,
    item.scanId,
    onActionCompleted,
    openBookSkinModal,
  ]);

  return (
    <DropdownMenuButton header="Manage">
      <DropdownItem onClick={handleRebook}>Rebook</DropdownItem>
      {item.skinAppointmentStatus ===
      APITypesV1.SkinAppointmentStatus.NoShow ? (
        <DropdownItem
          onClick={() =>
            handleStatusChange.callback(
              APITypesV1.SkinAppointmentStatus.CouldNotBook
            )
          }
        >
          Could Not Book
        </DropdownItem>
      ) : (
        <DropdownItem
          onClick={() =>
            handleStatusChange.callback(APITypesV1.SkinAppointmentStatus.NoShow)
          }
        >
          No-show
        </DropdownItem>
      )}
    </DropdownMenuButton>
  );
}
