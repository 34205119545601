import { Sticky } from "@pomle/react-viewstack";
import { ReactNode, useMemo } from "react";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { StudySymbol } from "render/ui/symbol/StudySymbol";
import { Layer } from "../../../../types";
import styles from "./styles.module.sass";

const HIDE = new Set([Layer.LesionLibrary]);
const SHOW_BACKGROUND_GRADIENT = new Set([Layer.Diabetes]);

export function DisclaimerLayout() {
  const { isCurrentVisitInSweden } = usePatientData();

  const { ui } = useDashboardContext();

  const { layer, dermaId, camera } = ui;

  const variant = useMemo(() => {
    if (layer === Layer.Skin && camera) {
      return "dark";
    }

    if (layer === Layer.LesionMacro && dermaId) {
      return "dark";
    }

    return "light";
  }, [layer, dermaId, camera]);

  const disclaimers = useMemo(() => {
    const disclaimers: Record<"primary" | "secondary", ReactNode> = {
      primary: <p>Consult with your medical team before taking action.</p>,
      secondary: null,
    };

    if (ui.layer === Layer.Heart) {
      disclaimers.primary = (
        <>
          <p>
            Metrics marked with <StudySymbol forceShow={true} /> are shown for
            educational purposes only.
          </p>
          <p>Consult with your medical team before taking action.</p>
        </>
      );
    }

    if (isCurrentVisitInSweden) {
      disclaimers.primary = (
        <>
          <p>
            Metrics marked with <StudySymbol forceShow={true} /> are part of our
            clinical studies.
          </p>
          <p>Consult with your medical team before taking action.</p>
        </>
      );
    }

    if (ui.layer === Layer.ArterialHealth) {
      disclaimers.secondary = (
        <p>
          *Paper reference: Hemodynamic Patterns of Age-Related Changes in Blood
          Pressure: The Framingham Heart Study (1997) Stanley S. Franklin et al.
        </p>
      );
    }

    if (ui.layer === Layer.Diabetes) {
      disclaimers.secondary = (
        <p>
          *Paper reference: Effect of Aging on A1C Levels in Individuals Without
          Diabetes: Evidence from the Framingham Offspring Study and the
          National Health and Nutrition Examination Survey 2001–2004 (2008)
          Lydie N. Pani, MD et al. 
        </p>
      );
    }

    return disclaimers;
  }, [isCurrentVisitInSweden, ui.layer]);

  if (isCurrentVisitInSweden == null) {
    return null;
  }

  return (
    <div
      className={styles.DisclaimerLayout}
      data-visible={!HIDE.has(layer)}
      data-variant={variant}
      data-show-background-gradient={SHOW_BACKGROUND_GRADIENT.has(layer)}
    >
      <div className={styles.content}>
        <div
          className={styles.disclaimer}
          key="primary"
          data-visible={!!disclaimers.primary}
        >
          <Sticky delay={300}>
            {!!disclaimers.primary && <>{disclaimers.primary}</>}
          </Sticky>
        </div>
        <div
          className={styles.disclaimer}
          key="secondary"
          data-visible={!!disclaimers.secondary}
        >
          <Sticky delay={300}>
            {!!disclaimers.secondary && <>{disclaimers.secondary}</>}
          </Sticky>
        </div>
      </div>
    </div>
  );
}
