import { RadioButtons } from "render/ui/form/RadioButtons";

const BOOL_TYPES = [
  {
    value: false,
    content: "No",
  },
  {
    value: true,
    content: "Yes",
  },
];

interface SafeguardingRadioInputProps {
  value: boolean | undefined;
  onChange: (value: boolean) => void;
  required?: boolean;
}

export default function SafeguardingRadioInput({
  value,
  onChange,
  required = false,
}: SafeguardingRadioInputProps) {
  return (
    <RadioButtons
      entries={BOOL_TYPES}
      value={value ?? false}
      onChange={onChange}
      required={required}
    />
  );
}
