import { HbA1c } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { useAge } from "render/hooks/patient/useAge";
import { MetricRange } from "render/ui/presentation/MetricRange";
import {
  capToMaxRanges,
  toChartRanges,
} from "render/ui/presentation/RangeChart/lib/risk";

interface HbA1cRangeProps {
  patient: Patient;
  metrics: Metric<"bloodwork.hba1c">[];
}

export function HbA1cRange({ patient, metrics }: HbA1cRangeProps) {
  const age = useAge(patient);

  const riskRange = useMemo(() => {
    return HbA1c.rangesFor({ age });
  }, [age]);

  const entries = useMemo(() => {
    return metrics.map((metric) => {
      return {
        risk: riskRange.findRisk(metric.unit),
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["mmol/mol"],
      };
    });
  }, [metrics, riskRange]);

  const currentValue = entries.at(0)?.value;

  const ranges = useMemo(() => {
    return capToMaxRanges(toChartRanges(riskRange.entries), currentValue);
  }, [riskRange, currentValue]);

  return (
    <MetricRange
      title="Long-term blood sugar"
      subtitle="[HbA1c]"
      unit="mmol/mol"
      entries={entries}
      ranges={ranges}
      formatValue={(value) => value?.toFixed(2)}
    />
  );
}
