import { useCallback } from "react";
import { usePopupDialog } from "render/context/PopupDialogContext";
import { MessagePopup } from "../components/MessagePopup";

export function useMessagePopup() {
  const { create } = usePopupDialog();

  const emitDialog = useCallback(
    (headline: string, msg: string) => {
      const { emit, close } = create();
      emit(<MessagePopup headline={headline} msg={msg} onClose={close} />);
    },
    [create]
  );

  return {
    emitDialog,
  };
}
