import { LineThrobber } from "render/ui/throbber/LineThrobber";
import styles from "./styles.module.sass";

export function BusyPlaceholder() {
  return (
    <div className={styles.BusyPlaceholder}>
      <div className={styles.container}>
        <LineThrobber />
      </div>
    </div>
  );
}
