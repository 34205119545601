import { PaginatedResponse } from "@cur8/api-client";
import { fromAPI } from "@cur8/rich-entity";
import { PrivateJournalNote } from "@cur8/rich-entity/dist/types/PrivateJournalNote";
import { useCallback, useEffect, useState } from "react";
import { FaPen } from "react-icons/fa";
import { useAPIClient } from "render/context/APIContext";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { usePatientCreateNewVersionPrivateJournalNote } from "render/pages/PatientDetailPage/hooks/usePatientCreateNewVersionPrivateJournalNote";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import PrivateJournalNoteAuthor from "../PrivateJournalNoteAuthor/PrivateJournalNoteAuthor";
import styles from "./styles.module.sass";

interface ViewPrivateJournalNoteProps {
  privateJournalNote: PrivateJournalNote;
  onNoteEdited: (note: PrivateJournalNote) => void;
  setSelectedNote: (note: PrivateJournalNote) => void;
}

export default function ViewPrivateJournalNote({
  privateJournalNote,
  onNoteEdited,
  setSelectedNote,
}: ViewPrivateJournalNoteProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedNote, setEditedNote] = useState(privateJournalNote.note);

  const createNewVersionOfPrivateJournalNote =
    usePatientCreateNewVersionPrivateJournalNote();

  const doEditPrivateJournalNoteHandle = useAsyncHandle(() => {
    return createNewVersionOfPrivateJournalNote(
      privateJournalNote.patientId,
      privateJournalNote.id,
      {
        note: editedNote,
      }
    ).then((newNoteVersion) => {
      const formattedNote = fromAPI.toPrivateJournalNote(newNoteVersion);
      setSelectedNote(formattedNote);
      onNoteEdited(formattedNote);
      setIsEditing(false);
      requestVersions({ continuationToken: undefined });
    });
  });

  const [noteVersions, setNoteVersions] = useState<
    PaginatedResponse<PrivateJournalNote>
  >({
    items: [],
    nextPage: undefined,
  });
  const api = useAPIClient();
  const requestVersions = useCallback(
    ({ continuationToken }: { continuationToken?: string }) => {
      const req = api.privateJournalNote.listVersions({
        patientId: privateJournalNote.patientId,
        privateJournalNoteId: privateJournalNote.id,
        pageSize: 10,
        continuationToken,
      });
      req.result.then(({ items, nextPage }) => {
        setNoteVersions((prevVersions) => {
          const itemsMap = new Map(
            [
              ...(prevVersions?.items ?? []),
              ...items.map(fromAPI.toPrivateJournalNote),
            ].map((item) => [item.itemVersion, item])
          );

          const sortedItems = Array.from(itemsMap.values()).sort(
            (a, b) => b.createdAt.toMillis() - a.createdAt.toMillis()
          );

          return {
            nextPage,
            items: sortedItems,
          };
        });
      });

      return req;
    },
    [
      api.privateJournalNote,
      privateJournalNote.id,
      privateJournalNote.patientId,
    ]
  );

  useEffect(() => {
    const req = requestVersions({ continuationToken: undefined });

    return req.abandon;
  }, [requestVersions]);

  return (
    <div className={styles.ViewPrivateJournalNote}>
      <header>
        <h1>Note</h1>
        <p>
          Doctor notes are for Clinic Staff only and never visible to members
        </p>
      </header>
      <div className={styles.noteContent}>
        <div className={styles.noteHeader}>
          <PrivateJournalNoteAuthor
            date={privateJournalNote.createdAt}
            userUri={privateJournalNote.audit.created?.userUri}
            showTimestamp={!isEditing}
          />
          {isEditing ? (
            <span className={styles.editingLabel}>Editing Note</span>
          ) : (
            <button
              className={styles.editButton}
              onClick={() => setIsEditing(true)}
            >
              <FaPen /> Edit
            </button>
          )}
        </div>
        {isEditing ? (
          <textarea
            className={styles.editTextarea}
            value={editedNote}
            onChange={(e) => setEditedNote(e.target.value)}
            rows={10}
          />
        ) : (
          <div className={styles.noteText}>{privateJournalNote.note}</div>
        )}
      </div>
      {isEditing && (
        <div className={styles.footerButtons}>
          <HoverTextButton
            active
            disabled={
              editedNote.trim().length === 0 ||
              doEditPrivateJournalNoteHandle.busy
            }
            onClick={doEditPrivateJournalNoteHandle.callback}
          >
            Save & Sign
          </HoverTextButton>
        </div>
      )}
      {noteVersions?.items && noteVersions.items.length > 1 && (
        <>
          <hr className={styles.separator} />
          <section className={styles.versionHistory}>
            <h3>Version History</h3>
            {noteVersions.items
              .filter(
                (version) =>
                  version.itemVersion !== privateJournalNote.itemVersion
              )
              .map((version) => (
                <div key={version.itemVersion} className={styles.noteContent}>
                  <div className={styles.noteHeader}>
                    <PrivateJournalNoteAuthor
                      date={version.createdAt}
                      userUri={version.audit.created?.userUri}
                      showTimestamp={true}
                    />
                  </div>
                  <div className={styles.noteText}>{version.note}</div>
                </div>
              ))}
            {noteVersions?.nextPage && (
              <button
                className={styles.loadMoreButton}
                onClick={() => {
                  requestVersions({ continuationToken: noteVersions.nextPage });
                }}
              >
                Load more
              </button>
            )}
          </section>
        </>
      )}
    </div>
  );
}
