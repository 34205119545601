import { Children, ReactElement, ReactNode, useState } from "react";
import { Container } from "./components/Container";
import styles from "./styles.module.sass";

interface ItemProps {
  active: boolean;
  children: ReactNode;
}

function Item({ children }: ItemProps) {
  return <>{children}</>;
}

interface ExpandableProps {
  children: ReactElement<{ active: boolean }>[];
}

export function Expandable({ children }: ExpandableProps) {
  const [height, setHeight] = useState(NaN);

  return (
    <div
      className={styles.Expandable}
      style={{ height: isFinite(height) ? height : undefined }}
    >
      {Children.map(children, (child, index) => {
        return (
          <Container
            key={index}
            active={child.props.active}
            onHeight={setHeight}
          >
            {child}
          </Container>
        );
      })}
    </div>
  );
}

Expandable.Item = Item;
