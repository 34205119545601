import { highestRisk } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { resolveSummaries } from "lib/health-risk";
import { useMemo } from "react";
import { useMostRecentMetrics } from "render/hooks/api/metrics/useMostRecentMetrics";
import { Score2 } from "render/hooks/api/metrics/useScore2";
import { useScore2Status } from "render/hooks/api/metrics/useScore2Status";
import { useAge } from "render/hooks/patient/useAge";
import { PatientMetrics } from "render/hooks/patient/usePatientMetrics";
import { MetricStatus } from "render/ui/presentation/MetricStatus";
import { MetricStatusBadge } from "render/ui/presentation/MetricStatusBadge";
import BloodFatsIcon from "./assets/blood-fats.svg?react";

type BloodfatsMetrics = Pick<
  PatientMetrics["bloodwork"],
  "cholesterol" | "cholesterolHDL" | "hdl" | "ldl" | "nonHDL" | "triglycerides"
>;

interface BloodFatsItemProps {
  patient: Patient;
  bloodfats: BloodfatsMetrics;
  score2: Score2 | undefined;
}

export function BloodFatsItem({
  patient,
  bloodfats: { cholesterol, hdl, ldl, nonHDL, triglycerides },
  score2,
}: BloodFatsItemProps) {
  const age = useAge(patient);
  const sex = patient.sex;

  const latest = useMostRecentMetrics({
    cholesterol,
    hdl,
    ldl,
    nonHDL,
    triglycerides,
  });

  const score2Risk = useScore2Status(patient, score2);

  const risk = useMemo(() => {
    if (!latest || !score2Risk) {
      return;
    }

    const summaries = resolveSummaries(latest, { sex, age, score2Risk });

    const risks = summaries.map((s) => s.summary.risk);

    return highestRisk(risks);
  }, [age, sex, score2Risk, latest]);

  return (
    <MetricStatus
      caption="Blood fats"
      summaryContent={risk != null ? <MetricStatusBadge risk={risk} /> : "--"}
    >
      <BloodFatsIcon />
    </MetricStatus>
  );
}
