import { Interval } from "luxon";

interface DateRangeDisplayProps {
  interval?: Interval;
}

export function DateRangeDisplay({ interval }: DateRangeDisplayProps) {
  if (!interval) {
    return null;
  }
  const sameMonth = interval.start.month === interval.end.month;
  const sameYear = interval.start.year === interval.end.year;

  if (!sameYear) {
    return (
      <>{`${interval.start.monthLong} ${interval.start.day}, ${interval.start.year} - ${interval.end.day} ${interval.end.monthLong}, ${interval.end.year}`}</>
    );
  }

  if (!sameMonth) {
    return (
      <>{`${interval.start.monthLong} ${interval.start.day} - ${interval.end.day} ${interval.end.monthLong}, ${interval.end.year}`}</>
    );
  }

  return (
    <>{`${interval.start.monthLong} ${interval.start.day}-${interval.end.day}, ${interval.end.year}`}</>
  );
}
