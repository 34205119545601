import { DateTime } from "luxon";
import { useCallback } from "react";
import { useBookingSchedule } from "./useBookingSchedule";
import { useCalendarState } from "./useCalendarState";

export function useFetchSlots() {
  const { selectedPeriod } = useCalendarState();
  const { fetchSlots: fetchSlotsByRange } = useBookingSchedule();

  const fetchSlots = useCallback(
    async (start: DateTime, end: DateTime) => {
      return await fetchSlotsByRange(start, end);
    },
    [fetchSlotsByRange]
  );

  const refetchCurrentSlots = useCallback(async () => {
    return fetchSlots(selectedPeriod.start, selectedPeriod.end);
  }, [fetchSlots, selectedPeriod]);

  return {
    fetchSlots,
    refetchCurrentSlots,
  };
}
