import { BrachialPressure, Risk } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { Metric } from "lib/metric";
import { bySystolic } from "lib/metric/sort";
import { useMemo } from "react";
import { useAge } from "render/hooks/patient/useAge";
import { RISK_COLORS } from "render/risk";
import { FloatingValueTarget } from "render/ui/presentation/FloatingValueTarget";
import { ValueItem } from "render/ui/symbol/ValueItem";
import { BloodPressure } from "../components/BloodPressure";

interface MaxArmBloodpressureProps {
  brachialPressure: {
    left: Metric<"cardio.brachial_pressure.left">[] | undefined;
    right: Metric<"cardio.brachial_pressure.right">[] | undefined;
  };
  patient: Patient;
}

export function MaxArmBloodpressure({
  brachialPressure: { left, right },
  patient,
}: MaxArmBloodpressureProps) {
  const age = useAge(patient);

  const highestPressure = useMemo(() => {
    if (!left || !right) {
      return;
    }

    return [...left.slice(0, 1), ...right.slice(0, 1)]
      .sort(bySystolic("desc"))
      .at(0);
  }, [left, right]);

  const risk = useMemo(() => {
    if (highestPressure) {
      const { systolic, diastolic } = highestPressure.unit;
      return BrachialPressure.riskFor({ systolic, diastolic, age });
    }
  }, [highestPressure, age]);

  const color = RISK_COLORS[risk ?? Risk.Unknown];

  return (
    <FloatingValueTarget
      label="Arm Pressure"
      value={
        <ValueItem
          value={
            highestPressure ? (
              <BloodPressure brachial={highestPressure.unit} />
            ) : (
              "--"
            )
          }
          size="huge"
        />
      }
      align="right"
      color={color}
      width={150}
    />
  );
}
