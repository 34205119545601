import { Patient, Sex } from "@cur8/rich-entity";
import { clamp } from "lib/math";
import { Metric } from "lib/metric";
import { useAge } from "render/hooks/patient/useAge";
import { getAgeBinForAge } from "render/pages/DashboardPage/lib/projection/age";
import { Typography } from "render/ui/presentation/Typography";
import { getBenchmarkMedianFor } from "../../lib/projection";
import { concatPercent } from "../../lib/util";
import {
  getBenchmarkValueClampLimitsForResultDiff,
  getMemberValueClampLimitsForResultDiff,
  normalizePercentageDifference,
  toFixedIfNecessary,
} from "./lib/chart";
import styles from "./styles.module.sass";

interface HbA1cBenchmarkProps {
  patient: Patient;
  metrics: Metric<"bloodwork.hba1c">[];
}

const SexToGenderBinTrack: Record<Sex, string> = {
  [Sex.Female]: "female",
  [Sex.Male]: "male",
  [Sex.Unknown]: "",
};

const round = (value: number) => Math.round(value * 2) / 2;

export function HbA1cBenchmark({ patient, metrics }: HbA1cBenchmarkProps) {
  const patientAge = useAge(patient);
  const ageBin = getAgeBinForAge(patientAge);

  const currentScan = metrics[0];
  const currentValue = currentScan?.unit["mmol/mol"];

  const benchmarkMedianValue = round(
    getBenchmarkMedianFor(patientAge, patient.sex)
  );

  const isAboveTheBenchmarkValue = currentValue > benchmarkMedianValue;
  const highlight = isAboveTheBenchmarkValue ? "warning" : "better";

  const yourResultDiff = currentValue - benchmarkMedianValue;

  const clampValuesForBenchmarkValue =
    getBenchmarkValueClampLimitsForResultDiff(yourResultDiff);
  const clampValuesForMembeerkValue =
    getMemberValueClampLimitsForResultDiff(yourResultDiff);

  const totalHeight = Math.max(benchmarkMedianValue, currentValue);
  const heightMemberValue = clamp(
    normalizePercentageDifference((currentValue / totalHeight) * 100),
    clampValuesForMembeerkValue.from,
    clampValuesForMembeerkValue.to
  );
  const heightBenchmarkValue = clamp(
    normalizePercentageDifference((benchmarkMedianValue / totalHeight) * 100),
    clampValuesForBenchmarkValue.from,
    clampValuesForBenchmarkValue.to
  );

  return (
    <div className={styles.HbA1cBenchmark}>
      <div className={styles.chart}>
        <div className={styles.content}>
          <div
            className={styles.benchmark}
            style={{
              height: concatPercent(heightBenchmarkValue),
            }}
          >
            <div className={styles.benchmarkDescription}>
              <div className={styles.type}>
                <Typography as="span" variant="label-m">
                  Typical Neko member{" "}
                </Typography>
                <Typography
                  as="span"
                  variant="body-m"
                  className={styles.subtype}
                >
                  [{SexToGenderBinTrack[patient.sex]} {ageBin} years]
                </Typography>
              </div>
              <div className={styles.valueContainer}>
                <div className={styles.value}>
                  <Typography variant="numeral-s">
                    {toFixedIfNecessary(benchmarkMedianValue)}
                  </Typography>
                </div>
                <div className={styles.unit}>
                  <Typography variant="label-m">mmol/mol</Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div
            className={styles.benchmark}
            data-highlight={highlight}
            style={{
              height: concatPercent(heightMemberValue),
            }}
          >
            <div className={styles.benchmarkDescription}>
              <div className={styles.type}>
                <Typography as="span" variant="label-m">
                  Your result
                </Typography>
              </div>
              <div className={styles.valueContainer}>
                <div className={styles.value}>
                  <Typography variant="numeral-s">
                    {toFixedIfNecessary(currentValue)}
                  </Typography>
                </div>
                <div className={styles.unit}>
                  <Typography variant="label-m">mmol/mol</Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
