import { APITypesV2 } from "@cur8/api-client";
import { Patient } from "@cur8/rich-entity";
import { convertQuestionnaire } from "lib/questionnaire/convert";
import { AdditionalWorries } from "render/fragments/metric/patient-details/AdditionalWorries";
import { Age } from "render/fragments/metric/patient-details/Age";
import { AlcoholFrequency } from "render/fragments/metric/patient-details/AlcoholFrequency";
import { Allergies } from "render/fragments/metric/patient-details/Allergies";
import { Diet } from "render/fragments/metric/patient-details/Diet";
import { ExerciseFrequency } from "render/fragments/metric/patient-details/ExerciseFrequency";
import { HealthConditions } from "render/fragments/metric/patient-details/HealthConditions";
import { HealthConditionsFamily } from "render/fragments/metric/patient-details/HealthConditionsFamily";
import { Medications } from "render/fragments/metric/patient-details/Medications";
import { SmokingFrequency } from "render/fragments/metric/patient-details/SmokingFrequency";
import { SnusFrequency } from "render/fragments/metric/patient-details/SnusFrequency";
import { UndefinedData } from "render/fragments/metric/patient-details/UndefinedData";
import { useAge } from "render/hooks/patient/useAge";
import { LocaleLanguage } from "render/ui/format/LocaleLanguage";
import { SexDefinition } from "render/ui/format/SexDefinition";
import { Table } from "render/ui/layouts/Table";

interface QuestionnaireOverviewV1Props {
  patient?: Patient;
  questionnaireResponse?: APITypesV2.QuestionnaireResponse;
}

export function QuestionnaireOverviewV1({
  patient,
  questionnaireResponse,
}: QuestionnaireOverviewV1Props) {
  const age = useAge(patient);
  const answers = convertQuestionnaire(questionnaireResponse);

  return (
    <Table>
      <thead>
        <tr>
          <th>Question</th>
          <th>Answer</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Age</td>
          <td>{age ? <Age value={age} /> : <UndefinedData />} </td>
        </tr>
        <tr>
          <td>Sex</td>
          <td>{patient && <SexDefinition sex={patient.sex} />}</td>
        </tr>
        <tr>
          <td>Language</td>
          <td>
            {patient?.preferredLanguage ? (
              <LocaleLanguage locale={patient.preferredLanguage} />
            ) : (
              <UndefinedData />
            )}
          </td>
        </tr>
        <tr>
          <td>Medications</td>
          <td>
            {answers?.medications ? (
              <Medications value={answers.medications} />
            ) : (
              <UndefinedData />
            )}
          </td>
        </tr>
        <tr>
          <td>Allergies</td>
          <td>
            {answers?.allergies ? (
              <Allergies value={answers.allergies} />
            ) : (
              <UndefinedData />
            )}
          </td>
        </tr>
        <tr>
          <td>Diet</td>
          <td>
            {answers?.diet ? <Diet value={answers.diet} /> : <UndefinedData />}
          </td>
        </tr>
        <tr>
          <td>Exercises</td>
          <td>
            {answers?.v1.exerciseHoursPerWeek ? (
              <ExerciseFrequency
                value={Number(answers?.v1.exerciseHoursPerWeek)}
              />
            ) : (
              <UndefinedData />
            )}
          </td>
        </tr>
        <tr>
          <td>Alcohol</td>
          <td>
            {answers?.alcohol ? (
              <AlcoholFrequency value={answers.alcohol} />
            ) : (
              <UndefinedData />
            )}
          </td>
        </tr>
        <tr>
          <td>Smoking</td>
          <td>
            <Smoking
              dailyCigarettes={answers?.smokingDailyCigarettes}
              isSmoker={answers?.isSmoker}
            />
          </td>
        </tr>
        <tr>
          <td>Snus</td>
          <td>
            {answers?.v1.snusCansPerWeek ? (
              <SnusFrequency value={answers.v1.snusCansPerWeek} />
            ) : (
              <UndefinedData />
            )}
          </td>
        </tr>
        <tr>
          <td>Questions or concerns</td>
          <td>
            {answers?.additionalWorries ? (
              <AdditionalWorries value={answers.additionalWorries} />
            ) : (
              <UndefinedData />
            )}
          </td>
        </tr>
        <tr>
          <td>Health conditions in the last year</td>
          <td>
            {answers?.healthConditions ? (
              <HealthConditions value={answers.healthConditions} />
            ) : (
              <UndefinedData />
            )}
          </td>
        </tr>
        <tr>
          <td>Family health conditions</td>
          <td>
            {answers?.v1.healthConditionsFamily ? (
              <HealthConditionsFamily
                value={answers.v1.healthConditionsFamily}
              />
            ) : (
              <UndefinedData />
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

type SmokingProps = {
  dailyCigarettes: string | undefined;
  isSmoker: boolean | undefined;
};

function Smoking({ dailyCigarettes, isSmoker }: SmokingProps) {
  if (isSmoker == null) {
    return <UndefinedData />;
  }
  if (isSmoker === false) {
    return <>No</>;
  }
  if (dailyCigarettes) {
    return <SmokingFrequency value={Number(dailyCigarettes) || 0} />;
  }
  return <>Yes</>;
}
