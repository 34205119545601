import { DateTime, Duration, Interval } from "luxon";
import { useCallback } from "react";
import { useCalendarInteraction } from "../components/Calendar/hooks/useCalendarInteraction";
import {
  useCalendarState,
  ViewRangeMode,
} from "../components/Calendar/hooks/useCalendarState";

export function usePeriodSelector() {
  const { setSelectedPeriod } = useCalendarInteraction();
  const { selectedPeriod } = useCalendarState();

  const durationJumper = useCallback(
    (duration: Duration, direction: "forward" | "backwards") => {
      const start =
        direction === "forward"
          ? selectedPeriod.start.plus(duration)
          : selectedPeriod.start.minus(duration);
      const end =
        direction === "forward"
          ? selectedPeriod.end.plus(duration)
          : selectedPeriod.end.minus(duration);

      const weekPeriod = Interval.fromDateTimes(start, end);
      setSelectedPeriod(weekPeriod);
    },
    [selectedPeriod.start, selectedPeriod.end, setSelectedPeriod]
  );

  const setPeriod = useCallback(
    (start: DateTime, end: DateTime) => {
      setSelectedPeriod(Interval.fromDateTimes(start, end));
    },
    [setSelectedPeriod]
  );

  const weekJumper = useCallback(
    (fwd: boolean = true) => {
      const duration = Duration.fromDurationLike({ weeks: 1 });
      durationJumper(duration, fwd ? "forward" : "backwards");
    },
    [durationJumper]
  );

  const dayJumper = useCallback(
    (fwd: boolean = true) => {
      const duration = Duration.fromDurationLike({ days: 1 });
      durationJumper(duration, fwd ? "forward" : "backwards");
    },
    [durationJumper]
  );

  const navigateToNextDay = useCallback(() => {
    dayJumper();
  }, [dayJumper]);
  const navigateToPrevDay = useCallback(() => {
    dayJumper(false);
  }, [dayJumper]);

  const navigateToNextWeek = useCallback(() => {
    weekJumper();
  }, [weekJumper]);

  const navigateToPreviousWeek = useCallback(() => {
    weekJumper(false);
  }, [weekJumper]);

  const monthJumper = useCallback(
    (months: number) => {
      const dt = DateTime.now().plus({ months: months }).startOf("week");
      const weekPeriod = Interval.fromDateTimes(
        dt,
        dt.endOf("week").endOf("day")
      );
      setSelectedPeriod(weekPeriod);
    },
    [setSelectedPeriod]
  );

  const goToToday = useCallback(
    (viewRange: ViewRangeMode) => {
      const now = DateTime.now();
      let period: Interval;

      switch (viewRange) {
        case ViewRangeMode.Daily:
          period = Interval.fromDateTimes(now.startOf("day"), now.endOf("day"));
          break;
        case ViewRangeMode.Weekly:
        default:
          period = Interval.fromDateTimes(
            now.startOf("week"),
            now.endOf("week")
          );
          break;
      }

      setSelectedPeriod(period);
      return;
    },
    [setSelectedPeriod]
  );

  return {
    setPeriod,
    monthJumper,
    goToToday,
    navigateToNextDay,
    navigateToPrevDay,
    navigateToPreviousWeek,
    navigateToNextWeek,
    selectedPeriod,
  };
}
