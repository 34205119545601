import { classNames } from "@pomle/classnames";
import { IconProps } from ".";
import styles from "./styles.module.sass";

export function EnlargeShrinkIcon({ active, color }: IconProps) {
  return (
    <svg
      className={classNames(styles.Common, styles.EnlargeShrinkIcon)}
      data-active={active ? "yes" : "no"}
      data-color={color}
      fill="none"
      height="40"
      width="40"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="19" />
      <path
        data-class="enlarge"
        strokeWidth="1.5"
        d="m18.31 21.69-6.75 6.75m16.88-11.82v-5.06h-5.07M11.56 23.37v5.07h5.06m11.82-16.88-6.75 6.75"
      />
      <path
        data-class="shrink"
        strokeWidth="1.5"
        d="m28.44 11.56-6.75 6.75m-3.38 8.44v-5.06h-5.06m8.44-8.44v5.06h5.06m-8.44 3.38-6.75 6.75"
      />
    </svg>
  );
}
