import { ButtonHTMLAttributes } from "react";
import style from "./styles.module.sass";

interface HoverTextButtonProps extends ButtonHTMLAttributes<any> {
  active?: boolean;
  variant?: "primary" | "danger";
}

export function HoverTextButton({
  children,
  active = false,
  variant = "primary",
  ...props
}: HoverTextButtonProps) {
  return (
    <button
      className={style.HoverTextButton}
      type="button"
      data-active={active}
      data-variant={variant}
      {...props}
    >
      {children}
    </button>
  );
}
