import { createMetric } from "lib/metric";
import { RiskAssessment } from "lib/types/metrics/risk-assessment";
import { useCallback, useEffect, useState } from "react";
import { useRiskAssessmentStore } from "render/hooks/api/metrics/useRiskAssessmentStore";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { useReporting } from "render/hooks/useReporting";
import { Field } from "render/ui/form/Field/Field";
import { Fieldset } from "render/ui/form/Fieldset";
import { RadioButtons } from "render/ui/form/RadioButtons";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { ButtonSet } from "render/ui/trigger/ButtonSet";
import styles from "./styles.module.sass";

const BipolarResponse = [
  { value: false, content: "No" },
  { value: true, content: "Yes" },
];

interface RiskSectionProps {
  patientId: string;
}

export function RiskSection({ patientId }: RiskSectionProps) {
  const { logError } = useReporting();

  const { fetchRiskAssessment, storeRiskAssessment } = useRiskAssessmentStore();

  const [risk, setRisk] = useState<RiskAssessment>();
  const [showDiabetesSubField, setShowDiabetesSubField] = useState(false);
  const [showValvularSubField, setShowValvularSubField] = useState(false);
  const [showDermaSubField, setShowDermaSubField] = useState(false);

  const mergeRisk = useCallback((values: RiskAssessment) => {
    setRisk((risk) => ({ ...risk, ...values }));
  }, []);

  useEffect(() => {
    const loadRisk = async () => {
      const risk = await fetchRiskAssessment(patientId);
      setRisk(risk);
      setShowDermaSubField(!!risk?.dermatologicalCondition?.unit);
    };

    loadRisk();

    return () => {
      setRisk(undefined);
    };
  }, [fetchRiskAssessment, patientId]);

  const handleSave = useCallback(async () => {
    if (!risk) {
      return;
    }

    return storeRiskAssessment(patientId, risk).catch(logError);
  }, [patientId, risk, logError, storeRiskAssessment]);

  const saveHandle = useAsyncHandle(handleSave);

  const isReady = !!risk;
  return (
    <form className={styles.RiskSection}>
      <section>
        <Fieldset legend="Medications">
          <Field label="Medication Usage Predisposing To Bleeding">
            <RadioButtons
              disabled={!isReady}
              entries={BipolarResponse}
              value={risk?.drugInducedBleeding?.unit}
              onChange={(value) =>
                mergeRisk({
                  drugInducedBleeding: createMetric(
                    "risk_assessment.drug-induced_bleeding",
                    value
                  ),
                })
              }
            />
          </Field>
        </Fieldset>
      </section>

      <section>
        <Fieldset legend="Recent surgical procedures">
          <Field label="Eye surgery">
            <RadioButtons
              disabled={!isReady}
              entries={BipolarResponse}
              value={risk?.eyeSurgery?.unit}
              onChange={(value) =>
                mergeRisk({
                  eyeSurgery: createMetric(
                    "risk_assessment.recent_eye_surgery",
                    value
                  ),
                })
              }
            />
          </Field>
        </Fieldset>
      </section>

      <section>
        <Fieldset legend="Cardiovascular Diseases">
          <Field label="Familial Hypercholesterolemia">
            <RadioButtons
              disabled={!isReady}
              entries={BipolarResponse}
              value={risk?.familialHypercholesterolemia?.unit}
              onChange={(value) =>
                mergeRisk({
                  familialHypercholesterolemia: createMetric(
                    "risk_assessment.familial_hypercholesterolemia",
                    value
                  ),
                })
              }
            />
          </Field>
          <Field label="Hypertension">
            <RadioButtons
              disabled={!isReady}
              entries={BipolarResponse}
              value={risk?.hypertensionHistory?.unit}
              onChange={(value) =>
                mergeRisk({
                  hypertensionHistory: createMetric(
                    "risk_assessment.hypertension_history",
                    value
                  ),
                })
              }
            />
          </Field>

          <Field label="Congestive Heart Failure">
            <RadioButtons
              disabled={!isReady}
              entries={BipolarResponse}
              value={risk?.congestiveHeartFailureHistory?.unit}
              onChange={(value) =>
                mergeRisk({
                  congestiveHeartFailureHistory: createMetric(
                    "risk_assessment.congestive_heart_failure_history",
                    value
                  ),
                })
              }
            />
          </Field>

          <Field label="Stroke">
            <RadioButtons
              disabled={!isReady}
              entries={BipolarResponse}
              value={risk?.strokeHistory?.unit}
              onChange={(value) =>
                mergeRisk({
                  strokeHistory: createMetric(
                    "risk_assessment.stroke_history",
                    value
                  ),
                })
              }
            />
          </Field>
          <Field label="Valvular Disease">
            <RadioButtons
              disabled={!isReady}
              entries={BipolarResponse}
              value={risk?.valvularDisease?.unit}
              onChange={(value) => {
                if (value !== undefined) {
                  setShowValvularSubField(value);
                }
                mergeRisk({
                  valvularDisease: createMetric(
                    "risk_assessment.valvular_disease",
                    value
                  ),
                });
              }}
            />
          </Field>

          {showValvularSubField && (
            <Field label="Aortic Valve Disease">
              <RadioButtons
                disabled={!isReady}
                entries={BipolarResponse}
                value={risk?.aorticValveDisease?.unit}
                onChange={(value) => {
                  mergeRisk({
                    aorticValveDisease: createMetric(
                      "risk_assessment.aortic_valve_disease",
                      value
                    ),
                  });
                }}
              />
            </Field>
          )}
          {showValvularSubField && (
            <Field label="Aorta Stenosis">
              <RadioButtons
                disabled={!isReady}
                entries={BipolarResponse}
                value={risk?.aortaStenosis?.unit}
                onChange={(value) =>
                  mergeRisk({
                    aortaStenosis: createMetric(
                      "risk_assessment.aorta_stenosis",
                      value
                    ),
                  })
                }
              />
            </Field>
          )}
          {showValvularSubField && (
            <Field label="Aorta Insufficiency">
              <RadioButtons
                disabled={!isReady}
                entries={BipolarResponse}
                value={risk?.aortaInsufficiency?.unit}
                onChange={(value) =>
                  mergeRisk({
                    aortaInsufficiency: createMetric(
                      "risk_assessment.aorta_insufficiency",
                      value
                    ),
                  })
                }
              />
            </Field>
          )}
          {showValvularSubField && (
            <Field label="Mitral Valve Disease">
              <RadioButtons
                disabled={!isReady}
                entries={BipolarResponse}
                value={risk?.mitralValveDisease?.unit}
                onChange={(value) => {
                  mergeRisk({
                    mitralValveDisease: createMetric(
                      "risk_assessment.mitral_valve_disease",
                      value
                    ),
                  });
                }}
              />
            </Field>
          )}
          {showValvularSubField && (
            <Field label="Mitral Valve Stenosis">
              <RadioButtons
                disabled={!isReady}
                entries={BipolarResponse}
                value={risk?.mitralValveStenosis?.unit}
                onChange={(value) =>
                  mergeRisk({
                    mitralValveStenosis: createMetric(
                      "risk_assessment.mitral_valve_stenosis",
                      value
                    ),
                  })
                }
              />
            </Field>
          )}
          {showValvularSubField && (
            <Field label="Mitral Valve Insufficiency">
              <RadioButtons
                disabled={!isReady}
                entries={BipolarResponse}
                value={risk?.mitralValveInsufficiency?.unit}
                onChange={(value) =>
                  mergeRisk({
                    mitralValveInsufficiency: createMetric(
                      "risk_assessment.mitral_valve_insufficiency",
                      value
                    ),
                  })
                }
              />
            </Field>
          )}
        </Fieldset>
      </section>
      {/* --------------------------------------------------------- */}
      <section>
        <Fieldset legend="Endocrine Disorders">
          <Field label="Diabetes Latent (Prediabetes)">
            <RadioButtons
              disabled={!isReady}
              entries={BipolarResponse}
              value={risk?.preDiabetes?.unit}
              onChange={(value) =>
                mergeRisk({
                  preDiabetes: createMetric(
                    "risk_assessment.pre_diabetes",
                    value
                  ),
                })
              }
            />
          </Field>
          <Field label="Diabetes">
            <RadioButtons
              disabled={!isReady}
              entries={BipolarResponse}
              value={risk?.diabetesHistory?.unit}
              onChange={(value) => {
                if (value !== undefined) {
                  setShowDiabetesSubField(value);
                }
                mergeRisk({
                  diabetesHistory: createMetric(
                    "risk_assessment.diabetes_history",
                    value
                  ),
                });
              }}
            />
          </Field>
          {showDiabetesSubField && (
            <Field label="Diabetes Type">
              <RadioButtons
                disabled={!isReady}
                entries={[
                  { value: true, content: "Type I" },
                  { value: false, content: "Type II" },
                ]}
                value={risk?.diabetesTypeOne?.unit}
                onChange={(value) => {
                  mergeRisk({
                    diabetesTypeOne: createMetric(
                      "risk_assessment.diabetes_type_one",
                      value
                    ),
                  });
                }}
              />
            </Field>
          )}
          {showDiabetesSubField && (
            <Field label="Diabetes With Complications">
              <RadioButtons
                disabled={!isReady}
                entries={BipolarResponse}
                value={risk?.diabetesWithComplications?.unit}
                onChange={(value) =>
                  mergeRisk({
                    diabetesWithComplications: createMetric(
                      "risk_assessment.diabetes_with_complications",
                      value
                    ),
                  })
                }
              />
            </Field>
          )}
        </Fieldset>
      </section>
      {/* ------------------- Dermatological questions ------------------- */}
      <section>
        <Fieldset legend="Dermatological Disorders">
          <Field label="Dermatological Condition & Skin Cancer">
            <RadioButtons
              entries={BipolarResponse}
              disabled={!isReady}
              value={risk?.dermatologicalCondition?.unit}
              onChange={(value) => {
                if (value !== undefined) {
                  setShowDermaSubField(value);
                }
                mergeRisk({
                  dermatologicalCondition: createMetric(
                    "risk_assessment.dermatological_condition",
                    value
                  ),
                });
              }}
            />
          </Field>
          {showDermaSubField && (
            <Field label="Skin Cancer">
              <RadioButtons
                disabled={!isReady}
                entries={BipolarResponse}
                value={risk?.skinCancer?.unit}
                onChange={(value) => {
                  mergeRisk({
                    skinCancer: createMetric(
                      "risk_assessment.skin_cancer",
                      value
                    ),
                  });
                }}
              />
            </Field>
          )}
          {showDermaSubField && (
            <Field label="Cutaneous Melanoma">
              <RadioButtons
                disabled={!isReady}
                entries={BipolarResponse}
                value={risk?.cutaneousMelanoma?.unit}
                onChange={(value) => {
                  mergeRisk({
                    cutaneousMelanoma: createMetric(
                      "risk_assessment.cutaneous_melanoma",
                      value
                    ),
                  });
                }}
              />
            </Field>
          )}
          {showDermaSubField && (
            <Field label="Basal Cell Carinoma">
              <RadioButtons
                disabled={!isReady}
                entries={BipolarResponse}
                value={risk?.basalCellCarinoma?.unit}
                onChange={(value) => {
                  mergeRisk({
                    basalCellCarinoma: createMetric(
                      "risk_assessment.basal_cell_carinoma",
                      value
                    ),
                  });
                }}
              />
            </Field>
          )}
          {showDermaSubField && (
            <Field label="Squamous Cell Carinoma">
              <RadioButtons
                disabled={!isReady}
                entries={BipolarResponse}
                value={risk?.squamousCellCarcinoma?.unit}
                onChange={(value) => {
                  mergeRisk({
                    squamousCellCarcinoma: createMetric(
                      "risk_assessment.squamous_cell_carcinoma",
                      value
                    ),
                  });
                }}
              />
            </Field>
          )}
          {showDermaSubField && (
            <Field label="Acne Vulgaris">
              <RadioButtons
                disabled={!isReady}
                entries={BipolarResponse}
                value={risk?.acneVulgaris?.unit}
                onChange={(value) => {
                  mergeRisk({
                    acneVulgaris: createMetric(
                      "risk_assessment.acne_vulgaris",
                      value
                    ),
                  });
                }}
              />
            </Field>
          )}
          {showDermaSubField && (
            <Field label="Atopic Dermatitis">
              <RadioButtons
                disabled={!isReady}
                entries={BipolarResponse}
                value={risk?.atopicDermatitis?.unit}
                onChange={(value) => {
                  mergeRisk({
                    atopicDermatitis: createMetric(
                      "risk_assessment.atopic_dermatitis",
                      value
                    ),
                  });
                }}
              />
            </Field>
          )}
          {showDermaSubField && (
            <Field label="Psoriasis">
              <RadioButtons
                disabled={!isReady}
                entries={BipolarResponse}
                value={risk?.psoriasis?.unit}
                onChange={(value) => {
                  mergeRisk({
                    psoriasis: createMetric("risk_assessment.psoriasis", value),
                  });
                }}
              />
            </Field>
          )}
          {showDermaSubField && (
            <Field label="Rosacea">
              <RadioButtons
                disabled={!isReady}
                entries={BipolarResponse}
                value={risk?.rosacea?.unit}
                onChange={(value) => {
                  mergeRisk({
                    rosacea: createMetric("risk_assessment.rosacea", value),
                  });
                }}
              />
            </Field>
          )}
        </Fieldset>
      </section>
      <Field label="Melanoma in the family?">
        <RadioButtons
          disabled={!isReady}
          entries={BipolarResponse}
          value={risk?.cutaneousMelanomaFamilyHistory?.unit}
          onChange={(value) => {
            mergeRisk({
              cutaneousMelanomaFamilyHistory: createMetric(
                "risk_assessment.cutaneous_melanoma_family_history",
                value
              ),
            });
          }}
        />
      </Field>
      {/* --------------------------------------------------------- */}
      <section>
        <Fieldset legend="Vascular Disorders">
          <Field label="Peripheral Vascular Disease">
            <RadioButtons
              disabled={!isReady}
              entries={BipolarResponse}
              value={risk?.peripheralVascularDisease?.unit}
              onChange={(value) =>
                mergeRisk({
                  peripheralVascularDisease: createMetric(
                    "risk_assessment.peripheral_vascular_disease",
                    value
                  ),
                })
              }
            />
          </Field>
          <Field label="Raynauds Syndrome">
            <RadioButtons
              disabled={!isReady}
              entries={BipolarResponse}
              value={risk?.raynauds?.unit}
              onChange={(value) =>
                mergeRisk({
                  raynauds: createMetric("risk_assessment.raynauds", value),
                })
              }
            />
          </Field>
        </Fieldset>
      </section>
      <section>
        <Fieldset legend="Musculoskeletal Disorders">
          <Field label="Arthritis">
            <RadioButtons
              disabled={!isReady}
              entries={BipolarResponse}
              value={risk?.arthritis?.unit}
              onChange={(value) =>
                mergeRisk({
                  arthritis: createMetric("risk_assessment.arthritis", value),
                })
              }
            />
          </Field>
        </Fieldset>
      </section>
      <section>
        <Fieldset legend="Kidney Disorders">
          <Field label="Kidney Disease">
            <RadioButtons
              disabled={!isReady}
              entries={BipolarResponse}
              value={risk?.kidneyDisease?.unit}
              onChange={(value) =>
                mergeRisk({
                  kidneyDisease: createMetric(
                    "risk_assessment.kidney_disease",
                    value
                  ),
                })
              }
            />
          </Field>
        </Fieldset>
      </section>

      <section>
        <ButtonSet>
          <ActionButton handle={saveHandle}>Save</ActionButton>
        </ButtonSet>
      </section>
    </form>
  );
}
