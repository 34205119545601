import { Assignment, fromAPI } from "@cur8/rich-entity";
import { useCallback, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { useUserInput } from "render/hooks/useUserInput";
import { Input } from "render/ui/form/Input";
import { PopupDialog } from "render/ui/layouts/PopupDialog";
import { PopupDialogContent } from "render/ui/layouts/PopupDialogContent/PopupDialogContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import { toTerminateAssignmentRequest } from "./convert";
import styles from "./styles.module.sass";

type Entries = {
  note: string;
};

const INITIAL: Entries = {
  note: "",
};

interface TerminateAssignmentDialogProps {
  assignment: Assignment;
  onSuccess: (assignment: Assignment) => void;
  onClose(): void;
}

export function TerminateAssignmentDialog({
  assignment,
  onClose,
  onSuccess,
}: TerminateAssignmentDialogProps) {
  const api = useAPIClient();

  const [entries, setEntries] = useState(INITIAL);

  const handleComplete = useCallback(async () => {
    const payload = toTerminateAssignmentRequest(assignment, entries.note);

    const result = await api.assignment.updateAssignmentInfoFor(
      assignment.patientId,
      assignment.id,
      payload
    ).result;

    onSuccess(fromAPI.toAssignment(result));
  }, [api, assignment, onSuccess, entries]);

  const userInput = useUserInput(entries, setEntries);

  const completeHandle = useAsyncHandle(handleComplete);

  return (
    <PopupDialog>
      <PopupDialogContent
        head={<h2>Abandon the assignment?</h2>}
        body={
          <div className={styles.AbandonAssignmentDialog}>
            <div className={styles.form}>
              <p>
                Are you sure? After abandoning, the member cannot upload any
                more data. This cannot be undone.
              </p>
              <p>
                Please write a doctor’s note for the member in order to abandon
                the assignment.
              </p>
              <Input>
                <textarea placeholder="Doctor's note" {...userInput.note} />
              </Input>
            </div>

            <div className={styles.author}></div>
          </div>
        }
        buttons={
          <>
            <HoverTextButton type="button" onClick={onClose}>
              No cancel
            </HoverTextButton>
            <ActionButton handle={completeHandle}>Yes, abandon</ActionButton>
          </>
        }
      />
    </PopupDialog>
  );
}
