import { useMemo } from "react";
import { ArtefactHistoryEntry } from "../../history";
import { ArtefactHistoryEvent } from "./components/ArtefactHistoryEvent";
import { FlipBookCards } from "./components/FlipBookCards";
import { ImageStep } from "./components/ImageStep";
import styles from "./styles.module.sass";

interface ImageHistoryProps {
  entries: ArtefactHistoryEntry[];
  selectedEntry?: ArtefactHistoryEntry;
  onSelectEntry: (entry: ArtefactHistoryEntry) => void;
  onUpdate: () => void;
}

export function ImageHistory({
  entries,
  selectedEntry,
  onSelectEntry,
  onUpdate,
}: ImageHistoryProps) {
  const activeIndex = useMemo(() => {
    if (selectedEntry) {
      const index = entries.indexOf(selectedEntry);
      if (index > -1) {
        return index;
      }
    }

    return 0;
  }, [entries, selectedEntry]);

  return (
    <div className={styles.ImageHistory}>
      <div className={styles.images}>
        <FlipBookCards activeIndex={activeIndex} depth={3}>
          {entries.map((entry) => {
            return (
              <ArtefactHistoryEvent
                key={entry.image.id}
                entry={entry}
                onUpdate={onUpdate}
              />
            );
          })}
        </FlipBookCards>
      </div>

      <div className={styles.control}>
        <ImageStep
          items={entries}
          selected={selectedEntry}
          onChange={onSelectEntry}
        />
      </div>
    </div>
  );
}
