import { convertQuestionnaire } from "lib/questionnaire/convert";
import { Country, getCountryFromVisit, getLatestVisit } from "lib/visit/visit";
import { createContext, ReactNode, useContext, useMemo } from "react";
import { useABIScore } from "render/hooks/api/metrics/useABIScore";
import { useBMIScore } from "render/hooks/api/metrics/useBMIScore";
import { useScore2 } from "render/hooks/api/metrics/useScore2";
import { useTBIScore } from "render/hooks/api/metrics/useTBIScore";
import { usePatient } from "render/hooks/api/usePatient";
import { usePatientImmutableScans } from "render/hooks/api/usePatientImmutableScans";
import { usePatientSMPLMetrics } from "render/hooks/api/usePatientSMPLMetrics";
import { useQuestionnaire } from "render/hooks/api/useQuestionnaire";
import { useVisits } from "render/hooks/api/useVisits";
import { usePatientMetrics } from "render/hooks/patient/usePatientMetrics";
import { useLiveTime } from "render/hooks/useLiveTime";
import { useLesions } from "./hooks/lesions";

function useDashboardData(patientId: string) {
  const now = useLiveTime("minute");

  const patient = usePatient(patientId);

  const scans = usePatientImmutableScans(patientId);

  const metrics = usePatientMetrics(patientId);
  const { data } = useQuestionnaire({ patientId, visitId: undefined });
  const lifestyle = convertQuestionnaire(data);

  const smplResult = usePatientSMPLMetrics(scans?.skin);

  const aggregates = {
    score2: useScore2({
      patient,
      date: now,
      brachial: metrics.cardio.brachialPressure,
      isSmoker: lifestyle?.isSmoker,
      nonHDL: metrics.bloodwork.nonHDL,
    }),

    bmi: useBMIScore(metrics.body),

    abi: {
      left: useABIScore("left", metrics.cardio),
      right: useABIScore("right", metrics.cardio),
    },

    tbi: useTBIScore(metrics.cardio),
  };

  const lesionsResult = useLesions(scans?.skin);
  const visits = useVisits(patientId);

  const country = useMemo(() => {
    if (!visits) {
      return undefined;
    }
    const latestVisit = getLatestVisit(visits);
    if (!latestVisit) {
      return Country.Unknown;
    }

    return getCountryFromVisit(latestVisit);
  }, [visits]);

  const isCurrentVisitInSweden = useMemo(() => {
    return country === Country.Sweden;
  }, [country]);

  return {
    skin: {
      lesions: lesionsResult?.data,
    },
    country,
    patientId,
    patient,
    scans,
    metrics,
    aggregates,
    lifestyle,
    isCurrentVisitInSweden,
    smpl: {
      metrics: smplResult?.data,
    },
  };
}

type PatientContextValue = ReturnType<typeof useDashboardData>;

const Context = createContext<PatientContextValue | null>(null);

interface PatientDataContextProps {
  patientId: string;
  children: ReactNode;
}

export function PatientDataContext({
  patientId,
  children,
}: PatientDataContextProps) {
  const value = useDashboardData(patientId);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function usePatientData() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("usePatientData without PatientContext");
  }
  return context;
}
