import NekoLogo from "render/assets/neko-logo.svg?react";
import { SessionState, useMSAL } from "render/context/MSALContext";
import { PageContent } from "render/ui/layouts/PageContent";
import { LineThrobber } from "render/ui/trigger/AsyncButton/components/LineThrobber";
import { ButtonSet } from "render/ui/trigger/ButtonSet";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import styles from "./styles.module.sass";

export function LoginPage() {
  const { auth, session } = useMSAL();

  const canLogin = !auth && session.state === SessionState.Settled;

  return (
    <PageContent>
      <div className={styles.LoginPage}>
        <div className={styles.logo}>
          <NekoLogo height="42" width="192" />
        </div>

        <div className={styles.busy}>
          {session.state !== SessionState.Settled && <LineThrobber />}
        </div>

        <div className={styles.content}>
          <ButtonSet>
            <HoverTextButton disabled={!canLogin} onClick={session.logIn}>
              Sign In
            </HoverTextButton>
          </ButtonSet>
        </div>
      </div>
    </PageContent>
  );
}
