import { ReactNode } from "react";
import styles from "./styles.module.sass";

export interface LayoutProps {
  active: boolean;
}

export function Layout({
  active,
  children,
}: LayoutProps & { children: ReactNode }) {
  return (
    <div className={styles.Layout} data-active={active}>
      {children}
    </div>
  );
}
