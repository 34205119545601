import { ButtonHTMLAttributes } from "react";
import styles from "./styles.module.sass";

export function MenuButton({ children, ...props }: ButtonHTMLAttributes<any>) {
  return (
    <button type="button" className={styles.MenuButton} {...props}>
      <div className={styles.icon}>{children}</div>
      <label>{props.title}</label>
    </button>
  );
}
