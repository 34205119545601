import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { BackControl } from "./components/BackControl/BackControl";
import styles from "./styles.module.sass";

export function NavigationLayout() {
  const { patientId } = usePatientData();

  return (
    <div className={styles.NavigationLayout}>
      <BackControl patientId={patientId} />
    </div>
  );
}
