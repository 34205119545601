import { APITypesV1 } from "@cur8/api-client";
import { BoundingBoxAnnotation } from "@cur8/rich-entity";
import { ChangeEvent, useEffect, useState } from "react";
import { useLatestPhysicalArtefact } from "render/hooks/api/physical-artefact/useLatestPhysicalArtefact";
import { SkinLesionClassification } from "render/ui/format/SkinLesionClassification";
import ClassIcon from "./assets/class.svg?react";
import styles from "./styles.module.sass";

const PossibleValues = Object.values(APITypesV1.SkinLesionClassification);

interface ChangeClassificationProps {
  annotation: BoundingBoxAnnotation;
}

export function ChangeClassification({
  annotation,
}: ChangeClassificationProps) {
  const { latestPhysicalArtefact: physicalArtefact, createVersion } =
    useLatestPhysicalArtefact({
      patientId: annotation.patientId,
      physicalArtefactId: annotation.physicalArtefactId || "",
    });

  const [selectedValue, setSelectedValue] = useState(
    APITypesV1.SkinLesionClassification.Other
  );

  useEffect(() => {
    if (physicalArtefact.data) {
      setSelectedValue(
        physicalArtefact.data.skinLesionClassification ||
          APITypesV1.SkinLesionClassification.Other
      );
    }
  }, [physicalArtefact]);

  async function handleChange(event: ChangeEvent<HTMLSelectElement>) {
    const selected = event.currentTarget
      .value as APITypesV1.SkinLesionClassification;
    if (physicalArtefact.data) {
      // extract out the unused parameters
      const { patientId, id, version, audit, ...physicalArtefactRequest } =
        physicalArtefact.data;
      await createVersion({
        ...physicalArtefactRequest,
        skinLesionClassification: selected,
      });
    }
  }

  return (
    <>
      <div className={styles.ChangeClassification}>
        <div className={styles.icon}>
          <ClassIcon />
        </div>
        <select
          disabled={!physicalArtefact.data}
          value={selectedValue}
          onChange={handleChange}
        >
          {PossibleValues.map((skinLesionClassification) => (
            <option
              key={skinLesionClassification}
              value={skinLesionClassification}
            >
              <SkinLesionClassification
                classification={skinLesionClassification}
              />
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
