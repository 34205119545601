import { ImmutableScan } from "@cur8/rich-entity";
import { ViewAssessment } from "./components/ViewAssessment";
import { WriteAssessment } from "./components/WriteAssessment";
import styles from "./styles.module.sass";

interface SkinAssessmentProps {
  active: boolean;
  onClose: () => void;
  scan: ImmutableScan;
}

export function SkinAssessment({ active, onClose, scan }: SkinAssessmentProps) {
  return (
    <div data-active={active} className={styles.SkinAssessment}>
      <div className={styles.content}>
        {scan.journalNoteId ? (
          <ViewAssessment scan={scan} onClose={onClose} />
        ) : (
          <WriteAssessment scan={scan} onClose={onClose} />
        )}
      </div>
    </div>
  );
}
