import { Site } from "@cur8/rich-entity";
import { useMemo } from "react";
import { useLiveTime } from "render/hooks/useLiveTime";
import { useCalendar } from "../../hooks/useCalendar";
import { useCalendarState, ViewRangeMode } from "../../hooks/useCalendarState";
import styles from "./styles.module.sass";

interface CurrentTimeIndicatorProps {
  totalDayCount: number;
  weekday: number;
  site: Site;
}

export function CurrentTimeIndicator({
  weekday,
  totalDayCount,
  site,
}: CurrentTimeIndicatorProps) {
  const { hours } = useCalendar();
  const { viewRange, selectedPeriod } = useCalendarState();
  const now = useLiveTime("minute", { zone: site.timeZoneId });

  const gap = 4;
  const leftOffset = 60 + gap;

  const lineTopOffset = useMemo(() => {
    if (!selectedPeriod.contains(now)) {
      return undefined;
    }

    if (now < hours[0]) {
      return undefined;
    }

    if (now > hours[hours.length - 1].endOf("hour")) {
      return undefined;
    }

    const totalMinutes = hours.length * 60;
    const start = hours[0];

    const currentlyElapsedCalendarMinutes =
      (now.hour - start.hour) * 60 + now.minute;
    const percentPosition =
      (100 / totalMinutes) * currentlyElapsedCalendarMinutes;

    return percentPosition;
  }, [now, selectedPeriod, hours]);

  const width = useMemo(() => {
    if (viewRange === ViewRangeMode.Daily) {
      return `calc((100% - ${leftOffset}px))`;
    }
    return `calc((100% - ${leftOffset}px) * ${weekday} / ${totalDayCount})`;
  }, [viewRange, weekday, leftOffset, totalDayCount]);

  const gridTemplateColumns = useMemo(() => {
    if (viewRange === ViewRangeMode.Daily) {
      return `0fr 100%`;
    }
    return `1fr calc(100% / ${weekday})`;
  }, [viewRange, weekday]);

  if (lineTopOffset === undefined) {
    return null;
  }

  return (
    <div
      className={styles.line}
      style={{
        top: `${lineTopOffset}%`,
        left: `${leftOffset}px`,
        width,
        gridTemplateColumns: gridTemplateColumns,
      }}
    >
      <div className={styles.dashed} />
      <div className={styles.solid} />
    </div>
  );
}
