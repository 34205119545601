// File: Tooltip.js
import { CSSProperties, MouseEvent, ReactNode, useRef, useState } from "react";
import styles from "./styles.module.sass";

interface TooltipProps {
  children: [ReactNode, ReactNode];
  size?: Size;
}

interface CSSPositionProperties {
  bottom: number | undefined;
  left: number | undefined;
  right: number | undefined;
  top: number | undefined;
}

export enum Size {
  small = "small",
  medium = "medium",
  large = "large",
}

function toCSSProperties(pos: CSSPositionProperties): CSSProperties {
  let props = {} as CSSProperties;
  if (pos.bottom) {
    props.bottom = pos.bottom + "px";
  } else {
    props.top = pos.top + "px";
  }
  if (pos.right) {
    props.right = pos.right + "px";
  } else {
    props.left = pos.left + "px";
  }
  return props;
}

export function Tooltip({ children, size = Size.small }: TooltipProps) {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState<CSSPositionProperties>(
    {} as CSSPositionProperties
  );
  const toolRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.currentTarget.getBoundingClientRect();
    setVisible(true);
    setPosition((pos) => {
      const tooltipHeight = toolRef.current?.offsetHeight || 40;
      const tooltipWidth = toolRef.current?.offsetWidth || 175;
      // Default position: below and to the right of trigger
      pos.left = target.left;
      pos.top = target.bottom + 5;
      if (pos.top + tooltipHeight > window.innerHeight) {
        pos.top = target.top - tooltipHeight - target.height;
      }
      if (pos.left + tooltipWidth > window.innerWidth) {
        pos.left = target.left - tooltipWidth + target.width;
      }
      return pos;
    });
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  const [trigger, content] = children;

  return (
    <div
      className={styles.ToolTipTrigger}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {trigger}
      <div
        className={styles.TooltipContent}
        data-size={size}
        data-visible={visible}
        style={toCSSProperties(position)}
        ref={toolRef}
      >
        {content}
      </div>
    </div>
  );
}
