import { UserURI } from "@cur8/uri";
import { useUser } from "render/hooks/api/useUser";

interface AssignedToProps {
  doctor: UserURI;
}

export function AssignedToDoctor({ doctor }: AssignedToProps) {
  const user = useUser(doctor);
  if (!user.data) {
    return null;
  }

  return <>{user.data.displayName}</>;
}
