import { MergedRecord } from "lib/doctor-scribe/types";
import { serializeMergedRecord } from "render/pages/PatientAppointmentPage/components/LLMBrief/results/resultHelpers";
import { MarkdownText } from "../MarkdownText";
import styles from "./style.module.sass";

interface JSONViewProps {
  data: MergedRecord | undefined;
}
export function JSONView({ data }: JSONViewProps) {
  return (
    <div className={styles.DataView}>
      {data ? (
        <MarkdownText>{`\`\`\`json ${serializeMergedRecord(
          data,
          true
        )}`}</MarkdownText>
      ) : (
        "No data available"
      )}
    </div>
  );
}
