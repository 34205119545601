import * as THREE from "three";

export function initTHREE() {
  THREE.Object3D.DEFAULT_UP.set(0, 0, 1);
}

export function toCenter(box: THREE.Box3) {
  const pos = new THREE.Vector3();
  box.getCenter(pos);
  return pos;
}

export function getCameraDistanceForViewportHeight(
  fov: number,
  targetViewportHeight: number
) {
  const fovRadians = (fov * Math.PI) / 180;
  const distance = targetViewportHeight / (2 * Math.tan(fovRadians / 2));

  return distance;
}
