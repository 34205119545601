import { AggregatedBloodPressureData, Patient } from "@cur8/rich-entity";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { LabelsLayer } from "../LabelsLayer";
import { DayDataColumn } from "./components/DayDataColumn";
import styles from "./styles.module.sass";

interface DataLayerProps {
  patient: Patient;
  days: DateTime[];
  measurements: AggregatedBloodPressureData[];
  labels: number[];
  toY: (n: number) => number;
}

export function DataLayer({
  toY,
  days,
  measurements,
  labels,
  patient,
}: DataLayerProps) {
  const measurementsTable = useMemo(() => {
    return measurements.reduce((acc, measurement) => {
      if (measurement.measuredAt) {
        acc.set(measurement.measuredAt.toISODate(), measurement);
      }

      return acc;
    }, new Map<string, AggregatedBloodPressureData>());
  }, [measurements]);

  return (
    <div className={styles.DataLayer}>
      <div className={styles.body}>
        {days.map((day) => (
          <DayDataColumn
            patient={patient}
            data={measurementsTable.get(day.toISODate())}
            key={day.toISO()}
            toY={toY}
          />
        ))}
      </div>
      <div className={styles.labels}>
        <LabelsLayer labels={labels} toY={toY} />
      </div>
    </div>
  );
}
