import { useCallback, useMemo } from "react";
import { DropdownItem } from "render/ui/trigger/DropdownMenuButton/components/DropdownItem";
import DollarCircle from "render/ui/trigger/DropdownMenuButton/components/DropdownItem/assets/dollar-circle.svg?react";
import { usePatientBookingToken } from "../../hooks/usePatientBookingToken";
import { useNoActiveBookingTokenPopup } from "./hooks/useNoActiveBookingTokenPopup";
import { useRefundBookingTokenPopup } from "./hooks/useRefundBookingTokenPopup";

interface Props {
  patientId: string;
}

export function RefundPatientBookingTokenButton({ patientId }: Props) {
  const { emitNoActiveBookingTokenDialog } = useNoActiveBookingTokenPopup();
  const { emitRefundActiveBookingTokenDialog } = useRefundBookingTokenPopup();

  const { fetch: fetchPatientBookingTokens } =
    usePatientBookingToken(patientId);

  const bookingTokens = usePatientBookingToken(patientId);

  const hasActiveBookingToken = useMemo(() => {
    if (!bookingTokens.entries) {
      return false;
    }

    return bookingTokens.entries.length > 0 || false;
  }, [bookingTokens]);

  const onClickHandler = useCallback(() => {
    // TODO: hasActiveBookingToken comes as null if the request is still pending,
    // so we might not show the confirmation dialog
    if (!hasActiveBookingToken) {
      emitNoActiveBookingTokenDialog(patientId, () => {});
    } else {
      emitRefundActiveBookingTokenDialog(patientId, () => {
        fetchPatientBookingTokens();
      });
    }
    return;
  }, [
    hasActiveBookingToken,
    patientId,
    emitNoActiveBookingTokenDialog,
    emitRefundActiveBookingTokenDialog,
    fetchPatientBookingTokens,
  ]);

  return (
    <DropdownItem onClick={onClickHandler}>
      <DollarCircle />
      Refund token
    </DropdownItem>
  );
}
