import { APITypesV1 } from "@cur8/api-client";
import { BodyMetrics } from "lib/types/metrics/body";
import { useCallback, useEffect, useState } from "react";
import { useBodyMetricStore } from "render/hooks/api/metrics/useBodyMetricsStore";
import { useAssessmentContext } from "render/pages/AssessmentPage/context/AssessmentContext";
import { CardioSignals } from "../../hooks/useCardioSignalsTimestamps";
import {
  calculatePWV,
  calculateTimeRangeToDistanceMS,
} from "../../lib/calculations";
import { PulsewaveType } from "../../lib/types";
import { getPWADrange } from "../../lib/utils";
import { GraphWithRuler } from "../shared/GraphWithRuler";
import { ECG_COLOR, FEMORAL_COLOR, SSN_COLOR } from "../shared/constants";
import { JSXResult } from "../shared/fragments";
import { SiradSelector } from "./components/SiradSelector/SiradSelector";

interface PWVProps {
  patientId: string;
  signals: CardioSignals;
}

export function PWV({ patientId, signals }: PWVProps) {
  const { createAssessment, selected, setPulseWaveData } =
    useAssessmentContext();

  const assessmentSelection = () => {
    return getPWADrange(
      createAssessment,
      selected,
      PulsewaveType.pulseWaveVelocity
    );
  };
  const [currentSelection, setCurrentSelection] = useState<
    APITypesV1.Range | undefined
  >(assessmentSelection);

  const [femDistance, setFemDistance] = useState<number>();
  const [siradChannel, setSiradChannel] = useState(0);
  const [qualityMap, setQualityMap] =
    useState<Record<number, APITypesV1.PulseScore>>();

  const [resultElement, setResultElement] = useState<JSX.Element | null>(
    JSXResult("", "Make a selection in the plot to measure PWV")
  );

  const { fetchBodyMetrics } = useBodyMetricStore();

  useEffect(() => {
    fetchBodyMetrics(patientId).then((metrics: BodyMetrics) => {
      const fd = metrics.ssn_femoralis_left?.measurement;
      if (fd) {
        setFemDistance(Math.round(fd.dimensions[0].value));
      }
    });
  }, [fetchBodyMetrics, patientId]);

  const handleChannelChange = useCallback((increase: boolean) => {
    setSiradChannel((prev) => {
      if (increase) {
        return prev < 9 ? prev + 1 : prev;
      } else {
        return prev > 0 ? prev - 1 : prev;
      }
    });
  }, []);

  useEffect(() => {
    if (signals.siradQuality.bestChannel !== undefined) {
      setSiradChannel(signals.siradQuality.bestChannel);
    }

    if (signals.siradQuality.quality?.siradQuality) {
      const dataMap: Record<number, APITypesV1.PulseScore> =
        signals.siradQuality.quality.siradQuality.reduce((acc, item) => {
          if (item.channel !== undefined) {
            acc[item.channel] = item;
          }
          return acc;
        }, {} as Record<number, APITypesV1.PulseScore>);
      setQualityMap(dataMap);
    }
  }, [
    signals.siradQuality.bestChannel,
    signals.siradQuality.quality?.siradQuality,
  ]);

  const calcPwv = useCallback(
    (range: APITypesV1.Range) => {
      const distanceMs = calculateTimeRangeToDistanceMS(range);
      if (!femDistance) {
        setResultElement(
          JSXResult(
            "Cannot compute PWV",
            "no SSN-Femoral distance has been entered",
            `PTT: ${Math.round(distanceMs)} ms`
          )
        );
        return;
      }
      const pwv = calculatePWV(femDistance, distanceMs);
      setResultElement(
        JSXResult(
          `PWV: ${pwv} m/s`,
          `Using SSN-Femoral distance: ${femDistance} cm, PTT: ${Math.round(
            distanceMs
          )} ms`
        )
      );
      return pwv;
    },
    [femDistance]
  );

  const onSelectionRange = useCallback(
    (range: APITypesV1.Range, complete: boolean = false) => {
      let setRange: APITypesV1.Range | undefined = range;
      if (!range || range.to - range.from <= 0) {
        setRange = undefined;
      }
      setCurrentSelection(setRange);
      if (!setRange) {
        if (complete) {
          setPulseWaveData("pulseWaveVelocity", undefined);
        }
        return;
      }
      const pwv = calcPwv(range);
      if (complete && pwv) {
        setPulseWaveData("pulseWaveVelocity", {
          value: pwv,
          range,
          description: `siradchannel:${siradChannel}`,
        });
      }
    },
    [calcPwv, setPulseWaveData, siradChannel]
  );

  return signals ? (
    <div>
      <GraphWithRuler
        data={[
          {
            label: "Cardiac Rhythm",
            signal: signals.bioamp_ecg,
            color: ECG_COLOR,
          },
          { label: "SSN", signal: signals.ssn_displacement, color: SSN_COLOR },
          {
            label: "Femoral",
            signal: signals.sirads[siradChannel].signal,
            color: FEMORAL_COLOR,
          },
        ]}
        diffData={[
          {
            label: "SSN Acceleration",
            signal: signals.ssn_acceleration,
            color: SSN_COLOR,
          },
          {
            label: "Femoral Acceleration",
            signal: signals.sirads[siradChannel].acc,
            color: FEMORAL_COLOR,
          },
        ]}
        plugin={
          <SiradSelector
            channel={siradChannel}
            quality={qualityMap ? qualityMap[siradChannel].score : undefined}
            onChange={handleChannelChange}
          />
        }
        onSelectionRange={onSelectionRange}
        selectionRange={currentSelection}
      />
      {resultElement}
    </div>
  ) : null;
}
