import { SkinBookTracking } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { paths } from "render/routes/paths";
import { FullDate } from "render/ui/format/FullDate";
import { ArrowIcon } from "render/ui/symbol/ArrowIcon";
import { AppointmentManagementMenu } from "../AppointmentManagementMenu/AppointmentManagementMenu";
import { SkinBookAppointmentStatus } from "../AppointmentStatus";
import { PaginatedTable } from "../PaginatedTable";
import { PaginatedTableProps } from "../PaginatedTable/PaginatedTable";
import styles from "./styles.module.sass";

type OperatorTableProps = Pick<
  PaginatedTableProps<SkinBookTracking>,
  "data" | "fetchStatus" | "hasMoreData" | "onLoadMore"
> & {
  onDataRefresh: () => void;
};

export function OperatorTable({
  data,
  fetchStatus,
  hasMoreData,
  onLoadMore,
  onDataRefresh,
}: OperatorTableProps) {
  const nav = {
    markings: useNav(paths.patient.trackedMarkings),
  };

  return (
    <div className={styles.OperatorTable}>
      <PaginatedTable
        data={data}
        fetchStatus={fetchStatus}
        hasMoreData={hasMoreData}
        onLoadMore={onLoadMore}
        renderHeader={() => (
          <tr>
            <th>Member</th>
            <th>Member ID</th>
            <th>Phone no</th>
            <th>Appointment</th>
            <th className={styles.statusCol}>Status</th>
            <th>Action</th>
          </tr>
        )}
        renderRow={(item) => (
          <tr
            key={`${item.scanId}-${item.patientId}-${item.audit.created?.timeStamp}`}
          >
            <td>{item.patientDisplayName}</td>
            <td>{item.patientId}</td>
            <td>{item.patientPhoneNumber}</td>
            <td>
              {item.appointmentStartTime ? (
                <FullDate date={item.appointmentStartTime} />
              ) : (
                "N/A"
              )}
            </td>
            <td>
              <SkinBookAppointmentStatus item={item} />
            </td>
            <td className={styles.actionCol}>
              <AppointmentManagementMenu
                item={item}
                onActionCompleted={onDataRefresh}
              />
              <a
                href={nav.markings.to({ patientId: item.patientId })}
                target="_blank"
                rel="noreferrer"
              >
                Markings
                <ArrowIcon direction="right" />
              </a>
            </td>
          </tr>
        )}
      />
    </div>
  );
}
