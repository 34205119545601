import { LineThrobber } from "render/ui/throbber/LineThrobber";
import { MarkdownText } from "../MarkdownText";
import styles from "./styles.module.sass";

interface LLMItemProps {
  title: string;
  response: string | null | undefined;
}

export function LLMItem({ title, response }: LLMItemProps) {
  return (
    <div className={styles.LLMItem}>
      <div className={styles.title}>{title}</div>
      <div className={styles.item}>
        {response ? <MarkdownText>{response}</MarkdownText> : <LineThrobber />}
      </div>
    </div>
  );
}
