import { Glucose } from "@cur8/health-risks-calc";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { MetricRange } from "render/ui/presentation/MetricRange";
import {
  capToMaxRanges,
  toChartRanges,
} from "render/ui/presentation/RangeChart/lib/risk";

interface GlucoseRangeProps {
  metrics: Metric<"bloodwork.glucose">[];
}

export function GlucoseRange({ metrics }: GlucoseRangeProps) {
  const riskRange = useMemo(() => {
    return Glucose.rangesFor();
  }, []);

  const entries = useMemo(() => {
    return metrics.map((metric) => {
      return {
        risk: riskRange.findRisk(metric.unit),
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["mmol/L"],
      };
    });
  }, [riskRange, metrics]);

  const currentValue = entries.at(0)?.value;

  const ranges = useMemo(() => {
    return capToMaxRanges(toChartRanges(riskRange.entries), currentValue);
  }, [riskRange, currentValue]);

  return (
    <MetricRange
      title="Glucose"
      unit="mmol/L"
      entries={entries}
      ranges={ranges}
      formatValue={(value) => value?.toFixed(0)}
    />
  );
}
