import { HbA1c } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { useAge } from "render/hooks/patient/useAge";
import { MetricStatus } from "render/ui/presentation/MetricStatus";
import { MetricStatusBadge } from "render/ui/presentation/MetricStatusBadge";
import DiabetesIcon from "./assets/diabetes.svg?react";

interface DiabetesItemItemProps {
  patient: Patient;

  bloodwork: {
    hba1c: Metric<"bloodwork.hba1c">[] | undefined;
  };
}

export function DiabetesItem({
  patient,
  bloodwork: { hba1c },
}: DiabetesItemItemProps) {
  const age = useAge(patient);
  const latestHba1c = hba1c?.at(0);

  const risk = useMemo(() => {
    if (latestHba1c) {
      return HbA1c.rangesFor({ age }).findRisk(latestHba1c.unit);
    }
  }, [age, latestHba1c]);

  return (
    <MetricStatus
      caption="Diabetes factors"
      summaryContent={risk != null ? <MetricStatusBadge risk={risk} /> : "--"}
    >
      <DiabetesIcon />
    </MetricStatus>
  );
}
