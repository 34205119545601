import { ReactNode } from "react";
import { Property, PropertyLabel } from "../lib/types";

interface PropertySelectorProps {
  onSelectProperty: (property: Property) => void;
  properties: PropertyLabel[] | undefined;
  selected: Property;
  children?: ReactNode;
}

export function PropertySelector({
  onSelectProperty,
  properties,
  selected,
  children,
}: PropertySelectorProps) {
  return (
    <>
      <h3>Property</h3>
      <div data-section="properties">
        {properties &&
          properties.map(([property, label]) => (
            <button
              onClick={() => onSelectProperty(property)}
              data-selected={selected === property}
              key={property}
            >
              {label}
            </button>
          ))}
      </div>
      {children}
    </>
  );
}
