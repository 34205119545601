import { SkinBookTracking } from "@cur8/rich-entity";
import { DayDate } from "render/ui/format/DayDate";
import { FullDate } from "render/ui/format/FullDate";
import CalendarAdd from "render/ui/trigger/DropdownMenuButton/components/DropdownItem/assets/calendar-add.svg?react";
import { IconButton } from "render/ui/trigger/IconButton";
import { useBookSkinModal } from "../BookSkin/useBookSkinModal";
import { PaginatedTable } from "../PaginatedTable";
import { PaginatedTableProps } from "../PaginatedTable/PaginatedTable";
import RemoveFromDermaOpsAtriumMenu from "../RemoveFromDermaOpsAtriumMenu";
import { SkinBookStatus } from "../SkinBookStatus";
import styles from "./styles.module.sass";

type OpsTableProps = Pick<
  PaginatedTableProps<SkinBookTracking>,
  "data" | "fetchStatus" | "hasMoreData" | "onLoadMore"
> & {
  onDataRefresh: () => void;
  onRemoveItem: (patientId: string, scanId: string) => void;
};

export function OpsTable({
  data,
  fetchStatus,
  hasMoreData,
  onLoadMore,
  onDataRefresh,
  onRemoveItem,
}: OpsTableProps) {
  const { openBookSkinModal } = useBookSkinModal();

  return (
    <div className={styles.OpsTable}>
      <PaginatedTable
        data={data}
        fetchStatus={fetchStatus}
        hasMoreData={hasMoreData}
        onLoadMore={onLoadMore}
        renderHeader={() => (
          <tr>
            <th>Member</th>
            <th>Member ID</th>
            <th>Phone no</th>
            <th>Scan</th>
            <th>Dermo date</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        )}
        renderRow={(item) => (
          <tr
            key={`${item.scanId}-${item.patientId}-${item.audit.created?.timeStamp}`}
          >
            <td>{item.patientDisplayName}</td>
            <td>{item.patientId}</td>
            <td>{item.patientPhoneNumber}</td>
            <td>
              {item.scanCreatedAt ? (
                <DayDate date={item.scanCreatedAt} />
              ) : (
                "N/A"
              )}
            </td>
            <td>
              {item.appointmentStartTime ? (
                <FullDate date={item.appointmentStartTime} />
              ) : (
                "N/A"
              )}
            </td>
            <td>
              <SkinBookStatus item={item} />
            </td>
            <td className={styles.actionCol}>
              <RemoveFromDermaOpsAtriumMenu
                item={item}
                childText="Remove from Ops"
                onRemove={() => onRemoveItem(item.patientId, item.scanId)}
              />
              <IconButton
                label="Book now"
                onClick={() =>
                  openBookSkinModal({
                    patientId: item.patientId,
                    patientName: item.patientDisplayName,
                    scanId: item.scanId,
                    onClose: onDataRefresh,
                  })
                }
              >
                <CalendarAdd />
              </IconButton>
            </td>
          </tr>
        )}
      />
    </div>
  );
}
