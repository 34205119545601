import { Risk } from "@cur8/health-risks-calc";
import { ReactNode } from "react";
import { MetricStatusBadge } from "../MetricStatusBadge";
import { Typography } from "../Typography";
import styles from "./styles.module.sass";

interface FixedAvatarValueProps {
  label: ReactNode;
  value: ReactNode;
  risk: Risk;
  align?: "left" | "right";
}

export function FixedAvatarValue({
  label,
  value,
  risk,
  align = "left",
}: FixedAvatarValueProps) {
  return (
    <div className={styles.FixedAvatarValue} data-align={align}>
      <label className={styles.label}>
        <Typography as="span" variant="label-m">
          {label}
        </Typography>
      </label>
      {value}
      {risk && <MetricStatusBadge risk={risk} />}
    </div>
  );
}
