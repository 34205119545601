import { AnklePressure, Risk } from "@cur8/health-risks-calc";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { RISK_COLORS } from "render/risk";
import { FloatingValueTarget } from "render/ui/presentation/FloatingValueTarget";
import { ValueItem } from "render/ui/symbol/ValueItem";

interface RightAnklePressureProps {
  anklePressure: Metric<"cardio.ankle_pressure.right">[];
}

export function RightAnklePressure({ anklePressure }: RightAnklePressureProps) {
  const pressure = anklePressure.at(0);

  const risk = useMemo(() => {
    if (pressure) {
      return AnklePressure.rangesFor().findRisk(pressure.unit);
    }
  }, [pressure]);

  const color = RISK_COLORS[risk ?? Risk.Unknown];

  return (
    <FloatingValueTarget
      label="Right ankle"
      value={
        <ValueItem
          value={pressure ? pressure.unit.mmHg : "--"}
          unit="mmHg"
          size="huge"
        />
      }
      width={190}
      color={color}
      align="left"
    />
  );
}
