import { toPolyline } from "lib/svg";
import { SVGAttributes, useMemo } from "react";
import { GraphData } from "../../../../../lib/types";

interface NavGraphProps extends Omit<SVGAttributes<unknown>, "points"> {
  data: GraphData[];
}

export function NavGraph({ data, ...props }: NavGraphProps) {
  const paths = useMemo(() => {
    let scaledArray: string[] = [];
    for (const entry of data) {
      const scaled = entry.signal.signal.map((v, index, slice) => {
        return {
          x: (index / slice.length) * 100,
          y: v * 100,
        };
      });
      scaledArray.push(toPolyline(scaled));
    }
    return scaledArray;
  }, [data]);

  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="none" {...props}>
      {paths.map((path, index) => {
        return (
          <polyline
            vectorEffect="non-scaling-stroke"
            points={path}
            fill="none"
            stroke={data[index].color}
            key={index}
          />
        );
      })}
    </svg>
  );
}
