import { AuditInformation } from "@cur8/rich-entity";
import { useUser } from "render/hooks/api/useUser";

interface AuditInfoProps {
  auditInfo: AuditInformation;
}

export function AuditInfo({ auditInfo }: AuditInfoProps) {
  const user = useUser(
    auditInfo.lastModified?.userUri ?? auditInfo.created?.userUri
  );
  const label = auditInfo.lastModified ? "Modified" : "Created";
  const timestamp =
    auditInfo.lastModified && auditInfo.lastModified.timeStamp
      ? auditInfo.lastModified.timeStamp
      : auditInfo.created!.timeStamp!;

  return (
    <>
      <label>{label}</label>
      <span>{timestamp.toFormat("yyyy-LL-dd HH:mm")}</span>
      {user.data && (
        <>
          <label>by</label>
          <span>{user.data.displayName}</span>
        </>
      )}
    </>
  );
}
