export type QuestionId =
  | "diet"
  | "weeklyStrengthExerciseHours"
  | "weeklyCardioExerciseHours"
  | "smoking.style"
  | "smoking.dailyCigarettes"
  | "snus.style"
  | "snus.frequency"
  | "alcohol"
  | "alcohol.weeklyDrinks"
  | "sunburn"
  | "cardiovascular"
  | "cardiovascular.details"
  | "ethnicities"
  | "skinCancer"
  | "skinCancer.melanoma"
  | "skinCancer.melanoma.who"
  | "diabetes"
  | "diabetes.details"
  | "relevantConditions"
  | "relevantConditions.autoimmuneDetails"
  | "relevantConditions.cancerDetails"
  | "relevantConditions.somethingElseDetails"
  | "allergies"
  | "allergies.list"
  | "allergies.other"
  | "medications"
  | "medications.type"
  | "medications.somethingElse"
  | "skinConditions"
  | "skinConditions.type"
  | "skinConditions.skinCancerType"
  | "medicalHistoryDiabetes"
  | "medicalHistoryDiabetes.type"
  | "medicalHistoryDiabetes.complications"
  | "bloodPressure"
  | "cardioConditions"
  | "cardioConditions.valvularDisease"
  | "healthConditions"
  | "otherHealthConditions"
  | "healthProblems"
  | "additionalWorries";

export enum YesNoEnum {
  Yes = "Yes",
  No = "No",
}

export enum AllergiesListEnum {
  Dairy = "Dairy",
  InsectSting = "Insect sting",
  Latex = "Latex",
  Morphine = "Morphine",
  Nuts = "Nuts",
  Pets = "Pets",
  Pollen = "Pollen",
  Wheat = "Wheat",
  SomethingElse = "Something else",
}

export enum CardioConditionsEnum {
  Angina = "Angina",
  AtrialFibrillation = "Atrial fibrillation",
  CongestiveHeartFailure = "Congestive heart failure",
  FamilialHypercholesterolemia = "Familial hypercholesterolemia",
  HeartAttack = "Heart attack",
  Stroke = "Stroke",
  ValvularDisease = "Valvular disease",
}

export enum CardioConditionsValvularDiseaseEnum {
  AorticValveInsufficiency = "Aortic valve insufficiency",
  AorticValveStenosis = "Aortic valve stenosis",
  MitralValveInsufficiency = "Mitral valve insufficiency",
  MitralValveStenosis = "Mitral valve stenosis",
}

export enum CardioExerciseWeeklyHoursEnum {
  None = "Zero hours",
  OneToTwo = "One to two hours",
  ThreeToFour = "Three to four hours",
  MoreThanFive = "More than five hours",
}

export enum RecencyChoicesEnum {
  LessThanSixMonths = "Less than six months ago",
  MoreThanSixMonths = "More than six months ago",
}

export enum DietEnum {
  Vegan = "vegan",
  Vegetarian = "vegetarian",
  Pescatarian = "pescatarian",
  Keto = "ketogenic",
  Paleo = "paleo",
  LowCarb = "low carbohydrates",
  LowMeat = "low meat",
  Other = "other",
  More = "more",
  LowCarbHighFat = "low carbohydrates, high fat (LCHF)",
}

export enum DrinkChoicesEnum {
  LessThanOne = "Less than one glass",
  OneToTwo = "One to two glasses",
  ThreeToFour = "Three to four glasses",
  FiveToSix = "Five to six glasses",
  SevenToEight = "Seven to eight glasses",
  MoreThanNine = "More than nine glasses",
}

export enum HealthConditionsEnum {
  Athritis = "Arthritis",
  KidneyDisease = "Kidney disease",
  PeripheralVascularDisease = "Peripheral Vascular Disease",
  RaynaudsSyndrome = "Raynaud’s Syndrome",
}

export enum Heritage {
  Unknown = "unknown",
  Europe = "europe",
  NorthAmerica = "north-america",
  CentralAmerica = "central-america",
  SouthAmerica = "south-america",
  NorthAsia = "north-asia",
  MiddleEast = "middle-east",
  EastAsia = "east-asia",
  SouthAsia = "south-asia",
  SouthEastAsia = "south-east-asia",
  Oceania = "oceania",
  NorthAfrica = "north-africa",
  WestAfrica = "west-africa",
  EastAfrica = "east-africa",
  SouthAfrica = "south-africa",
}

export enum MedicalHistoryDiabetesTypeEnum {
  TypeOne = "Type one",
  TypeTwo = "Type two",
}

export enum MedicalHistoryDiabetesComplicationsEnum {
  EyeProblems = "Eye problems",
  FootProblems = "Foot problems",
  HeartProblems = "Heart or vessel problems",
  KidneyProblems = "Kidney problems",
}

export enum MedicationsTypeEnum {
  AntiDiabetic = "Anti-diabetic",
  Cholesterol = "Cholesterol",
  BloodPressure = "Blood pressure",
  SomethingElse = "Something else",
}

export enum RelevantConditionsEnum {
  Autoimmune = "Autoimmune disease",
  Cancer = "Cancer",
  Neurological = "Neurological disease",
  SomethingElse = "Something else",
}

export enum SkinCancerWhoEnum {
  Child = "Child",
  Parent = "Parent",
  Sibling = "Sibling",
  OtherRelative = "Other relative",
}

export enum SkinConditionsTypeEnum {
  Acne = "Acne",
  Eczema = "Eczema",
  Psoriasis = "Psoriasis",
  Rosacea = "Rosacea",
  SkinCancer = "Skin cancer",
  SomethingElse = "Something else",
}

export enum SkinCancerTypeEnum {
  ActinicKeratosis = "Actinic Keratosis",
  BCC = "BCC",
  Melanoma = "Melanoma",
  SCC = "SCC",
  NotSure = "Not sure",
}

export enum CigaretteCountEnum {
  LessThanOne = "Less than one",
  LessThanHalfAPack = "Less than half a pack",
  HalfAPackOrMore = "Half a pack or more",
  OnePackOrMore = "One pack or more",
}

export enum IsSmokingEnum {
  No = "No",
  Quit = "Quit",
  Yes = "Yes",
}

export enum SnusCanCountEnum {
  AFewTimesAMonth = "A few times a month",
  OnWeekends = "On weekends",
  EveryOtherDay = "Every other day",
  EveryDay = "Every day",
}

export enum StrengthExerciseWeeklyHoursEnum {
  None = "Zero hours",
  OneToTwo = "One to two hours",
  ThreeToFour = "Three to four hours",
  MoreThanFive = "More than five hours",
}

export enum SunburnFrequencyEnum {
  No = "No",
  OneToTwo = "One to two times",
  ThreeToFive = "Three to five times",
  SixToTen = "Six to ten times",
  MoreThanTen = "More than ten times",
}
