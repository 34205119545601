import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface PulseWaveLocationProps {
  caption?: ReactNode;
}

export function PulseWaveLocation({ caption }: PulseWaveLocationProps) {
  return (
    <div className={styles.PulseWaveLocation}>
      {caption && <label>{caption}</label>}

      <svg
        className={styles.pointer}
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <circle cx={12} cy={12} r={12} fill="#019dc9" opacity={0.2} />
        <rect x={10} y={10} width={4} height={4} fill="#019dc9" />
      </svg>
    </div>
  );
}
