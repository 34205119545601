import { APITypesV1 } from "@cur8/api-client";
import { isThermalAssessment } from "@cur8/rich-entity";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Checkbox } from "render/ui/form/Checkbox";
import { ButtonSet } from "render/ui/trigger/ButtonSet";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import { useAssessmentContext } from "../../context/AssessmentContext";
import styles from "./styles.module.sass";

interface MakeAssessmentProps {
  setShowAssessment: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MakeAssessment({ setShowAssessment }: MakeAssessmentProps) {
  const {
    addTag,
    createAssessment,
    isSavable,
    loadLatest,
    removeTag,
    save,
    selected,
    setComment,
    setState,
    setThermalStatus,
    isModified,
  } = useAssessmentContext();
  const [text, setText] = useState<string>(createAssessment.comment ?? "");
  const [showForm, setShowForm] = useState<boolean>(
    selected.assessmentState === APITypesV1.AssessmentState.New &&
      selected.isLatestVersion
  );
  const [flaggedIncomplete, setFlaggedIncomplete] = useState<boolean>(false);

  const stateCleared = useCallback(() => {
    setState(APITypesV1.AssessmentState.Cleared);
  }, [setState]);
  const stateFollowUp = useCallback(() => {
    setState(APITypesV1.AssessmentState.FollowUpRequested);
  }, [setState]);
  const stateReview = useCallback(() => {
    setState(APITypesV1.AssessmentState.ReviewRequested);
  }, [setState]);

  const stateIncomplete = useCallback(
    (checked: boolean) => {
      setFlaggedIncomplete(checked);
      if (checked) {
        addTag("incomplete");
      } else {
        removeTag("incomplete");
      }
    },
    [addTag, removeTag]
  );

  const textChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  }, []);

  useEffect(() => {
    if (isModified) {
      setShowForm(true);
    }
  }, [isModified]);

  useEffect(() => {
    const saveText = async () => {
      if (text.length > 0) {
        setComment(text);
      }
    };
    saveText();
  }, [setComment, text]);

  /**
   * Status for Thermal
   */
  const statusNormal = useCallback(() => {
    setThermalStatus(APITypesV1.ThermalAssessmentStatus.Normal);
  }, [setThermalStatus]);
  const statusAbnormal = useCallback(() => {
    setThermalStatus(APITypesV1.ThermalAssessmentStatus.Abnormal);
  }, [setThermalStatus]);

  const reAssess = useCallback(() => {
    setShowForm(true);
    setShowAssessment(false);
  }, [setShowAssessment]);

  const cancelReAssess = useCallback(() => {
    setShowForm(false);
    setShowAssessment(true);
  }, [setShowAssessment]);

  const saveWrapper = useCallback(() => {
    save();
    setShowForm(false);
    setShowAssessment(true);
  }, [save, setShowAssessment]);

  if (!selected.isLatestVersion) {
    return (
      <div className={styles.notLatest}>
        <p>
          <b>Viewing an old version!</b>
          Please load the latest version to make an assessments.
        </p>
        <HoverTextButton onClick={loadLatest}>Load latest</HoverTextButton>
      </div>
    );
  }

  return (
    <div className={styles.MakeAssessment}>
      {showForm ? (
        <div className={styles.assForm}>
          <h3>Make assessment</h3>
          {isThermalAssessment(selected) &&
            (() => {
              const ca =
                createAssessment as APITypesV1.CreateThermalAssessmentRequest;
              const isNormal =
                ca.status === APITypesV1.ThermalAssessmentStatus.Normal;
              const isAbnormal =
                ca.status === APITypesV1.ThermalAssessmentStatus.Abnormal;

              return (
                <>
                  <label>Status*</label>
                  <ButtonSet stacked={true}>
                    <Checkbox
                      onChange={statusNormal}
                      label="Normal"
                      checked={isNormal}
                    />
                    <Checkbox
                      onChange={statusAbnormal}
                      label="Abnormal"
                      checked={isAbnormal}
                    />
                  </ButtonSet>
                </>
              );
            })()}
          <label>State*</label>
          <ButtonSet stacked={true}>
            <Checkbox
              onChange={stateCleared}
              label="Cleared"
              checked={
                createAssessment.assessmentState ===
                APITypesV1.AssessmentState.Cleared
              }
            />
            <Checkbox
              onChange={stateFollowUp}
              label="Follow up"
              checked={
                createAssessment.assessmentState ===
                APITypesV1.AssessmentState.FollowUpRequested
              }
            />
            <Checkbox
              onChange={stateReview}
              label="Request Review"
              checked={
                createAssessment.assessmentState ===
                APITypesV1.AssessmentState.ReviewRequested
              }
            />
          </ButtonSet>
          <label>Action / Tags</label>
          <ButtonSet stacked={true}>
            <Checkbox
              onChange={stateIncomplete}
              label="Incomplete scan"
              checked={flaggedIncomplete}
            />
          </ButtonSet>

          <label>Comment</label>
          <textarea
            placeholder="Add your comment here"
            value={text}
            onChange={textChange}
            rows={4}
          />
          <HoverTextButton onClick={saveWrapper} disabled={!isSavable}>
            Save
          </HoverTextButton>
          {selected.assessmentState !== APITypesV1.AssessmentState.New && (
            <HoverTextButton onClick={cancelReAssess}>Cancel</HoverTextButton>
          )}
        </div>
      ) : (
        <HoverTextButton onClick={reAssess} style={{ marginTop: "16px" }}>
          Reassess
        </HoverTextButton>
      )}
    </div>
  );
}
