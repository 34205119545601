import { BoundingBoxAnnotation, Box } from "@cur8/rich-entity";

interface LocatorBoxProps {
  annotation: BoundingBoxAnnotation;
  crop: Box;
}

export function LocatorBox({ annotation, crop }: LocatorBoxProps) {
  const box = annotation.data.rect;

  const bracketW = crop.w / 25;
  const bracketH = crop.h / 25;

  return (
    <svg
      viewBox={`${crop.x} ${crop.y} ${crop.w} ${crop.h}`}
      width="100%"
      height="100%"
    >
      <g fill="none" stroke="#fff" strokeWidth="1">
        <rect
          x={box.x}
          y={box.y}
          width={box.w}
          height={box.h}
          strokeDasharray="1 3"
          vectorEffect="non-scaling-stroke"
        />

        <path
          d={`
            M${box.left + bracketW},${box.top}
            L${box.left},${box.top}
            L${box.left},${box.top + bracketH}
          `}
          vectorEffect="non-scaling-stroke"
        />

        <path
          d={`
            M${box.right - bracketW},${box.top}
            L${box.right},${box.top}
            L${box.right},${box.top + bracketH}
          `}
          vectorEffect="non-scaling-stroke"
        />

        <path
          d={`
            M${box.right - bracketW},${box.bottom}
            L${box.right},${box.bottom}
            L${box.right},${box.bottom - bracketH}
          `}
          vectorEffect="non-scaling-stroke"
        />

        <path
          d={`
            M${box.left + bracketW},${box.bottom}
            L${box.left},${box.bottom}
            L${box.left},${box.bottom - bracketH}
          `}
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  );
}
