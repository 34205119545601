import { Children, ReactNode } from "react";
import { CarouselItem } from "./components/CarouselItem";
import styles from "./styles.module.sass";

interface CarouselProps {
  activeIndex: number;
  children: ReactNode;
}

export function Carousel({ activeIndex, children }: CarouselProps) {
  return (
    <div className={styles.Carousel}>
      <div className={styles.items}>
        {Children.map(children, (child, childIndex) => {
          const offset = childIndex - activeIndex;

          const style = {
            transform: `
              translateX(${offset * 110}%)
              scale(${childIndex === activeIndex ? 1 : 1})
            `,
            zIndex: -Math.abs(activeIndex - childIndex),
          };

          return (
            <div
              key={childIndex}
              className={styles.item}
              data-index={Math.sign(childIndex - activeIndex)}
              style={style}
            >
              <CarouselItem>{child}</CarouselItem>
            </div>
          );
        })}
      </div>
    </div>
  );
}
