import { Patient } from "@cur8/rich-entity";
import { Typography } from "render/ui/presentation/Typography";
import Diagram from "./assets/cholesterol-v3.svg?react";
import styles from "./styles.module.sass";
interface ArterySectionProps {
  patient: Patient;
}

export function ArterySection({ patient }: ArterySectionProps) {
  return (
    <div className={styles.ArterySection}>
      <div className={styles.diagram}>
        <Diagram height={687} width={687} />
        <label data-variant="hdl">
          <Typography as="span" variant="title-s">
            HDL
          </Typography>
        </label>
        <label data-variant="nonhdl">
          <Typography as="span" variant="title-s">
            Non-HDL
          </Typography>
        </label>
      </div>
    </div>
  );
}
