import { AvatarPodium } from "lib/avatar/podium";
import { useTexture } from "render/hooks/three/useTexture";
import { Layer } from "render/pages/DashboardPage/types";
import * as THREE from "three";
import pointImage from "./assets/moon5.png";
import { AvatarPointCloud } from "./components/AvatarPointCloud";
import { Floor } from "./components/Floor";

interface PointCloudProps {
  layer: Layer;
  scene: THREE.Scene;
  podium: AvatarPodium;
}

export function PointCloud({ layer, scene, podium }: PointCloudProps) {
  const pointTexture = useTexture(pointImage);

  return (
    <>
      {pointTexture && <Floor podium={podium} pointTexture={pointTexture} />}

      {pointTexture && (
        <AvatarPointCloud
          scene={scene}
          podium={podium}
          pointTexture={pointTexture}
          layer={layer}
        />
      )}
    </>
  );
}
