import { UserURI } from "@cur8/uri";
import { DateTime } from "luxon";
import { useUser } from "render/hooks/api/useUser";
import { useUserProfilePhoto } from "render/hooks/api/useUserProfilePhoto";
import { FullDate } from "render/ui/format/FullDate";
import styles from "./styles.module.sass";

interface AssessmentAuthorProps {
  userUri?: UserURI;
  date: DateTime;
}

export function AssessmentAuthor({ userUri, date }: AssessmentAuthorProps) {
  const profilePhotoSize = 48;
  const user = useUser(userUri);
  const profilePhoto = useUserProfilePhoto(profilePhotoSize * 2, userUri);

  return (
    <div className={styles.AssessmentAuthor}>
      <div className={styles.details}>
        <div className={styles.name}>{user.data?.displayName}</div>
        <div className={styles.date}>
          <FullDate date={date} />
        </div>
      </div>
      {profilePhoto && (
        <div>
          <img src={profilePhoto.src} height={profilePhotoSize} alt="" />
        </div>
      )}
    </div>
  );
}
