import { Point } from "lib/math";
import { PointerEvent, SyntheticEvent } from "react";

export function stopPropagation(event: SyntheticEvent) {
  event.stopPropagation();
}

export function getPointerMovement(event: PointerEvent) {
  return new Point(event.movementX, event.movementY);
}
