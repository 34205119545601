interface RectangleProps {
  max: number;
  min: number;
  fill: string;
  toY: (n: number) => number;
}

export function Rectangle({ max, min, fill, toY }: RectangleProps) {
  const maxNormalized = toY(max);
  const minNormalized = toY(min);

  return (
    <rect
      x="calc(50% - 2.5px)"
      y={`${maxNormalized}%`}
      width={"5px"}
      fill={fill}
      rx="2"
      height={`${Math.abs(maxNormalized - minNormalized)}%`}
    />
  );
}
