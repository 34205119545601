import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layout, LayoutProps } from "../Layout";
import { SidePanel } from "../SidePanel";
import { BodySection } from "./components/BodySection";
import styles from "./styles.module.sass";

interface BodyLayoutProps extends LayoutProps {
  active: boolean;
}

export function BodyLayout({ active }: BodyLayoutProps) {
  const { patient } = usePatientData();

  return (
    <Layout active={active}>
      <div className={styles.BodyLayout}>
        <div className={styles.bodyPanel} data-active={active}>
          <SidePanel>{patient && <BodySection patient={patient} />}</SidePanel>
        </div>
      </div>
    </Layout>
  );
}
