import { OutputImageSize, SourceImageCrop } from "lib/api/types";
import { PanoramaImageURI } from "lib/api/uri";
import { usePanorama } from "render/hooks/usePanorama";

interface PanoramaImageProps {
  uri: PanoramaImageURI;
  crop?: SourceImageCrop;
  size?: OutputImageSize;
}

export function PanoramaImage({ uri, crop, size }: PanoramaImageProps) {
  const image = usePanorama(uri, size, crop);

  if (!image) {
    return null;
  }

  return (
    <img
      src={image.src}
      alt=""
      height={image.naturalHeight}
      width={image.naturalWidth}
    />
  );
}
