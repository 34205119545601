import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface GridIronSummaryProps {
  children: ReactNode;
}

export function GridIronSummary({ children }: GridIronSummaryProps) {
  return <div className={styles.GridIronSummary}>{children}</div>;
}
