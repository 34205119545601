import { ButtonHTMLAttributes, ReactNode } from "react";
import { FormHandle } from "render/hooks/useFormHandle";
import { AsyncButton } from "../AsyncButton";

interface SubmitButtonProps
  extends Omit<ButtonHTMLAttributes<any>, "type" | "onClick"> {
  children: ReactNode;
  handle: FormHandle;
}

export function SubmitButton({
  children,
  handle,
  ...props
}: SubmitButtonProps) {
  return (
    <AsyncButton {...props} type="submit" handle={handle}>
      {children}
    </AsyncButton>
  );
}
