import { APITypesV2 } from "@cur8/api-client";
import { Patient } from "@cur8/rich-entity";
import { convertQuestionnaire } from "lib/questionnaire/convert";
import { getFamilyHealthConditions } from "lib/questionnaire/getFamilyHealthConditions";
import { AdditionalWorries } from "render/fragments/metric/patient-details/AdditionalWorries";
import { Age } from "render/fragments/metric/patient-details/Age";
import { Diet } from "render/fragments/metric/patient-details/Diet";
import { HealthConditions } from "render/fragments/metric/patient-details/HealthConditions";
import { HealthConditionsFamily } from "render/fragments/metric/patient-details/HealthConditionsFamily";
import { UndefinedData } from "render/fragments/metric/patient-details/UndefinedData";
import { useAge } from "render/hooks/patient/useAge";
import { LocaleLanguage } from "render/ui/format/LocaleLanguage";
import { SexDefinition } from "render/ui/format/SexDefinition";
import { Table } from "render/ui/layouts/Table";

interface QuestionnaireOverviewProps {
  patient?: Patient;
  questionnaireResponse?: APITypesV2.QuestionnaireResponse;
}

export function QuestionnaireOverview({
  patient,
  questionnaireResponse,
}: QuestionnaireOverviewProps) {
  const age = useAge(patient);

  const answers = convertQuestionnaire(questionnaireResponse);
  const healthConditionsFamily = getFamilyHealthConditions(answers);

  return (
    <Table>
      <thead>
        <tr>
          <th>Question</th>
          <th>Answer</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Age</td>
          <td>{age ? <Age value={age} /> : <UndefinedData />} </td>
        </tr>
        <tr>
          <td>Sex</td>
          <td>{patient && <SexDefinition sex={patient.sex} />}</td>
        </tr>
        <tr>
          <td>Language</td>
          <td>
            {patient?.preferredLanguage ? (
              <LocaleLanguage locale={patient.preferredLanguage} />
            ) : (
              <UndefinedData />
            )}
          </td>
        </tr>
        <tr>
          <td>Medications</td>
          <td>{answers?.medications ?? <UndefinedData />}</td>
        </tr>
        <tr>
          <td>Allergies</td>
          <td>{answers?.allergies ?? <UndefinedData />}</td>
        </tr>
        <tr>
          <td>Diet</td>
          <td>
            {answers?.diet ? <Diet value={answers?.diet} /> : <UndefinedData />}
          </td>
        </tr>
        <tr>
          <td>Cardio exercise</td>
          <td>{answers?.cardioExercise ?? <UndefinedData />}</td>
        </tr>
        <tr>
          <td>Strength exercise</td>
          <td>{answers?.strengthExercise ?? <UndefinedData />}</td>
        </tr>
        <tr>
          <td>Alcohol</td>
          <td>{answers?.alcohol ?? <UndefinedData />}</td>
        </tr>
        <tr>
          <td>Smoking</td>
          <td>
            {answers?.smokingDailyCigarettes ?? answers?.smoking ?? (
              <UndefinedData />
            )}
          </td>
        </tr>
        <tr>
          <td>Snus</td>
          <td>{answers?.snus ?? <UndefinedData />}</td>
        </tr>
        <tr>
          <td>Questions or concerns</td>
          <td>
            {answers?.additionalWorries ? (
              <AdditionalWorries value={answers?.additionalWorries} />
            ) : (
              <UndefinedData />
            )}
          </td>
        </tr>
        <tr>
          <td>Health conditions in the last year</td>
          <td>
            {answers?.healthConditions ? (
              <HealthConditions value={answers?.healthConditions} />
            ) : (
              <UndefinedData />
            )}
          </td>
        </tr>
        <tr>
          <td>Family health conditions</td>
          <td>
            {healthConditionsFamily ? (
              <HealthConditionsFamily value={healthConditionsFamily} />
            ) : (
              <UndefinedData />
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
}
