import { AvatarPodium } from "lib/avatar/podium";
import { createTimer } from "lib/timer";
import { useEffect, useMemo } from "react";

export function usePodium() {
  const podium = useMemo(() => {
    return new AvatarPodium();
  }, []);

  useEffect(() => {
    return createTimer((deltaTime) => {
      const dt = deltaTime / 1000;
      podium.update(dt);
    });
  }, [podium]);

  return podium;
}
