import { APITypesV1 } from "@cur8/api-client";
import { Patient } from "@cur8/rich-entity";
import { DateTime } from "luxon";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useRef,
} from "react";
import { useAPIClient } from "render/context/APIContext";
import { useAppInsights } from "render/context/AppInsightsContext";
import { RecordingSession, uploadPatientAudioBlob } from "./utils";
import { VadData, VoiceActivityDetector } from "./vad";

const MIME_TYPE = "audio/webm;codecs=opus";
const MIN_REC_TIME_S = 1;
const TARGET_REC_TIME_MS = 60000;

export function useAudioRecordingState() {
  const api = useAPIClient();
  const vadRef = useRef<VoiceActivityDetector>();
  const recStartTimeRef = useRef(DateTime.now().toMillis()); // used to track the length of the current ~1 min recording
  const recTotalTimeRef = useRef(DateTime.now().toMillis()); // used to track the total recording length
  const filesCount = useRef(0);
  const isStoppedRef = useRef(true);
  const timestampRef = useRef<number>();
  const hasErrorRef = useRef(false);
  const scanRef = useRef<APITypesV1.ImmutableScan>();
  const appInsights = useAppInsights();

  const onDataAvailable = useCallback(
    async (patient: Patient, event: BlobEvent) => {
      if (!timestampRef.current) {
        console.warn("no timestampRef...");
        return;
      }
      if (!scanRef.current) {
        console.error("has no scan when trying to upload audio file!");
        return;
      }

      const durationSeconds =
        DateTime.now().toMillis() - recStartTimeRef.current / 1000;

      vadRef.current?.stop();

      if (durationSeconds > MIN_REC_TIME_S) {
        console.debug(
          `recorded ${durationSeconds}s, uploading [${filesCount.current}]: ${event.data.size} bytes`
        );

        const fileName = `${filesCount.current}.webm`;

        const success = await uploadPatientAudioBlob(
          api,
          patient,
          event.data,
          fileName,
          scanRef.current
        );

        if (success) {
          filesCount.current++;
        } else {
          hasErrorRef.current = true;
          console.error("uploading of file failed");
        }
      } else {
        console.warn("Recorded segment too short, not sending...");
      }

      if (!isStoppedRef.current) {
        try {
          vadRef.current?.start();
        } catch {
          console.warn("could not restart vad..");
        }
      }
    },
    [api]
  );

  const configureMediaRecorder = useCallback(
    (patient: Patient, stream: MediaStream) => {
      const mediaRecorder = new MediaRecorder(stream, { mimeType: MIME_TYPE });
      mediaRecorder.onstart = () => {
        recStartTimeRef.current = DateTime.now().toMillis();
      };
      mediaRecorder.start();
      mediaRecorder.addEventListener("dataavailable", (event) => {
        onDataAvailable(patient, event);
      });
      return mediaRecorder;
    },
    [onDataAvailable]
  );

  const handleVADUpdate = useCallback(
    (mediaRecorder: MediaRecorder, data: VadData) => {
      if (!data.active && mediaRecorder.state === "recording") {
        // stopped talking
        const recTime = DateTime.now().toMillis() - recStartTimeRef.current;
        if (recTime > TARGET_REC_TIME_MS) {
          console.debug("stopping media recorder");
          mediaRecorder.stop();
        }
      } else if (
        data.active &&
        mediaRecorder.state !== "recording" &&
        !isStoppedRef.current
      ) {
        // started talking
        console.debug("starting recorder");
        try {
          mediaRecorder.start();
        } catch {
          console.warn("could not restart recorder.");
        }
      } else if (isStoppedRef.current) {
        appInsights.trackEvent({ name: "recording_vad_update_when_stopped" });
        console.warn("vad update when stopped...");
      }
    },
    [appInsights]
  );

  const configureVAD = useCallback(
    async (stream: MediaStream, mediaRecorder: MediaRecorder) => {
      const vad = new VoiceActivityDetector(stream, (data) => {
        // Handle stopping/starting of recorder based on voice activity
        try {
          handleVADUpdate(mediaRecorder, data);
        } catch (err) {
          console.warn("could not manage recorder's state.", err);
        }
      });
      await vad.initialize();
      return vad;
    },
    [handleVADUpdate]
  );

  const start = useCallback(
    async (
      patient: Patient,
      deviceId: string,
      scan: APITypesV1.ImmutableScan
    ) => {
      isStoppedRef.current = false;
      filesCount.current = 0;
      hasErrorRef.current = false;
      scanRef.current = scan;
      recTotalTimeRef.current = DateTime.now().toMillis();

      // If no preferred device was found, deviceId is empty. In this case, do not constrain:
      const audio = deviceId ? { deviceId: { exact: deviceId } } : true;

      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio });
        timestampRef.current = DateTime.now().toMillis();
        const mediaRecorder = configureMediaRecorder(patient, stream);
        vadRef.current = await configureVAD(stream, mediaRecorder);
        return new RecordingSession(stream);
      } catch (err) {
        console.error("Could not start recording:", err);
      }
    },
    [configureMediaRecorder, configureVAD]
  );

  const stop = useCallback(
    (patient: Patient | undefined) => {
      if (isStoppedRef.current || !scanRef.current) {
        return;
      }

      vadRef.current?.stop();
      vadRef.current = undefined;
      isStoppedRef.current = true;

      if (!patient) {
        appInsights.trackEvent(
          { name: "recording_stop_called_without_patient" },
          {
            scanId: scanRef.current?.id,
            scanVersion: scanRef.current?.version,
          }
        );
        return;
      }

      const totalDuration =
        (DateTime.now().toMillis() - recTotalTimeRef.current) / 1000;

      api.scan
        .createScanResult({
          patientId: patient.patientId,
          scanId: scanRef.current.id,
          scanVersion: scanRef.current.version,
          resultName: "audioRecordingCount",
          state: APITypesV1.ResultState.Complete,
          data: {
            $type: "AudioRecordingCount",
            count: (filesCount.current + 1).toString(),
            duration: totalDuration,
          },
        })
        .result.catch((err) => {
          console.error("Could not create scan result: ", err);
          appInsights.trackEvent(
            { name: "recording_create_scan_result_failed" },
            {
              patient: patient.patientId,
              scanId: scanRef.current?.id,
              scanVersion: scanRef.current?.version,
              exception: err,
            }
          );
        });
    },
    [appInsights, api.scan]
  );

  return { start, stop };
}

export const Context = createContext<ReturnType<
  typeof useAudioRecordingState
> | null>(null);

interface AudioRecordingContextProps {
  children: ReactNode;
}

export function AudioRecordingContext({
  children,
}: AudioRecordingContextProps) {
  const value = useAudioRecordingState();

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useAudioRecording() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useAudioRecording without AudioRecordingContext");
  }
  return context;
}
