import { useState } from "react";
import { FaRegEdit, FaRegFileAlt } from "react-icons/fa";
import styles from "./styles.module.sass";

interface AddNewButtonProps {
  onAddNote: () => void;
  onAddMemberSummary: () => void;
}

export default function AddNewButton({
  onAddNote,
  onAddMemberSummary,
}: AddNewButtonProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  return (
    <div className={styles.addNewContainer}>
      <button
        className={styles.addNewButton}
        onClick={toggleDropdown}
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        <FaRegEdit className={styles.icon} />
        Add new
      </button>

      {isOpen && (
        <ul className={styles.dropdownMenu}>
          <li
            className={styles.dropdownItem}
            onClick={() => {
              onAddMemberSummary();
              setIsOpen(false);
            }}
          >
            <FaRegFileAlt className={styles.icon} />
            Debrief summary
          </li>
          <li
            className={styles.dropdownItem}
            onClick={() => {
              onAddNote();
              setIsOpen(false);
            }}
          >
            <FaRegEdit className={styles.icon} />
            Note
          </li>
        </ul>
      )}
    </div>
  );
}
