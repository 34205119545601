import { BMI } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { useMemo } from "react";
import { BMIScore } from "render/hooks/api/metrics/useBMIScore";
import { useAge } from "render/hooks/patient/useAge";
import { MetricStatus } from "render/ui/presentation/MetricStatus";
import { MetricStatusBadge } from "render/ui/presentation/MetricStatusBadge";
import { StudySymbol } from "render/ui/symbol/StudySymbol";
import { ValueItem } from "render/ui/symbol/ValueItem";

interface BMIItemProps {
  patient: Patient;
  bmi: BMIScore | undefined;
  previousBMI?: BMIScore | undefined;
}

export function BMIItem({ patient, bmi, previousBMI }: BMIItemProps) {
  const age = useAge(patient);

  const summary = useMemo(() => {
    if (!bmi) {
      return;
    }

    return {
      risk: BMI.rangesFor({ age }).findRisk({ bmi: bmi.score }),
    };
  }, [bmi, age]);

  const differenceToPreviousValue = useMemo(() => {
    if (!bmi?.score || !previousBMI?.score) {
      return 0;
    }

    return Number((bmi.score - previousBMI.score).toFixed(1));
  }, [bmi?.score, previousBMI?.score]);

  return (
    <MetricStatus
      caption={
        <>
          BMI
          <StudySymbol />
        </>
      }
      summaryContent={
        summary ? <MetricStatusBadge risk={summary.risk} /> : "--"
      }
      differenceToPreviousValue={differenceToPreviousValue}
    >
      <ValueItem
        value={bmi && isFinite(bmi.score) ? bmi.score.toFixed(1) : "--"}
      />
    </MetricStatus>
  );
}
