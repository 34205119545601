import { APITypesV1 } from "@cur8/api-client";
import { CreatePadAssessmentRequestBox } from "render/pages/AssessmentPage/context/AssessmentContext";
import {
  CreatePulseWaveAssessmentRequestBox,
  CreateThermalAssessmentRequestBox,
} from "render/pages/AssessmentPage/context/AssessmentContext/lib";

export interface PlotData {
  range: APITypesV1.Range;
  signal: number[];
  timestamps: number[];
}

export type GraphData = {
  signal: PlotData;
  label: string;
  color: string;
};

export enum PulsewaveType {
  lveti = "lveti",
  pulseWaveVelocity = "pulseWaveVelocity",
  handAsymmetry = "handAsymmetry",
  footAsymmetry = "footAsymmetry",
}

export const PulsewaveTypes = new Map<PulsewaveType, string>([
  [PulsewaveType.lveti, "lveti"],
  [PulsewaveType.pulseWaveVelocity, "pulseWaveVelocity"],
  [PulsewaveType.handAsymmetry, "handAsymmetry"],
  [PulsewaveType.footAsymmetry, "footAsymmetry"],
]);

/**
 * @See APITypesV1.LVETIRangeData
 */
export type LVETISelection = {
  hr?: number;
  lvet?: number;
  lveti?: number;
  ssn?: APITypesV1.Range; // Required in LVETIRangeData
  rrInterval?: APITypesV1.Range; // Required in LVETIRangeData
};
export type Selections = Record<number, LVETISelection>;

export function isCreatePWVAssessment(
  src:
    | CreatePadAssessmentRequestBox
    | CreatePulseWaveAssessmentRequestBox
    | CreateThermalAssessmentRequestBox
): src is CreatePulseWaveAssessmentRequestBox {
  return src.hasOwnProperty("lveti");
}
