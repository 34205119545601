import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { Layout, LayoutProps } from "../Layout";
import { DetectedSection } from "./components/DetectedSection";
import { TrackedSection } from "./components/TrackedSection";
import styles from "./styles.module.sass";

interface LesionLayoutProps extends LayoutProps {
  active: boolean;
}

export function LesionLayout({ active }: LesionLayoutProps) {
  const { ui } = useDashboardContext();

  return (
    <Layout active={active}>
      <div
        className={styles.LesionLayout}
        data-active={active}
        data-layer={ui.layer}
      >
        <TrackedSection active={active} />

        <hr className={styles.separator} />

        <DetectedSection active={active} />
      </div>
    </Layout>
  );
}
