import { useCallback } from "react";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import { useTissueAnnotationContext } from "../../context/TissueAnnotationContext";
import { useQueries } from "../../hooks/useQueries";
import {
  PredefinedRoI,
  coordsToBox,
  heatProvocationArm,
  heatProvocationFoot,
  occlusionArm,
} from "../../lib/predefinedRoIs";
import { Property, TissueAnnotationDataTypes } from "../../lib/types";
import { RegionLabel } from "./RegionLabel";
import styles from "./styles.module.sass";

interface RegionTrackerProps {
  allowEdit?: boolean;
  pixelsPerCm: number | undefined;
}

export function RegionTracker({
  allowEdit = true,
  pixelsPerCm,
}: RegionTrackerProps) {
  const { property } = useQueries();
  const { crudCreateHandler, fetch, filtered, getAnnoURI } =
    useTissueAnnotationContext();

  const annos = [...filtered.roi, ...filtered.lines];

  const createAnno = useCallback(
    (data: TissueAnnotationDataTypes, label: string) => {
      const uri = getAnnoURI(label);
      return crudCreateHandler(uri.toString(), data).then((anno) => {
        return anno;
      });
    },
    [crudCreateHandler, getAnnoURI]
  );

  const addRoI = useCallback(
    (rois: PredefinedRoI[]) => {
      if (!pixelsPerCm) {
        return;
      }
      const promRes = rois.map((roi) => {
        return createAnno(
          { rect: coordsToBox(roi.pos, pixelsPerCm) },
          roi.label
        );
      });
      Promise.all(promRes)
        .then(() => {
          fetch();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [createAnno, fetch, pixelsPerCm]
  );

  const addRoIHeatProvArm = useCallback(() => {
    return addRoI(heatProvocationArm);
  }, [addRoI]);

  const addRoIOcclusionArm = useCallback(() => {
    return addRoI(occlusionArm);
  }, [addRoI]);

  const addRoIHeatProvocationFoot = useCallback(() => {
    return addRoI(heatProvocationFoot);
  }, [addRoI]);

  return (
    <div className={styles.RegionTracker}>
      <h3>Regions of Interest</h3>
      {annos.length === 0 && (
        <div className={styles.noRoI}>
          {property !== Property.thermal && pixelsPerCm && (
            <>
              <h4>Add predefined RoI's</h4>
              <div className={styles.defaultRoIs}>
                <HoverTextButton onClick={addRoIHeatProvArm}>
                  Heat Provocation Arm
                </HoverTextButton>
                <HoverTextButton onClick={addRoIOcclusionArm}>
                  Occlusion Arm
                </HoverTextButton>
                <HoverTextButton onClick={addRoIHeatProvocationFoot}>
                  Heat Provocation Foot
                </HoverTextButton>
              </div>
            </>
          )}

          <h4>Add custom RoI's</h4>
          <ul>
            <li>Click the [+ RoI]-button.</li>
            <li>Then click and drag in the image to mark the area.</li>
            <li>Give the RoI a label and click Save.</li>
          </ul>
        </div>
      )}
      {annos.map((anno) => (
        <RegionLabel
          key={anno.annotation.id}
          allowEdit={allowEdit}
          anno={anno}
          pixelsPerCm={pixelsPerCm}
        />
      ))}
    </div>
  );
}
