import { ColorMap } from "lib/webgl/colorizer";
import { useGradientMap } from "render/hooks/api/image/useGradientMap";
import { ImageType, TissueImage } from "../../lib/types";
import { WaitingForImage } from "./WaitingForImage";

interface GradientImageProps {
  colormap: ColorMap;
  tissueImage: TissueImage;
  transparencyCutoff?: number;
  type?: ImageType;
}

export function GradientImage({
  colormap,
  tissueImage,
  transparencyCutoff,
}: GradientImageProps) {
  const sourceImage = tissueImage.image;
  // Undefined range === RGB-image
  const preferSourceImage = tissueImage.imin === undefined;

  let outputImage: HTMLImageElement | undefined;

  outputImage = useGradientMap(
    colormap,
    preferSourceImage ? undefined : sourceImage,
    transparencyCutoff
  );

  if (preferSourceImage) {
    outputImage = sourceImage;
  }

  if (!outputImage) {
    return <WaitingForImage />;
  }

  return <image href={outputImage.src} />;
}
