import { MetricName } from "@cur8/measurements";

export const metricNames: MetricName[] = [
  // "bloodwork.cholesterol_hdl", // should not be included according to https://dev.azure.com/cur8/TheRigg/_workitems/edit/12670/
  "bloodwork.cholesterol",
  "bloodwork.crp",
  "bloodwork.glucose",
  "bloodwork.haemoglobin",
  "bloodwork.hba1c",
  "bloodwork.hdl",
  "bloodwork.ldl",
  "bloodwork.non_hdl",
  "bloodwork.triglycerides",
  "bloodwork.white_blood_cells.basophils",
  "bloodwork.white_blood_cells.eosinophils",
  "bloodwork.white_blood_cells.lymphocytes",
  "bloodwork.white_blood_cells.neutrophils",
  "bloodwork.white_blood_cells.total",
  "body.grip_strength.left",
  "body.grip_strength.right",
  "body.height",
  "body.waist",
  "body.weight",
  "cardio.ankle_pressure.left",
  "cardio.ankle_pressure.right",
  "cardio.brachial_pressure.left",
  "cardio.brachial_pressure.right",
  "cardio.heart_rate",
  "cardio.oxygen_saturation",
  "cardio.respiratory_rate",
  "cardio.toe_pressure.left",
  "cardio.toe_pressure.right",
  "lifestyle.drinking-habit",
  "lifestyle.physical-activity",
  "lifestyle.smoking-habit",
  "lifestyle.snus-habit",
  "risk_assessment.congestive_heart_failure_history",
  "risk_assessment.diabetes_history",
  "risk_assessment.drug-induced_bleeding",
  "risk_assessment.ecg_discrepancy",
  "risk_assessment.eye_pressure.left",
  "risk_assessment.eye_pressure.right",
  //  "risk_assessment.eye_pressure.wears_contact_lenses",
  "risk_assessment.hypertension_history",
  "risk_assessment.kidney_disease",
  "risk_assessment.labile_inr",
  "risk_assessment.liver_disease",
  "risk_assessment.major_bleeding_history",
  "risk_assessment.stroke_history",
  "risk_assessment.thromboembolism_history",
  "risk_assessment.tia_history",
  "risk_assessment.vascular_disease_history",
];
