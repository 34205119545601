import { APITypesV1 } from "@cur8/api-client";
import {
  AktiiaMonitorBloodPressureAssignmentData,
  Assignment,
} from "@cur8/rich-entity";
import { useMemo } from "react";
import styles from "./styles.module.sass";

interface DataMeasurementDeviceInfoProps {
  assignment: Assignment<AktiiaMonitorBloodPressureAssignmentData>;
}

const LocationsRecord: Record<APITypesV1.WearableLocation, string> = {
  leftWrist: "left wrist",
  rightWrist: "right wrist",
  upperLeftArm: "upper left arm",
  upperRightArm: "upper right arm",
};

export function DataMeasurementDeviceInfo({
  assignment,
}: DataMeasurementDeviceInfoProps) {
  const { wearableLocation, deviceSerialNumber } = assignment.data;
  const position = useMemo(() => {
    return wearableLocation ? LocationsRecord[wearableLocation] : "--/--";
  }, [wearableLocation]);

  return (
    <div className={styles.DataMeasurementDeviceInfo}>
      Measured from the {position} by Aktiia 24/7 monitor ({deviceSerialNumber})
    </div>
  );
}
