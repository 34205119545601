import { Sex } from "@cur8/rich-entity";
import { ReactNode } from "react";

const SEX_MAP: Record<Sex, ReactNode> = {
  [Sex.Unknown]: "Unknown",
  [Sex.Female]: "Female",
  [Sex.Male]: "Male",
};

interface SexDefinitionProps {
  sex: Sex;
}

export function SexDefinition({ sex }: SexDefinitionProps) {
  return <>{SEX_MAP[sex]}</>;
}
