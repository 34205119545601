import { ReactNode, useCallback, useEffect, useState } from "react";
import { useNotifications } from "render/context/NotificationContext";
import { NotificationBar } from "./components/NotificationBar";
import styles from "./styles.module.sass";

const AUTO_HIDE_DELAY = 10000;

interface NotificationFrameProps {
  children: ReactNode;
}

export function NotificationFrame({ children }: NotificationFrameProps) {
  const { entries } = useNotifications();

  const [timedOpen, setTimedOpen] = useState<boolean>(false);
  const [userOpen, setUserOpen] = useState<boolean>(false);

  useEffect(() => {
    if (entries.length === 0) {
      return;
    }

    setTimedOpen(true);

    const timeout = setTimeout(() => {
      setTimedOpen(false);
    }, AUTO_HIDE_DELAY);

    return () => {
      clearTimeout(timeout);
    };
  }, [entries]);

  const handleInteraction = useCallback(() => {
    setUserOpen(true);
  }, []);

  const close = useCallback(() => {
    setUserOpen(false);
    setTimedOpen(false);
  }, []);

  const visible = userOpen || timedOpen;

  return (
    <div className={styles.NotificationFrame}>
      <div className={styles.content}>{children}</div>

      <div
        className={styles.notifications}
        onPointerDown={handleInteraction}
        style={{
          transform: visible ? "none" : "translateX(calc(100% + 32px))",
        }}
      >
        <NotificationBar onClose={close} />
      </div>
    </div>
  );
}
