import { downsample } from "lib/downsample";
import { normalize } from "lib/normalize";
import { useMemo } from "react";
import { useCardioGraphPattern } from "render/assets/svg/gradient";
import { CardioGrid } from "render/ui/graph/CardioGrid";
import styles from "./styles.module.sass";

function highest(values: number[]) {
  return Math.max(...values);
}

interface AudioGraphProps {
  volume: number[];
  duration?: number;
  progress?: number;
}

export function AudioGraph({
  volume,
  duration = 1,
  progress,
}: AudioGraphProps) {
  const sampleCount = 200;

  const samples = useMemo(() => {
    return volume
      .reduce(downsample(volume.length / sampleCount, highest), [])
      .map(normalize(0, 1));
  }, [volume]);

  const path = useMemo(() => {
    const d: string[] = [];
    samples.forEach((amp, index) => {
      const pos = (index * 2 + 1) / (samples.length * 2);
      const height = amp * 35;

      d.push(`
        M${pos * 100},${50 + height} 
        L${pos * 100},${50 - height} 
      `);
    });

    return d.join(" ");
  }, [samples]);

  const progressX = progress || 0;

  const pattern = useCardioGraphPattern({ progress: progressX });

  return (
    <svg
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      className={styles.AudioGraph}
    >
      <defs>{pattern.def}</defs>

      <CardioGrid vertical={[20, 40, 60, 80]} horizontal={[]} />

      <rect width="100" height="100" fill={pattern.fill.ref} />

      <path
        d={path}
        strokeWidth={2}
        vectorEffect="non-scaling-stroke"
        stroke={pattern.line.ref}
      />

      <line
        x1={progressX * 100}
        x2={progressX * 100}
        y1="0"
        y2="100"
        stroke="#019dc9"
        strokeWidth="1"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
