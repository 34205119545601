import { createCodec, createQuery } from "@pomle/paths";
import {
  PulsewaveType,
  PulsewaveTypes,
} from "render/pages/AssessmentPage/components/PulseWavePage/lib/types";
import { PROPERTIES, Property } from "render/pages/TissuePages/lib/types";
import { codecs } from "./codec";

/**
 * Assessment codecs
 */
const propertyCodec = createCodec<Property>(
  (source) => {
    return source;
  },
  (source: string) => {
    if (PROPERTIES.has(source as Property)) {
      return source as Property;
    }
    return Property.t1BloodVolume;
  }
);
const pulsewaveTypesCodec = createCodec<PulsewaveType>(
  (source: PulsewaveType) => {
    return source.toString();
  },
  (source: string) => {
    if (PulsewaveTypes.has(source as PulsewaveType)) {
      return source as PulsewaveType;
    }
    return PulsewaveType.lveti;
  }
);

/** END Assessment codecs */

const panorama = createQuery({
  camera: codecs.string,
  side: codecs.side,
});

const schedule = createQuery({
  from: codecs.date,
  to: codecs.date,
  assignPatientId: codecs.string,
  censorAppointments: codecs.boolean,
  slotManagement: codecs.boolean,
  roomId: codecs.string,
});

const atlas = createQuery({
  camera: codecs.string,
  side: codecs.side,
  view: codecs.string,
  selectedAnnotation: codecs.string,
  scanId: codecs.string,
  scanVersion: codecs.string,
});

const skinCRM = createQuery({
  tab: codecs.string,
});

const assessment = createQuery({
  property: propertyCodec,
  pulsewaveType: pulsewaveTypesCodec,
  roi: codecs.string,
  unhandledQueue: codecs.boolean,
});

const patientDetail = createQuery({
  tab: codecs.string,
});

export const query = {
  assessment,
  atlas,
  panorama,
  schedule,
  skinCRM,
  patientDetail,
};
