import { Patient, PulseWaveAssessment } from "@cur8/rich-entity";
import { useAssessmentNav } from "../../hooks/useAssessmentNav";
import { EjectionTime } from "./components/EjectionTime";
import { FootAsymmetry } from "./components/FootAsymmetry";
import { HandAsymmetry } from "./components/HandAsymmetry";
import { PWV } from "./components/PWV";
import { ButtonControl } from "./components/shared/ButtonControl";
import { RangeContext } from "./context/RangeContext";
import { CardioSignals } from "./hooks/useCardioSignalsTimestamps";
import { PulsewaveType } from "./lib/types";

interface RangeHandlerProps {
  patient: Patient;
  assessment: PulseWaveAssessment;
  signals: CardioSignals;
}

export function RangeHandler({
  patient,
  assessment,
  signals,
}: RangeHandlerProps) {
  const { pulsewaveType } = useAssessmentNav();

  return (
    <RangeContext
      assessment={assessment}
      ecgRange={signals.bioamp_ecg.range}
      patient={patient}
    >
      <ButtonControl />
      {pulsewaveType === PulsewaveType.lveti && (
        <EjectionTime patient={patient} signals={signals} />
      )}
      {pulsewaveType === PulsewaveType.pulseWaveVelocity && (
        <PWV patientId={patient.patientId} signals={signals} />
      )}
      {pulsewaveType === PulsewaveType.handAsymmetry && (
        <HandAsymmetry signals={signals} />
      )}
      {pulsewaveType === PulsewaveType.footAsymmetry && (
        <FootAsymmetry signals={signals} />
      )}
    </RangeContext>
  );
}
