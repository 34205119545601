import { ButtonHTMLAttributes } from "react";
import { IconButton } from "./IconButton";
import classes from "./styles.module.sass";

interface IconButtonProps extends ButtonHTMLAttributes<any> {
  label?: string;
}

export function TagIconButton({ label, ...props }: IconButtonProps) {
  return (
    <IconButton label={label} {...props}>
      <svg
        className={classes.tagIcon}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.59 13.8 2 10.2a1 1 0 0 1 0-1.4l7-7a1 1 0 0 1 .7-.3h3.6a1 1 0 0 1 1 1v3.59" />
        <path d="M11.8 3.75a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5" />
        <path d="M15.88 10.91 10.79 16l-2.5.5.5-2.5 5.09-5.09a1.41 1.41 0 0 1 2 0h0c.55.56.55 1.45 0 2Z" />
      </svg>
    </IconButton>
  );
}
