import { UserURI } from "@cur8/uri";
import { useUser } from "render/hooks/api/useUser";

interface UserDisplayNameProps {
  userUri: UserURI;
}

export function UserDisplayName({ userUri }: UserDisplayNameProps) {
  const user = useUser(userUri);

  if (!user || !user.data) {
    return <></>;
  }

  return <>{user.data.displayName}</>;
}
