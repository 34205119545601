import { APITypesV1 } from "@cur8/api-client";
import { Patient } from "@cur8/rich-entity";
import * as HeartAgeLib from "lib/heart-age";
import { convertQuestionnaire } from "lib/questionnaire/convert";
import { useMemo, useState } from "react";
import { useQuestionnaire } from "render/hooks/api/useQuestionnaire";
import { useAge } from "render/hooks/patient/useAge";
import { useHeartAge } from "render/hooks/patient/useHeartAge";
import { Typography } from "render/ui/presentation/Typography";
import InfoIcon from "./assets/info.svg?react";
import { HeartAgeGraph } from "./components/HeartAgeGraph";
import { RollingNumber } from "./components/RollingNumber";
import styles from "./styles.module.sass";

export type HeartAgeSectionState = "locked" | "idle" | "active";

interface HeartAgeSectionProps {
  patient: Patient;
}

export function HeartAgeSection({ patient }: HeartAgeSectionProps) {
  const age = useAge(patient);
  const { data: heartAgePrediction, error } = useHeartAge(patient);
  const { data: questionnaire } = useQuestionnaire({
    patientId: patient.patientId,
    visitId: undefined,
  });
  const [isSectionReadyToReveal, setIsSectionReadyToReveal] = useState(false);

  const state = useMemo(() => {
    if (heartAgePrediction.score == null) {
      return "locked";
    } else if (isSectionReadyToReveal) {
      return "active";
    } else {
      return "idle";
    }
  }, [isSectionReadyToReveal, heartAgePrediction.score]);

  const labels: {
    heartAge: string;
    unit: string;
    lockedTitle?: string;
    lockedReason?: string;
  } = useMemo(() => {
    if (error || heartAgePrediction.error) {
      if (
        heartAgePrediction.error ===
        APITypesV1.ReasonInvalid.ALREADY_HAD_A_CVD_EVENT
      ) {
        return {
          heartAge: "00",
          unit: "years",
          lockedTitle: "N/A",
          lockedReason: "Previous CVD",
        };
      }

      if (
        heartAgePrediction.error ===
          APITypesV1.ReasonInvalid.AGE_OUT_OF_RANGE &&
        age < HeartAgeLib.AgeInputBounds.min
      ) {
        return {
          heartAge: `<${HeartAgeLib.AgeInputBounds.min}`,
          unit: "years",
          lockedTitle: "N/A",
          lockedReason: `Unlock module at age ${HeartAgeLib.AgeInputBounds.min}`,
        };
      }

      if (
        heartAgePrediction.error ===
          APITypesV1.ReasonInvalid.AGE_OUT_OF_RANGE &&
        age > HeartAgeLib.AgeInputBounds.max
      ) {
        return {
          heartAge: `>${HeartAgeLib.AgeInputBounds.max}`,
          unit: "years",
          lockedTitle: "N/A",
          lockedReason: `Prediction not possible`,
        };
      }

      return {
        heartAge: "00",
        unit: "years",
        lockedTitle: "N/A",
        lockedReason: "Prediction failed",
      };
    }

    if (heartAgePrediction.score != null) {
      return {
        heartAge: heartAgePrediction.score.toString(),
        unit: "years",
      };
    }

    return {
      heartAge: "00",
      unit: "years",
      lockedTitle: "N/A",
      lockedReason: "Information missing",
    };
  }, [age, error, heartAgePrediction.score, heartAgePrediction.error]);

  const riskFactors: string = useMemo(() => {
    if (!heartAgePrediction.input) {
      return "";
    }

    const list = [];

    if (heartAgePrediction.input.cvd) {
      const lifestyle = convertQuestionnaire(questionnaire);
      list.push(lifestyle?.cardioConditions);
    }

    if (heartAgePrediction.input.chronicRenalDisease) {
      list.push("Kidney disease");
    }

    if (heartAgePrediction.input.diabetesStatus !== "None") {
      list.push("Diabetes");
    }

    if (heartAgePrediction.input.atrialFibrillation) {
      list.push("Afib");
    }

    return list.filter(Boolean).join(", ");
  }, [heartAgePrediction.input, questionnaire]);

  return (
    <div
      className={styles.HeartAgeSection}
      data-state={state}
      onClick={() => setIsSectionReadyToReveal(true)}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <Typography variant="title-s">Heart age</Typography>
          {riskFactors && (
            <div className={styles.riskFactors}>
              <InfoIcon />
              <Typography variant="body-s">{riskFactors}</Typography>
            </div>
          )}
        </div>
        <div className={styles.main}>
          <div className={styles.number}>
            <Typography variant="numeral-xl">
              {state === "locked" || heartAgePrediction.score == null ? (
                labels.heartAge
              ) : (
                <RollingNumber
                  value={state === "active" ? heartAgePrediction.score : age}
                />
              )}
            </Typography>
          </div>
          <div className={styles.unit}>
            <Typography variant="label-m">{labels.unit}</Typography>
          </div>
        </div>
        {state === "locked" && (
          <div className={styles.sub}>
            <Typography variant="body-m">{labels.lockedTitle}</Typography>
            <hr />
            <Typography variant="body-m">{labels.lockedReason}</Typography>
          </div>
        )}
      </div>
      <div className={styles.graph}>
        <HeartAgeGraph
          age={age}
          heartAge={heartAgePrediction.score}
          state={state}
          bounds={{ min: -10, max: 10 }}
        />
      </div>
    </div>
  );
}
