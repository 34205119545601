import { APITypesV1, APITypesV2 } from "@cur8/api-client";
import { Sex } from "@cur8/rich-entity";
import { bySystolic } from "lib/metric/sort";
import { createResolver } from "lib/questionnaire/resolve";
import * as QuestionnaireTypes from "lib/questionnaire/types";
import { mean, std } from "lib/stats";
import { toBMI } from "lib/types/metrics/body";
import { PatientMetrics } from "render/hooks/patient/usePatientMetrics";

/**
 * Age limits defined by the QRISK3 heart age API
 */
export const AgeInputBounds = {
  min: 25,
  max: 84,
} as const;

/**
 * Subset of our patient metrics used in the payload to the Heart Age API.
 */
export type HeartAgePatientMetrics = {
  body: Pick<PatientMetrics["body"], "weight" | "height">;
  bloodwork: Pick<PatientMetrics["bloodwork"], "hba1c" | "cholesterolHDL">;
  cardio: Pick<PatientMetrics["cardio"], "brachialPressure">;
};

/**
 * Subset of the available Heart Age API inputs that we are able to collect and send to the API.
 */
export type HeartAgeAPIPayload = {
  sex: APITypesV1.EPInputModel["sex"] | undefined;
} & Pick<
  APITypesV1.EPInputModel,
  | "requestedEngines"
  | "age"
  | "cvd"
  | "statins"
  | "alcoholStatus"
  | "smokingStatus"
  | "diabetesStatus"
  | "familyHistoryDiabetes"
  | "hba1c"
  | "chronicRenalDisease"
  | "atrialFibrillation"
  | "bloodPressureTreatment"
  | "cholesterolRatio"
  | "systolicBloodPressures"
  | "systolicBloodPressureMean"
  | "systolicBloodPressureStDev"
  | "bmi"
>;

export type HeartAgeAPIPayloadResolvers = {
  sex: (sex: Sex) => HeartAgeAPIPayload["sex"] | undefined;
  cvd: (
    questionnaire: APITypesV2.QuestionnaireResponse
  ) => HeartAgeAPIPayload["cvd"];
  statins: (
    questionnaire: APITypesV2.QuestionnaireResponse
  ) => HeartAgeAPIPayload["statins"];
  alcoholStatus: (
    questionnaire: APITypesV2.QuestionnaireResponse
  ) => HeartAgeAPIPayload["alcoholStatus"];
  smokingStatus: (
    questionnaire: APITypesV2.QuestionnaireResponse
  ) => HeartAgeAPIPayload["smokingStatus"];
  diabetesStatus: (
    questionnaire: APITypesV2.QuestionnaireResponse
  ) => HeartAgeAPIPayload["diabetesStatus"];
  familyHistoryDiabetes: (
    questionnaire: APITypesV2.QuestionnaireResponse
  ) => HeartAgeAPIPayload["familyHistoryDiabetes"];
  hba1c: (metrics: HeartAgePatientMetrics) => HeartAgeAPIPayload["hba1c"];
  chronicRenalDisease: (
    questionnaire: APITypesV2.QuestionnaireResponse
  ) => HeartAgeAPIPayload["chronicRenalDisease"];
  atrialFibrillation: (
    questionnaire: APITypesV2.QuestionnaireResponse
  ) => HeartAgeAPIPayload["atrialFibrillation"];
  bloodPressureTreatment: (
    questionnaire: APITypesV2.QuestionnaireResponse
  ) => HeartAgeAPIPayload["bloodPressureTreatment"];
  cholesterolRatio: (
    metrics: HeartAgePatientMetrics
  ) => HeartAgeAPIPayload["cholesterolRatio"];
  systolicBloodPressures: (
    metrics: HeartAgePatientMetrics
  ) => HeartAgeAPIPayload["systolicBloodPressures"];
  systolicBloodPressureMean: (
    metrics: HeartAgePatientMetrics
  ) => HeartAgeAPIPayload["systolicBloodPressureMean"];
  systolicBloodPressureStDev: (
    metrics: HeartAgePatientMetrics
  ) => HeartAgeAPIPayload["systolicBloodPressureStDev"];
  bmi: (metrics: HeartAgePatientMetrics) => HeartAgeAPIPayload["bmi"];
};

/**
 * Resolvers for converting our collected data to the format expected by the Heart Age API.
 */
export const resolvers: HeartAgeAPIPayloadResolvers = {
  sex: (sex) => {
    switch (sex) {
      case Sex.Male:
        return APITypesV1.HeartAgeSex.Male;
      case Sex.Female:
        return APITypesV1.HeartAgeSex.Female;
      default:
        return undefined;
    }
  },
  cvd: (questionnaire) => {
    const questionnaireResolver = createResolver(questionnaire);
    const answer = questionnaireResolver.findAnswer("cardioConditions");

    return (
      Array.isArray(answer) &&
      answer.some(
        (item) =>
          item === QuestionnaireTypes.CardioConditionsEnum.HeartAttack ||
          item === QuestionnaireTypes.CardioConditionsEnum.Stroke ||
          item === QuestionnaireTypes.CardioConditionsEnum.Angina
      )
    );
  },
  statins: (questionnaire) => {
    const questionnaireResolver = createResolver(questionnaire);
    const answer = questionnaireResolver.findAnswer("medications.type");

    return (
      Array.isArray(answer) &&
      answer.includes(QuestionnaireTypes.MedicationsTypeEnum.Cholesterol)
    );
  },
  alcoholStatus: (questionnaire) => {
    const questionnaireResolver = createResolver(questionnaire);

    const isDrinking =
      questionnaireResolver.findAnswer("alcohol") ===
      QuestionnaireTypes.YesNoEnum.Yes;

    if (!isDrinking) {
      return APITypesV1.AlcoholCat6.None;
    }

    const weeklyDrinks = questionnaireResolver.findAnswer(
      "alcohol.weeklyDrinks"
    ) as QuestionnaireTypes.DrinkChoicesEnum;

    switch (weeklyDrinks) {
      case QuestionnaireTypes.DrinkChoicesEnum.LessThanOne:
        return APITypesV1.AlcoholCat6.None;
      case QuestionnaireTypes.DrinkChoicesEnum.OneToTwo:
      case QuestionnaireTypes.DrinkChoicesEnum.ThreeToFour:
      case QuestionnaireTypes.DrinkChoicesEnum.FiveToSix:
      case QuestionnaireTypes.DrinkChoicesEnum.SevenToEight:
      case QuestionnaireTypes.DrinkChoicesEnum.MoreThanNine:
        return APITypesV1.AlcoholCat6.OneToTwoUnitsPerDay;
      default:
        return APITypesV1.AlcoholCat6.NotKnown;
    }
  },
  smokingStatus: (questionnaire) => {
    const questionnaireResolver = createResolver(questionnaire);

    const smokingStyle = questionnaireResolver.findAnswer("smoking.style");
    const smokingFrequency = questionnaireResolver.findAnswer(
      "smoking.dailyCigarettes"
    );

    const isCurrentSmoker =
      smokingStyle === QuestionnaireTypes.IsSmokingEnum.Yes;

    const isExSmoker = smokingStyle === QuestionnaireTypes.IsSmokingEnum.Quit;

    if (isCurrentSmoker) {
      if (
        smokingFrequency === QuestionnaireTypes.CigaretteCountEnum.OnePackOrMore
      ) {
        return APITypesV1.SmokeCat.HeavySmoker;
      } else if (
        smokingFrequency ===
        QuestionnaireTypes.CigaretteCountEnum.HalfAPackOrMore
      ) {
        return APITypesV1.SmokeCat.ModerateSmoker;
      } else {
        return APITypesV1.SmokeCat.LightSmoker;
      }
    } else if (isExSmoker) {
      return APITypesV1.SmokeCat.ExSmoker;
    } else {
      return APITypesV1.SmokeCat.NonSmoker;
    }
  },
  diabetesStatus: (questionnaire) => {
    const questionnaireResolver = createResolver(questionnaire);

    if (
      questionnaireResolver.findAnswer("medicalHistoryDiabetes") !==
      QuestionnaireTypes.YesNoEnum.Yes
    ) {
      return APITypesV1.DiabetesCat.None;
    }

    const diabetesType = questionnaireResolver.findAnswer(
      "medicalHistoryDiabetes.type"
    ) as QuestionnaireTypes.MedicalHistoryDiabetesTypeEnum;

    switch (diabetesType) {
      case QuestionnaireTypes.MedicalHistoryDiabetesTypeEnum.TypeOne:
        return APITypesV1.DiabetesCat.Type1;
      case QuestionnaireTypes.MedicalHistoryDiabetesTypeEnum.TypeTwo:
        return APITypesV1.DiabetesCat.Type2;
      default:
        return APITypesV1.DiabetesCat.None;
    }
  },
  familyHistoryDiabetes: (questionnaire) => {
    const questionnaireResolver = createResolver(questionnaire);

    return (
      questionnaireResolver.findAnswer("diabetes") ===
      QuestionnaireTypes.YesNoEnum.Yes
    );
  },
  chronicRenalDisease: (questionnaire) => {
    const questionnaireResolver = createResolver(questionnaire);
    const answer = questionnaireResolver.findAnswer("healthConditions");

    return (
      Array.isArray(answer) &&
      answer.includes(QuestionnaireTypes.HealthConditionsEnum.KidneyDisease)
    );
  },
  atrialFibrillation: (questionnaire) => {
    const questionnaireResolver = createResolver(questionnaire);
    const answer = questionnaireResolver.findAnswer("cardioConditions");

    return (
      Array.isArray(answer) &&
      answer.includes(
        QuestionnaireTypes.CardioConditionsEnum.AtrialFibrillation
      )
    );
  },
  bloodPressureTreatment: (questionnaire) => {
    const questionnaireResolver = createResolver(questionnaire);
    const answer = questionnaireResolver.findAnswer("medications.type");

    return (
      Array.isArray(answer) &&
      answer.includes(QuestionnaireTypes.MedicationsTypeEnum.BloodPressure)
    );
  },
  hba1c: (metrics) => {
    return metrics.bloodwork.hba1c?.at(0)?.unit["mmol/mol"];
  },
  cholesterolRatio: (metrics) => {
    return metrics.bloodwork.cholesterolHDL?.at(0)?.unit["fraction"];
  },
  systolicBloodPressures: (metrics) => {
    const NUM_OF_BP_READINGS_TO_INCLUDE = 5;
    const readings: number[] = [];

    for (let i = 0; i < NUM_OF_BP_READINGS_TO_INCLUDE; i++) {
      const left = metrics.cardio.brachialPressure.left?.at(i);
      const right = metrics.cardio.brachialPressure.right?.at(i);

      if (!left || !right) {
        continue;
      }

      const highest = [left, right].sort(bySystolic("desc")).at(0);

      if (!highest) {
        continue;
      }

      readings.push(highest.unit.systolic.mmHg);
    }

    return readings;
  },
  systolicBloodPressureMean: (metrics) => {
    const readings = resolvers.systolicBloodPressures(metrics);

    if (!readings?.length) {
      return undefined;
    }

    return mean(readings);
  },
  systolicBloodPressureStDev: (metrics) => {
    const readings = resolvers.systolicBloodPressures(metrics);

    if (!readings?.length) {
      return undefined;
    }

    return std(readings);
  },
  bmi: (metrics) => {
    const weight = metrics.body.weight;
    const height = metrics.body.height;

    if (!weight || !height || !weight.length || !height.length) {
      return undefined;
    }

    return toBMI(weight[0], height[0]);
  },
};

/**
 * Convert our collected biomarkers to the format expected by the Heart Age API.
 */
export function toPayload(data: {
  age: number;
  sex: Sex;
  metrics: HeartAgePatientMetrics;
  questionnaire: APITypesV2.QuestionnaireResponse;
}): HeartAgeAPIPayload {
  const { age, sex, metrics, questionnaire } = data;

  const payload: HeartAgeAPIPayload = {
    requestedEngines: [APITypesV1.Engines.QRisk3],
    age,
    sex: resolvers.sex(sex),
    cvd: resolvers.cvd(questionnaire),
    statins: resolvers.statins(questionnaire),
    alcoholStatus: resolvers.alcoholStatus(questionnaire),
    smokingStatus: resolvers.smokingStatus(questionnaire),
    diabetesStatus: resolvers.diabetesStatus(questionnaire),
    familyHistoryDiabetes: resolvers.familyHistoryDiabetes(questionnaire),
    chronicRenalDisease: resolvers.chronicRenalDisease(questionnaire),
    atrialFibrillation: resolvers.atrialFibrillation(questionnaire),
    bloodPressureTreatment: resolvers.bloodPressureTreatment(questionnaire),
    hba1c: resolvers.hba1c(metrics),
    cholesterolRatio: resolvers.cholesterolRatio(metrics),
    systolicBloodPressures: resolvers.systolicBloodPressures(metrics),
    systolicBloodPressureMean: resolvers.systolicBloodPressureMean(metrics),
    systolicBloodPressureStDev: resolvers.systolicBloodPressureStDev(metrics),
    bmi: resolvers.bmi(metrics),
  };

  const prunedPayload = Object.fromEntries(
    Object.entries(payload).filter(([, value]) => value != null)
  ) as HeartAgeAPIPayload;

  return prunedPayload;
}

export type ParsedHeartAgeAPIResponse = {
  input: APITypesV1.EPInputModel | undefined;
  score: number | undefined;
  error: string | undefined;
};

/**
 * Parse heart age and error from the Heart Age API response
 * See more info about the expected API response here: https://dev.endeavourpredict.org/catalog/api/13d7e8c6-60fd-4b1a-97e8-c660fd9b1a44?c=live
 */
export function toParsedResponse(
  response: APITypesV1.PredictionModel
): ParsedHeartAgeAPIResponse {
  const input = response.epInputModel;

  const heartAge =
    response.engineResults?.[0]?.results?.find(
      (result) => result.id === "http://endhealth.info/im#Qrisk3HeartAge"
    )?.score ?? undefined;

  const error =
    response.engineResults?.[0].calculationMeta?.engineResultStatusReason !==
    "VALID"
      ? response.engineResults?.[0].calculationMeta?.engineResultStatusReason
      : undefined;

  return {
    input,
    score: heartAge,
    error,
  };
}
