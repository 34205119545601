import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface BannerProps {
  children: ReactNode;
}

export function Banner({ children }: BannerProps) {
  return <div className={styles.Banner}>{children}</div>;
}
