import { APITypesV1 } from "@cur8/api-client";
import {
  AktiiaMonitorBloodPressureAssignmentData,
  Assignment,
  fromAPI,
} from "@cur8/rich-entity";
import { DateTime } from "luxon";
import { useCallback, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { Checkbox } from "render/ui/form/Checkbox";
import { PopupDialog } from "render/ui/layouts/PopupDialog";
import { PopupDialogContent } from "render/ui/layouts/PopupDialogContent/PopupDialogContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import styles from "./styles.module.sass";

interface CompleteAssignmentDialogProps {
  assignment: Assignment<AktiiaMonitorBloodPressureAssignmentData>;
  onDone(): void;
  onSuccess: (assignment: Assignment) => void;
}

type Entries = {
  correctDevice: boolean;
  deviceCharged: boolean;
  deviceUnpaired: boolean;
};

export function ReturnBloodMonitorDeviceDialog({
  onSuccess,
  onDone,
  assignment,
}: CompleteAssignmentDialogProps) {
  const api = useAPIClient();

  const handleComplete = useCallback(async () => {
    const result = await api.assignment.updateAssignmentInfoFor(
      assignment.patientId,
      assignment.id,
      {
        eTag: assignment.eTag ?? "",
        action: APITypesV1.AssignmentAction.Close,
        returnedAt: DateTime.now().toISO(),
      }
    ).result;
    onSuccess(fromAPI.toAssignment(result));
  }, [api, assignment, onSuccess]);

  const [entries, setEntries] = useState<Entries>({
    correctDevice: false,
    deviceCharged: false,
    deviceUnpaired: false,
  });

  const isValid = useMemo(
    () => !Object.values(entries).some((v) => !v),
    [entries]
  );

  const updateValues = useCallback((values: Partial<Entries>) => {
    setEntries((v) => ({ ...v, ...values }));
  }, []);

  const completeHandle = useAsyncHandle(handleComplete);

  return (
    <PopupDialog>
      <PopupDialogContent
        head={<h2>Return device</h2>}
        body={
          <div className={styles.CompleteAssignmentDialog}>
            <div className={styles.form}>
              <p>
                Please check this list to confirm the return before proceeding.
              </p>
              <div className={styles.todo}>
                <Checkbox
                  label={`Device serial number is ${assignment.data.deviceSerialNumber}`}
                  checked={entries.correctDevice}
                  onChange={(value) => updateValues({ correctDevice: value })}
                />
                <Checkbox
                  label="Device has been unpaired from the member’s phone"
                  checked={entries.deviceUnpaired}
                  onChange={(value) => updateValues({ deviceUnpaired: value })}
                />
                <Checkbox
                  label="Device is charged"
                  checked={entries.deviceCharged}
                  onChange={(value) => updateValues({ deviceCharged: value })}
                />
              </div>
            </div>

            <div className={styles.author}></div>
          </div>
        }
        buttons={
          <>
            <HoverTextButton type="button" onClick={onDone}>
              Cancel
            </HoverTextButton>
            <ActionButton disabled={!isValid} handle={completeHandle}>
              Return device
            </ActionButton>
          </>
        }
      />
    </PopupDialog>
  );
}
