import { ReactNode, useCallback, useEffect, useState } from "react";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import styles from "./styles.module.sass";

interface RightDrawerProps {
  title: string;
  onClose?: () => void;
  children: ReactNode;
  active: boolean;
}

export function RightDrawer({
  title,
  onClose,
  children,
  active,
}: RightDrawerProps) {
  const [renderComplete, setRenderComplete] = useState(false);

  useEffect(() => {
    if (active) {
      setRenderComplete(true);
    }
  }, [active]);

  const onAnimationEnd = useCallback(() => {
    setRenderComplete(active);
  }, [active]);

  return (
    <div
      data-active={active}
      data-complete={renderComplete}
      className={styles.RightDrawer}
      onAnimationEnd={onAnimationEnd}
    >
      <h1>{title}</h1>
      <div className={styles.children}>{children}</div>
      {onClose ? (
        <div className={styles.footer}>
          <HoverTextButton onClick={onClose}>Close</HoverTextButton>
        </div>
      ) : null}
    </div>
  );
}
