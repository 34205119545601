import { ReactNode } from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { base16AteliersulphurpoolLight as theme } from "react-syntax-highlighter/dist/esm/styles/prism";
import styles from "./style.module.sass";

interface MarkdownTextProps {
  children: ReactNode;
}

export function MarkdownText({ children }: MarkdownTextProps) {
  return (
    <ReactMarkdown
      className={styles.MarkdownText}
      components={{
        code(props) {
          const { children, className, node, ...rest } = props;
          const match = /language-(\w+)/.exec(className || "");
          return match ? (
            <SyntaxHighlighter
              {...rest}
              children={String(children).replace(/\n$/, "")}
              style={theme}
              language={match[1]}
              PreTag="div"
              ref={null}
            />
          ) : (
            <code {...rest} className={className}>
              {children}
            </code>
          );
        },
        p({ node, ...props }) {
          return <p {...props} />;
        },
      }}
    >
      {children?.toString()}
    </ReactMarkdown>
  );
}
