import { SkinBookTracking } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { paths } from "render/routes/paths";
import { DayDate } from "render/ui/format/DayDate";
import { ArrowIcon } from "render/ui/symbol/ArrowIcon";
import { PaginatedTable } from "../PaginatedTable";
import { PaginatedTableProps } from "../PaginatedTable/PaginatedTable";
import RemoveFromDermaOpsAtriumMenu from "../RemoveFromDermaOpsAtriumMenu";
import { SkinBookStatus } from "../SkinBookStatus";
import styles from "./styles.module.sass";

type DermaTableProps = Pick<
  PaginatedTableProps<SkinBookTracking>,
  "data" | "fetchStatus" | "hasMoreData" | "onLoadMore"
> & {
  onRemoveItem: (patientId: string, scanId: string) => void;
};
export function DermaTable({
  data,
  fetchStatus,
  hasMoreData,
  onLoadMore,
  onRemoveItem,
}: DermaTableProps) {
  const nav = {
    lesions: useNav(paths.patient.lesions),
  };

  return (
    <div className={styles.DermaTable}>
      <PaginatedTable
        data={data}
        fetchStatus={fetchStatus}
        hasMoreData={hasMoreData}
        onLoadMore={onLoadMore}
        renderHeader={() => (
          <tr>
            <th>Member</th>
            <th>Member ID</th>
            <th>Scan</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        )}
        renderRow={(item) => (
          <tr
            key={`${item.scanId}-${item.patientId}-${item.audit.created?.timeStamp}`}
          >
            <td>{item.patientDisplayName}</td>
            <td>{item.patientId}</td>
            <td>
              {item.scanCreatedAt ? (
                <DayDate date={item.scanCreatedAt} />
              ) : (
                "N/A"
              )}
            </td>
            <td>
              <SkinBookStatus item={item} />
            </td>
            <td className={styles.actionCol}>
              <RemoveFromDermaOpsAtriumMenu
                item={item}
                childText="Remove from Derma"
                onRemove={() => onRemoveItem(item.patientId, item.scanId)}
              />
              <a
                href={nav.lesions.to({ patientId: item.patientId })}
                target="_blank"
                rel="noreferrer"
              >
                Lesions
                <ArrowIcon direction="right" />
              </a>
            </td>
          </tr>
        )}
      />
    </div>
  );
}
