import { Site, fromAPI } from "@cur8/rich-entity";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { FramedPage } from "render/ui/layouts/FramedPage/FramedPage";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { Calendar } from "./components/Calendar";
import { RoomDropDown } from "./components/RoomDropDown";
import { SchedulePageHeader } from "./components/SchedulePageHeader";
import { SchedulePageSubHeader } from "./components/SchedulePageSubHeader";
import { ScheduleContext } from "./context/ScheduleContext";
import styles from "./styles.module.sass";

interface SchedulePageProps {
  siteId: string;
}

export function SchedulePage({ siteId }: SchedulePageProps) {
  const apiClient = useAPIClient();
  const [sites, setSites] = useState<Site[]>();

  useEffect(() => {
    const req = apiClient.sites.querySites();

    req.result
      .then((result) => result.items.map(fromAPI.toSite))
      .then(setSites);

    return () => {
      setSites(undefined);
      req.abandon();
    };
  }, [apiClient]);

  const name = sites?.find((site) => site.siteId === siteId)?.siteName ?? "--";

  return (
    <ScheduleContext siteId={siteId}>
      <FramedPage>
        <div className={styles.SchedulePage}>
          <div>
            <div className={styles.header}>
              <SchedulePageHeader />
              <PageHeader caption={name}>
                <RoomDropDown siteId={siteId} />
              </PageHeader>
            </div>
            <SchedulePageSubHeader />
          </div>
          <Calendar />
        </div>
      </FramedPage>
    </ScheduleContext>
  );
}
