import { DateTime } from "luxon";
import styles from "./styles.module.sass";

interface BloodPressureTableFooterProps {
  days: DateTime[];
}

export function BloodPressureTableFooter({
  days,
}: BloodPressureTableFooterProps) {
  return (
    <div className={styles.BloodPressureTableFooter}>
      <div className={styles.days}>
        {days.map((day) => {
          return (
            <div key={day.toISO()} className={styles.day}>
              <div className={styles.weekDay}>{day.day}</div>
              <div className={styles.weekDayShort}>{day.weekdayShort}</div>
            </div>
          );
        })}
      </div>
      <div />
    </div>
  );
}
