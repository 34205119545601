import { Patient } from "@cur8/rich-entity";
import { RecordingURI } from "@cur8/uri";
import { Camera } from "lib/api/rigg";
import { useCallback, useState } from "react";
import { Derma1 } from "render/fragments/regulatory/Derma-1";
import { useThermalURIs } from "render/hooks/api/useThermalURIs";
import { useKeyboardPress } from "render/hooks/useKeyboardPress";
import { NextIcon, PrevIcon } from "render/ui/symbol/HoverIcon";
import { FigureButton } from "render/ui/trigger/FigureButton";
import { AssessmentHandler } from "../AssessmentHandler";
import { Carousel } from "./components/Carousel";
import { ThermalScanItem } from "./components/ThermalScanItem";
import styles from "./styles.module.sass";

const THERMAL_CAMERAS: Camera[] = ["c3", "b3", "c2", "b2", "c1", "b1"];

interface ThermalPageProps {
  patient: Patient;
  recordingUri: RecordingURI;
}

export function ThermalPage({ patient, recordingUri }: ThermalPageProps) {
  const thermalURIs = useThermalURIs({
    deviceId: recordingUri.deviceId,
    recordingId: recordingUri.recordingId,
    cameras: THERMAL_CAMERAS,
  });
  const [activeIndex, setIndex] = useState<number>(0);

  const flipImage = useCallback(
    (selectedIndex: number) => {
      if (!thermalURIs || !thermalURIs[selectedIndex] || selectedIndex < 0) {
        return;
      }
      setIndex(selectedIndex);
    },
    [thermalURIs]
  );

  const handleKeyboard = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === "ArrowLeft") {
        flipImage(activeIndex - 1);
      } else if (event.code === "ArrowRight") {
        flipImage(activeIndex + 1);
      }
    },
    [activeIndex, flipImage]
  );
  useKeyboardPress(handleKeyboard);

  if (!thermalURIs) {
    return <h1>Fetching thermal...</h1>;
  }

  return (
    <div className={styles.ThermalPage}>
      <div className={styles.AssessmentSidebox}>
        <AssessmentHandler />
      </div>
      <div className={styles.plotArea}>
        <main>
          <div className={styles.images}>
            <Carousel activeIndex={activeIndex}>
              {thermalURIs.map((uri, index) => (
                <div
                  key={index}
                  onClick={() => flipImage(index)}
                  style={{
                    cursor: index === activeIndex ? "pointer" : "default",
                  }}
                >
                  <ThermalScanItem key={index} thermalURI={uri} />
                </div>
              ))}
            </Carousel>
          </div>
        </main>
        <footer>
          <div className={styles.ceMarking}>(i)</div>
          <div className={styles.index}>
            {activeIndex + 1} / {thermalURIs.length}
          </div>

          <div className={styles.control}>
            <FigureButton
              disabled={activeIndex - 1 < 0}
              onClick={() => flipImage(activeIndex - 1)}
              title="Previous"
            >
              <PrevIcon color="black" />
            </FigureButton>
            <FigureButton
              disabled={activeIndex + 1 >= thermalURIs.length}
              onClick={() => flipImage(activeIndex + 1)}
              title="Next"
            >
              <NextIcon color="black" />
            </FigureButton>
          </div>
        </footer>
      </div>
      <Derma1 patientId={patient.patientId} />
    </div>
  );
}
