export function groupBy<T>(
  array: readonly T[],
  getKey: (item: T) => string
): Record<string, readonly T[]> {
  const groups: Record<string, T[]> = {};
  for (const item of array) {
    const key = getKey(item);
    const group = (groups[key] ??= []);
    group.push(item);
  }
  return groups;
}
