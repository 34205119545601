import { Assessment, isThermalAssessment } from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { toReadableState } from "../../lib/utils";
import { AssessedBy } from "./AssessedBy";
import styles from "./styles.module.sass";

interface DisplayAssessmentProps {
  assessment: Assessment;
}

export function DisplayAssessment({ assessment }: DisplayAssessmentProps) {
  return (
    <div
      className={classNames(styles.DisplayAssessment, styles.assessmentInfo)}
      data-state={assessment.assessmentState}
    >
      {isThermalAssessment(assessment) && (
        <div className={styles.item}>
          <label>Status</label>
          <span className={styles.status} data-status={assessment.status}>
            {assessment.status}
          </span>
        </div>
      )}
      <div className={styles.item}>
        <label>State</label>
        <span className={styles.state}>
          {toReadableState(assessment.assessmentState)}
        </span>
      </div>
      {assessment.comment && (
        <div className={styles.item}>
          <label>Comment</label>
          <span className={styles.comment}>{assessment.comment}</span>
        </div>
      )}
      <div className={styles.byline}>
        <label>Created</label>
        <span>{assessment.createdAt.toFormat("yyyy-LL-dd HH:mm")}</span>
      </div>
      {assessment.audit && (
        <div className={styles.byline}>
          <label>by</label>
          <span>
            <AssessedBy auditInfo={assessment.audit} />
          </span>
        </div>
      )}
    </div>
  );
}
