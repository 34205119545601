import { BoundingBoxDataType, Box, LineDataType } from "@cur8/rich-entity";
import { Line, Point } from "lib/math";
import { PointerEvent, useCallback, useEffect, useState } from "react";
import { useTissueAnnotationContext } from "render/pages/TissuePages/context/TissueAnnotationContext";
import { useMessagePopup } from "render/pages/TissuePages/hooks/useMessagePopup";
import {
  CreateTissueAnnotation,
  ImageSize,
} from "render/pages/TissuePages/lib/types";
import {
  checkRoIMaxSize,
  lineWithinBoundry,
  roiWithinBoundry,
} from "render/pages/TissuePages/lib/utils";
import { SvgLine } from "./SvgLine";
import { SvgRect } from "./SvgRect";
import styles from "./styles.module.sass";

type EditState = "init" | "dragging" | "naming" | "done";

interface AddAnnotationProps {
  canvasSize: ImageSize;
  onCancelCreate: () => void;
  onCreated: () => void;
  scale: number;
}

export function AddAnnotation({
  canvasSize,
  onCancelCreate,
  onCreated,
  scale,
}: AddAnnotationProps) {
  const { emitDialog } = useMessagePopup();
  const { newAnno, setData } = useTissueAnnotationContext();

  const [line, setLine] = useState<Line>();
  const [box, setBox] = useState<Box>();
  const [state, setState] = useState<EditState>("init");

  useEffect(() => {
    if (!newAnno) {
      setState("init");
    }
  }, [newAnno]);

  const handlePointerDown = useCallback(
    (event: PointerEvent) => {
      if (event.buttons !== 1 || !newAnno || state === "naming") {
        return;
      }
      event.preventDefault();
      event.currentTarget.setPointerCapture(event.pointerId);
      const rect = event.currentTarget.getBoundingClientRect();
      const a = new Point(
        Math.round((event.clientX - rect.left) / scale),
        Math.round((event.clientY - rect.top) / scale)
      );
      setLine(new Line(a, a));
    },
    [newAnno, scale, state]
  );

  const handlePointerMove = useCallback(
    (event: PointerEvent) => {
      if (event.buttons !== 1 || !newAnno || state === "naming" || !line) {
        return;
      }
      event.preventDefault();
      setState("dragging");
      const rect = event.currentTarget.getBoundingClientRect();
      const b = new Point(
        Math.round((event.clientX - rect.left) / scale),
        Math.round((event.clientY - rect.top) / scale)
      );
      if (newAnno.type === "roi") {
        // Max size check for RoI's
        const nl = checkRoIMaxSize(line.a, b);
        if (roiWithinBoundry(nl.toBox(), canvasSize)) {
          setLine(nl);
          setBox(nl.toBox());
        } else {
          emitDialog(
            "Can't create RoI",
            "The RoI seams to be out of bounds. It needs to be completely within the image."
          );
          onCancelCreate();
        }
      } else {
        const nl = new Line(line.a, b);
        if (lineWithinBoundry(nl, canvasSize)) {
          setLine(new Line(line.a, b));
        } else {
          emitDialog(
            "Can't create Line",
            "The line seams to be out of bounds. It needs to be completely within the image."
          );
          onCancelCreate();
        }
      }
    },
    [canvasSize, emitDialog, line, newAnno, onCancelCreate, scale, state]
  );

  const handlePointerUp = useCallback(
    (event: PointerEvent) => {
      if (!newAnno || state === "naming" || !line) {
        return;
      }
      event.preventDefault();
      event.currentTarget.releasePointerCapture(event.pointerId);
      switch (newAnno.type) {
        case "line":
          setData({ line: line });
          break;
        case "roi":
          if (!box) {
            return;
          }
          setData({ rect: box });
          break;
        default:
          console.warn("Unsupported type");
          return;
      }
      setState("naming");
      onCreated();
    },
    [box, line, newAnno, onCreated, setData, state]
  );

  if (!newAnno) {
    return <></>;
  }

  return (
    <>
      {line && state !== "init" && newAnno.type === "line" && (
        <SvgLine
          anno={newAnno as CreateTissueAnnotation<LineDataType>}
          canvasSize={canvasSize}
          isSelected={true}
          line={line}
          scale={scale}
        />
      )}
      {box && state !== "init" && newAnno.type === "roi" && (
        <SvgRect
          anno={newAnno as CreateTissueAnnotation<BoundingBoxDataType>}
          box={box}
          canvasSize={canvasSize}
          isSelected={true}
          scale={scale}
        />
      )}
      <rect
        className={styles.AddAnno}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        x={0}
        y={0}
        width={canvasSize.width}
        height={canvasSize.height}
      />
    </>
  );
}
