import { ButtonHTMLAttributes } from "react";
import BackIcon from "./assets/back.svg?react";
import styles from "./styles.module.sass";

export function BackButton({ ...props }: ButtonHTMLAttributes<any>) {
  return (
    <button type="button" className={styles.BackButton} {...props}>
      <BackIcon />
    </button>
  );
}
