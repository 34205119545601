import * as THREE from "three";

export type Projector = ReturnType<typeof createProjector>;

export function createProjector(
  camera: THREE.PerspectiveCamera,
  parent: THREE.Object3D
) {
  const buffer = new THREE.Vector3();
  const offset = new THREE.Vector3();

  function getPositions(sources: THREE.Vector3[]) {
    const mapped = sources.map((source) => {
      buffer.copy(source);
      buffer.applyMatrix4(parent.matrixWorld);
      buffer.project(camera);
      const output = buffer.clone();
      offset.subVectors(camera.position, buffer);
      output.z = offset.length();
      return output;
    });

    return mapped;
  }

  function getPosition(source: THREE.Vector3) {
    return getPositions([source])[0];
  }

  return { getPosition, getPositions };
}
