import { MouseEvent } from "react";
import { Marking } from "../../../../../../../../types";
import { AnnotationArea } from "./components/AnnotationArea";
import { WeakAnnotationArea } from "./components/WeakAnnotationArea";

interface MarkingBoxProps {
  marking: Marking;
  onClick: (event?: MouseEvent<Element>) => void;
  onSelectedEvent: (selected: boolean) => void;
  highlight?: boolean;
}

export function MarkingBox({
  marking,
  onClick,
  onSelectedEvent,
  highlight,
}: MarkingBoxProps) {
  if (marking.type === "confirmed") {
    return (
      <AnnotationArea
        annotation={marking.annotation}
        onClick={onClick}
        onSelectedEvent={onSelectedEvent}
        highlight={highlight}
      />
    );
  }

  return (
    <WeakAnnotationArea
      annotation={marking.annotation}
      onClick={onClick}
      onSelectedEvent={onSelectedEvent}
    />
  );
}
