import { Slot } from "@cur8/rich-entity";

interface Props {
  slot: Slot | null;
}
export function SlotTime({ slot }: Props) {
  if (!slot) {
    return null;
  }
  const timeZoneId = slot.room?.site?.timeZoneId;

  return (
    <>
      {slot.startTime.setZone(timeZoneId).toFormat("HH:mm")} -
      {slot.endTime.setZone(timeZoneId).toFormat("HH:mm")}
    </>
  );
}
