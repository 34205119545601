import { APITypesV1 } from "@cur8/api-client";
import { SkinBookTracking } from "@cur8/rich-entity";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";
import { DropdownMenuButton } from "render/ui/trigger/DropdownMenuButton";
import { DropdownItem } from "render/ui/trigger/DropdownMenuButton/components/DropdownItem";

interface SendSMSMenuProps {
  item: SkinBookTracking;
  onActionCompleted: () => void;
}

export function SendSMSMenu({ item, onActionCompleted }: SendSMSMenuProps) {
  const api = useAPIClient();
  const { handleError } = useReporting();

  const handleNothingSMS = useCallback(async () => {
    await api.skinBook
      .sendNothingSMS(item.patientId, {
        scanId: item.scanId,
        summaryText: APITypesV1.NothingSMSVisitSummaryText.Nothing,
      })
      .result.catch(handleError);
    onActionCompleted();
  }, [
    api.skinBook,
    handleError,
    item.patientId,
    item.scanId,
    onActionCompleted,
  ]);

  const handleDermoSMS = useCallback(async () => {
    await api.skinBook
      .sendNothingSMS(item.patientId, {
        scanId: item.scanId,
        summaryText: APITypesV1.NothingSMSVisitSummaryText.Dermo,
      })
      .result.catch(handleError);
    onActionCompleted();
  }, [
    api.skinBook,
    handleError,
    item.patientId,
    item.scanId,
    onActionCompleted,
  ]);

  return (
    <DropdownMenuButton header="Send SMS">
      <DropdownItem onClick={handleNothingSMS}>Nothing SMS</DropdownItem>
      <DropdownItem onClick={handleDermoSMS}>Dermo SMS</DropdownItem>
    </DropdownMenuButton>
  );
}
