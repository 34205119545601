import { ImmutableScan } from "@cur8/rich-entity";
import { ReactNode } from "react";
import { Property, TissueContextMode } from "../../lib/types";
import { Context, useValue } from "./context";

interface TissueImageContextProps {
  scan: ImmutableScan;
  property: Property;
  index: number;
  maxIndex: number;
  mode?: TissueContextMode;
  version: number;
  hasBloodVesselsMask: boolean;
  indexRemap?: number[];
  children: ReactNode;
}

export function TissueImageContext({
  scan,
  property,
  index,
  maxIndex,
  mode = TissueContextMode.Regular,
  version,
  hasBloodVesselsMask,
  indexRemap,
  children,
}: TissueImageContextProps) {
  const value = useValue(
    scan,
    property,
    index,
    maxIndex,
    mode,
    version,
    hasBloodVesselsMask,
    indexRemap
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
