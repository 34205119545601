import { ButtonHTMLAttributes } from "react";
import { IconButton } from "./IconButton";
import classes from "./styles.module.sass";

interface IconButtonProps extends ButtonHTMLAttributes<any> {
  label?: string;
}

export function CameraIconButton({ label, ...props }: IconButtonProps) {
  return (
    <IconButton label={label} {...props}>
      <svg
        className={classes.cameraIcon}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M 14.9538 4.8179 V 3.1462 h -1.6717 V 1.9799 h 1.6717 V 0.2888 h 1.1663 V 1.9799 H 17.8113 v 1.1663 h -1.6911 v 1.6717 Z M 1.7551 15.9756 q -0.4665 0 -0.8164 -0.3499 q -0.3499 -0.3499 -0.3499 -0.8164 V 4.8374 q 0 -0.4471 0.3499 -0.8067 q 0.3499 -0.3596 0.8164 -0.3596 h 2.8575 L 6.0316 1.9799 h 5.4428 v 1.1663 H 6.5758 l -1.419 1.6911 H 1.7551 V 14.8093 h 13.2182 V 6.6452 h 1.1663 v 8.1642 q 0 0.4665 -0.3596 0.8164 q -0.3596 0.3499 -0.8067 0.3499 Z m 6.6091 -2.838 q 1.3996 0 2.3521 -0.9525 q 0.9525 -0.9525 0.9525 -2.3715 q 0 -1.3996 -0.9525 -2.3423 Q 9.7637 6.5285 8.3642 6.5285 q -1.419 0 -2.3618 0.9428 Q 5.0596 8.4141 5.0596 9.8136 q 0 1.419 0.9428 2.3715 Q 6.9452 13.1376 8.3642 13.1376 Z m 0 -1.1663 q -0.933 0 -1.5356 -0.6123 q -0.6026 -0.6123 -0.6026 -1.5454 q 0 -0.9136 0.6026 -1.5162 q 0.6026 -0.6026 1.5356 -0.6026 q 0.9136 0 1.5259 0.6026 q 0.6123 0.6026 0.6123 1.5162 q 0 0.933 -0.6123 1.5454 Q 9.2778 11.9713 8.3642 11.9713 Z m 0 -2.1382 Z" />
      </svg>
    </IconButton>
  );
}
