import { Annotation } from "@cur8/rich-entity";
import { useCallback, useState } from "react";
import { CameraIconButton } from "render/ui/trigger/IconButton/CameraIconButton";
import { QRCodeOverlay } from "./components/QRCodeOverlay";
import styles from "./styles.module.sass";

enum CodeState {
  None,
  Shown,
}

interface RetakeLayerProps {
  annotation: Annotation;
  onDone: () => void;
}

export function RetakeLayer({
  annotation: sourceAnnotation,
  onDone,
}: RetakeLayerProps) {
  const [state, setState] = useState<CodeState>(CodeState.None);

  const handleTake = useCallback(() => {
    setState(CodeState.Shown);
  }, []);

  const handleClose = useCallback(() => {
    setState(CodeState.None);
    onDone();
  }, [onDone]);
  return (
    <div className={styles.RetakeLayer}>
      <div className={styles.buttons}>
        <CameraIconButton label="Take new" onClick={handleTake} />
      </div>
      <div
        className={styles.overlay}
        data-state={state === CodeState.Shown ? "visible" : "hidden"}
      >
        <QRCodeOverlay annotation={sourceAnnotation} onClose={handleClose} />
      </div>
    </div>
  );
}
