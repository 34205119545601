import { AnklePressure, Risk } from "@cur8/health-risks-calc";
import { Metric } from "lib/metric";
import { byPressure } from "lib/metric/sort";
import { useMemo } from "react";
import { RISK_COLORS } from "render/risk";
import { FloatingValueTarget } from "render/ui/presentation/FloatingValueTarget";
import { ValueItem } from "render/ui/symbol/ValueItem";

interface MaxAnklePressureProps {
  anklePressure: {
    left: Metric<"cardio.ankle_pressure.left">[] | undefined;
    right: Metric<"cardio.ankle_pressure.right">[] | undefined;
  };
}

export function MaxAnklePressure({
  anklePressure: { left, right },
}: MaxAnklePressureProps) {
  const highestPressure = useMemo(() => {
    if (!left || !right) {
      return;
    }

    return [...left.slice(0, 1), ...right.slice(0, 1)]
      .sort(byPressure("desc"))
      .at(0);
  }, [left, right]);

  const risk = useMemo(() => {
    if (highestPressure) {
      return AnklePressure.rangesFor().findRisk(highestPressure.unit);
    }
  }, [highestPressure]);

  const color = RISK_COLORS[risk ?? Risk.Unknown];

  return (
    <FloatingValueTarget
      label="Ankle Pressure"
      value={
        <ValueItem
          value={highestPressure ? highestPressure.unit.mmHg : "--"}
          unit="mmHg"
          size="huge"
        />
      }
      align="right"
      width={200}
      color={color}
    />
  );
}
