import { ComponentProps, RefObject } from "react";
import { type Point } from "../lib/svg";
import { useProjectionGraphContext } from "../ProjectionGraph.context";
import styles from "../styles.module.sass";

export type Highlight = "none" | "danger" | "warning" | "normal";
export type Variant = "primary" | "secondary" | "outlined";

interface ProjectionMarkerProps extends ComponentProps<"div"> {
  markerRef?: RefObject<HTMLDivElement>;
  point: Point;
  highlight?: Highlight;
  variant?: Variant;
}

export function ProjectionMarker({
  markerRef,
  point,
  variant = "primary",
  highlight = "none",
  style = {},
  className = "",
  ...props
}: ProjectionMarkerProps) {
  const { Xpercent, Ypercent } = useProjectionGraphContext();

  return (
    <div
      ref={markerRef}
      className={`${styles.ProjectionMarkerContainer} ${className}`}
      style={{
        left: `${Xpercent(point.x)}%`,
        bottom: `${Ypercent(point.y)}%`,
        ...style,
      }}
      {...props}
    >
      <div
        className={styles.ProjectionMarker}
        data-variant={variant}
        data-highlight={highlight}
      >
        <div className={styles.inner} />
      </div>
    </div>
  );
}
