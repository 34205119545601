import { PanoramaImageURI } from "lib/api/uri";
import { usePanorama } from "render/hooks/usePanorama";
import { BusyPlaceholder } from "render/ui/throbber/BusyPlaceholder";

const SIZE = { w: 1024, h: 1024 };

interface PanoramaImageProps {
  uri: PanoramaImageURI;
}

export function PanoramaImage({ uri }: PanoramaImageProps) {
  const image = usePanorama(uri, SIZE);

  if (!image) {
    return <BusyPlaceholder />;
  }

  return <img src={image.src} alt="" />;
}
