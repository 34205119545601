import { APITypesV1 } from "@cur8/api-client";
import { Assessment, Patient } from "@cur8/rich-entity";
import { createContext, ReactNode } from "react";
import { useRanges } from "./lib";

interface RangeContextProps {
  assessment: Assessment;
  children: ReactNode;
  ecgRange: APITypesV1.Range;
  patient: Patient;
}
type RangeContextValue = ReturnType<typeof useRanges>;

export const Context = createContext<RangeContextValue | null>(null);

export function RangeContext({
  assessment,
  children,
  ecgRange,
  patient,
}: RangeContextProps) {
  const value = useRanges({
    assessment,
    ecgRange,
    patient,
  });

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
