import { Sex } from "@cur8/rich-entity";
import { useMemo } from "react";
import { getLVETIrange, RangeLabel } from "../../lib/calculations";
import { LVETISelection } from "../../lib/types";
import styles from "./styles.module.sass";

interface SelectionBoxProps {
  idx: number;
  data?: LVETISelection;
  selected: boolean;
  setSelected: () => void;
  sex?: Sex;
}

export function SelectionBox({
  idx,
  data,
  selected,
  setSelected,
  sex,
}: SelectionBoxProps) {
  const boundryLabel = useMemo(() => {
    if (!sex || !data || !data.lveti) {
      return;
    }
    return getLVETIrange(data.lveti, sex);
  }, [data, sex]);

  const indicator = useMemo(() => {
    switch (boundryLabel) {
      case RangeLabel.above:
        return "↑";
      case RangeLabel.below:
        return "↓";
      default:
        return;
    }
  }, [boundryLabel]);

  return (
    <div
      className={styles.SelectionBox}
      data-selected={selected}
      onClick={setSelected}
    >
      <h4>Selection {idx + 1}</h4>
      <div className={styles.data}>
        <label>LVETI</label>
        <span data-boundry={boundryLabel}>
          {data?.lveti ? Math.round(data?.lveti) : "-"} <b>ms</b>
          <i>{indicator}</i>
        </span>
        <label>HR</label>
        <span>
          {data?.hr ? Math.round(data?.hr) : "-"} <b>bpm</b>
        </span>
        <label>LVET</label>
        <span>
          {data?.lvet ? Math.round(data?.lvet) : "-"} <b>ms</b>
        </span>
      </div>
    </div>
  );
}
