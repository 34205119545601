import { createContext, ReactNode, useContext, useMemo } from "react";
import { SkinScanSpatial, useAvatar } from "render/hooks/patient/useAvatar";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";

type AvatarContextValue = SkinScanSpatial;

const Context = createContext<AvatarContextValue | null>(null);

interface AvatarContextProps {
  children: ReactNode;
}

export function AvatarContext({ children }: AvatarContextProps) {
  const { scans } = usePatientData();

  const skinScan = useMemo(() => {
    if (!scans) {
      return;
    }

    const skinScan = scans.skin.at(0);
    if (!skinScan) {
      return new Error("No SkinScan available");
    }

    return skinScan;
  }, [scans]);

  const avatar = useAvatar(skinScan);

  return <Context.Provider value={avatar}>{children}</Context.Provider>;
}

export function useAvatarContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useAvatarContext without AvatarContext");
  }
  return context;
}
