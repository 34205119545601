import { useEffect, useRef } from "react";
import { Table } from "render/ui/layouts/Table";
import styles from "./styles.module.sass";

export interface PaginatedTableProps<T> {
  data: T[];
  renderRow: (item: T) => JSX.Element;
  renderHeader: () => JSX.Element;
  fetchStatus: "loading" | "idle";
  hasMoreData: boolean;
  onLoadMore: () => void;
}

export function PaginatedTable<T>({
  data,
  renderHeader,
  renderRow,
  fetchStatus,
  hasMoreData,
  onLoadMore,
}: PaginatedTableProps<T>) {
  const loadMoreRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onLoadMore();
        }
      },
      { threshold: 1 }
    );

    const currentLoadMoreRef = loadMoreRef.current;

    if (currentLoadMoreRef) {
      observer.observe(currentLoadMoreRef);
    }

    return () => {
      if (currentLoadMoreRef) {
        observer.unobserve(currentLoadMoreRef);
      }
    };
  }, [onLoadMore]);

  return (
    <div className={styles.PaginatedTable}>
      <Table>
        <thead>{renderHeader()}</thead>
        <tbody>
          {fetchStatus === "idle" && data.length === 0 && (
            <tr>
              <td colSpan={100}>
                <div className={styles.centered}> Nothing to display</div>
              </td>
            </tr>
          )}
          {data.map((item) => renderRow(item))}
          {fetchStatus === "loading" && (
            <tr>
              <td colSpan={100}>
                <div className={styles.centered}>Loading...</div>
              </td>
            </tr>
          )}
          {hasMoreData && fetchStatus === "idle" && <div ref={loadMoreRef} />}
        </tbody>
      </Table>
    </div>
  );
}
