import { SVGAttributes } from "react";

interface GridProps extends Omit<SVGAttributes<unknown>, "spacing"> {
  lines: { x: number[]; y: number[] };
}

export function Grid({ lines, ...props }: GridProps) {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="none" {...props}>
      <g className="lines" stroke="#cfcfcf" strokeWidth={1}>
        {lines.x.map((x) => {
          return (
            <line
              key={x}
              x1={x * 100}
              x2={x * 100}
              y1={0}
              y2={100}
              vectorEffect="non-scaling-stroke"
            />
          );
        })}

        {lines.y.map((y) => {
          return (
            <line
              key={y}
              x1={0}
              x2={100}
              y1={y * 100}
              y2={y * 100}
              vectorEffect="non-scaling-stroke"
            />
          );
        })}
      </g>
    </svg>
  );
}
