import CheckSvg from "render/assets/svg/check.svg?react";
import styles from "./styles.module.sass";

export function ScanReviewed() {
  return (
    <div className={styles.ScanReviewed}>
      Reviewed <CheckSvg height={12} width={12} />
    </div>
  );
}
