import { Patient, ThermalAssessment } from "@cur8/rich-entity";
import { RecordingURI } from "@cur8/uri";
import { ThermalPage } from "./ThermalPage";

interface ThermalLoaderProps {
  patient: Patient;
  selected: ThermalAssessment;
}

export function ThermalLoader({ patient, selected }: ThermalLoaderProps) {
  const recUri = RecordingURI.parse(selected.sourceUris[0]);

  if (!recUri) {
    return <h1>No recording information found...</h1>;
  }
  return <ThermalPage patient={patient} recordingUri={recUri} />;
}
