import { PadAssessmentBox, Patient } from "@cur8/rich-entity";
import { useEffect, useState } from "react";
import { useAssessmentContext } from "../../context/AssessmentContext";
import { useAnnotation } from "./hooks/useAnnotation";
import { PadPage } from "./PadPage";

interface PadAnnotationLoaderProps {
  patient: Patient;
}

export function PadAnnotationLoader({ patient }: PadAnnotationLoaderProps) {
  const { createAssessment, moveRoI, selected } = useAssessmentContext();
  const { forearm, hypothenar } = useAnnotation(
    patient.patientId,
    selected.scanId,
    selected.scanVersion
  );
  const [hasROI, setHasROI] = useState<boolean>(false);

  useEffect(() => {
    if ((selected as PadAssessmentBox).regionsOfInterest) {
      setHasROI(true);
      return;
    }
    if (!forearm || !hypothenar) {
      return;
    }
    // No ROI => Use from annotations
    moveRoI("forearm", forearm.annotation.data.rect);
    moveRoI("hypothenar", hypothenar.annotation.data.rect);
    setHasROI(true);
  }, [forearm, hypothenar, moveRoI, selected]);

  if (!hasROI) {
    return <h1>Fetching data...</h1>;
  }

  return (
    <PadPage
      patient={patient}
      createAssessment={createAssessment}
      selected={selected}
    />
  );
}
