import { BMI } from "@cur8/formulas";
import * as Risks from "@cur8/health-risks-calc";
import { Risk } from "@cur8/health-risks-calc";
import { MetricName, Unit } from "@cur8/measurements";
import { Patient, Sex, VisitSummary } from "@cur8/rich-entity";
import { patientAge } from "lib/datetime";
import {
  AggregateEntry,
  AggregateName,
  AggregateRecord,
  Entry,
  ExtendedMetricName,
  MergedEntry,
  MergedRecord,
  Result,
  ResultRecord,
} from "lib/doctor-scribe/types";
import { Deviation, resolveSummary, toSummary } from "lib/health-risk";
import { Metric } from "lib/metric";
import { DateTime } from "luxon";
import { Score2RiskStatus } from "render/hooks/api/metrics/useScore2Status";

export function notEmpty<T>(arr: T[]): boolean {
  return arr.some((el) => el !== null && el !== undefined);
}

export type RiskFactors = {
  age: number;
  sex: Sex;
  score2Risk?: Score2RiskStatus;
};

function resolveWithFailover(metric: Metric | undefined, factors: RiskFactors) {
  if (!metric) {
    return undefined;
  }

  try {
    return resolveSummary(metric, factors).summary;
  } catch (error: unknown) {
    return { deviation: undefined, risk: undefined };
  }
}

export function remapName(name: MetricName): ExtendedMetricName {
  switch (name) {
    case "bloodwork.cholesterol_hdl":
      return "bloodwork.cholesterol_hdl_ratio"; // The original name confuses the LLM
    default:
      return name;
  }
}

export function computeAggregates(metrics: ResultRecord, patient: Patient) {
  const aggregates: AggregateRecord = {} as AggregateRecord;

  // Compute BMI score
  const bmiRecords: AggregateEntry[] = [];
  const weights = metrics["body.weight"];
  const heights = metrics["body.height"];
  for (const key in weights) {
    if (key in heights && weights[key].timestamp === heights[key].timestamp) {
      const weight: Unit<"body.weight"> = weights[key]
        .value as Unit<"body.weight">;
      const height: Unit<"body.height"> = heights[key]
        .value as Unit<"body.height">;

      const score = BMI.calculate({
        height: {
          meters: height.meters,
        },
        weight: {
          kilograms: weight.kilograms,
        },
      }).bmi;

      const age = patientAge(patient, DateTime.now());
      const { risk, deviation } = toSummary(
        Risks.BMI.rangesFor({ age }).entries,
        score
      );

      const scoreOneDigit = Math.round(score * 10) / 10;

      bmiRecords.push({
        timestamp: weights[key].timestamp,
        value: { score: scoreOneDigit },
        risk,
        deviation,
      });
    }
  }
  aggregates["body.bmi"] = bmiRecords;

  return aggregates;
}

export function uniqueByDay(m: Metric[]): Metric[] {
  return m.reduce((acc: Metric[], curr: Metric) => {
    const index = acc.findIndex(
      (x: Metric) =>
        x.measurement.timestampStart.toFormat("yyyy-dd-MM") ===
        curr.measurement.timestampStart.toFormat("yyyy-dd-MM")
    );

    if (index === -1) {
      acc.push(curr);
    } else if (
      curr.measurement.timestampStart > acc[index].measurement.timestampStart
    ) {
      acc[index] = curr;
    }

    return acc;
  }, []);
}

export function removeUndefined<T extends Record<string, unknown>>(
  input: T
): T {
  const result: Partial<T> = {};
  Object.keys(input).forEach((key) => {
    const value = input[key as keyof T];
    if (Array.isArray(value)) {
      result[key as keyof T] = value.map((item) =>
        typeof item === "object" && item !== null
          ? removeUndefined(item as Record<string, unknown>)
          : item
      ) as T[keyof T];
    } else if (typeof value === "object" && value !== null) {
      result[key as keyof T] = removeUndefined(
        value as Record<string, unknown>
      ) as T[keyof T];
    } else if (value !== undefined) {
      result[key as keyof T] = value;
    }
  });

  return result as T;
}

export function mergeRecords(
  resultRecord: ResultRecord,
  aggregateRecord: AggregateRecord
): MergedRecord {
  const mergedRecord = { ...aggregateRecord, ...resultRecord } as MergedRecord;
  return mergedRecord;
}

export function getResults(metrics: Metric[][], patient: Patient): Result[] {
  return metrics.map((um) => {
    return {
      name: remapName(um[0].name),
      measurements: um.map((item) => {
        const summary = resolveWithFailover(item, {
          sex: patient.sex,
          age: patientAge(patient, DateTime.now()),
        });

        return {
          timestamp: item.measurement.timestampStart.toISODate(),
          value: item.unit,
          deviation: summary?.deviation,
          risk: summary?.risk,
        };
      }),
    };
  });
}

function checkRelevanceForObject(object: Object): boolean {
  let isRelevant = false;
  Object.values(object).forEach((v) => {
    if (v) {
      isRelevant = true;
    }
  });
  return isRelevant;
}

// ** Remove all entries that do not contain any relevant information (in order to send less stuff to the model...) */
function isRelevant(name: string, measurements: Entry[]): boolean {
  if (name.startsWith("lifestyle.physical-activity")) {
    // Include even if 0 since may be relevant to know...
    return true;
  }
  if (name.startsWith("lifestyle")) {
    for (const m of measurements) {
      if (checkRelevanceForObject(m.value as Object)) {
        return true;
      }
    }
    return false;
  } else if (name.startsWith("risk_assessment")) {
    if (!name.startsWith("risk_assessment.eye_pressure")) {
      for (const m of measurements) {
        if ((m.value as boolean) === true) {
          return true;
        }
      }
      return false;
    }
  }

  return true;
}

export function getResultRecord(results: Result[]): ResultRecord {
  return removeUndefined(
    results.reduce((acc, result) => {
      const { name, measurements } = result;
      if (isRelevant(name, measurements)) {
        acc[name] = measurements;
      }
      return acc;
    }, {} as ResultRecord)
  );
}

export function removeEntries(
  record: MergedRecord,
  toRemove: Array<AggregateName | ExtendedMetricName>
) {
  const newRecord = { ...record };
  for (const name of toRemove) {
    delete newRecord[name];
  }
  return newRecord;
}

export function latestOnly(results: MergedRecord) {
  const filteredRecord: any = {};
  Object.keys(results).forEach((key) => {
    const value = (results as any)[key];
    const subKeys = Object.keys(value || {});
    if (subKeys.length > 0 && subKeys[0] === "0") {
      filteredRecord[key] = [value[subKeys[0]]]; // keep only latest
    } else {
      filteredRecord[key] = value;
    }
  });
  return filteredRecord as MergedRecord;
}

export function hasPreviousScan(results: MergedRecord | undefined) {
  if (!results) {
    return false;
  }
  let N = 0;
  let NHist = 0;
  Object.keys(results).forEach((key) => {
    const value = (results as any)[key];
    const subKeys = Object.keys(value || {});
    if (subKeys.length > 0 && subKeys[0] === "0") {
      N++;
      if (subKeys.length > 1) {
        NHist++;
      }
    }
  });
  return NHist / N > 0.3;
}

export function latestMeasurementDaysAgo(results: MergedRecord | undefined) {
  let days: number | undefined = undefined;

  if (!results) {
    return undefined;
  }

  const now = DateTime.local();

  Object.keys(results).forEach((key) => {
    const value = (results as any)[key];
    const subKeys = Object.keys(value || {});
    if (subKeys.length > 0 && subKeys[0] === "0") {
      const timestamp = DateTime.fromISO(
        value[subKeys[0]]["timestamp"] as string
      );
      const daysAgo = now.diff(timestamp, "days").toObject().days;
      days =
        days === undefined || (daysAgo && days && daysAgo < days)
          ? daysAgo
          : days;
    }
  });

  return days;
}

export function latestVisitSummaryDaysAgo(visits: VisitSummary[]) {
  if (visits.length > 0) {
    const now = DateTime.local();
    return now.diff(visits[0].visitDate, "days").toObject().days;
  } else {
    return undefined;
  }
}

export function addVisits(results: MergedRecord, visits: VisitSummary[]) {
  return {
    ...results,
    previousVisitSummaries: visits.map((v) => ({
      date: v.visitDate.toISODate(),
      summary: v.summaryText,
    })),
  };
}

const RiskMap: Record<Risk, string> = {
  [Risk.Risk]: "Risk",
  [Risk.HighRisk]: "High Risk",
  [Risk.ImmediateRisk]: "Immediate Risk",
  [Risk.Optimal]: "Optimal",
  [Risk.Normal]: "Normal",
  [Risk.Unknown]: "Unknown",
  [Risk.LowRisk]: "Low Risk",
  [Risk.ModerateRisk]: "Moderate Risk",
};

const DeviationMap: Record<Deviation, string> = {
  [Deviation.None]: "None",
  [Deviation.Above]: "Above",
  [Deviation.Below]: "Below",
};

function reducePrecision(key: string, entry: MergedEntry): MergedEntry {
  switch (key) {
    case "cardio.heart_rate":
      const hr = entry.value as Unit<"cardio.heart_rate">;
      return { ...entry, value: { bpm: Math.round(hr.bpm) } };
    case "cardio.respiratory_rate":
      const rr = entry.value as Unit<"cardio.respiratory_rate">;
      return { ...entry, value: { bpm: Math.round(rr.bpm) } };
    default:
      return entry;
  }
}

export function mapMergedRecord(record: MergedRecord) {
  const mapped: any = {};
  for (const [key, entries] of Object.entries(record)) {
    if (Array.isArray(entries)) {
      mapped[key] = entries.map((entry) => {
        return {
          ...reducePrecision(key, entry),
          risk: entry.risk !== undefined ? RiskMap[entry.risk] : undefined,
          deviation:
            entry.deviation && entry.risk !== Risk.Optimal
              ? DeviationMap[entry.deviation]
              : undefined,
        };
      });
    } else {
      mapped[key] = entries;
    }
  }
  return mapped;
}

export function serializeMergedRecord(
  record: MergedRecord,
  indentJSON: boolean
) {
  return indentJSON
    ? JSON.stringify(mapMergedRecord(record), null, 2)
    : JSON.stringify(mapMergedRecord(record));
}
