import { baseApiUrlResolver } from "@cur8/config";
import { createClient } from "lib/api/client";
import { ReactNode, useCallback, useMemo } from "react";
import { useAppInsights } from "../AppInsightsContext";
import { useConfig } from "../ConfigContext";
import { useMSAL } from "../MSALContext";
import { Context } from "./context";

interface APIContextProps {
  children: ReactNode;
}

export function APIContext({ children }: APIContextProps) {
  const config = useConfig();
  const { auth, instance } = useMSAL();
  const appInsights = useAppInsights();

  const getToken = useCallback(() => {
    const request = {
      account: auth?.account ?? undefined,
      scopes: config.msal.scopes.token,
    };

    return instance.acquireTokenSilent(request).then((response) => {
      return response.accessToken;
    });
  }, [auth, instance, config]);

  const onError = useCallback(
    (error: any) => {
      appInsights.trackException(error);
    },
    [appInsights]
  );

  const api = useMemo(() => {
    return {
      client: createClient({
        buildURL: baseApiUrlResolver(config),
        getToken,
        onError,
      }),
    };
  }, [getToken, onError, config]);

  return <Context.Provider value={api}>{children}</Context.Provider>;
}
