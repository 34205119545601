import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface SummarySetProps {
  children: ReactNode;
}

export function SummarySet({ children }: SummarySetProps) {
  return <div className={styles.SummarySet}>{children}</div>;
}
