import { ReactNode } from "react";
import { Typography } from "render/ui/presentation/Typography";
import { PositionBar } from "./components/PositionBar";
import styles from "./styles.module.sass";

interface Bounds {
  min: number;
  max: number;
}

function normalize(value: number, { max, min }: Bounds) {
  return (value - min) / (max - min);
}

export function DetailsGraph({
  header,
  labels,
  value,
  bounds,
}: {
  bounds: Bounds;
  labels: { from: ReactNode; to: ReactNode };
  header: ReactNode;
  value: number;
  previousValue?: number | null;
  overlapPercentageTreshold?: number;
}) {
  const normalizedValue = normalize(value, bounds);

  return (
    <div className={styles.Details}>
      <div className={styles.header}>
        <Typography variant="title-s">{header}</Typography>
      </div>

      <div className={styles.bar}>
        <PositionBar value={normalizedValue} />
      </div>

      <div className={styles.labels}>
        <Typography variant="label-m">{labels.from}</Typography>
        <Typography variant="label-m">{labels.to}</Typography>
      </div>
    </div>
  );
}
