export function isDistinct<T, K>(selectKey: (item: T) => K) {
  const seen = new Set<K>();

  return function compare(item: T) {
    const key = selectKey(item);
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  };
}
