import { Diastolic, Systolic } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { softCap } from "lib/health-risk";
import { useMemo } from "react";
import { useAge } from "render/hooks/patient/useAge";
import {
  AuxTitle,
  Diff,
  DiffValue,
  MainTitle,
  MetricResultHeader,
  Subtitle,
  Titles,
} from "render/ui/presentation/MetricResultHeader";
import {
  RangeChart,
  RangeChartDataComposer,
} from "render/ui/presentation/RangeChart";
import {
  capToMaxRanges,
  toChartRanges,
} from "render/ui/presentation/RangeChart/lib/risk";
import { BloodPressureType } from "../../../ArterialHealthLayout/lib/projection";

const getTitlesForValuesAndType = (
  value: number,
  type: BloodPressureType,
  prevValue?: number
): { title: string; subtitle?: string; aux?: string } => {
  const areValuesEqual = value === prevValue;

  const AuxTitleForBloodPressureType: Record<BloodPressureType, string> = {
    systolic_blood_pressure: "[Systolic]",
    diastolic_blood_pressure: "[Diastolic]",
  };

  const TitleTitleForBloodPressureType: Record<BloodPressureType, string> = {
    systolic_blood_pressure: "upper arm",
    diastolic_blood_pressure: "lower arm",
  };

  if (!prevValue) {
    return {
      title: "Blood Pressure",
      aux: AuxTitleForBloodPressureType[type],
    };
  }

  if (areValuesEqual) {
    return {
      title: "Maintained",
      subtitle: TitleTitleForBloodPressureType[type],
      aux: AuxTitleForBloodPressureType[type],
    };
  }

  if (value > prevValue) {
    return {
      title: "Increased",
      subtitle: TitleTitleForBloodPressureType[type],
      aux: AuxTitleForBloodPressureType[type],
    };
  }

  if (value < prevValue) {
    return {
      title: "Improved",
      subtitle: TitleTitleForBloodPressureType[type],
      aux: AuxTitleForBloodPressureType[type],
    };
  }

  return {
    title: AuxTitleForBloodPressureType[type],
    aux: AuxTitleForBloodPressureType[type],
  };
};

interface BloodPressureResultItemProps {
  patient: Patient;
  currentValue: number;
  previousValue?: number;
  bloodPressureType: BloodPressureType;
}

export function BloodPressureResultItem({
  patient,
  currentValue,
  previousValue,
  bloodPressureType,
}: BloodPressureResultItemProps) {
  const age = useAge(patient);

  const ranges = useMemo(() => {
    return {
      systolic: Systolic.rangesFor({ age }),
      diastolic: Diastolic.rangesFor({ age }),
    };
  }, [age]);

  const rangesForValue = useMemo(() => {
    if (bloodPressureType === "diastolic_blood_pressure") {
      return capToMaxRanges(
        toChartRanges(ranges.diastolic.entries),
        currentValue
      );
    }

    const isSysBloodPressureInLowRange =
      ranges.systolic.findRange({ mmHg: currentValue })?.label === "low";

    // if the blood pressure value is in the "low" range, we want to show 3 chart ranges
    // otherwise, we want to show 4 ranges and soft cap the ranges between 100-180mmHg
    if (isSysBloodPressureInLowRange) {
      return capToMaxRanges(
        toChartRanges(ranges.systolic.entries),
        currentValue,
        3
      );
    } else {
      return capToMaxRanges(
        toChartRanges(ranges.systolic.entries).map(softCap(100, 180)),
        currentValue,
        4
      );
    }
  }, [bloodPressureType, currentValue, ranges]);

  const titles = getTitlesForValuesAndType(
    currentValue,
    bloodPressureType,
    previousValue
  );

  return (
    <div>
      <MetricResultHeader>
        <Titles>
          <MainTitle>{titles.title}</MainTitle>
          <Subtitle>{titles.subtitle}</Subtitle>
          <AuxTitle>{titles.aux}</AuxTitle>
        </Titles>
        <Diff>
          <DiffValue
            valuesDiff={previousValue && currentValue - previousValue}
            unit="mmHg"
          />
        </Diff>
      </MetricResultHeader>
      <RangeChartDataComposer
        ranges={rangesForValue}
        value={currentValue}
        previousValue={previousValue}
      >
        {({ ranges, values }) => <RangeChart ranges={ranges} values={values} />}
      </RangeChartDataComposer>
    </div>
  );
}
