import { ReactNode } from "react";
import { TissueContextMode } from "../../lib/types";
import { Context, useValue } from "./lib";

interface TissueAnnotationContextProps {
  mode?: TissueContextMode;
  patientId: string;
  scanId: string;
  scanVersion: string;
  children: ReactNode;
}

export function TissueAnnotationContext({
  mode = TissueContextMode.Regular,
  patientId,
  scanId,
  scanVersion,
  children,
}: TissueAnnotationContextProps) {
  const value = useValue(mode, patientId, scanId, scanVersion);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
