import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface PageContentProps {
  children: ReactNode;
}

export function PageContent({ children }: PageContentProps) {
  return <div className={styles.PageContent}>{children}</div>;
}
