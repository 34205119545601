import { AvatarPodium } from "lib/avatar/podium";
import { BodyAreas } from "lib/smpl";
import { Layer } from "render/pages/DashboardPage/types";
import { BodyLayer } from "./components/BodyLayer";
import { DiabetesLayer } from "./components/DiabetesLayer";

interface RenderLayersProps {
  layer: Layer;
  podium: AvatarPodium;
  areas: BodyAreas;
}

export function RenderLayers({ layer, podium, areas }: RenderLayersProps) {
  return (
    <>
      <BodyLayer active={layer === Layer.Body} areas={areas} podium={podium} />

      <DiabetesLayer
        active={layer === Layer.Diabetes}
        areas={areas}
        podium={podium}
      />
    </>
  );
}
