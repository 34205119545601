import { APITypesV1 } from "@cur8/api-client";
import { RequestEnvelope } from "@cur8/api-client/dist/api/types";
import { ImmutableScan } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { normalize } from "lib/normalize";
import { parseAsJSON } from "lib/parse";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useRPeaks } from "render/hooks/api/scan/cardio/useRPeaks";
import { useReporting } from "render/hooks/useReporting";
import { PlotData } from "../lib/types";
import { useSiradQuality } from "./useSiradQuality";

type PlotSource = {
  signal: number[];
  timestamps: number[];
};

interface PlotFetch {
  range: APITypesV1.Range;
  signal: number[];
  timestamps: number[];
}

type PlotFetchName = PlotFetch & { name: string };
type SiradFetch = PlotFetch & {
  idx: number;
  sub: string;
};

function isPlotFetchName(obj: PlotFetch): obj is PlotFetchName {
  return "name" in obj && typeof obj.name === "string";
}

function isSiradFetch(obj: PlotFetch): obj is SiradFetch {
  return (
    "idx" in obj &&
    typeof obj.idx === "number" &&
    "sub" in obj &&
    typeof obj.sub === "string"
  );
}

type SiradPair = {
  signal: PlotData;
  acc: PlotData;
};

/**
 * Regular signals (not sirads)
 */
const signalNames = [
  "bioamp_ecg",
  "ssn_displacement",
  "ssn_acceleration",
  "piezo_foot_left",
  "piezo_foot_right",
  "piezo_foot_left_acceleration",
  "piezo_foot_right_acceleration",
  "piezo_hand_left",
  "piezo_hand_right",
  "piezo_hand_left_acceleration",
  "piezo_hand_right_acceleration",
] as const;

type SignalName = (typeof signalNames)[number];
export type Signals = Record<SignalName, PlotData>;

async function parseAndNormalize(src: Response): Promise<PlotFetch> {
  return parseAsJSON<PlotSource>(src).then((res) => {
    const normalized = res.signal.map(normalize(0, 1)).map((v) => 1 + -v);
    return {
      range: {
        from: res.timestamps.at(0)!,
        to: res.timestamps.at(-1)!,
        unit: "s",
      },
      signal: normalized,
      timestamps: res.timestamps,
    };
  });
}

export function useCardioSignalsTimestamps(scan: ImmutableScan | undefined) {
  const { handleError } = useReporting();
  const api = useAPIClient().blob;
  const [loading, setLoading] = useState<boolean>(true);
  const [signals, setSignals] = useState<Signals>({} as Signals);
  const [sirads, setSirads] = useState<SiradPair[]>([] as SiradPair[]);

  const siradQuality = useSiradQuality(scan);
  const rPeaks = useRPeaks(scan);

  useEffect(() => {
    if (!scan) {
      return;
    }

    let isMounted = true;
    const requests: RequestEnvelope<Response>[] = [];

    const fetch = async () => {
      setLoading(true);
      try {
        const proms: Promise<PlotFetchName | void>[] = [];
        const promsSirad: Promise<SiradFetch | void>[] = [];

        // Fetch regular signals
        for (const signame of signalNames) {
          const req = api.fetchScanBlob({
            patientId: scan.patientId,
            scanId: scan.id,
            scanVersion: scan.version,
            resultName: signame,
            path: "data.json",
          });
          requests.push(req);

          const prom = req.result
            .then(parseAndNormalize)
            .then((pf) => ({
              name: signame,
              ...pf,
            }))
            .catch(silenceAbort)
            .catch((err) => {
              const e = err as Error;
              console.debug("error", e);
              return; // Return on failure to continue processing other signals
            });
          proms.push(prom);
        }

        // Fetch sirad signals
        for (let i = 0; i <= 9; i++) {
          for (const sub of ["acceleration", "displacement"]) {
            const name = `sirad${i}_${sub}`;
            const req = api.fetchScanBlob({
              patientId: scan.patientId,
              scanId: scan.id,
              scanVersion: scan.version,
              resultName: name,
              path: "data.json",
            });
            requests.push(req);

            const prom = req.result
              .then(parseAndNormalize)
              .then((pf) => ({
                idx: i,
                sub,
                ...pf,
              }))
              .catch(silenceAbort)
              .catch((err) => {
                handleError(
                  `Error fetching Sirad signal ${name}: ${err.message}`
                );
                return; // Skip failed sirad signals
              });
            promsSirad.push(prom);
          }
        }

        const results = await Promise.all([...proms, ...promsSirad]);

        if (isMounted) {
          const tmpSignals = {} as Signals;
          const tmpSirads = [] as SiradPair[];
          for (const res of results) {
            if (!res) {
              continue; // Skip failed results
            }

            const data = {
              range: res.range,
              signal: res.signal,
              timestamps: res.timestamps,
            };
            if (isPlotFetchName(res)) {
              tmpSignals[res.name as SignalName] = data;
            } else if (isSiradFetch(res)) {
              if (!tmpSirads[res.idx]) {
                tmpSirads[res.idx] = {} as SiradPair;
              }
              if (res.sub === "acceleration") {
                tmpSirads[res.idx].acc = data;
              } else {
                tmpSirads[res.idx].signal = data;
              }
            }
          }
          setSignals(tmpSignals);
          setSirads(tmpSirads);
        }
      } catch (err) {
        const e = err as Error;
        if (e.name !== "AbortError") {
          handleError(`General fetch error: ${e.message}`);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetch();

    return () => {
      isMounted = false;
      requests.forEach((req) => req.abandon());
    };
  }, [scan, api, handleError]);

  return {
    loading,
    ...signals,
    sirads,
    siradQuality,
    rPeaks,
  };
}

export type CardioSignals = ReturnType<typeof useCardioSignalsTimestamps>;
