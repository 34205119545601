import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { ReactNode } from "react";
import { usePopupDialog } from "render/context/PopupDialogContext";
import styles from "./styles.module.sass";

interface PopupDialogFrameProps {
  children: ReactNode;
}

export function PopupDialogFrame({ children }: PopupDialogFrameProps) {
  const { dialogs } = usePopupDialog();

  const content = dialogs.at(-1)?.content;

  return (
    <div className={styles.PopupDialogFrame} data-active={!!content}>
      <ViewStack>
        <div className={styles.context}>{children}</div>
        <div className={styles.overlay}>
          <div className={styles.content}>
            <Sticky>{content}</Sticky>
          </div>
        </div>
      </ViewStack>
    </div>
  );
}
