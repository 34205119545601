import { SmplBodyMeasurementsResultData } from "@cur8/api-client/dist/api/cur8/generated-types/v1/Api";
import { ImmutableScan } from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

export type SMPLBodyMetrics = ReturnType<typeof convertUnits>;

function toMeters(value?: number): { meters: number } {
  if (value == null) {
    return {
      meters: NaN,
    };
  }

  return {
    meters: value / 1000,
  };
}

function convertUnits(data: SmplBodyMeasurementsResultData) {
  return {
    height: toMeters(data.height),
    circumferences: {
      calf: toMeters(data?.calf),
      hip: toMeters(data?.hip),
      neck: toMeters(data?.neck),
      thorax: toMeters(data?.thorax),
      waist: toMeters(data?.waist),
    },
  };
}

type SMPLMeasurementMetric = {
  timestamp: DateTime;
  unit: SMPLBodyMetrics;
};

async function fetchSMPLBodyMetrics(
  api: APIClient,
  scan: ImmutableScan
): Promise<SMPLBodyMetrics> {
  const result = await api.scan.fetchSMPLXBodyMeasurements({
    patientId: scan.patientId,
    scanId: scan.id,
    scanVersion: scan.version,
  }).result;

  if (result.data == null) {
    throw new Error("No data");
  }

  return convertUnits(result.data);
}

async function fetchBodyMetrics(api: APIClient, scans: ImmutableScan[]) {
  const metrics: SMPLMeasurementMetric[] = [];

  for (const scan of scans) {
    try {
      const unit = await fetchSMPLBodyMetrics(api, scan);
      metrics.push({
        timestamp: scan.timestamp,
        unit,
      });
    } catch (error: unknown) {
      console.warn(`No body metrics found for scan`, scan, error);
    }
  }

  return metrics;
}

export function usePatientSMPLMetrics(scans?: ImmutableScan[]) {
  const api = useAPIClient();

  const [result, setResult] = useState<AsyncResult<SMPLMeasurementMetric[]>>();

  useEffect(() => {
    if (!scans) {
      return;
    }

    fetchBodyMetrics(api, scans).then((data) => {
      setResult({
        data,
      });
    });

    return () => {
      setResult(undefined);
    };
  }, [api, scans]);

  return result;
}
