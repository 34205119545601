import { Patient } from "@cur8/rich-entity";
import { PatientAge } from "render/fragments/patient/PatientAge";
import { PatientName } from "render/fragments/patient/PatientName";
import { PatientPhysicalIdentifier } from "render/fragments/patient/PatientPhysicalIdentifier";
import styles from "./styles.module.sass";

export function PatientSummary({ patient }: { patient: Patient }) {
  return (
    <div className={styles.PatientSummary}>
      <div className={styles.name}>
        <PatientName patient={patient} />
      </div>
      <div className={styles.age}>
        <PatientPhysicalIdentifier patient={patient} />
        {patient.dateOfBirth && (
          <>
            {" "}
            (<PatientAge patient={patient} /> years)
          </>
        )}
      </div>
    </div>
  );
}
