import { APITypesV1 } from "@cur8/api-client";
import { Assessment } from "@cur8/rich-entity";
import { useCallback, useEffect, useState } from "react";
import { useAssessmentContext } from "../../context/AssessmentContext";
import { DisplayAssessment } from "./DisplayAssessment";
import { HistoryList } from "./HistoryList";
import { MakeAssessment } from "./MakeAssessment";
import styles from "./styles.module.sass";

export function AssessmentHandler() {
  const { assessments, selected } = useAssessmentContext();
  const [showAssessment, setShowAssessment] = useState<boolean>(true);
  const [showHistory, setShowHistory] = useState<boolean>();
  const toggleHistory = useCallback(() => {
    setShowHistory((sh) => !!!sh);
  }, []);
  const [history, setHistory] = useState<Assessment[]>([]);

  useEffect(() => {
    if (!assessments) {
      return;
    }
    const filtered = assessments.filter(
      (ass) => ass.assessmentState !== APITypesV1.AssessmentState.New
    );
    setHistory(filtered);
  }, [assessments]);

  if (!assessments) {
    return <h3>Assessment missing</h3>;
  }

  return (
    <div className={styles.AssessmentHandler}>
      {showAssessment && history.length > 0 && (
        <>
          <div className={styles.header}>
            <h3>Assessment</h3>
            {history.length > 1 && (
              <div className={styles.histLink} onClick={toggleHistory}>
                &raquo; {showHistory ? "Hide" : "Show"} history
              </div>
            )}
          </div>
          <DisplayAssessment assessment={selected} />
        </>
      )}
      <MakeAssessment setShowAssessment={setShowAssessment} />
      {showHistory && (
        <HistoryList hide={toggleHistory} assessments={history} />
      )}
    </div>
  );
}
