import { AuditRecord } from "@cur8/rich-entity/dist/types/AuditInformation";
import { useUser } from "render/hooks/api/useUser";
import { useUserProfilePhoto } from "render/hooks/api/useUserProfilePhoto";
import { FullDate } from "render/ui/format/FullDate";
import styles from "./styles.module.sass";

interface CommentAuthorProps {
  auditRecord: AuditRecord;
}

export function CommentAuthor({ auditRecord }: CommentAuthorProps) {
  const profilePhotoSize = 48;
  const user = useUser(auditRecord.userUri);
  const profilePhoto = useUserProfilePhoto(
    profilePhotoSize * 2,
    auditRecord.userUri
  );
  return (
    user.data && (
      <div className={styles.CommentAuthor}>
        <div className={styles.photo}>
          {profilePhoto && <img src={profilePhoto.src} alt="Author" />}
        </div>
        <div className={styles.auditRecord}>
          <div className={styles.author}>{user.data.displayName}</div>
          <div className={styles.date}>
            {auditRecord.timeStamp && <FullDate date={auditRecord.timeStamp} />}
          </div>
        </div>
      </div>
    )
  );
}
