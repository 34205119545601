import { UserURI } from "@cur8/uri";
import { DateTime } from "luxon";
import { useUser } from "render/hooks/api/useUser";
import { useUserProfilePhoto } from "render/hooks/api/useUserProfilePhoto";
import { FullDate } from "render/ui/format/FullDate";
import styles from "./styles.module.sass";

interface PrivateJournalNoteAuthorProps {
  userUri?: UserURI;
  date: DateTime;
  showTimestamp: boolean;
}

export default function PrivateJournalNoteAuthor({
  userUri,
  date,
  showTimestamp,
}: PrivateJournalNoteAuthorProps) {
  const profilePhotoSize = 32;
  const user = useUser(userUri);
  const profilePhoto = useUserProfilePhoto(profilePhotoSize * 2, userUri);

  return (
    <div className={styles.PrivateJournalNoteAuthor}>
      {profilePhoto && (
        <div>
          <img src={profilePhoto.src} height={40} alt="" />
        </div>
      )}
      <div className={styles.details}>
        <div className={styles.name}>{user.data?.displayName}</div>
        {showTimestamp && (
          <div className={styles.date}>
            <FullDate date={date} />
          </div>
        )}
      </div>
    </div>
  );
}
