import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";

export function usePatientCreatePrivateJournalNote() {
  const api = useAPIClient();

  return useCallback(
    async (patientId: string, payload: { note: string }) => {
      return api.privateJournalNote.createPrivateJournalNote(
        {
          patientId: patientId,
        },
        {
          note: payload.note,
        }
      ).result;
    },
    [api.privateJournalNote]
  );
}
