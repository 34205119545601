import styles from "./styles.module.sass";

export type Highlight = "none" | "danger" | "warning" | "normal";
export type Variant =
  | "primary"
  | "outlined"
  | "primary-outlined"
  | "outlined-with-dot"
  | "squircle";

interface MarkerProps {
  highlight: Highlight;
  variant?: Variant;
}

export function Marker({ variant = "primary", highlight }: MarkerProps) {
  return (
    <div
      className={styles.Marker}
      data-variant={variant}
      data-highlight={highlight}
    >
      <div className={styles.inner} />
    </div>
  );
}
