import { Annotation } from "@cur8/rich-entity";
import { ViewStack } from "@pomle/react-viewstack";
import { ThermalImageURI } from "lib/api/thermal-uri";
import { ThermalImage } from "./components/ThermalImage";
import styles from "./styles.module.sass";

interface ThermalScanItemProps {
  thermalURI: ThermalImageURI;
  annotations: Annotation[];
}

export function ThermalScanItem({
  thermalURI,
  annotations,
}: ThermalScanItemProps) {
  return (
    <div className={styles.ScanItem}>
      <ViewStack>
        <div className={styles.image}>
          <ThermalImage uri={thermalURI} />
        </div>
      </ViewStack>
    </div>
  );
}
