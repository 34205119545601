import { APITypesV2 } from "@cur8/api-client";
import { QuestionId } from "./types";
type v1QuestionId =
  | "weeklyExerciseHours"
  | "snus.weeklyCans"
  | "medication"
  | "healthConditionsFamily";

export function createResolver(response: APITypesV2.QuestionnaireResponse) {
  return {
    /**
     * Get a raw answer value in the questionnaire response.
     */
    findAnswer(questionId: QuestionId | v1QuestionId) {
      return findAnswer(response, questionId);
    },
    /**
     * Get a parsed answer value in the questionnaire response.
     */
    readValue(questionId: QuestionId | v1QuestionId) {
      const answer = findAnswer(response, questionId);
      return convertValue(answer);
    },
  };
}

function findAnswer(
  response: APITypesV2.QuestionnaireResponse,
  questionId: QuestionId | v1QuestionId
) {
  return response.questionsAndAnswers.find((q) => q.questionId === questionId)
    ?.answer?.value;
}

function convertValue(
  answer: NonNullable<APITypesV2.QuestionAnswer["answer"]>["value"] | undefined
) {
  if (answer == null) {
    return;
  }
  if (Array.isArray(answer)) {
    return answer.join(", ");
  }
  if (typeof answer === "boolean") {
    return answer ? "Yes" : "No";
  }
  return String(answer);
}
