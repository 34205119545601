import { ReactNode } from "react";
import { PageContent } from "render/ui/layouts/PageContent";
import { PageFrame } from "render/ui/layouts/PageFrame";

interface PageFrameProps {
  children: ReactNode;
}

export function FramedPage({ children }: PageFrameProps) {
  return (
    <PageFrame>
      <PageContent>{children}</PageContent>
    </PageFrame>
  );
}
