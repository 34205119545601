import { Risk } from "@cur8/health-risks-calc";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { BalanceGraph } from "render/ui/presentation/BalanceGraph";
import {
  AuxTitle,
  Diff,
  DiffValue,
  MainTitle,
  MetricResultHeader,
  Titles,
} from "render/ui/presentation/MetricResultHeader";
import styles from "./styles.module.sass";

const RiskToHighlightMap: Record<
  Risk,
  "danger" | "warning" | "normal" | "none"
> = {
  [Risk.Optimal]: "normal",
  [Risk.Normal]: "normal",
  [Risk.Risk]: "warning",
  [Risk.LowRisk]: "warning",
  [Risk.ModerateRisk]: "danger",
  [Risk.HighRisk]: "danger",
  [Risk.ImmediateRisk]: "danger",
  [Risk.Unknown]: "normal",
};

interface BloodPressureBalanceSegmentProps {
  brachial: {
    left: Metric<"cardio.brachial_pressure.left">[] | undefined;
    right: Metric<"cardio.brachial_pressure.right">[] | undefined;
  };
}

export function BloodPressureBalanceSegment({
  brachial,
}: BloodPressureBalanceSegmentProps) {
  const left = brachial.left?.at(0);
  const right = brachial.right?.at(0);

  const diff = useMemo(() => {
    if (!left || !right) {
      return;
    }

    return left.unit.systolic.mmHg - right.unit.systolic.mmHg;
  }, [left, right]);

  const risk = useMemo(() => {
    if (diff == null) {
      return Risk.Unknown;
    }

    if (Math.abs(diff) >= 20) {
      return Risk.HighRisk;
    }

    if (Math.abs(diff) >= 9) {
      return Risk.Risk;
    }

    return Risk.Normal;
  }, [diff]);

  return (
    <div className={styles.BloodPressureBalanceSegment}>
      <MetricResultHeader>
        <Titles>
          <MainTitle>Balance</MainTitle>
          <AuxTitle>[Systolic]</AuxTitle>
        </Titles>
        <Diff>
          <DiffValue unit="mmHg" />
        </Diff>
      </MetricResultHeader>
      <div className={styles.graph}>
        <BalanceGraph
          value={diff ?? 0}
          highlight={RiskToHighlightMap[risk]}
          bounds={{ min: -20, max: 20 }}
          labels={{ from: "R", to: "L" }}
        />
      </div>
    </div>
  );
}
