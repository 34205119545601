import { useDelay } from "render/hooks/useDelay";
import { useAvatarContext } from "render/pages/DashboardPage/context/AvatarContext";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "../../../../types";
import { BodyPanel } from "./components/BodyPanel";
import { HeartPanel } from "./components/HeartPanel";
import { PatientIdentity } from "./components/PatientIdentity";
import { SkinPanel } from "./components/SkinPanel";
import styles from "./styles.module.sass";

export function IdentityLayout() {
  const { patient } = usePatientData();
  const avatar = useAvatarContext();

  const { ui } = useDashboardContext();

  const isPCDReady =
    !!avatar.front.world &&
    !!avatar.back.world &&
    !!avatar.heart &&
    !!avatar.front.smpl;

  const isReady = useDelay({
    active: isPCDReady,
    delay: 1000,
  });

  return (
    <div
      className={styles.IdentityLayout}
      data-active={ui.layer === Layer.Identity && isReady}
    >
      <section className={styles.content}>
        <div className={styles.patientIdentity}>
          <PatientIdentity patient={patient} />
        </div>

        <div className={styles.heart}>
          <HeartPanel />
        </div>

        <div className={styles.skin}>
          <SkinPanel />
        </div>

        <div className={styles.body}>
          <BodyPanel />
        </div>
      </section>
    </div>
  );
}
