import { classNames } from "@pomle/classnames";
import { IconProps } from ".";
import styles from "./styles.module.sass";

export function ChartIcon({ active, color }: IconProps) {
  return (
    <svg
      className={classNames(styles.Common, styles.ChartIcon)}
      data-active={active ? "yes" : "no"}
      data-color={color}
      fill="none"
      height="40"
      width="40"
      viewBox="-100 -1060 1160 1160"
    >
      <circle cx="480" cy="-480" r="550" />
      <path d="m140 -85 -46 -46 300 -300 160 161 298 -335 42 41 -340 384 -160 -159L140 -85Zm0 -269 -46 -46 300 -300 160 161 298 -335 42 41 -340 384 -160 -159 -254 254Z" />
    </svg>
  );
}
