import { useNav } from "@pomle/react-router-paths";
import { useCallback, useState } from "react";
import { PatientName } from "render/fragments/patient/PatientName";
import { usePatient } from "render/hooks/api/usePatient";
import { useQuestionnaire } from "render/hooks/api/useQuestionnaire";
import { useEscapeNumberScroll } from "render/hooks/useEscapeNumberScroll";
import { paths } from "render/routes/paths";
import { FramedPage } from "render/ui/layouts/FramedPage/FramedPage";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { BackButtonLink } from "render/ui/trigger/BackButtonLink";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import { BodyMetricsSection } from "./components/BodyMetricsSection";
import { CardioSection } from "./components/CardioSection";
import { EyePressureSection } from "./components/EyePressureSection";
import { GripStrengthSection } from "./components/GripStrengthSection";
import { RiskSection } from "./components/RiskSection";
import styles from "./styles.module.sass";

enum Section {
  None,
  Body,
  Grip,
  Eye,
  Risk,
  Cardio,
}

const MENU_ITEMS = [
  { section: Section.Cardio, title: "Cardio" },
  { section: Section.Body, title: "Body" },
  { section: Section.Eye, title: "Eye Pressure" },
  { section: Section.Grip, title: "Grip Strength" },
  { section: Section.Risk, title: "Risk Factors" },
];

interface RiskAssessmentEntryPageProps {
  patientId: string;
}

export function RiskAssessmentEntryPage({
  patientId,
}: RiskAssessmentEntryPageProps) {
  const patient = usePatient(patientId);

  const questionnaire = useQuestionnaire({ patientId, visitId: undefined });

  const isV1Questionnaire =
    questionnaire.data?.questionnaireType === "onboarding/1";

  const filteredMenuItems = isV1Questionnaire
    ? MENU_ITEMS
    : MENU_ITEMS.filter((item) => item.section !== Section.Risk);

  const [section, setSection] = useState(Section.Cardio);

  const isSection = useCallback(
    (compare: Section) => {
      return section === compare ? "active" : "inactive";
    },
    [section]
  );

  const nav = {
    patient: useNav(paths.patient.detail),
  };

  const handle = useEscapeNumberScroll<HTMLDivElement>();

  return (
    <FramedPage>
      <div className={styles.RiskAssessmentEntryPage} ref={handle}>
        <BackButtonLink to={nav.patient.to({ patientId })}>
          {patient ? <PatientName patient={patient} /> : "Back"}
        </BackButtonLink>

        <PageHeader caption="Risk Assessment" />

        <div className={styles.panels}>
          <div className={styles.menu}>
            {filteredMenuItems.map((item) => {
              return (
                <HoverTextButton
                  active={item.section === section}
                  key={item.section}
                  onClick={() => setSection(item.section)}
                >
                  {item.title}
                </HoverTextButton>
              );
            })}
          </div>

          <div className={styles.content}>
            <section data-state={isSection(Section.Risk)}>
              <RiskSection patientId={patientId} />
            </section>

            <section data-state={isSection(Section.Body)}>
              <BodyMetricsSection patientId={patientId} />
            </section>

            <section data-state={isSection(Section.Eye)}>
              <EyePressureSection patientId={patientId} />
            </section>

            <section data-state={isSection(Section.Grip)}>
              <GripStrengthSection patientId={patientId} />
            </section>

            <section data-state={isSection(Section.Cardio)}>
              <CardioSection patientId={patientId} />
            </section>
          </div>
        </div>
      </div>
    </FramedPage>
  );
}
