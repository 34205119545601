import { RegionsOfInterestBox } from "@cur8/rich-entity";
import { ImageSize } from "../../lib/types";
import { SvgRect } from "./SvgRect";
import styles from "./styles.module.sass";

interface RegionsProps {
  canvasSize: ImageSize;
  rois: RegionsOfInterestBox;
  scale: number;
  selectedRoi?: string;
}

export function Regions({
  canvasSize,
  rois,
  scale,
  selectedRoi,
}: RegionsProps) {
  return (
    <g className={styles.Regions}>
      <SvgRect
        box={rois.forearm}
        canvasSize={canvasSize}
        isSelected={selectedRoi === "forearm"}
        roiName="forearm"
        scale={scale}
      />
      <SvgRect
        box={rois.hypothenar}
        canvasSize={canvasSize}
        isSelected={selectedRoi === "hypothenar"}
        roiName="hypothenar"
        scale={scale}
      />
    </g>
  );
}
