import { MouseEvent } from "react";
import { BoundingBox } from "render/ui/presentation/BoundingBox";
import AddIcon from "./assets/add.svg?react";

interface DragAreaProps {
  dragging: boolean;
  onClick: (e: MouseEvent<Element>) => void;
  onSelectedEvent: (selected: boolean) => void;
}

export function DragArea({
  dragging,
  onClick,
  onSelectedEvent,
}: DragAreaProps) {
  return (
    <BoundingBox
      color="#23DBF4"
      onClick={onClick}
      onSelectedEvent={onSelectedEvent}
    >
      {!dragging && <AddIcon />}
    </BoundingBox>
  );
}
