import { ArtefactHistoryEntry } from "../../history";
import { AnnotationNotes } from "./components/AnnotationNotes";
import styles from "./styles.module.sass";

interface NotesProps {
  entries: ArtefactHistoryEntry[];
  selectedEntry?: ArtefactHistoryEntry;
  onSelectEntry: (entry: ArtefactHistoryEntry) => void;
}

export function Notes({ entries, selectedEntry, onSelectEntry }: NotesProps) {
  return (
    <div className={styles.Notes}>
      <div className={styles.content}>
        <div className={styles.entries}>
          {entries.map((entry) => {
            return (
              <div className={styles.entry} key={entry.image.id}>
                <AnnotationNotes
                  annotation={entry.image}
                  selected={selectedEntry === entry}
                  onSelect={() => onSelectEntry(entry)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
