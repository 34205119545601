import { useCallback, useMemo } from "react";
import { useNotifications } from "render/context/NotificationContext";
import { useLiveTime } from "render/hooks/useLiveTime";
import { Timestamp } from "render/ui/format/Timestamp";
import { CloseIcon } from "render/ui/symbol/HoverIcon";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import styles from "./styles.module.sass";

interface NotificationBarProps {
  onClose: () => void;
}

export function NotificationBar({ onClose }: NotificationBarProps) {
  const now = useLiveTime("second");

  const { entries, clear } = useNotifications();

  const handleClear = useCallback(() => {
    clear();
    onClose();
  }, [onClose, clear]);

  const notifications = useMemo(() => {
    return [...entries].sort(
      (a, b) => b.timestamp.diff(a.timestamp).milliseconds
    );
  }, [entries]);

  return (
    <div className={styles.NotificationBar}>
      <header>
        <div className={styles.close} onClick={onClose}>
          <CloseIcon />
        </div>

        <h3>Notifications</h3>

        <HoverTextButton onClick={handleClear}>Clear</HoverTextButton>
      </header>

      <div className={styles.notifications}>
        {notifications.length > 0 ? (
          notifications.map((notification) => {
            return (
              <div
                key={notification.id}
                className={styles.notification}
                data-type={notification.type}
                data-recency={
                  now.diff(notification.timestamp).milliseconds < 5000
                    ? "hot"
                    : "cold"
                }
              >
                <div className={styles.content}>{notification.content}</div>
                <Timestamp date={notification.timestamp} />
              </div>
            );
          })
        ) : (
          <div className={styles.notification}>No notifications</div>
        )}
      </div>
    </div>
  );
}
