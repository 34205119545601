import { classNames } from "@pomle/classnames";
import { IconProps } from ".";
import styles from "./styles.module.sass";

export function MagicWandIcon({ active, color }: IconProps) {
  return (
    <svg
      className={classNames(styles.Common, styles.MagicWandIcon)}
      data-active={active ? "yes" : "no"}
      data-color={color}
      fill="none"
      height="40"
      width="40"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="19" />
      <path d="m11.5 27 10.15-9.6m6.1-6.46c.3-.1.45.06.36.36l-1.12 3.33c-.09.34 0 .7.24.96l2.13 2.13c.22.23.15.38-.17.35l-3.28-.36a.94.94 0 0 0-.85.44l-1.9 3.41c-.15.28-.34.25-.4-.06l-.86-3.67a1 1 0 0 0-.69-.7l-3.67-.84c-.31-.07-.34-.25-.06-.4l3.42-1.9c.3-.18.46-.51.44-.85l-.37-3.29c-.04-.31.11-.39.35-.16l2.13 2.13c.26.23.62.32.96.23l3.33-1.11Z" />
    </svg>
  );
}
