import { AuditInformation } from "@cur8/rich-entity";
import { useUser } from "render/hooks/api/useUser";

interface AssessedByProps {
  auditInfo: AuditInformation;
}

export function AssessedBy({ auditInfo }: AssessedByProps) {
  const user = useUser(
    auditInfo.lastModified?.userUri ?? auditInfo.created?.userUri
  );
  if (!user || !user.data) {
    return <>N/A</>;
  }

  return <>{user.data.displayName}</>;
}
