import { Patient } from "@cur8/rich-entity";
import { AvatarPodium } from "lib/avatar/podium";
import { BodyAreas } from "lib/smpl";
import { useScreenProject } from "render/hooks/three/useProject";
import { Layer } from "render/pages/DashboardPage/types";
import * as THREE from "three";
import { BodyMeasurementsLayer } from "./layers/BodyMeasurementsLayer";
import { CardioLayer } from "./layers/CardioLayer";
import { CardioVascularSystemLayer } from "./layers/CardioVascularSystemLayer";
import { DetectedLesionsLayer } from "./layers/DetectedLesionsLayer";
import { DiabetesLayer } from "./layers/DiabetesLayer";
import { HeartLayer } from "./layers/HeartLayer";
import { PiezoLayer } from "./layers/PiezoLayer";
import { PulseWaveLayer } from "./layers/PulseWaveLayer";
import { TrackedLesionsLayer } from "./layers/TrackedLesionsLayer";
import styles from "./styles.module.sass";

interface OverlaysProps {
  layer: Layer;
  canvas: HTMLCanvasElement;
  camera: THREE.PerspectiveCamera;
  patient: Patient;
  podium: AvatarPodium;
  bodyAreas: BodyAreas;
}

export function Overlays({
  camera,
  patient,
  podium,
  layer,
  canvas,
  bodyAreas,
}: OverlaysProps) {
  const projector = useScreenProject(camera, podium, canvas);

  return (
    <div className={styles.Overlays} data-layer={layer}>
      <CardioVascularSystemLayer
        layer={layer}
        areas={bodyAreas}
        projector={projector}
      />

      <CardioLayer
        layer={layer}
        patient={patient}
        areas={bodyAreas}
        projector={projector}
      />

      <PulseWaveLayer layer={layer} areas={bodyAreas} projector={projector} />

      <PiezoLayer layer={layer} areas={bodyAreas} projector={projector} />

      <HeartLayer layer={layer} areas={bodyAreas} projector={projector} />

      <BodyMeasurementsLayer
        layer={layer}
        areas={bodyAreas}
        projector={projector}
      />

      <DiabetesLayer
        layer={layer}
        patient={patient}
        areas={bodyAreas}
        projector={projector}
      />

      <DetectedLesionsLayer
        layer={layer}
        areas={bodyAreas}
        projector={projector}
      />

      <TrackedLesionsLayer
        layer={layer}
        areas={bodyAreas}
        projector={projector}
      />
    </div>
  );
}
