import { BoundingBoxAnnotation } from "@cur8/rich-entity";
import { useState } from "react";
import { BoundingBox } from "render/ui/presentation/BoundingBox";
import AddIcon from "./assets/add.svg?react";

interface WeakAnnotationAreaProps {
  annotation: BoundingBoxAnnotation;
  onClick: () => void;
  onSelectedEvent: (selected: boolean) => void;
}

export function WeakAnnotationArea({
  onClick,
  onSelectedEvent,
}: WeakAnnotationAreaProps) {
  const [isPointerOverAdd, setIsPointerOverAdd] = useState(false);
  return (
    <BoundingBox
      color="#F5E06D"
      weak
      onClick={() => {}}
      onSelectedEvent={(selected) => {
        return isPointerOverAdd
          ? onSelectedEvent(false)
          : onSelectedEvent(selected);
      }}
    >
      <AddIcon
        onClick={onClick}
        onPointerOver={() => {
          setIsPointerOverAdd(true);
        }}
        onPointerOut={() => {
          setIsPointerOverAdd(false);
        }}
      />
    </BoundingBox>
  );
}
