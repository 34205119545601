import { ConvertedQuestionnaire } from "lib/questionnaire/convert";
import { GridIronSummary } from "render/ui/layouts/GridIronSummary";

interface LifestyleItemsProps {
  lifestyle: ConvertedQuestionnaire;
}

export function LifestyleItems({ lifestyle }: LifestyleItemsProps) {
  return (
    <GridIronSummary>
      <ul>
        <li>Smoking</li>
        <li>
          {lifestyle?.isSmoker ? (
            lifestyle?.smoking != null ? (
              <>{lifestyle?.smoking} cigarettes / day</>
            ) : (
              "Yes"
            )
          ) : (
            "--"
          )}
        </li>
      </ul>

      {lifestyle?.v1.snusCansPerWeek != null && (
        <ul>
          <li>Snus</li>
          <li>
            <>{lifestyle?.v1.snusCansPerWeek} cans / week</>
          </li>
        </ul>
      )}

      <ul>
        <li>Exercise</li>
        <li>
          {lifestyle?.v1.exerciseHoursPerWeek != null ? (
            <>{lifestyle?.v1.exerciseHoursPerWeek} hrs / week</>
          ) : (
            "--"
          )}
        </li>
      </ul>

      <ul>
        <li>Alcohol</li>
        <li>
          {lifestyle?.alcohol != null ? (
            <>{lifestyle?.alcohol ?? "--"} units / week</>
          ) : (
            "--"
          )}
        </li>
      </ul>

      <ul>
        <li>Diet</li>
        <li>{lifestyle?.diet !== null ? <>{lifestyle?.diet}</> : "--"}</li>
      </ul>
    </GridIronSummary>
  );
}
