import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface FieldProps {
  label?: ReactNode;
  children?: ReactNode;
}

export function Field({ label, children }: FieldProps) {
  return (
    <div className={styles.Field}>
      {label && <label>{label}</label>}

      <div className={styles.content}>{children}</div>
    </div>
  );
}
