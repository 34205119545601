import { useCallback } from "react";
import { usePopupDialog } from "render/context/PopupDialogContext";
import { RefundActiveBookingTokenPopup } from "../RefundActiveBookingTokenPopup";

export function useRefundBookingTokenPopup() {
  const { create } = usePopupDialog();

  const emitRefundActiveBookingTokenDialog = useCallback(
    (patientId: string, onSuccess: () => void) => {
      const { emit, close } = create();
      emit(
        <RefundActiveBookingTokenPopup
          patientId={patientId}
          onClose={close}
          onSuccess={onSuccess}
        />
      );
    },
    [create]
  );

  return {
    emitRefundActiveBookingTokenDialog,
  };
}
