import { MetricName } from "@cur8/measurements";
import { Metric } from "lib/metric";
import { useEffect, useState } from "react";
import { useMetricAPI } from "./useMetricAPI";

export function useMetric<M extends MetricName>(
  metricName: M,
  patientId: string
) {
  return useMetrics(metricName, patientId, 1)?.[0];
}

export function useMetrics<M extends MetricName>(
  metricName: M,
  patientId: string,
  length: number = 1
): Metric<M>[] | undefined {
  const { fetchMetrics } = useMetricAPI();

  const [result, setResult] = useState<Metric<M>[]>();

  useEffect(() => {
    fetchMetrics(patientId, metricName, length).then(setResult);
  }, [fetchMetrics, setResult, patientId, metricName, length]);

  return result;
}
