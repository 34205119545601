import { Room, ScheduleViewSlot, Site } from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { DateTime, Interval } from "luxon";
import { useMemo } from "react";
import { Direction, SlotRenderer } from "../SlotRenderer";
import { Timeslot } from "../Timeslot";
import styles from "./styles.module.sass";

type Positioned<T extends object> = { direction: Direction; data: T };

type PositionedSlot = Positioned<ScheduleViewSlot>;

export function AppointmentsLayer({
  slots,
  selectedPeriod,
  closingHour,
  openingHour,
  isCensored,
  room,
  site,
}: {
  room: Room;
  slots: ScheduleViewSlot[];
  isCensored: boolean;
  selectedPeriod: Interval;
  closingHour: DateTime;
  openingHour: DateTime;
  site: Site;
}) {
  const slotsAsc = useMemo(() => {
    return (slots ?? []).sort(
      (a, b) => a.startTime.toUnixInteger() - b.startTime.toUnixInteger()
    );
  }, [slots]);
  const groups = useMemo(() => {
    const daysGrouped = selectedPeriod
      .splitBy({ day: 1 })
      .reduce<Record<string, ScheduleViewSlot[]>>((acc, val) => {
        acc[val.start.toISODate()] = [];
        return acc;
      }, {});

    slotsAsc.forEach((slot) => {
      const key = slot.startTime.toISODate();
      if (daysGrouped[key]) {
        daysGrouped[key].push(slot);
      }
    });

    return daysGrouped;
  }, [slotsAsc, selectedPeriod]);

  const dataFormated = useMemo(() => {
    return Object.entries(groups).map<[string, PositionedSlot[]]>(
      ([key, daySlots]) => {
        return [
          key,
          daySlots.reduce<PositionedSlot[]>((acc, current) => {
            const prev = acc.length ? acc[acc.length - 1] : undefined;
            const data = current;
            let direction = Direction.Left;
            if (data.startTime.minute >= 30) {
              direction = Direction.Right;
            }
            if (prev) {
              const overlap = prev.data.endTime > current.startTime;
              if (overlap) {
                direction =
                  prev.direction === Direction.Left
                    ? Direction.Right
                    : Direction.Left;
              }
            }
            acc.push({
              data,
              direction,
            });

            return acc;
          }, []),
        ];
      }
    );
  }, [groups]);

  return (
    <div className={classNames(styles.AppointmentsLayer)}>
      {dataFormated.map(([dayISOstring, slots]) => {
        return (
          <div className={styles.slot} key={dayISOstring}>
            {slots.map(({ data: slot, direction }) => {
              return (
                <SlotRenderer
                  key={slot.startTime.toUnixInteger()}
                  rowGapSize={2}
                  direction={direction}
                  slot={slot}
                  openingHour={DateTime.fromISO(dayISOstring, {
                    zone: site.timeZoneId,
                  }).plus({
                    hour: openingHour.hour,
                  })}
                  closingHour={DateTime.fromISO(dayISOstring, {
                    zone: site.timeZoneId,
                  }).plus({
                    hour: closingHour.hour,
                  })}
                >
                  <Timeslot
                    isCensored={isCensored}
                    slot={{
                      room,
                      interval: Interval.fromDateTimes(
                        slot.startTime,
                        slot.endTime
                      ),
                      slot: slot,
                    }}
                  />
                </SlotRenderer>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
