import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface SidePanelProps {
  children: ReactNode;
}

export function SidePanel({ children }: SidePanelProps) {
  return (
    <div className={styles.SidePanel}>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
