import { classNames } from "@pomle/classnames";
import { ColorMap } from "lib/webgl/colorizer";
import { useEffect, useRef, useState } from "react";
import { useGradientMap } from "render/hooks/api/image/useGradientMap";
import { ImageSize, PropertyRange, Tick } from "../../lib/types";
import { calculateTicks } from "../../lib/utils";
import styles from "./styles.module.sass";

const rangeCanvas: ImageSize = {
  width: 400,
  height: 20,
};

function createGradient(imgSize: ImageSize) {
  const canvas = document.createElement("canvas");
  canvas.width = imgSize.width;
  canvas.height = imgSize.height;
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("No GL context");
  }
  const grd = ctx.createLinearGradient(0, 0, imgSize.width, 0);
  grd.addColorStop(0.0, "rgb(1,1,1)");
  grd.addColorStop(1.0, "rgb(255,255,255)");
  ctx.fillStyle = grd;
  ctx.fillRect(0, 0, imgSize.width, imgSize.height);

  const gradient = new Image();
  gradient.src = canvas.toDataURL();
  canvas.remove();
  return gradient;
}

interface PropertyRangeLegendProps {
  colormap: ColorMap;
  range: PropertyRange;
}

export function RangeLegend({ colormap, range }: PropertyRangeLegendProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [ticks, setTicks] = useState<Tick[]>([] as Tick[]);
  const rangeImage = useGradientMap(colormap, createGradient(rangeCanvas));

  useEffect(() => {
    setTicks(calculateTicks(range.low, range.high, 5));
  }, [range.high, range.low]);

  return (
    <div className={styles.RangeLegend} ref={containerRef}>
      <div className={styles.gradient}>
        {rangeImage && <img src={rangeImage.src} alt="" />}
      </div>
      <div className={styles.ticks}>
        <svg xmlns="http://www.w3.org/2000/svg">
          {ticks.map(({ label, bottom }, idx) => {
            const x = bottom * rangeCanvas.width + 10;
            return (
              <g key={idx}>
                <text x={x} y={25} className={styles.textStroke}>
                  {label}
                </text>
                <text x={x} y={25}>
                  {label}
                </text>
                <line x1={x} y1={0} x2={x} y2={22} />
              </g>
            );
          })}
          <text
            x={rangeCanvas.width + 17}
            y={4}
            className={classNames(styles.unit, styles.unitStroke)}
          >
            {range.unit}
          </text>
          <text x={rangeCanvas.width + 17} y={4} className={styles.unit}>
            {range.unit}
          </text>
        </svg>
      </div>
    </div>
  );
}
