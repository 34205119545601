import { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./styles.module.sass";

function getSize(element: HTMLElement) {
  return {
    height: element.offsetHeight,
    width: element.offsetWidth,
  };
}

interface ContainerProps {
  active: boolean;
  children: ReactNode;
  onHeight(height: number): void;
}

export function Container({ active, children, onHeight }: ContainerProps) {
  const ref = useRef<HTMLDivElement>(null);

  const [stable, setStable] = useState(false);

  useEffect(() => {
    if (!active) {
      return;
    }

    const element = ref.current;
    if (!element) {
      return;
    }

    const updateSize = () => {
      const size = getSize(element);
      if (size.height != null) {
        if (onHeight) {
          onHeight(size.height);
        }
        setStable(true);
      }
    };

    const observer = new ResizeObserver(updateSize);

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [active, onHeight]);

  return (
    <div
      className={styles.Container}
      ref={ref}
      data-stable={stable}
      data-active={active}
    >
      {children}
    </div>
  );
}
