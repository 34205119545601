import { APITypesV1 } from "@cur8/api-client";
import { useNav } from "@pomle/react-router-paths";
import { useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { PatientName } from "render/fragments/patient/PatientName";
import { usePatient } from "render/hooks/api/usePatient";
import { paths } from "render/routes/paths";
import { FramedPage } from "render/ui/layouts/FramedPage/FramedPage";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { BackButtonLink } from "render/ui/trigger/BackButtonLink";
import { ButtonSet } from "render/ui/trigger/ButtonSet";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import { Lesion } from "./components/Lesion";
import styles from "./styles.module.sass";
import { Mode } from "./types";
import { extractLesions } from "./utils/lesionHelpers";

interface LesionPageProps {
  patientId: string;
}

export function LesionPage({ patientId }: LesionPageProps) {
  const api = useAPIClient();
  const [annotations, setAnnotations] = useState<APITypesV1.Annotation[]>([]);
  const [mode, setMode] = useState<Mode>("overview");
  const patient = usePatient(patientId);
  const lesions = useMemo(() => {
    return extractLesions(annotations);
  }, [annotations]);
  const nav = {
    patient: useNav(paths.patient.detail),
  };

  useEffect(() => {
    api.annotation
      .queryAnnotations({
        patientId: patientId,
        acceptState: APITypesV1.AcceptState.Accepted,
        pageSize: 100,
      })
      .result.then((response) => {
        setAnnotations(response.items);
      });

    return () => {};
  }, [api.annotation, patientId]);

  const patientLabel = patient ? <PatientName patient={patient} /> : undefined;

  return (
    <FramedPage>
      <div className={styles.LesionPage}>
        <PageHeader caption="Lesions">
          <ButtonSet>
            <HoverTextButton
              active={mode === "overview"}
              onClick={() => {
                setMode("overview");
              }}
            >
              Overview
            </HoverTextButton>
            <HoverTextButton
              active={mode === "macro"}
              onClick={() => {
                setMode("macro");
              }}
            >
              Macro
            </HoverTextButton>
            <HoverTextButton
              active={mode === "derma"}
              onClick={() => {
                setMode("derma");
              }}
            >
              Derma
            </HoverTextButton>
          </ButtonSet>
        </PageHeader>
        <BackButtonLink to={nav.patient.to({ patientId })}>
          {patientLabel}
        </BackButtonLink>
        <div>
          {lesions.map((l) => {
            const lannos = annotations.filter(
              (a) => a.physicalArtefactId === l
            );
            return <Lesion annotations={lannos} mode={mode} key={l} />;
          })}
        </div>
      </div>
    </FramedPage>
  );
}
