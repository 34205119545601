import { Length } from "@cur8/measurements";
import { StudySymbol } from "render/ui/symbol/StudySymbol";
import { LengthValue } from "../components/LengthValue";
import { MeasurementToggle } from "../components/MeasurementToggle";
import styles from "./styles.module.sass";

interface HipProps {
  circumference: Length;
}

export function Hip({ circumference }: HipProps) {
  return (
    <div className={styles.Hip}>
      <MeasurementToggle anchor="left">
        <LengthValue
          value={
            <>
              {(circumference.meters * 100).toFixed(0)}
              <StudySymbol />
            </>
          }
          unit="cm"
        />
      </MeasurementToggle>
    </div>
  );
}
