import { DateTime } from "luxon";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

type NotificationType = "success" | "error" | "info";

type NotificationEntry = {
  id: string;
  timestamp: DateTime;
  type: NotificationType;
  content: ReactNode;
};

let counter = 0;

function useNotificationState() {
  const [entries, setEntries] = useState<NotificationEntry[]>([]);

  const emit = useCallback((content: ReactNode, type: NotificationType) => {
    const entry: NotificationEntry = {
      id: (++counter).toString(),
      timestamp: DateTime.now(),
      type,
      content,
    };

    setEntries((entries) => {
      return [...entries, entry];
    });
  }, []);

  const clear = useCallback(() => {
    setEntries([]);
  }, []);

  return { entries, emit, clear };
}

interface ContextType {
  entries: NotificationEntry[];
  emit: (content: ReactNode, type: NotificationType) => void;
  clear: () => void;
}

const Context = createContext<ContextType | null>(null);

interface NotificationContextProps {
  children: ReactNode;
}

export function NotificationContext({ children }: NotificationContextProps) {
  const value = useNotificationState();

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useNotifications() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useNotifications without NotificationContext");
  }
  return context;
}
