import { APITypesV1 } from "@cur8/api-client";
import { ImmutableScan } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

export function useSiradQuality(scan: ImmutableScan | undefined) {
  const api = useAPIClient().scan;

  const [bestChannel, setBestChannel] = useState<number>();
  const [quality, setQuality] = useState<APITypesV1.SiradQualityResultData>();
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!scan) {
      return;
    }

    const request = api.fetchScanResult({
      patientId: scan.patientId,
      scanId: scan.id,
      scanVersion: scan.version,
      resultName: "sirad_signal_quality",
    });

    request.result
      .then((r) => {
        const data = r.data as APITypesV1.SiradQualityResultData;
        if (data.siradQuality && data.siradQuality.length > 0) {
          setBestChannel(data.siradQuality[0].channel);
          setQuality(data);
        }
      })
      .catch(silenceAbort)
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          console.error("Data not found (404):", err);
        } else {
          console.error("An error occurred:", err);
        }
        setError(err);
      });

    return () => {
      setBestChannel(undefined);
      setQuality(undefined);
      request.abandon();
    };
  }, [api, scan]);

  return { bestChannel, error, quality };
}
