import { PathLink } from "@pomle/react-router-paths";
import { ButtonHTMLAttributes } from "react";
import styles from "./styles.module.sass";

interface MenuButtonProps extends ButtonHTMLAttributes<any> {
  to: string;
}

export function MenuButtonLink({ children, to, ...props }: MenuButtonProps) {
  return (
    <PathLink to={to} className={styles.MenuButton}>
      <div className={styles.icon}>{children}</div>
      <label>{props.title}</label>
    </PathLink>
  );
}
