import { APITypesV1 } from "@cur8/api-client";
import { debounce } from "@pomle/throb";
import { clamp } from "lib/math";
import {
  Dispatch,
  PointerEvent,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { VerticalLine } from "../VerticalLine/VerticalLine";
import { MINIMUM_SELECTION } from "../constants";
import styles from "./style.module.sass";

export type Range = [number, number];

interface RulerProps {
  children: ReactNode;
  selectedRange: Range;
  signalLengthMs: number;
  onDistance: (d: number | undefined, r?: APITypesV1.Range) => void;
  onZoomTo: Dispatch<SetStateAction<Range>>;
}

function distance(
  selectedRange: Range,
  rulerRange: Range,
  signalLengthMs: number
) {
  const selectionLength =
    Math.abs(selectedRange[1] - selectedRange[0]) * signalLengthMs;
  const delta = Math.abs(rulerRange[1] - rulerRange[0]);
  return Math.round(delta * selectionLength);
}

/**
 * Calculate the actual time range for assessment summary
 */
function timeRange(
  selectedRange: Range,
  rulerRange: Range,
  signalLengthMs: number
): APITypesV1.Range {
  const length = Math.abs(selectedRange[1] - selectedRange[0]);
  const from =
    ((selectedRange[0] + length * rulerRange[0]) * signalLengthMs) / 1000;
  const to =
    ((selectedRange[0] + length * rulerRange[1]) * signalLengthMs) / 1000;
  return { from, to };
}

export function Ruler({
  children,
  selectedRange,
  signalLengthMs,
  onDistance,
  onZoomTo,
}: RulerProps) {
  const [rulerRange, setRulerRange] = useState<Range>([0, 0]);
  const [indicatorPos, setIndicatorPos] = useState<number>();

  const onDistanceDebounced = debounce(onDistance, 100);

  const selection = useMemo(() => {
    return {
      start: Math.min(...selectedRange),
      end: Math.max(...selectedRange),
    };
  }, [selectedRange]);

  const ruler = useMemo(() => {
    return {
      start: Math.min(...rulerRange),
      end: Math.max(...rulerRange),
    };
  }, [rulerRange]);

  const handlePointer = useCallback(
    (event: PointerEvent<HTMLDivElement>) => {
      if (event.button === 2) {
        event.preventDefault();

        if (event.type === "pointerdown") {
          // Right click to zoom in to selection
          const delta = selection.end - selection.start;
          const start = selection.start + ruler.start * delta;
          const end = selection.start + ruler.end * delta;
          if (end - start > MINIMUM_SELECTION) {
            onZoomTo([start, end]);
          }
        }
        return;
      }

      const bounds = event.currentTarget.getBoundingClientRect();
      const pos = clamp((event.clientX - bounds.left) / bounds.width, 0, 1);

      if (event.type === "pointerdown") {
        setRulerRange([pos, pos]);
        setIndicatorPos(undefined);
      } else if (event.type === "pointermove" && !!event.buttons) {
        // dragging
        setRulerRange((prev) => {
          const newRange: Range = [prev ? prev[0] : pos, pos];
          onDistanceDebounced(
            distance(selectedRange, newRange, signalLengthMs)
            /* No need to define range here. It's done in pointerup */
          );
          return newRange;
        });
      } else if (event.type === "pointermove") {
        // just moving around
        setIndicatorPos(pos);
      } else if (event.type === "pointerup") {
        const newRange: Range = [rulerRange ? rulerRange[0] : pos, pos];
        onDistanceDebounced(
          distance(selectedRange, newRange, signalLengthMs),
          timeRange(selectedRange, newRange, signalLengthMs)
        );
        setRulerRange(newRange);
        setIndicatorPos(pos);
      }
    },
    [
      selection.end,
      selection.start,
      ruler.start,
      ruler.end,
      onZoomTo,
      onDistanceDebounced,
      selectedRange,
      signalLengthMs,
      rulerRange,
    ]
  );

  useEffect(() => {
    // Clear ruler if selection in navigator is changed
    setRulerRange([0, 0]);
    onDistance(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRange]);

  return (
    <div className={styles.Ruler}>
      <div
        className={styles.content}
        onPointerDown={handlePointer}
        onPointerMove={handlePointer}
        onPointerUp={handlePointer}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        onPointerLeave={() => setIndicatorPos(undefined)}
      >
        <div data-testid="rulerChildren">
          {children}
          <VerticalLine position={indicatorPos} />
        </div>
      </div>

      {ruler.end - ruler.start > 0 && (
        <div
          className={styles.selection}
          style={{
            left: `${ruler.start * 100}%`,
            right: `${(1 - ruler.end) * 100}%`,
          }}
        />
      )}
    </div>
  );
}
