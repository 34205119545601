import { Annotation, BoundingBoxAnnotation } from "@cur8/rich-entity";
import { useQueryParams } from "@pomle/react-router-paths";
import { useCallback, useMemo } from "react";
import { FoldUpCard } from "render/ui/layouts/FoldUpCard";
import { selectionQuery } from "../../query";
import { PhysicalArtefactView } from "./components/PhysicalArtefactView";

interface PhysicalArtifactExplorerProps {
  annotations: Annotation[];
  reloadAnnotations: () => void;
}

export function PhysicalArtifactExplorer({
  annotations,
  reloadAnnotations,
}: PhysicalArtifactExplorerProps) {
  const [params, setParams] = useQueryParams(selectionQuery);

  const selectedAnnotation = useMemo(() => {
    return annotations.find((annotation) => {
      return annotation.id === params.selectedAnnotation[0];
    });
  }, [annotations, params]);

  const handleClose = useCallback(() => {
    setParams({
      selectedAnnotation: [],
      selectedImage: [],
      highlightedAnnotation: params.selectedAnnotation ?? null,
    });
  }, [params.selectedAnnotation, setParams]);

  return (
    <FoldUpCard active={!!selectedAnnotation}>
      {selectedAnnotation && (
        <PhysicalArtefactView
          key={selectedAnnotation.id}
          annotation={selectedAnnotation as BoundingBoxAnnotation}
          onClose={() => {
            handleClose();
            reloadAnnotations();
          }}
          onDelete={() => {
            handleClose();
            reloadAnnotations();
          }}
        />
      )}
    </FoldUpCard>
  );
}
