import { APITypesV1 } from "@cur8/api-client";

const State = APITypesV1.PhysicalArtefactState;

export const LabelMap: Record<APITypesV1.PhysicalArtefactState, string> = {
  [State.ClosedAsHarmless]: "Closed - harmless",
  [State.ClosedAsTreated]: "Closed - treated",
  [State.NeedsFollowUp]: "Needs follow-up",
  [State.NeedsTreatment]: "Needs treatment",
  [State.SelfMonitor]: "Self-monitor",
  [State.NeedsDermoscope]: "Needs dermoscope",
  [State.FollowUp4Months]: "Follow-up in 4 months",
  [State.NeedsExcision]: "Needs excision",
  [State.NeedsDermatologistReferral]: "Needs dermatologist referral",
  [State.Dermoscoped]: "Dermoscoped",
  [State.ReferralSent]: "Referral sent",
  [State.Excised]: "Excised",
  [State.WaitingForTestResults]: "Waiting for test results",
  [State.ReferralSentDerma]: "Referral sent - Derma referral",
  [State.ReferralSentExcision]: "Referral sent - Excision",
  [State.None]: "None",
};

export function PhysicalArtefactState({
  state,
}: {
  state: APITypesV1.PhysicalArtefactState;
}) {
  return <>{LabelMap[state] ?? "Unknown"}</>;
}
