import { Plot } from "lib/api/cardio";
import { normalize } from "lib/normalize";
import { useMemo } from "react";
import { CardioGraph } from "render/ui/graph/CardioGraph";
import { CardioGrid } from "render/ui/graph/CardioGrid";
import styles from "./styles.module.sass";

interface ECGGraphProps {
  plot: Plot;
  progress: number;
}

export function ECGGraph({ plot, progress }: ECGGraphProps) {
  const scaledPoints = useMemo(() => {
    const slice = plot.signal;

    return slice.map(normalize(0, 1)).map((v, index) => {
      return {
        x: (index / slice.length) * 100,
        y: 5 + (1 - v) * 90,
      };
    });
  }, [plot]);

  const points = useMemo(() => {
    return [
      ...scaledPoints,
      { x: 200, y: 50 },
      { x: 100, y: 101 },
      { x: 0, y: 101 },
    ];
  }, [scaledPoints]);

  return (
    <svg
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      className={styles.ECGGraph}
    >
      <CardioGrid
        vertical={[20, 40, 60, 80]}
        horizontal={[0, 20, 40, 60, 80, 100]}
      />

      <CardioGraph points={points} progress={progress} />
    </svg>
  );
}
