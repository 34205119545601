import { PathLink } from "@pomle/react-router-paths";
import { ReactNode } from "react";
import { ArrowIcon } from "render/ui/symbol/ArrowIcon";
import classes from "./styles.module.sass";

interface BackButtonProps {
  to: string;
  children: ReactNode;
}

export function BackButtonLink({ children, ...props }: BackButtonProps) {
  return (
    <PathLink className={classes.BackButtonLink} {...props}>
      <ArrowIcon /> {children}
    </PathLink>
  );
}
