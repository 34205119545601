import { Patient } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { paths } from "render/routes/paths";
import { DayDate } from "render/ui/format/DayDate";
import { Table } from "render/ui/layouts/Table";
import { ArrowIcon } from "render/ui/symbol/ArrowIcon";
import { PatientName } from "../PatientName";
import { PatientPhysicalIdentifier } from "../PatientPhysicalIdentifier";

interface PatientSearchResultTableProps {
  patients: Patient[];
}

export function PatientSearchResultTable({
  patients,
}: PatientSearchResultTableProps) {
  const nav = {
    details: useNav(paths.patient.detail),
  };

  return (
    <Table>
      <tbody>
        {patients.map((patient) => {
          const patientId = patient.patientId;
          return (
            <tr
              key={patientId}
              onClick={nav.details.on({ patientId })}
              style={{ cursor: "pointer " }}
            >
              <td>
                <svg width="12" height="12" viewBox="0 0 12 12">
                  <circle cx="6" cy="6" r="6" fill="#48D3E6" />
                </svg>
              </td>
              <td>
                <b>
                  <PatientName patient={patient} />
                </b>
              </td>
              <td>
                <code>
                  <PatientPhysicalIdentifier patient={patient} />
                </code>
              </td>
              <td>
                {patient.dateOfBirth ? (
                  <DayDate date={patient.dateOfBirth} />
                ) : (
                  <>&mdash;</>
                )}
              </td>
              <td>
                <b>
                  View&ensp;
                  <ArrowIcon direction="right" />
                </b>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
