import { PhysicalIdType } from "@cur8/uri";
import { humanName } from "./humanName";

interface PhysicalIdNameProps {
  type: PhysicalIdType;
}

export function PhysicalIdName({ type }: PhysicalIdNameProps) {
  return <>{humanName(type)}</>;
}
