import { DateTime, Interval } from "luxon";
import { useCallback } from "react";
import { useCalendarInteraction } from "../../../Calendar/hooks/useCalendarInteraction";
import {
  useCalendarState,
  ViewRangeMode,
} from "../../../Calendar/hooks/useCalendarState";
import CalendarDayIcon from "./assets/calendar-day.svg?react";
import CalendarWeekIcon from "./assets/calendar-week.svg?react";
import styles from "./styles.module.sass";

export function ViewRangeSelector() {
  const { viewRange, selectedPeriod } = useCalendarState();
  const { setSelectedPeriod } = useCalendarInteraction();

  const toggleDailyView = useCallback(() => {
    const today = DateTime.now();
    if (selectedPeriod.contains(today)) {
      setSelectedPeriod(
        Interval.fromDateTimes(today.startOf("day"), today.endOf("day"))
      );
    } else {
      const start = selectedPeriod.start.startOf("week");
      setSelectedPeriod(
        Interval.fromDateTimes(start.startOf("day"), start.endOf("day"))
      );
    }
  }, [selectedPeriod, setSelectedPeriod]);

  const toggleWeeklyView = useCallback(() => {
    setSelectedPeriod(
      Interval.fromDateTimes(
        selectedPeriod.start.startOf("week"),
        selectedPeriod.start.endOf("week")
      )
    );
  }, [selectedPeriod, setSelectedPeriod]);

  return (
    <>
      {viewRange === ViewRangeMode.Daily && (
        <button className={styles.button} onClick={toggleWeeklyView}>
          <div>Daily</div>
          <CalendarDayIcon className={styles.calendarIcon} />
        </button>
      )}
      {viewRange === ViewRangeMode.Weekly && (
        <button className={styles.button} onClick={toggleDailyView}>
          <div>Weekly</div>
          <CalendarWeekIcon className={styles.calendarIcon} />
        </button>
      )}
    </>
  );
}
