import { AggregatedBloodPressureData, Patient } from "@cur8/rich-entity";
import { Interval } from "luxon";
import { useMemo } from "react";
import { BloodPressureTableBody } from "./components/BloodPressureTableBody";
import { BloodPressureTableFooter } from "./components/BloodPressureTableFooter";
import { BloodPressureTableHeader } from "./components/BloodPressureTableHeader";

interface BloodPressureTableProps {
  patient: Patient;
  interval: Interval;
  measurements: AggregatedBloodPressureData[];
}

export function BloodPressureTable({
  interval,
  measurements,
  patient,
}: BloodPressureTableProps) {
  const days = useMemo(
    () => interval.splitBy({ day: 1 }).map(({ start }) => start),
    [interval]
  );

  return (
    <>
      <BloodPressureTableHeader patient={patient} measurements={measurements} />
      <BloodPressureTableBody
        patient={patient}
        days={days}
        measurements={measurements}
      />
      <BloodPressureTableFooter days={days} />
    </>
  );
}
