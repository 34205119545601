import { APITypesV1 } from "@cur8/api-client";
import { ImmutableScan } from "@cur8/rich-entity";
import { sleep } from "lib/doctor-scribe/utils";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useVisits } from "render/hooks/api/useVisits";
import { useLatestLLMScan } from "./useLatestLLMScan";

export function useLLMScan(patientId: string | undefined) {
  const isMountedRef = useRef(false);

  const api = useAPIClient();
  const visits = useVisits(patientId);

  const { latestLLMScan, reloadLatestLLMScan } = useLatestLLMScan(patientId);
  const scanRef = useRef(latestLLMScan);

  const ongoingVisit = useMemo(() => {
    const ongoing =
      visits !== undefined
        ? visits?.filter((v) => {
            return (
              v.status === APITypesV1.VisitStatus.Ongoing ||
              v.status === APITypesV1.VisitStatus.CheckedIn
            );
          })
        : [];
    return ongoing.length > 0 ? ongoing[0] : undefined;
  }, [visits]);

  const createLLMScan = useCallback(
    async (patientId: string) => {
      const timestamp = new Date();

      const primarySourceUri = `cur8:imported-device-data:llm:mic:${timestamp.getTime()}`;

      try {
        const scan = await api.scan.createPatientScan({
          patientId,
          primarySourceUri,
          sourceUris: [],
          scanType: "llm/1",
          pendingResults: [
            "audioFiles",
            "audioRecordingCount",
            "transcriptionFiles",
            "transcription",
            "memberSummary",
            "tunedMemberSummary",
            "reviewResult",
          ],
          recordingTimestamp: timestamp.toISOString(),
          isInternalScan: true,
        }).result;

        return scan;
      } catch (e) {
        console.error("Failed to create scan:", e);
        return undefined;
      }
    },
    [api.scan]
  );

  const completeResult = useCallback(
    (scan: APITypesV1.ImmutableScan, resultName: string) => {
      return api.scan.createScanResult({
        patientId: scan.patientId,
        scanId: scan.id,
        scanVersion: scan.version,
        resultName,
        state: APITypesV1.ResultState.Complete,
      }).result;
    },
    [api]
  );

  const saveAudioRecordingCount = useCallback(
    async (scan: APITypesV1.ImmutableScan, count: number, duration: number) => {
      await api.scan.createScanResult({
        patientId: scan.patientId,
        scanId: scan.id,
        scanVersion: scan.version,
        resultName: "audioRecordingCount",
        state: APITypesV1.ResultState.Complete,
        data: {
          $type: "AudioRecordingCount",
          count: count.toString(),
          duration,
        },
      }).result;
    },
    [api]
  );

  const triggerMemberSummaryGenerationWithoutTranscription = useCallback(
    async (scan: APITypesV1.ImmutableScan) => {
      const p1 = completeResult(scan, "audioFiles");
      const p2 = completeResult(scan, "transcriptionFiles");
      const p3 = completeResult(scan, "transcription");
      const p4 = completeResult(scan, "transcriptionFiles");
      const p5 = saveAudioRecordingCount(scan, 0, 0);
      await Promise.all([p1, p2, p3, p4, p5]);
    },
    [completeResult, saveAudioRecordingCount]
  );

  const pollForNewScan = async () => {
    await sleep(1000);
    reloadLatestLLMScan();
    while (
      isMountedRef.current &&
      (!scanRef.current ||
        scanRef.current.resultStateSummary["memberSummary"] !== "complete" ||
        scanRef.current.resultStateSummary["tunedMemberSummary"] !== "complete")
    ) {
      await sleep(1000);
      reloadLatestLLMScan();
    }
  };

  const saveReviewResult = useCallback(
    async (
      scan: ImmutableScan,
      internalData: APITypesV1.InternalReviewResult
    ) => {
      await api.scan.createScanResult({
        patientId: scan.patientId,
        scanId: scan.id,
        scanVersion: scan.version,
        resultName: "reviewResult",
        state: APITypesV1.ResultState.Complete,
        internalData,
      }).result;
    },
    [api]
  );

  useEffect(() => {
    scanRef.current = latestLLMScan;
  }, [latestLLMScan]);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return {
    latestLLMScan,
    reloadLatestLLMScan,
    ongoingVisit,
    createLLMScan,
    saveAudioRecordingCount,
    triggerMemberSummaryGenerationWithoutTranscription,
    pollForNewScan,
    saveReviewResult,
  };
}

export type LLMScanType = ReturnType<typeof useLLMScan>;
