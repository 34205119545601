import { APITypesV1 } from "@cur8/api-client";
import {
  Annotation,
  BoundingBoxAnnotation,
  Patient,
  Recording,
} from "@cur8/rich-entity";
import { RecordingURI } from "@cur8/uri";
import { useQueryParams } from "@pomle/react-router-paths";
import { matchesPanorama } from "lib/api/resolvers/annotation";
import { Side } from "lib/api/types";
import { PanoramaImageURI } from "lib/api/uri";
import { Box, Point } from "lib/math";
import { useCallback, useMemo, useState } from "react";
import { usePhysicalArtefactCRUD } from "render/hooks/api/physical-artefact/usePhysicalArtefactCRUD";
import { useAnnotationCRUD } from "render/hooks/api/useAnnotationCRUD";
import { CloseIcon } from "render/ui/symbol/HoverIcon";
import { FigureButton } from "render/ui/trigger/FigureButton";
import { selectionQuery } from "../../../../query";
import { Marking } from "../../../../types";
import { createMarkings } from "../../../PhysicalArtifactExplorer/markings";
import { ImagePresentationLayer } from "./components/ImagePresentationLayer";
import { toCreatePhysicalArtefactRequest } from "./conversion";
import styles from "./styles.module.sass";

function moveAnnotation(
  annotation: BoundingBoxAnnotation,
  movement: Point
): BoundingBoxAnnotation {
  let bb = annotation.data.rect;

  bb.x += movement.x;
  bb.y += movement.y;
  return {
    ...annotation,
    data: {
      rect: bb,
    },
  };
}

interface BoundingBoxAnnotatorProps {
  patient: Patient;
  recording: Recording;
  annotations: Annotation[];
  detectedAnnotations: Annotation[];
  reloadAnnotations: () => void;
  side: Side;
  cameraName: string;
  onQuit: () => void;
}

export function BoundingBoxAnnotator({
  patient,
  recording,
  annotations,
  detectedAnnotations,
  reloadAnnotations,
  side,
  cameraName,
  onQuit,
}: BoundingBoxAnnotatorProps) {
  const patientId = patient.patientId;
  const deviceId = recording.deviceId;
  const recordingId = recording.id;

  const recordingURI = useMemo(() => {
    return new RecordingURI(deviceId, recordingId);
  }, [deviceId, recordingId]);

  const panoramaURI = useMemo(() => {
    return new PanoramaImageURI(deviceId, recordingId, side, cameraName);
  }, [deviceId, recordingId, side, cameraName]);

  const detected = useMemo(() => {
    return detectedAnnotations.filter((anno) => {
      return matchesPanorama(anno, panoramaURI);
    });
  }, [panoramaURI, detectedAnnotations]);

  const relevantAnnotations = useMemo(() => {
    return annotations.filter((a) => {
      if (a.applicationSpecificTarget?.startsWith("panorama")) {
        const uri = PanoramaImageURI.fromString(
          (a.applicationSpecificTarget || "").toString()
        );
        return uri.side === side && uri.cameraName === cameraName;
      } else {
        return false;
      }
    }) as BoundingBoxAnnotation[];
  }, [annotations, cameraName, side]);

  const [movedAnnotations, setMovedAnnotations] = useState<
    BoundingBoxAnnotation[]
  >([]);

  const markings = useMemo((): Marking[] => {
    return createMarkings(recording, relevantAnnotations, movedAnnotations);
  }, [relevantAnnotations, recording, movedAnnotations]);

  const { create: createAnnotation } = useAnnotationCRUD();
  const { create: createPhysicalArtefact } = usePhysicalArtefactCRUD(patientId);

  const saveAnnotation = useCallback(
    async (bounds: Box, source?: Annotation) => {
      const annotation = await createAnnotation(patientId, {
        classification: source?.classification,
        physicalArtefactId: source?.physicalArtefactId,
        targetURI: recordingURI,
        acceptState: APITypesV1.AcceptState.Accepted,
        applicationSpecificTarget: panoramaURI.toString(),
        data: {
          rect: bounds,
        },
      });

      reloadAnnotations();

      return annotation as BoundingBoxAnnotation;
    },
    [patientId, recordingURI, panoramaURI, createAnnotation, reloadAnnotations]
  );

  const [params, setParams] = useQueryParams(selectionQuery);

  const setSelectedAnnotation = useCallback(
    (annotation: Annotation) => {
      setParams({
        selectedAnnotation: [annotation.id],
      });
    },
    [setParams]
  );

  const handleMarkingSelected = useCallback(
    async (marking: Marking) => {
      const annotation = marking.annotation;
      if (marking.type === "assumed") {
        const createdAnnotation = await saveAnnotation(
          annotation.data.rect,
          annotation
        );
        setSelectedAnnotation(createdAnnotation);
      } else {
        setSelectedAnnotation(marking.annotation);
      }
    },
    [setSelectedAnnotation, saveAnnotation]
  );

  const handleAnnotationMove = useCallback(
    (annotation: BoundingBoxAnnotation, movement: Point) => {
      const newMovedAnnotation = moveAnnotation(annotation, movement);
      const indx = movedAnnotations.findIndex((ma) => ma.id === annotation.id);
      if (indx > -1) {
        // exists, replace
        setMovedAnnotations(
          movedAnnotations.map((ma) => {
            return ma.id === newMovedAnnotation.id ? newMovedAnnotation : ma;
          })
        );
      } else {
        // new, add
        setMovedAnnotations([...movedAnnotations, newMovedAnnotation]);
      }
    },
    [movedAnnotations]
  );

  const handleRectCreated = useCallback(
    async (bounds: Box, source?: Annotation) => {
      const annotation = await saveAnnotation(bounds, source);
      await createPhysicalArtefact(toCreatePhysicalArtefactRequest(annotation));
      setSelectedAnnotation(annotation);
    },
    [saveAnnotation, setSelectedAnnotation, createPhysicalArtefact]
  );

  const highlightedAnnotation = useMemo(() => {
    if (
      !params.highlightedAnnotation ||
      params.highlightedAnnotation.length === 0
    ) {
      return;
    }

    const highlightedAnnotationId = params.highlightedAnnotation[0];

    return markings.find(
      (marking) => marking.annotation.id === highlightedAnnotationId
    );
  }, [markings, params.highlightedAnnotation]);

  return (
    <div className={styles.BoundingBoxAnnotator}>
      <div className={styles.Nav}>
        <FigureButton onClick={onQuit}>
          <CloseIcon />
        </FigureButton>
      </div>

      <ImagePresentationLayer
        panoramaURI={panoramaURI}
        markings={markings}
        detectedAnnotations={detected}
        highlightedAnnotation={highlightedAnnotation}
        onMarkingSelected={handleMarkingSelected}
        onRectCreated={handleRectCreated}
        onAnnotationMove={handleAnnotationMove}
      />
    </div>
  );
}
