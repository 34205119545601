import { useCallback } from "react";
import { usePopupDialog } from "render/context/PopupDialogContext";
import { ReportLLMProblem } from "./ReportLLMProblem";

export function useReportProblemDialog() {
  const { create } = usePopupDialog();

  const emitProblemDialog = useCallback(
    (content: string, onSend: (feedback: string) => void) => {
      const { emit, close } = create();
      emit(
        <ReportLLMProblem
          content={content}
          onOK={(feedback: string) => {
            onSend(feedback);
            close();
          }}
          onCancel={close}
        />
      );
    },
    [create]
  );

  return {
    emitProblemDialog,
  };
}
