import { ReactNode } from "react";
import { FormHandle } from "render/hooks/useFormHandle";
import styles from "./styles.module.sass";

interface FormProps {
  children: ReactNode;
  handle: FormHandle;
}

export function Form({ handle, children }: FormProps) {
  return (
    <form className={styles.Form} onSubmit={handle.onSubmit}>
      {children}
    </form>
  );
}
