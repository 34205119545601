import { Assessment } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { useCallback, useMemo } from "react";
import {
  toReadableState,
  toTypeName,
} from "render/pages/AssessmentPage/lib/utils";
import { paths } from "render/routes/paths";
import { UserDisplayName } from "./UserDisplayName";
import styles from "./styles.module.sass";

interface AssessmentsTableProps {
  assessment: Assessment;
}

export function AssessmentRow({ assessment }: AssessmentsTableProps) {
  const userUri = useMemo(() => {
    const uri = assessment.audit.created?.userUri;
    if (!uri || uri.toString().startsWith("cur8:user:worker:")) {
      return undefined;
    } else {
      return uri;
    }
  }, [assessment.audit.created?.userUri]);

  const nav = {
    assessment: useNav(paths.patient.assessment),
  };

  const showAssessment = useCallback(() => {
    nav.assessment.go({
      patientId: assessment.patientId,
      assessmentId: assessment.itemId,
      versionId: assessment.itemVersion,
    });
  }, [
    assessment.itemId,
    assessment.itemVersion,
    assessment.patientId,
    nav.assessment,
  ]);

  return (
    <tr onClick={showAssessment} className={styles.tr}>
      <td>{toTypeName(assessment.itemTypeName)}</td>
      <td>{toReadableState(assessment.assessmentState)}</td>
      <td>{userUri && <UserDisplayName userUri={userUri} />}</td>
      <td>
        <time dateTime={assessment.createdAt.toISO()}>
          {assessment.createdAt.toFormat("yyyy-LL-dd HH:mm")}
        </time>
      </td>
    </tr>
  );
}
