import { DateTime } from "luxon";
import { useMemo } from "react";

interface PassedTimeBetweenProps {
  start: DateTime;
  end: DateTime;
}

export function PassedTimeBetween({ start, end }: PassedTimeBetweenProps) {
  const duration = end
    .diff(start, ["years", "months", "days", "hours", "minutes", "seconds"])
    .normalize();

  const { years, months, days, hours, minutes } = duration;

  const text = useMemo(() => {
    if (years >= 1) {
      return years > 1 ? <>{years} years ago</> : <>{years} year ago</>;
    }

    if (months >= 1) {
      return months > 1 ? <>{months} months ago</> : <>{months} month ago</>;
    }

    if (days >= 1) {
      return days > 1 ? <>{days} days ago</> : <>{days} day ago</>;
    }

    if (hours >= 1) {
      return hours > 1 ? <>{hours} hours ago</> : <>{hours} hour ago</>;
    }

    if (minutes >= 1) {
      return minutes > 1 ? (
        <>{minutes} minutes ago</>
      ) : (
        <>{minutes} minute ago</>
      );
    }

    return <>right now</>;
  }, [years, months, days, hours, minutes]);

  return <time dateTime={duration.toISO()}>{text}</time>;
}
