import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface CaptionedBoxProps {
  caption: ReactNode;
  children: ReactNode;
  selected: boolean;
  onSelect: () => void;
}

export function CaptionedBox({
  caption,
  children,
  selected,
  onSelect,
}: CaptionedBoxProps) {
  return (
    <div className={styles.CaptionedBox} data-active={selected}>
      <header onClick={onSelect}>{caption}</header>

      <div className={styles.body}>{children}</div>
    </div>
  );
}
