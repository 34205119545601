import { APITypesV1 } from "@cur8/api-client";
import { ReactNode } from "react";

const STATE_MAP: Record<APITypesV1.ScanState, ReactNode> = {
  [APITypesV1.ScanState.Available]: "Available",
  [APITypesV1.ScanState.Failed]: "Failed",
  [APITypesV1.ScanState.Inprogress]: "In Progress",
  [APITypesV1.ScanState.Finished]: "Finished",
};

interface ScanStateProps {
  state: APITypesV1.ScanState;
}

export function ScanState({ state }: ScanStateProps) {
  return <>{STATE_MAP[state]}</>;
}
