import { useNotifications } from "render/context/NotificationContext";
import { Timestamp } from "render/ui/format/Timestamp";
import { FramedPage } from "render/ui/layouts/FramedPage/FramedPage";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { Table } from "render/ui/layouts/Table";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";

export function NotificationPage() {
  const { entries, clear } = useNotifications();
  return (
    <FramedPage>
      <div>
        <PageHeader caption="Notifications">
          <HoverTextButton onClick={clear}>Clear</HoverTextButton>
        </PageHeader>

        <Table>
          <thead>
            <tr>
              <th>Time</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {entries.length > 0 ? (
              entries.map((notification) => {
                return (
                  <tr key={notification.id}>
                    <td>
                      <Timestamp date={notification.timestamp} />
                    </td>
                    <td>{notification.content}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>-</td>
                <td>Notification Center empty</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </FramedPage>
  );
}
