import { useState } from "react";
import { FeedbackIcon } from "render/ui/layouts/PageFrame/components/Sidebar/components/FeedbackIcon/FeedbackIcon";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";

import styles from "./style.module.sass";

interface FeedbackProps {
  onLike: (like: boolean) => void;
  onReportProblem: () => void;
}

export function Feedback({ onLike, onReportProblem }: FeedbackProps) {
  const [isLiked, setIsLiked] = useState<boolean>();

  return (
    <div className={styles.Feedback}>
      <div className={styles.row}>
        <div>What do you think about the generated summary?</div>
        <div className={styles.buttons}>
          <HoverTextButton
            active={isLiked === true}
            onClick={() => {
              setIsLiked(true);
              onLike(true);
            }}
          >
            👍Great
          </HoverTextButton>
          <HoverTextButton
            active={isLiked === false}
            onClick={() => {
              setIsLiked(false);
              onLike(false);
            }}
          >
            👎Yikes
          </HoverTextButton>
          <HoverTextButton onClick={onReportProblem}>
            <div className={styles.feedback}>
              <FeedbackIcon /> Feedback
            </div>
          </HoverTextButton>
        </div>
      </div>
    </div>
  );
}
