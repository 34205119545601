import { TextButton } from "render/ui/trigger/TextButton";
import styles from "./styles.module.sass";
import { Arrow } from "./ui/Arrow";

interface Props {
  navigateToPreviousPeriod: () => void;
  navigateToNextPeriod: () => void;
}

export function PeriodSelector({
  navigateToPreviousPeriod,
  navigateToNextPeriod,
}: Props) {
  return (
    <nav className={styles.PeriodSelector}>
      <TextButton onClick={navigateToPreviousPeriod}>
        <Arrow />
      </TextButton>
      <TextButton onClick={navigateToNextPeriod}>
        <Arrow direction="right" />
      </TextButton>
    </nav>
  );
}
