import { APITypesV1 } from "@cur8/api-client";

const COLOR_MAP: Record<APITypesV1.ScanState, string> = {
  [APITypesV1.ScanState.Available]: "#48D3E5",
  [APITypesV1.ScanState.Failed]: "#F4A6A7",
  [APITypesV1.ScanState.Inprogress]: "#2B2B59",
  [APITypesV1.ScanState.Finished]: "#48D3E5",
};

interface ScanBulletProps {
  state: APITypesV1.ScanState;
}

export function ScanBullet({ state }: ScanBulletProps) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12">
      <circle cx="6" cy="6" r="6" fill={COLOR_MAP[state]} />
    </svg>
  );
}
