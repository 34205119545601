import { Patient } from "@cur8/rich-entity";
import { HDLItem } from "./components/HDLItem";
import { NonHDLItem } from "./components/NonHDL/NonHDLItem";
import { TriglyceridesItem as Triglycerides } from "./components/Triglycerides";
import styles from "./styles.module.sass";

interface BloodFatsLeftSectionProps {
  patient: Patient;
}

export function BloodFatsLeftSection({ patient }: BloodFatsLeftSectionProps) {
  return (
    <div className={styles.BloodFatsSection}>
      <div className={styles.items}>
        <HDLItem patient={patient} />
        <NonHDLItem patient={patient} />
        <Triglycerides patient={patient} />
      </div>
    </div>
  );
}
