import { ButtonHTMLAttributes } from "react";
import classes from "./styles.module.sass";

export function FigureButton({
  children,
  ...props
}: ButtonHTMLAttributes<any>) {
  return (
    <button
      className={classes.FigureButton}
      type="button"
      {...props}
      data-clickabe={!!props.onClickCapture || !!props.onClick}
    >
      {children}
    </button>
  );
}
