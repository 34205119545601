import { APITypesV1 } from "@cur8/api-client";
import { Patient, ScheduleViewSlot, Slot } from "@cur8/rich-entity";
import { Reader } from "@stripe/terminal-js";
import { useCallback, useEffect, useMemo, useState } from "react";
import QRCode from "react-qr-code";
import { useAPIClient } from "render/context/APIContext";
import { useAppInsights } from "render/context/AppInsightsContext";
import { PatientName } from "render/fragments/patient/PatientName";
import { useBookingPrice } from "render/hooks/api/billing/useBookingPrice";
import { useBookingStore } from "render/hooks/api/useBookingStore";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { useReporting } from "render/hooks/useReporting";
import {
  ConnectionStatus,
  ReaderStatus,
  availableLocations,
  useStripeTerminal,
} from "render/hooks/useStripeTerminal";
import { usePatientBookingToken } from "render/pages/PatientDetailPage/hooks/usePatientBookingToken";
import { useRecharge } from "render/pages/PatientDetailPage/hooks/useRecharge";
import { PriceNumber } from "render/ui/format/PriceNumber";
import { Expandable } from "render/ui/layouts/Expandable";
import { PopupDialog } from "render/ui/layouts/PopupDialog";
import { LineThrobber } from "render/ui/throbber/LineThrobber";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import CalendarClock from "./assets/calendar-clock.svg?react";
import { hasNoBookingTokensAvailableForBooking } from "./lib/hasNoBookingTokensAvailableForBooking";
import styles from "./styles.module.sass";
import { getPatientAppPaymentUrl } from "./urls";

interface Props {
  patient: Patient;
  slot: ScheduleViewSlot;
  onDone(slot: Slot): void;
  onCancel(): void;
}

enum Stage {
  Initial = "Initial",
  RequiresPaymentDetails = "RequiresPaymentDetails",
  SelectCardReaderLocation = "SelectCardReaderLocation",
  SelectCardReader = "SelectCardReader",
  UseCardReader = "UseCardReader",
  PaymentConfirmed = "PaymentConfirmed",
  Completed = "Completed",
  Failure = "Failure",
}

export function SlotManagementPopup({
  patient,
  slot,
  onDone,
  onCancel,
}: Props) {
  const api = useAPIClient();
  const appInsights = useAppInsights();

  const [stage, setStage] = useState<Stage>(Stage.Initial);
  const payment = useRecharge(patient.patientId);
  const {
    fetch: fetchPatientBookingToken,
    entries: patientBookingTokenEntries,
  } = usePatientBookingToken(patient.patientId);
  const { total, currency } = useBookingPrice(
    patient.patientId,
    patient.preferredCountry
  );
  const { bookSlot } = useBookingStore();
  const { logError } = useReporting();

  const membersAppPaymentUrl = useMemo(
    () => getPatientAppPaymentUrl(patient.preferredCountry),
    [patient.preferredCountry]
  );

  const alreadyHasToken = !!patientBookingTokenEntries?.length;

  const [paymentMethods, setPaymentMethods] =
    useState<APITypesV1.StripeCustomerPaymentMethod>();

  useEffect(() => {
    if (paymentMethods?.hasPaymentMethod) {
      return;
    }

    const poll = () => {
      api.billing
        .hasPaymentMethod({ patientId: patient.patientId })
        .result.then(setPaymentMethods)
        .catch(logError);
    };

    const timer = window.setInterval(poll, 1500);

    return () => {
      window.clearInterval(timer);
    };
  }, [
    api,
    patient.patientId,
    logError,
    stage,
    paymentMethods?.hasPaymentMethod,
  ]);

  const {
    cancelPayment,
    collectPayment,
    errorMsg: cardReaderError,
    readerConnection,
    readerStatus: cardReaderStatus,
    readersAvailable,
    setupReader,
    setLocationById,
    location,
    terminal,
    fetchReaders,
  } = useStripeTerminal();

  useEffect(() => {
    function keyDownHandler(ev: KeyboardEvent) {
      if (ev.key === "Escape") {
        onCancel();
      }
    }

    window.addEventListener("keydown", keyDownHandler);
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [onCancel]);

  useEffect(() => {
    if (stage !== Stage.Initial) {
      return;
    }
    fetchPatientBookingToken();
  }, [fetchPatientBookingToken, stage]);

  const patientHasNoPaymentDetails = paymentMethods?.hasPaymentMethod === false;
  const cardDetails = paymentMethods?.card;

  useEffect(() => {
    if (stage !== Stage.RequiresPaymentDetails) {
      return;
    }

    const id = window.setInterval(() => {
      if (stage !== Stage.RequiresPaymentDetails) {
        return;
      }

      fetchPatientBookingToken()
        .then((data) => {
          if (hasNoBookingTokensAvailableForBooking(data)) {
            return;
          }
          setStage(Stage.PaymentConfirmed);
          window.clearInterval(id);
        })
        .catch((err) => {
          console.debug("Error", err);
        });
    }, 1500);

    return () => {
      window.clearInterval(id);
    };
  }, [stage, fetchPatientBookingToken]);

  const slotBookingHandler = useCallback(async () => {
    const tokens = await fetchPatientBookingToken();
    if (tokens.length === 0) {
      throw Error("No booking tokens");
    }
    const token = tokens[0];

    const bookedSlot = await bookSlot(slot, {
      bookingTokenId: token.id,
      patientId: patient.patientId,
    });
    onDone(bookedSlot);
  }, [bookSlot, onDone, patient.patientId, slot, fetchPatientBookingToken]);

  useEffect(() => {
    if (stage !== Stage.RequiresPaymentDetails) {
      return;
    }

    if (location) {
      setupReader();
    }
  }, [setupReader, location, stage]);

  useEffect(() => {
    if (
      stage === Stage.UseCardReader &&
      cardReaderStatus === ReaderStatus.ReaderConnected
    ) {
      collectPayment(patient.patientId);
    }
  }, [cardReaderStatus, collectPayment, patient.patientId, stage]);

  const activateCardReader = useCallback(() => {
    if (!location) {
      setStage(Stage.SelectCardReaderLocation);
    } else if (cardReaderStatus === ReaderStatus.SelectReader) {
      setStage(Stage.SelectCardReader);
    } else {
      setStage(Stage.UseCardReader);
    }
  }, [cardReaderStatus, location]);

  const selectLocation = useCallback(
    (id: string) => {
      setLocationById(id);
    },
    [setLocationById]
  );

  useEffect(() => {
    if (stage !== Stage.SelectCardReaderLocation) {
      return;
    }

    if (!terminal) {
      return;
    }

    fetchReaders().then((rs) => {
      setStage(Stage.SelectCardReader);
    });
  }, [fetchReaders, stage, terminal]);

  const selectCardReader = useCallback(
    (reader: Reader) => {
      setupReader(reader.id).then(() => {
        setStage(Stage.UseCardReader);
      });
    },
    [setupReader]
  );

  const handleCardReaderPurchase = useAsyncHandle(
    useCallback(async () => {
      if (stage !== Stage.UseCardReader) {
        return;
      }
      if (cardReaderStatus !== ReaderStatus.Completed) {
        return;
      }

      appInsights.trackEvent({
        name: "handle-card-purchase-start",
        properties: { patientId: patient.patientId },
      });

      try {
        const MAX_RETRIES = 10;
        let count = 0;
        let hasToken = false;

        do {
          const delay = count * 1000;
          const [tokens] = await Promise.all([
            fetchPatientBookingToken(),
            new Promise((resolve) => window.setTimeout(resolve, delay)),
          ]);
          appInsights.trackEvent({
            name: "handle-card-purchase-fetch-tokens",
            properties: { patientId: patient.patientId, count },
          });

          count += 1;
          hasToken = tokens.length > 0;
        } while (!hasToken && count <= MAX_RETRIES);

        if (count === MAX_RETRIES && !hasToken) {
          throw new Error("No booking token !");
        }

        await slotBookingHandler();
        setStage(Stage.Completed);
      } catch (e) {
        const exception =
          e instanceof Error ? e : new Error("card purchase failed");

        appInsights.trackException({ exception });
        logError(e);
      }
    }, [
      patient.patientId,
      appInsights,
      cardReaderStatus,
      fetchPatientBookingToken,
      slotBookingHandler,
      stage,
      logError,
    ])
  );

  useEffect(() => {
    if (stage !== Stage.UseCardReader) {
      return;
    }
    if (cardReaderStatus !== ReaderStatus.Completed) {
      return;
    }
    handleCardReaderPurchase.callback();
  }, [cardReaderStatus, handleCardReaderPurchase, stage]);

  const handleSubmit = useCallback(async () => {
    try {
      const bookingTokens = await fetchPatientBookingToken();

      if (hasNoBookingTokensAvailableForBooking(bookingTokens)) {
        if (patientHasNoPaymentDetails) {
          if (stage !== Stage.UseCardReader) {
            setStage(Stage.RequiresPaymentDetails);
          }
          return Promise.reject();
        }

        await payment.recharge();
      }

      await slotBookingHandler();
      setStage(Stage.Completed);
    } catch (e) {
      if (stage === Stage.Initial) {
        setStage(Stage.RequiresPaymentDetails);
      } else {
        setStage(Stage.Failure);
        logError(e);
      }
    }
  }, [
    patientHasNoPaymentDetails,
    stage,
    logError,
    fetchPatientBookingToken,
    payment,
    slotBookingHandler,
  ]);
  const submitHandler = useAsyncHandle(handleSubmit);

  useEffect(() => {
    if (stage === Stage.PaymentConfirmed && !submitHandler.busy) {
      submitHandler.callback();
    }
  }, [stage, submitHandler]);

  const cancelHandler = useCallback(() => {
    if (readerConnection === ConnectionStatus.CONNECTED) {
      cancelPayment();
    }
    onCancel();
  }, [cancelPayment, onCancel, readerConnection]);

  const changePaymentMethod = useCallback(() => {
    setStage(Stage.RequiresPaymentDetails);
  }, [setStage]);

  const showCardReaderThrobber =
    !(
      cardReaderStatus === ReaderStatus.Completed ||
      cardReaderStatus === ReaderStatus.Failure
    ) || handleCardReaderPurchase.busy;

  return (
    <PopupDialog>
      <div className={styles.SlotManagementPopup}>
        <div className={styles.content}>
          <Expandable>
            <Expandable.Item active={stage === Stage.Initial}>
              <header>
                <h2>
                  <b>Book a time </b>
                  <span>
                    for <PatientName patient={patient} />
                  </span>
                </h2>
                <h3>
                  <CalendarClock />
                  <span>
                    {slot.startTime.toFormat("yyyy LLLL dd")}
                    {" at "}
                    {slot.startTime.toFormat("HH:mm")}
                    {"-"}
                    {slot.endTime.toFormat("HH:mm")}
                  </span>
                </h3>
              </header>
              <section
                className={styles.cardDetails}
                style={{
                  opacity: cardDetails || alreadyHasToken ? 1 : 0,
                }}
              >
                {!alreadyHasToken && (
                  <>
                    <h4 className={styles.caps}>
                      Charge {cardDetails?.brand} Card
                    </h4>
                    Do you want to charge{" "}
                    <span className={styles.digits}>
                      {total ? (
                        <PriceNumber price={total} currency={currency!} />
                      ) : (
                        "--"
                      )}
                    </span>{" "}
                    to the card
                    <br />
                    ending in{" "}
                    <span className={styles.digits}>
                      {cardDetails?.last4}
                    </span>{" "}
                    that expires{" "}
                    <span className={styles.digits}>
                      {cardDetails?.exp_month}/{cardDetails?.exp_year}
                    </span>
                  </>
                )}
              </section>
            </Expandable.Item>
            <Expandable.Item
              active={
                stage !== Stage.Initial &&
                stage !== Stage.UseCardReader &&
                stage !== Stage.SelectCardReaderLocation &&
                stage !== Stage.SelectCardReader
              }
            >
              <section
                className={styles.verifyPayment}
                style={{
                  opacity: stage !== Stage.Initial ? 1 : 0,
                }}
              >
                <h2>Verify Payment Method</h2>
                <div className={styles.codeContainer}>
                  <div className={styles.code}>
                    <div className={styles.sideLine} />
                    <div className={styles.underLine} />
                    <QRCode value={membersAppPaymentUrl} size={222} />
                  </div>
                </div>

                <div className={styles.text}>
                  Point the camera at the QR code shown here on the screen to
                  verify your payment method with Neko Health.
                </div>
              </section>
            </Expandable.Item>
            <Expandable.Item active={stage === Stage.SelectCardReaderLocation}>
              <section
                className={styles.selectCardReaderLocation}
                style={{
                  opacity: stage !== Stage.Initial ? 1 : 0,
                }}
              >
                <h2>Card Reader Location</h2>
                <div className={styles.text}>
                  Please select the card reader location
                </div>
                <div className={styles.buttons}>
                  {availableLocations.map(({ stripeId, label }, key) => (
                    <HoverTextButton
                      key={key}
                      onClick={() => selectLocation(stripeId)}
                      active={stripeId === location?.stripeId}
                    >
                      <b>{label}</b>
                    </HoverTextButton>
                  ))}
                </div>
              </section>
            </Expandable.Item>
            <Expandable.Item active={stage === Stage.SelectCardReader}>
              <section
                className={styles.selectCardReader}
                style={{
                  opacity: stage !== Stage.Initial ? 1 : 0,
                }}
              >
                <h2>Select Card Reader</h2>
                <div className={styles.buttons}>
                  {readersAvailable.map((reader, key) => (
                    <HoverTextButton
                      key={key}
                      onClick={() => selectCardReader(reader)}
                      disabled={reader.status !== "online"}
                    >
                      <b>{reader.label}</b>
                      <span>{reader.id}</span>
                    </HoverTextButton>
                  ))}
                </div>
              </section>
            </Expandable.Item>
            <Expandable.Item active={stage === Stage.UseCardReader}>
              <section
                className={styles.cardReader}
                style={{
                  opacity: stage !== Stage.Initial ? 1 : 0,
                }}
              >
                <h2>Card Reader</h2>
                <div className={styles.progressInfo}>
                  {cardReaderStatus === ReaderStatus.ReadersLoaded && (
                    <p>Connecting card reader...</p>
                  )}
                  {cardReaderStatus === ReaderStatus.ReaderConnected && (
                    <p>Follow the instructions on the card reader</p>
                  )}
                  {cardReaderStatus === ReaderStatus.CollectPaymentMethod && (
                    <p>Processing payment</p>
                  )}
                  {cardReaderStatus === ReaderStatus.PaymentProcessed && (
                    <p>Processing payment</p>
                  )}
                  {cardReaderStatus === ReaderStatus.Failure && (
                    <>
                      <div className={styles.error}>Payment failed</div>
                      <p>{cardReaderError}</p>
                    </>
                  )}
                  {cardReaderStatus === ReaderStatus.Completed && (
                    <p>Booking appointment</p>
                  )}
                  {showCardReaderThrobber && <LineThrobber />}
                </div>
              </section>
            </Expandable.Item>
          </Expandable>

          <footer className={styles.footer}>
            <div className={styles.cta}>
              <div className={styles.buttonContainer}>
                <HoverTextButton type="button" onClick={cancelHandler}>
                  Cancel
                </HoverTextButton>
              </div>

              {stage === Stage.RequiresPaymentDetails && (
                <HoverTextButton
                  disabled={
                    cardReaderStatus !== ReaderStatus.ReaderConnected &&
                    cardReaderStatus !== ReaderStatus.SelectReader &&
                    cardReaderStatus !== ReaderStatus.Initial
                  }
                  type="button"
                  onClick={activateCardReader}
                >
                  Card Reader
                </HoverTextButton>
              )}

              {stage === Stage.Initial && cardDetails && !alreadyHasToken && (
                <HoverTextButton type="button" onClick={changePaymentMethod}>
                  Use Other
                </HoverTextButton>
              )}

              {stage === Stage.Initial && (
                <ActionButton
                  disabled={submitHandler.busy || !patientBookingTokenEntries}
                  handle={submitHandler}
                >
                  {alreadyHasToken ? "Redeem" : "Charge"} & Book
                </ActionButton>
              )}
            </div>
            <section className={styles.busy}>
              {submitHandler.busy && <LineThrobber />}
            </section>
          </footer>
        </div>
      </div>
    </PopupDialog>
  );
}
