import { CameraView, DashboardCamera } from "lib/avatar/camera";
import { createTimer } from "lib/timer";
import { useEffect, useMemo } from "react";

export function useCamera() {
  const camera = useMemo(() => {
    const origin = new CameraView();
    origin.pos.set(0, -1500, 1400);
    origin.focus.set(0, 0, 1450);

    const camera = new DashboardCamera();
    camera.setTo(origin);
    return camera;
  }, []);

  useEffect(() => {
    return createTimer((deltaTime) => {
      const dt = deltaTime / 1000;
      camera.update(dt);
    });
  }, [camera]);

  return camera;
}
