import { useState } from "react";
import Arrow from "./assets/arrow.svg";
import styles from "./styles.module.sass";

interface SiradSelectorProps {
  channel: number;
  quality: number | undefined;
  onChange: (increase: boolean) => void;
}

export function SiradSelector({
  channel,
  quality,
  onChange,
}: SiradSelectorProps) {
  const [animateLeft, setAnimateLeft] = useState<boolean>(false);
  const [animateRight, setAnimateRight] = useState<boolean>(false);
  const [animateChannel, setAnimateChannel] = useState<boolean>(false);

  return (
    <div className={styles.SiradSelector}>
      <div className={styles.container}>
        <div className={styles.control}>
          <img
            className={
              animateLeft ? `${styles.down} ${styles.animateLeft}` : styles.down
            }
            src={Arrow}
            alt="minus"
            onClick={() => {
              onChange(false);
              setAnimateLeft(true);
              setAnimateChannel(true);
            }}
            onAnimationEnd={() => setAnimateLeft(false)}
          />
          <div
            className={animateChannel ? styles.animateChannel : ""}
            onAnimationEnd={() => setAnimateChannel(false)}
          >
            {channel}
          </div>
          <img
            className={
              animateRight ? `${styles.up} ${styles.animateRight}` : styles.up
            }
            src={Arrow}
            alt="plus"
            onClick={() => {
              onChange(true);
              setAnimateRight(true);
              setAnimateChannel(true);
            }}
            onAnimationEnd={() => setAnimateRight(false)}
          />
        </div>
        <div className={styles.foot}>Sirad channel</div>
        <div className={styles.subFoot}>quality: {quality?.toFixed(2)}</div>
      </div>
    </div>
  );
}
