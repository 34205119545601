import { Patient } from "@cur8/rich-entity";
import { CholesterolItem as Cholesterol } from "./components/Cholesterol";
import { LDLItem } from "./components/LDL/LDL";
import styles from "./styles.module.sass";

interface BloodFatsRightSectionProps {
  patient: Patient;
}

export function BloodFatsRightSection({ patient }: BloodFatsRightSectionProps) {
  return (
    <div className={styles.BloodFatsSection}>
      <div className={styles.items}>
        <Cholesterol patient={patient} />
        <LDLItem patient={patient} />
      </div>
    </div>
  );
}
