import { Weight } from "@cur8/measurements";

interface BodyMetricWeightProps {
  weight?: Weight;
  precision?: number;
}

export function BodyMetricWeight({
  weight,
  precision = 0,
}: BodyMetricWeightProps) {
  return (
    <span>
      {weight ? weight.kilograms.toFixed(precision) : "--"}
      &nbsp;kg
    </span>
  );
}
