import { ReactNode } from "react";
import { useMSAL } from "render/context/MSALContext";
import { LoginPage } from "render/pages/LoginPage";
import { NotificationFrame } from "render/ui/layouts/NotificationFrame";
import { PopupDialogFrame } from "render/ui/layouts/PopupDialogFrame";

function AuthenticationWall({ children }: { children: ReactNode }) {
  const { auth } = useMSAL();

  if (!auth) {
    return <LoginPage />;
  }

  return <>{children}</>;
}

export function ApplicationLayout({ children }: { children: ReactNode }) {
  return (
    <PopupDialogFrame>
      <NotificationFrame>
        <AuthenticationWall>{children}</AuthenticationWall>
      </NotificationFrame>
    </PopupDialogFrame>
  );
}
