import { Sticky } from "@pomle/react-viewstack";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layout, LayoutProps } from "../Layout";
import { SidePanel } from "../SidePanel";
import { DiabetesSection } from "./components/DiabetesSection";
import styles from "./styles.module.sass";

interface DiabetesLayoutProps extends LayoutProps {}

export function DiabetesLayout({ active }: DiabetesLayoutProps) {
  const { patient } = usePatientData();

  return (
    <Layout active={active}>
      <div className={styles.DiabetesLayout}>
        <div className={styles.diabetesPanel} data-active={active}>
          <Sticky delay={2000}>
            {active && (
              <SidePanel>
                {patient && <DiabetesSection patient={patient} />}
              </SidePanel>
            )}
          </Sticky>
        </div>
      </div>
    </Layout>
  );
}
