import * as Risks from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { PatientMetrics } from "render/hooks/patient/usePatientMetrics";
import { Table } from "render/ui/layouts/Table";
import { ResultRow } from "../ResultRow";

interface WhiteBloodCellsTableProps {
  patient?: Patient;
  metrics: PatientMetrics;
}

export function WhiteBloodCellsTable({
  patient,
  metrics,
}: WhiteBloodCellsTableProps) {
  const bloodwork = metrics.bloodwork;

  return (
    <Table>
      <thead>
        <tr>
          <th>White blood cells</th>
          <th>Result</th>
          <th>Risk</th>
          <th>Units</th>
        </tr>
      </thead>
      <tbody>
        <ResultRow
          caption="WBC Count"
          value={bloodwork.whiteBloodCells?.at(0)?.unit}
          format={(value) => value["x10⁹/L"].toFixed(2)}
          unit="x10⁹/L"
          summarize={(unit) => {
            return {
              risk: Risks.WhiteBloodCells.rangesFor().findRisk(unit),
            };
          }}
        />
        <ResultRow
          caption="Neutrophils"
          value={bloodwork.neutrophils?.at(0)?.unit}
          format={(value) => value["x10⁹/L"].toFixed(2)}
          unit="x10⁹/L"
          summarize={(unit) => {
            return {
              risk: Risks.Neutrophils.rangesFor().findRisk(unit),
            };
          }}
        />
        <ResultRow
          caption="Basophils"
          value={bloodwork.basophils?.at(0)?.unit}
          format={(value) => value["x10⁹/L"].toFixed(2)}
          unit="x10⁹/L"
          summarize={(unit) => {
            return {
              risk: Risks.Basophils.rangesFor().findRisk(unit),
            };
          }}
        />
        <ResultRow
          caption="Monocytes"
          value={bloodwork.monocytes?.at(0)?.unit}
          format={(value) => value["x10⁹/L"].toFixed(2)}
          unit="x10⁹/L"
          summarize={(unit) => {
            return {
              risk: Risks.Monocytes.rangesFor().findRisk(unit),
            };
          }}
        />
        <ResultRow
          caption="Eosinophils"
          value={bloodwork.eosinophils?.at(0)?.unit}
          format={(value) => value["x10⁹/L"].toFixed(2)}
          unit="x10⁹/L"
          summarize={(unit) => {
            return {
              risk: Risks.Eosinophils.rangesFor().findRisk(unit),
            };
          }}
        />
        <ResultRow
          caption="Lymphocytes"
          value={bloodwork.lymphocytes?.at(0)?.unit}
          format={(value) => value["x10⁹/L"].toFixed(2)}
          unit="x10⁹/L"
          summarize={(unit) => {
            return {
              risk: Risks.Lymphocytes.rangesFor().findRisk(unit),
            };
          }}
        />
      </tbody>
    </Table>
  );
}
