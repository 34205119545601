import { Annotation } from "@cur8/rich-entity";
import { urls } from "lib/derma";
import { useMemo } from "react";
import QRCode from "react-qr-code";
import { CloseIcon } from "render/ui/symbol/HoverIcon";
import { FigureButton } from "render/ui/trigger/FigureButton";
import styles from "./styles.module.sass";

interface QRCodeOverlayProps {
  annotation: Annotation;
  onClose: () => void;
}

export function QRCodeOverlay({ annotation, onClose }: QRCodeOverlayProps) {
  const url = useMemo(() => {
    try {
      return urls.appendImages(annotation);
    } catch (error) {
      console.error("Could not create Derma App URL", error);
    }
  }, [annotation]);
  return (
    <div className={styles.QRCodeOverlay}>
      <div className={styles.buttons}>
        <FigureButton onClick={onClose}>
          <CloseIcon color="black" />
        </FigureButton>
      </div>

      <div className={styles.content}>
        <div>{url && <QRCode value={url} />}</div>
        <p>Scan this code on your phone to take a new picture</p>
      </div>
    </div>
  );
}
