import { APITypesV1 } from "@cur8/api-client";
import { useMemo } from "react";
import { usePatientPrivateJournalNoteQuery } from "render/hooks/api/usePatientPrivateJournalNoteQuery";

export function usePatientPrivateJournalNotes(patientId: string) {
  const namespace = useMemo(() => {
    return `patient/${patientId}/private-journal-note`;
  }, [patientId]);

  const options = useMemo(() => {
    return {
      patientId,
      isLatestVersion: true,
      order: APITypesV1.SortOrder.Desc,
      pageSize: 100,
    };
  }, [patientId]);

  return usePatientPrivateJournalNoteQuery(options, namespace);
}
