import { ReactNode } from "react";
import * as THREE from "three";
import styles from "./styles.module.sass";

interface PerspectiveOverlayProps {
  children: ReactNode;
  pos: THREE.Vector3;
  perspective: number;
  active?: boolean;
  zIndex?: number;
}

export function PerspectiveOverlay({
  children,
  pos,
  perspective,
  active = false,
  zIndex = 0,
}: PerspectiveOverlayProps) {
  return (
    <div
      className={styles.PerspectiveOverlay}
      style={{
        pointerEvents: active ? "auto" : "none",
        perspective: `${perspective}px`,
        transform: `translate(${pos.x}px, ${pos.y}px)`,
        zIndex,
      }}
    >
      <div
        className={styles.content}
        style={{
          transform: `translateZ(${perspective - pos.z}px)`,
        }}
      >
        {children}
      </div>
    </div>
  );
}
