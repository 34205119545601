import styles from "./styles.module.sass";

export function WaitingForImage() {
  return (
    <div className={styles.WaitingForImage}>
      <div>
        <div />
      </div>
    </div>
  );
}
