import { APITypesV1 } from "@cur8/api-client";
import { isPulseWaveAssessment, Patient, Sex } from "@cur8/rich-entity";
import { useCallback, useEffect, useState } from "react";
import { useAssessmentContext } from "render/pages/AssessmentPage/context/AssessmentContext";
import { useAssessmentNav } from "render/pages/AssessmentPage/hooks/useAssessmentNav";
import { useRangeContext } from "../../context/RangeContext";
import { CardioSignals } from "../../hooks/useCardioSignalsTimestamps";
import { calculateLVETI } from "../../lib/calculations";
import {
  isCreatePWVAssessment,
  LVETISelection,
  PulsewaveType,
  Selections,
} from "../../lib/types";
import { assessmentToSelection } from "../../lib/utils";
import { GraphWithRuler } from "../shared/GraphWithRuler";
import { ECG_COLOR, SSN_COLOR } from "../shared/constants";
import { JSXResult } from "../shared/fragments";
import { SelectionBox } from "./SelectionBox";
import styles from "./styles.module.sass";

interface EjectionTimeProps {
  patient: Patient | undefined;
  signals: CardioSignals;
}

export function EjectionTime({ patient, signals }: EjectionTimeProps) {
  const { createAssessment, selected, setLvetiPulseWaveData } =
    useAssessmentContext();
  const { onPulseWaveTypeChanged } = useRangeContext();
  const { versionId } = useAssessmentNav();

  const populateSelections = (): Selections => {
    console.debug("populateSelections", selected, createAssessment);
    if (
      createAssessment &&
      isCreatePWVAssessment(createAssessment) &&
      createAssessment.lveti?.selections
    ) {
      // Unsaved changes in Create assessment
      return assessmentToSelection(
        createAssessment.lveti.selections,
        patient!.sex
      );
    }
    if (
      selected &&
      isPulseWaveAssessment(selected) &&
      selected.lveti?.selections
    ) {
      // Load from latest assessment
      return assessmentToSelection(selected.lveti.selections, patient!.sex);
    }
    return {
      0: {} as LVETISelection,
      1: {} as LVETISelection,
      2: {} as LVETISelection,
    };
  };
  const [selections, setSelections] = useState<Selections>(populateSelections);
  const [selectionIndex, setSelectionIndex] = useState<number>(0);
  const [lvetRange, setLvetRange] = useState<APITypesV1.Range | undefined>(
    selections[selectionIndex].ssn
  );
  const wrapSetSelectionIndex = useCallback(
    (idx: number) => {
      setSelectionIndex(idx);
      setLvetRange(selections[idx].ssn);
      onPulseWaveTypeChanged(PulsewaveType.lveti, idx);
    },
    [onPulseWaveTypeChanged, selections]
  );

  /**
   * Update selections if version changes
   */
  useEffect(() => {
    if (selected && selected.itemVersion !== versionId) {
      if (
        selected &&
        isPulseWaveAssessment(selected) &&
        selected.lveti?.selections
      ) {
        // Load from latest assessment
        const sel = assessmentToSelection(
          selected.lveti.selections,
          patient!.sex
        );
        setSelections(sel);
        setSelectionIndex(0);
        setLvetRange(sel[0].ssn);
        console.debug("version changed => updating selections");
      }
    }
  }, [patient, selected, versionId]);

  const [resultElement, setResultElement] = useState<JSX.Element | null>(
    JSXResult("", "Make a selection in the plot to measure LVETI")
  );

  useEffect(() => {
    if (!patient || patient.sex === Sex.Unknown) {
      setResultElement(
        JSXResult("Cannot compute LVETI", "Member's sex not specified")
      );
      return;
    }
  }, [patient]);

  /**
   * Update assessment
   */
  const updateAssessment = useCallback(() => {
    console.debug("updateAssessment", selections);
    if (!selections[0].lveti || !selections[1].lveti || !selections[2].lveti) {
      setLvetiPulseWaveData(undefined);
      return;
    }
    // OK to round to ms
    const average = Math.round(
      (selections[0].lveti + selections[1].lveti + selections[2].lveti) / 3
    );
    if (
      selections[0].ssn &&
      selections[1].ssn &&
      selections[2].ssn &&
      selections[0].rrInterval &&
      selections[1].rrInterval &&
      selections[2].rrInterval
    ) {
      setLvetiPulseWaveData({
        average,
        selections: [
          {
            lveti: selections[0].lveti,
            ssn: selections[0].ssn,
            rrInterval: selections[0].rrInterval,
          },
          {
            lveti: selections[1].lveti,
            ssn: selections[1].ssn,
            rrInterval: selections[1].rrInterval,
          },
          {
            lveti: selections[2].lveti,
            ssn: selections[2].ssn,
            rrInterval: selections[2].rrInterval,
          },
        ],
      });
    }
  }, [selections, setLvetiPulseWaveData]);

  /**
   * Find R-peaks and calculate HR
   */
  const findRPeaks = useCallback(
    (range: APITypesV1.Range) => {
      if (!signals || !signals.rPeaks) {
        return;
      }
      const rpeaks = signals.rPeaks;

      let left = 0;
      let right = rpeaks.length - 1;
      let resultIndex: number | null = null;

      while (left <= right) {
        const mid = Math.floor((left + right) / 2);

        if (rpeaks[mid] < range.from) {
          // If peak is less than time, update resultIndex to mid
          resultIndex = mid;
          // Move right to potentially find a closer value
          left = mid + 1;
        } else {
          // Move left to search for values less than time
          right = mid - 1;
        }
      }

      if (!resultIndex) {
        setResultElement(
          JSXResult("Cannot compute LVETI", "Can't find R-R Interval")
        );
        return;
      }
      // Verify that the R-peak after the one we've found is larger than end-time
      if (rpeaks[resultIndex + 1] < range.to) {
        setResultElement(
          JSXResult(
            "Cannot compute LVETI",
            "LVET-interval cannot be larger than R-R-interval"
          )
        );
        return;
      }
      const interval: APITypesV1.Range = {
        from: rpeaks[resultIndex],
        to: rpeaks[resultIndex + 1],
        unit: "s",
      };
      //setrrInterval(interval);
      return interval;
    },
    [signals]
  );

  /**
   * Do LVETI calculations on given ranges
   */
  const calcLvetiWrapper = useCallback(
    (lvetRange: APITypesV1.Range, rrInterval: APITypesV1.Range) => {
      const calcs = calculateLVETI(lvetRange, rrInterval, patient!.sex);
      setResultElement(
        JSXResult(
          `LVETI: ${calcs.lveti} ms`,
          `${Sex[patient!.sex]}, heart rate: ${calcs.hr} bpm, LVET: ${
            calcs.lvet
          } ms`
        )
      );
      return calcs;
    },
    [patient]
  );

  const rrIntervalResize = useCallback(
    (pos: number, dragLeft: boolean, complete: boolean = false) => {
      setSelections((sels) => {
        if (
          sels &&
          sels[selectionIndex] &&
          sels[selectionIndex].ssn &&
          sels[selectionIndex].rrInterval
        ) {
          const cur = sels[selectionIndex].rrInterval!;
          const lvetRange = sels[selectionIndex].ssn!;
          const rr: APITypesV1.Range = dragLeft
            ? { from: pos, to: cur.to }
            : { from: cur.from, to: pos };

          if (rr.from > lvetRange.from || rr.to < lvetRange.to) {
            setResultElement(
              JSXResult(
                "Cannot compute LVETI",
                "R-R-interval has to cover the LVET-interval"
              )
            );
            return sels;
          }
          sels[selectionIndex] = calcLvetiWrapper(lvetRange, rr);
        }
        return sels;
      });
      if (complete) {
        updateAssessment();
      }
    },
    [calcLvetiWrapper, selectionIndex, updateAssessment]
  );

  const onLvetRange = useCallback(
    (range: APITypesV1.Range | undefined, complete: boolean = false) => {
      let setRange: APITypesV1.Range | undefined = range;
      if (!range || range.to - range.from <= 0) {
        setRange = undefined;
      }
      setSelections((sels) => {
        if (!setRange) {
          // No area selected => reset
          sels[selectionIndex] = {} as LVETISelection;
          setLvetRange(undefined);
          return sels;
        }

        sels[selectionIndex].ssn = range ? range : { from: 0, to: 0 };
        const rrInt = findRPeaks(setRange);
        if (rrInt) {
          sels[selectionIndex] = calcLvetiWrapper(setRange, rrInt);
        }
        setLvetRange(setRange);
        return sels;
      });

      if (complete) {
        updateAssessment();
      }
    },
    [calcLvetiWrapper, findRPeaks, selectionIndex, updateAssessment]
  );

  return signals ? (
    <div>
      <GraphWithRuler
        data={[
          {
            label: "Cardiac Rhythm",
            signal: signals.bioamp_ecg,
            color: ECG_COLOR,
          },
          {
            label: "SSN",
            signal: signals.ssn_displacement,
            color: SSN_COLOR,
          },
        ]}
        diffData={[
          {
            label: "SSN Acceleration",
            signal: signals.ssn_acceleration,
            color: SSN_COLOR,
          },
        ]}
        onSelectionRange={onLvetRange}
        selectionRange={lvetRange}
        rrInterval={selections[selectionIndex].rrInterval}
        rrIntervalResize={rrIntervalResize}
      />
      <div className={styles.EjectionFooter}>
        <div className={styles.boxWrapper}>
          {Object.entries(selections).map(([key, sel]) => {
            const idx = parseInt(key) as 0 | 1 | 2;
            return (
              <SelectionBox
                key={idx}
                idx={idx}
                setSelected={() => wrapSetSelectionIndex(idx)}
                selected={selectionIndex === idx}
                data={sel}
                sex={patient?.sex}
              />
            );
          })}
        </div>
        <div className={styles.jsx}>{resultElement}</div>
      </div>
    </div>
  ) : null;
}
