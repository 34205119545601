import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface TableProps {
  children: ReactNode;
}

export function Table({ children }: TableProps) {
  return <table className={styles.Table}>{children}</table>;
}
