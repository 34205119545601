import { codecs, createQuery } from "@pomle/paths";
import { useNav, useQueryState } from "@pomle/react-router-paths";
import { PatientSearchResultTable } from "render/fragments/patient/PatientSearchResultTable";
import { paths } from "render/routes/paths";
import { FramedPage } from "render/ui/layouts/FramedPage/FramedPage";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { SearchResults } from "render/ui/presentation/SearchResults";
import { ButtonSet } from "render/ui/trigger/ButtonSet";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import { SearchInput } from "./components/SearchInput";
import { useSearchPatient } from "./hooks/useSearchPatient";
import styles from "./styles.module.sass";

const queryParams = createQuery({
  search: codecs.string,
});

export function PatientFindPage() {
  const [params, setParams] = useQueryState(queryParams);

  const query = params.search[0] ?? "";
  const setQuery = (query: string) => {
    setParams({
      search: query.length > 0 ? [query] : [],
    });
  };

  const effectiveQuery = query.trim();

  const result = useSearchPatient(effectiveQuery);

  const nav = {
    patientInvite: useNav(paths.patient.invite),
  };

  return (
    <FramedPage>
      <div className={styles.PatientFindPage}>
        <PageHeader caption="Find Member">
          <ButtonSet>
            <HoverTextButton onClick={nav.patientInvite.on({})}>
              + Invite Member
            </HoverTextButton>
          </ButtonSet>
        </PageHeader>

        <section className={styles.input}>
          <SearchInput
            value={query}
            onChange={setQuery}
            onClear={() => setQuery("")}
          />
        </section>

        <section>
          <SearchResults result={result}>
            {(matches) => {
              return (
                <PatientSearchResultTable
                  key={result.query}
                  patients={matches}
                />
              );
            }}
          </SearchResults>
        </section>
      </div>
    </FramedPage>
  );
}
