import { ReactNode } from "react";
import NekoLogo from "render/assets/neko-logo.svg?react";
import { FramedPage } from "render/ui/layouts/FramedPage/FramedPage";
import styles from "./styles.module.sass";

interface MessagePageProps {
  children?: ReactNode;
}

export function MessagePage({ children }: MessagePageProps) {
  return (
    <FramedPage>
      <div className={styles.MessagePage}>
        <div className={styles.logo}>
          <NekoLogo height="42" width="192" />
        </div>

        <div className={styles.message}>{children}</div>
      </div>
    </FramedPage>
  );
}
