import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import { Attention } from "../Attention";
import styles from "./styles.module.sass";

interface ReturnDeviceMessageProps {
  onOpen: () => void;
}

export function ReturnDeviceMessage({ onOpen }: ReturnDeviceMessageProps) {
  return (
    <Attention>
      <div className={styles.ReturnDeviceMessage}>
        <div>
          <div>Return device</div>
          <div>
            Confirm that the device has been returned to close the assignment.
          </div>
        </div>
        <HoverTextButton onClick={onOpen}>Return Device</HoverTextButton>
      </div>
    </Attention>
  );
}
