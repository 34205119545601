import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";

export function usePatientCreateNewVersionPrivateJournalNote() {
  const api = useAPIClient();

  return useCallback(
    async (
      patientId: string,
      privateJournalNoteId: string,
      payload: { note: string }
    ) => {
      return api.privateJournalNote.createPrivateJournalNoteVersionById(
        {
          patientId,
          privateJournalNoteId,
        },
        {
          note: payload.note,
        }
      ).result;
    },
    [api.privateJournalNote]
  );
}
