import { BookableSlot, fromAPI, Site } from "@cur8/rich-entity";
import { range } from "lib/map";
import { DateTime, Interval } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { CalendarDay } from "../CalendarDay";
import { MiniCalendar } from "../MiniCalendar";
import styles from "./styles.module.sass";

const START_OFFSET = 0;
const END_OFFSET = 2;

export function SiteMonthlySchedule({
  site,
  date,
}: {
  site: Site;
  date: DateTime;
}) {
  return (
    <div className={styles.SiteMonthlySchedule}>
      <h1 className={styles.site}>{site.siteName}</h1>
      <ul className={styles.list}>
        {range(START_OFFSET - 1, END_OFFSET + 1, (offset) => {
          const target = date.plus({ month: offset });
          const isActive = offset >= START_OFFSET && offset <= END_OFFSET;
          return (
            <li
              style={{
                transform: `translateX(${100 * offset}%)`,
                opacity: isActive ? 1 : 0,
                pointerEvents: isActive ? "initial" : "none",
              }}
              className={styles.listItem}
              key={target.startOf("day").toISODate()}
            >
              <h2 className={styles.header}>{target.toFormat("LLLL")}</h2>

              <MonthCalendar date={target} site={site} isActive={isActive} />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function MonthCalendar({
  date,
  site,
  isActive,
}: {
  date: DateTime;
  site: Site;
  isActive: boolean;
}) {
  const api = useAPIClient();

  const [result, setResult] = useState<{
    range: Interval;
    slots: BookableSlot[];
  }>();

  const range = useMemo(() => {
    const firstDay = date.startOf("month");
    const lastDay = date.endOf("month");
    return Interval.fromDateTimes(firstDay, lastDay);
  }, [date]);

  useEffect(() => {
    if (!isActive) {
      return;
    }

    if (result?.range === range) {
      return;
    }

    const now = DateTime.now();
    let cancel = false;
    async function exhaust() {
      const bookableSlots: BookableSlot[] = [];
      let continuationToken: string | undefined;

      do {
        if (cancel) {
          return;
        }

        const result = await api.bookingV2.querySlots({
          siteIds: [site.siteId],
          continuationToken,
          pageSize: 100,
          includeClosedForPublicSlots: true,
          startTime: {
            start: DateTime.max(range.start, now).toISO(),
            end: range.end.toISO(),
          },
        }).result;
        continuationToken = result.nextPage;
        bookableSlots.push(...result.items.map(fromAPI.toBookableSlot));
      } while (continuationToken != null);

      if (cancel) {
        return;
      }

      setResult({
        range,
        slots: bookableSlots,
      });
    }

    setTimeout(exhaust);

    return () => {
      cancel = true;
    };
  }, [api, result, site.siteId, range, isActive]);

  return (
    <MiniCalendar
      date={date}
      renderDay={(timestamp) => {
        return (
          <CalendarDay
            date={timestamp}
            site={site}
            slots={result?.slots}
            isActive={isActive}
          />
        );
      }}
    />
  );
}
