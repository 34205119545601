import { APITypesV1 } from "@cur8/api-client";
import {
  Assessment,
  AssessmentTypeName,
  Box,
  isPadAssessment,
  isPulseWaveAssessment,
  isThermalAssessment,
  Patient,
  RegionsOfInterestBox,
} from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { toRect } from "lib/api/adapter/annotation";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { usePatientAssessments } from "render/hooks/api/usePatientAssessments";
import { paths } from "render/routes/paths";
import { query } from "render/routes/querys";
import { useAssessmentNav } from "../../hooks/useAssessmentNav";

interface AssessmentProps {
  assessments: Assessment[];
  patient: Patient;
  selected: Assessment;
}

export type CreatePadAssessmentRequestBox = Pick<
  APITypesV1.CreatePadAssessmentRequest,
  "assessmentState" | "comment" | "ratios" | "tags"
> & {
  regionsOfInterest?: RegionsOfInterestBox;
};

export type CreatePulseWaveAssessmentRequestBox = Pick<
  APITypesV1.CreatePulseWaveAssessmentRequest,
  | "assessmentState"
  | "comment"
  | "lveti"
  | "footAsymmetry"
  | "handAsymmetry"
  | "pulseWaveVelocity"
  | "tags"
>;

export type CreateThermalAssessmentRequestBox = Pick<
  APITypesV1.CreateThermalAssessmentRequest,
  "assessmentState" | "comment" | "status" | "tags"
>;

export type PulseWaveData = Pick<
  CreatePulseWaveAssessmentRequestBox,
  "pulseWaveVelocity" | "handAsymmetry" | "footAsymmetry" | "tags"
>;
export type PadData = Pick<
  CreatePadAssessmentRequestBox,
  "ratios" | "regionsOfInterest" | "tags"
>;

function initCreateAssessment(
  ass: Assessment
):
  | CreatePadAssessmentRequestBox
  | CreatePulseWaveAssessmentRequestBox
  | CreateThermalAssessmentRequestBox {
  if (isPadAssessment(ass)) {
    return {
      assessmentState: ass.assessmentState,
      regionsOfInterest: ass.regionsOfInterest,
      ratios: ass.ratios,
    };
  } else if (isPulseWaveAssessment(ass)) {
    return {
      assessmentState: ass.assessmentState,
      lveti: ass.lveti,
      footAsymmetry: ass.footAsymmetry,
      handAsymmetry: ass.handAsymmetry,
      pulseWaveVelocity: ass.pulseWaveVelocity,
    };
  } else if (isThermalAssessment(ass)) {
    return {
      assessmentState: ass.assessmentState,
      status: undefined,
    };
  } else {
    throw new Error("Invalid assessment");
  }
}

export function useAssessment({
  assessments,
  patient,
  selected,
}: AssessmentProps) {
  const api = useAPIClient().assessment;
  const { unhandled } = usePatientAssessments(patient.patientId);
  const { setVersionId, unhandledQueue, versionId } = useAssessmentNav();
  const [isModified, setModified] = useState<boolean>(false);
  const [createAssessment, setCreateAssessment] = useState(() =>
    initCreateAssessment(selected)
  );

  const nav = {
    assessment: useNav(paths.patient.assessment, query.assessment),
    detailPage: useNav(paths.patient.detail),
  };

  useEffect(() => {
    if (selected && selected.itemVersion !== versionId) {
      console.debug("Update selected", selected);
      setCreateAssessment(() => initCreateAssessment(selected));
    }
  }, [selected, versionId]);

  /**
   * Determine what to do next (when unhandledQueue is activated)
   */
  const jumpToNextUnhandled = useCallback(
    (aid: string) => {
      if (!unhandled || unhandled.length <= 0) {
        // TODO: Go back to PatientDetailsPage
        nav.detailPage.go({ patientId: patient.patientId });
        return;
      }
      const next = unhandled.find(
        (ass) =>
          ass.assessmentState === APITypesV1.AssessmentState.New &&
          ass.itemId !== aid
      );
      if (next) {
        // Go to next unhandled assessment
        nav.assessment.go(
          {
            patientId: patient.patientId,
            assessmentId: next.itemId,
            versionId: next.itemVersion,
          },
          { unhandledQueue: [true] }
        );
        return;
      } else {
        // Go back to patient page
        nav.detailPage.go({ patientId: patient.patientId });
      }
    },
    [nav.assessment, nav.detailPage, patient.patientId, unhandled]
  );

  const save = useCallback(() => {
    if (!assessments) {
      throw new Error("No assessment found");
    }
    if (!selected.isLatestVersion) {
      throw new Error("Not viewing latest, not allowed to save assessment");
    }
    if (!isModified) {
      console.debug("Not modified, skip save");
      return;
    }

    let res;
    if (isPadAssessment(selected)) {
      const ca = createAssessment as CreatePadAssessmentRequestBox;
      console.debug("ratios:", ca.ratios);
      res = api.createPadAssessments(
        {
          patientId: patient.patientId,
          assessmentId: selected.itemId,
        },
        {
          assessmentState: ca.assessmentState,
          comment: ca.comment,
          ratios: ca.ratios,
          regionsOfInterest: {
            forearm: toRect(ca.regionsOfInterest!.forearm),
            hypothenar: toRect(ca.regionsOfInterest!.hypothenar),
          },
          tags: ca.tags,
        }
      ).result;
    } else if (isPulseWaveAssessment(selected)) {
      res = api.createPulseWaveAssessments(
        {
          patientId: patient.patientId,
          assessmentId: selected.itemId,
        },
        createAssessment
      ).result;
    } else if (isThermalAssessment(selected)) {
      res = api.createThermalAssessments(
        {
          patientId: patient.patientId,
          assessmentId: selected.itemId,
        },
        createAssessment
      ).result;
    } else {
      throw new Error("Unsupported assessment");
    }

    res.then((ass) => {
      console.debug("Created assessment", ass);
      try {
        setModified(false);
        if (unhandledQueue) {
          jumpToNextUnhandled(ass.itemId);
        } else {
          // Stay on this assessment, but show latest
          setVersionId(ass.itemVersion);
        }
      } catch (err) {
        console.error(err);
      }
    });
  }, [
    api,
    assessments,
    createAssessment,
    isModified,
    jumpToNextUnhandled,
    patient.patientId,
    selected,
    setVersionId,
    unhandledQueue,
  ]);

  const loadLatest = useCallback(() => {
    const latest = assessments[0];
    try {
      setVersionId(latest.itemVersion);
      setModified(false);
    } catch (err) {
      console.error(err);
    }
  }, [assessments, setVersionId]);

  const setComment = useCallback((comment: string) => {
    setCreateAssessment((ass) => ({
      ...ass,
      comment: comment,
    }));
    setModified(true);
  }, []);

  const setPadRatio = useCallback(
    (
      key: keyof APITypesV1.PadAssessmentRatios,
      val: APITypesV1.PadAssessmentRatios[keyof APITypesV1.PadAssessmentRatios]
    ) => {
      setCreateAssessment((ass: CreatePadAssessmentRequestBox) => ({
        ...ass,
        ratios: {
          ...ass.ratios,
          [key]: val,
        },
      }));
    },
    []
  );

  const setPulseWaveData = useCallback(
    (key: keyof PulseWaveData, val: PulseWaveData[keyof PulseWaveData]) => {
      setCreateAssessment((ass: CreatePulseWaveAssessmentRequestBox) => ({
        ...ass,
        [key]: val,
      }));
      setModified(true);
    },
    []
  );

  const setLvetiPulseWaveData = useCallback(
    (data: APITypesV1.PulseWaveLVETIAssessmentData | undefined) => {
      setCreateAssessment((ass: CreatePulseWaveAssessmentRequestBox) => ({
        ...ass,
        lveti: data,
      }));
      setModified(true);
    },
    []
  );

  const setThermalStatus = useCallback(
    (status: APITypesV1.ThermalAssessmentStatus) => {
      setCreateAssessment((ass) => ({
        ...ass,
        status: status,
      }));
      setModified(true);
    },
    []
  );

  const setState = useCallback((state: APITypesV1.AssessmentState) => {
    setCreateAssessment((ass) => ({
      ...ass,
      assessmentState: state,
    }));
    setModified(true);
  }, []);

  const addTag = useCallback((tag: string) => {
    setCreateAssessment((ass) => {
      const tags = ass.tags || [];
      if (!tags.includes(tag)) {
        tags.push(tag);
      }
      return {
        ...ass,
        tags,
      };
    });
  }, []);

  const removeTag = useCallback((tag: string) => {
    setCreateAssessment((ass) => {
      if (!ass.tags) {
        return ass;
      }
      const tags = ass.tags.filter((item) => item !== tag);
      if (tags.length === 0) {
        return {
          ...ass,
          tags: undefined,
        };
      }
      return {
        ...ass,
        tags,
      };
    });
  }, []);

  /**
   * Detect if we're allowed to save the assessment
   */
  const isSavable = useMemo(() => {
    if (createAssessment.tags && createAssessment.tags.includes("incomplete")) {
      // Allow to save if marked as incomplete
      return true;
    }

    if (
      !selected.isLatestVersion ||
      createAssessment.assessmentState === APITypesV1.AssessmentState.New ||
      !isModified
    ) {
      return false;
    }

    if (isPadAssessment(selected)) {
      const ca = createAssessment as APITypesV1.CreatePadAssessmentRequest;
      if (ca.ratios && ca.regionsOfInterest) {
        return true;
      }
    } else if (isPulseWaveAssessment(selected)) {
      const ca =
        createAssessment as APITypesV1.CreatePulseWaveAssessmentRequest;
      if (
        ca.footAsymmetry &&
        ca.handAsymmetry &&
        ca.lveti &&
        ca.pulseWaveVelocity
      ) {
        return true;
      }
    } else if (isThermalAssessment(selected)) {
      const ca = createAssessment as APITypesV1.CreateThermalAssessmentRequest;
      if (ca.status) {
        return true;
      }
    }
    return false;
  }, [createAssessment, isModified, selected]);

  /**
   * PAD-only
   * Move a Region of Interest
   */
  const moveRoI = useCallback(
    (id: string, box: Box) => {
      if (selected.itemTypeName !== AssessmentTypeName.Pad) {
        console.debug("Wrong type");
        return;
      }
      setCreateAssessment((ass: CreatePadAssessmentRequestBox) => ({
        ...ass,
        regionsOfInterest: {
          ...ass.regionsOfInterest,
          [id]: box,
        },
      }));
      setModified(true);
    },
    [selected.itemTypeName]
  );
  // Set initial RoI:s from annotations
  const setInitialRoIs = useCallback((forearm: Box, hypothenar: Box) => {}, []);

  return {
    addTag,
    assessments,
    createAssessment,
    fetch,
    isModified,
    isSavable,
    loadLatest,
    moveRoI,
    removeTag,
    save,
    selected,
    setComment,
    setInitialRoIs,
    setLvetiPulseWaveData,
    setPadRatio,
    setPulseWaveData,
    setState,
    setThermalStatus,
  };
}

export type AssessmentContextValue = ReturnType<typeof useAssessment>;
