import { useNav } from "@pomle/react-router-paths";
import { useCallback } from "react";
import { useMSAL } from "render/context/MSALContext";
import { paths } from "render/routes/paths";
import { Fieldset } from "render/ui/form/Fieldset";
import { FramedPage } from "render/ui/layouts/FramedPage/FramedPage";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { ButtonSet } from "render/ui/trigger/ButtonSet";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import { StripeTerminals } from "./components/StripeTerminals";
import styles from "./styles.module.sass";

export function SettingsPage() {
  const {
    session: { logOut },
  } = useMSAL();

  const nav = {
    root: useNav(paths.root),
  };

  const handleSignOut = useCallback(() => {
    nav.root.go({});
    logOut();
  }, [nav.root, logOut]);

  return (
    <FramedPage>
      <PageHeader caption="Settings" />

      <div className={styles.SettingsPage}>
        <Fieldset direction="row" legend="Session">
          <ButtonSet>
            <HoverTextButton onClick={handleSignOut}>Sign Out</HoverTextButton>
          </ButtonSet>
        </Fieldset>

        <StripeTerminals />
      </div>
    </FramedPage>
  );
}
