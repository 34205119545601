import { BodyMetrics } from "lib/types/metrics/body";
import { useCallback, useEffect, useState } from "react";
import { useBodyMetricStore } from "render/hooks/api/metrics/useBodyMetricsStore";
import { useFormHandle } from "render/hooks/useFormHandle";
import { useUserInput } from "render/hooks/useUserInput";
import { Field } from "render/ui/form/Field";
import { Fieldset } from "render/ui/form/Fieldset";
import { Input } from "render/ui/form/Input";
import { BodyMetricLength } from "render/ui/format/BodyMetricLength";
import { BodyMetricWeight } from "render/ui/format/BodyMetricWeight";
import { ButtonSet } from "render/ui/trigger/ButtonSet";
import { SubmitButton } from "render/ui/trigger/SubmitButton";
import { ReferenceValue } from "../../fragments/ReferenceValue";
import { Entries, toBodyMetrics } from "./conversion";
import styles from "./styles.module.sass";

const INITIAL: Entries = {
  biceps: "",
  calf: "",
  calf_left: "",
  calf_right: "",
  height: "",
  hip: "",
  neck: "",
  waist: "",
  weight: "",
  femoral_instep_left: "",
  femoral_instep_right: "",
  ssn_carotis_left: "",
  ssn_femoralis_left: "",
  ssn_symphysis: "",
  ssn_piezo_finger_left: "",
  ssn_piezo_finger_right: "",
};

interface BodyMetricsSectionProps {
  patientId: string;
}

export function BodyMetricsSection({ patientId }: BodyMetricsSectionProps) {
  const { fetchBodyMetrics, storeBodyMetrics } = useBodyMetricStore();

  const [latest, setLatest] = useState<BodyMetrics>();

  const updateLatest = useCallback(() => {
    fetchBodyMetrics(patientId).then((metrics) => {
      setLatest(metrics);
    });
  }, [fetchBodyMetrics, patientId]);

  useEffect(() => {
    updateLatest();

    return () => {
      setLatest(undefined);
    };
  }, [updateLatest]);

  const [entries, setEntries] = useState(INITIAL);

  const userInput = useUserInput(entries, setEntries);

  const handleSave = useCallback(async () => {
    await storeBodyMetrics(patientId, toBodyMetrics(entries));
    setEntries(INITIAL);

    setTimeout(updateLatest, 1000);
  }, [updateLatest, storeBodyMetrics, patientId, entries]);

  const saveHandle = useFormHandle(handleSave);

  return (
    <div>
      <form
        className={styles.BodyMetricsSection}
        onSubmit={saveHandle.onSubmit}
      >
        <Fieldset direction="row" legend="Body Metrics">
          <Field label="Height">
            <Input hint="cm">
              <input
                type="number"
                min={120}
                max={250}
                step={0.1}
                autoComplete="off"
                {...userInput.height}
              />
            </Input>

            <ReferenceValue
              value={<BodyMetricLength length={latest?.height?.unit} />}
            />
          </Field>

          <Field label="Weight">
            <Input hint="kg">
              <input
                type="number"
                min={35}
                max={250}
                step={0.1}
                autoComplete="off"
                {...userInput.weight}
              />
            </Input>

            <ReferenceValue
              value={<BodyMetricWeight weight={latest?.weight?.unit} />}
            />
          </Field>
        </Fieldset>

        <Fieldset direction="row">
          <Field label="Calf - Left">
            <Input hint="cm">
              <input
                type="number"
                min={25}
                max={70}
                step={0.1}
                autoComplete="off"
                {...userInput.calf_left}
              />
            </Input>

            <ReferenceValue
              value={<BodyMetricLength length={latest?.calf_left?.unit} />}
            />
          </Field>

          <Field label="Calf - Right">
            <Input hint="cm">
              <input
                type="number"
                min={25}
                max={70}
                step={0.1}
                autoComplete="off"
                {...userInput.calf_right}
              />
            </Input>

            <ReferenceValue
              value={<BodyMetricLength length={latest?.calf_right?.unit} />}
            />
          </Field>
        </Fieldset>

        <Fieldset direction="row">
          <Field label="Hip">
            <Input hint="cm">
              <input
                type="number"
                min={60}
                max={150}
                step={0.1}
                autoComplete="off"
                {...userInput.hip}
              />
            </Input>

            <ReferenceValue
              value={<BodyMetricLength length={latest?.hip?.unit} />}
            />
          </Field>

          <Field label="Neck">
            <Input hint="cm">
              <input
                type="number"
                min={25}
                max={55}
                step={0.1}
                autoComplete="off"
                {...userInput.neck}
              />
            </Input>

            <ReferenceValue
              value={<BodyMetricLength length={latest?.neck?.unit} />}
            />
          </Field>

          <Field label="Waist">
            <Input hint="cm">
              <input
                type="number"
                min={35}
                max={220}
                step={0.1}
                autoComplete="off"
                {...userInput.waist}
              />
            </Input>

            <ReferenceValue
              value={<BodyMetricLength length={latest?.waist?.unit} />}
            />
          </Field>
        </Fieldset>

        <Fieldset direction="row" legend="Cardio Distances">
          <Field label="SSN - Left Carotid">
            <Input hint="cm">
              <input
                type="number"
                min={5}
                max={20}
                step={0.1}
                autoComplete="off"
                {...userInput.ssn_carotis_left}
              />
            </Input>

            <ReferenceValue
              value={
                <BodyMetricLength length={latest?.ssn_carotis_left?.unit} />
              }
            />
          </Field>

          <Field label="SSN - Left Femoral">
            <Input hint="cm">
              <input
                type="number"
                min={25}
                max={90}
                step={0.1}
                autoComplete="off"
                {...userInput.ssn_femoralis_left}
              />
            </Input>

            <ReferenceValue
              value={
                <BodyMetricLength length={latest?.ssn_femoralis_left?.unit} />
              }
            />
          </Field>
        </Fieldset>

        <Fieldset direction="row">
          <Field label="SSN - Symphysis">
            <Input hint="cm">
              <input
                type="number"
                min={25}
                max={90}
                step={0.1}
                autoComplete="off"
                {...userInput.ssn_symphysis}
              />
            </Input>

            <ReferenceValue
              value={<BodyMetricLength length={latest?.ssn_symphysis?.unit} />}
            />
          </Field>
        </Fieldset>

        <Fieldset direction="row">
          <Field label="SSN - Left Finger Sensor">
            <Input hint="cm">
              <input
                type="number"
                min={40}
                max={90}
                step={0.1}
                autoComplete="off"
                {...userInput.ssn_piezo_finger_left}
              />
            </Input>

            <ReferenceValue
              value={
                <BodyMetricLength
                  length={latest?.ssn_piezo_finger_left?.unit}
                />
              }
            />
          </Field>

          <Field label="SSN - Right Finger Sensor">
            <Input hint="cm">
              <input
                type="number"
                min={40}
                max={90}
                step={0.1}
                autoComplete="off"
                {...userInput.ssn_piezo_finger_right}
              />
            </Input>

            <ReferenceValue
              value={
                <BodyMetricLength
                  length={latest?.ssn_piezo_finger_right?.unit}
                />
              }
            />
          </Field>
        </Fieldset>

        <Fieldset direction="row">
          <Field label="Left Femoral - Left Instep">
            <Input hint="cm">
              <input
                type="number"
                min={50}
                max={120}
                step={0.1}
                autoComplete="off"
                {...userInput.femoral_instep_left}
              />
            </Input>

            <ReferenceValue
              value={
                <BodyMetricLength length={latest?.femoral_instep_left?.unit} />
              }
            />
          </Field>

          <Field label="Left Femoral - Right Instep">
            <Input hint="cm">
              <input
                type="number"
                min={50}
                max={120}
                step={0.1}
                autoComplete="off"
                {...userInput.femoral_instep_right}
              />
            </Input>

            <ReferenceValue
              value={
                <BodyMetricLength length={latest?.femoral_instep_right?.unit} />
              }
            />
          </Field>
        </Fieldset>

        <ButtonSet>
          <SubmitButton handle={saveHandle}>Save</SubmitButton>
        </ButtonSet>
      </form>
    </div>
  );
}
