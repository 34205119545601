import { Risk } from "@cur8/health-risks-calc";
import { clamp } from "lib/math";
import { ReactElement, useMemo } from "react";
import {
  getLeftLabel,
  getRightLabel,
  groomDecilmasIfNecessary,
  isHigherOrEqualThanRange,
  isLowerOrEqualThanRange,
  isSameRange,
} from "./lib/util";
import { ChartRange, MarkerData, RangeData, Variant } from "./types";

const VariantMap: Record<Risk, Variant> = {
  [Risk.Unknown]: "normal",
  [Risk.Optimal]: "normal",
  [Risk.Normal]: "normal",
  [Risk.LowRisk]: "warning",
  [Risk.Risk]: "warning",
  [Risk.ModerateRisk]: "danger",
  [Risk.HighRisk]: "danger",
  [Risk.ImmediateRisk]: "danger",
};

interface ChildrenData {
  values: MarkerData[];
  ranges: RangeData[];
}

interface RangeChartDataComposerProps {
  ranges: ChartRange[];
  value?: number;
  previousValue?: number;
  formatValue?: (value?: number) => string | number | undefined;
  children: (data: ChildrenData) => ReactElement;
}

export function RangeChartDataComposer({
  ranges,
  children,
  value,
  previousValue,
  formatValue = groomDecilmasIfNecessary,
}: RangeChartDataComposerProps) {
  const max = Math.max(...ranges.map((range) => range.to));
  const min = Math.min(...ranges.map((range) => range.from));

  const lowestRange = ranges.find((range) => range.from === min);
  const highestRange = ranges.find((range) => range.to === max);
  const middleRange = ranges[Math.floor(ranges.length / 2)];

  const rangeData: RangeData[] = ranges.map((range) => ({
    from: range.from,
    to: range.to,
    label: range.label,
    variant: VariantMap[range.risk],
    leftLabel: getLeftLabel({
      isLowest: isSameRange(range, lowestRange),
      isHigherOrEqualThanMiddleMiddleRange: isHigherOrEqualThanRange(
        range.from,
        middleRange
      ),
      value: range.from,
    }),
    rightLabel: getRightLabel({
      isHighest: isSameRange(range, highestRange),
      isLowerThanMiddleMiddleRange: isLowerOrEqualThanRange(
        range.to,
        middleRange
      ),
      value: range.to,
    }),
    width: Math.floor(((range.to - range.from) / (max - min)) * 100),
  }));

  const values: MarkerData[] = useMemo(() => {
    if (value === undefined) {
      return [];
    }

    const areValuesTheSame = previousValue === value;
    const valueMaker: MarkerData = {
      value: clamp(value, min, max),
      label: formatValue(value),
      variant: areValuesTheSame ? "primary-outlined" : "primary",
    };

    const previousValueMaker: MarkerData | undefined =
      previousValue && !areValuesTheSame
        ? {
            value: clamp(previousValue, min, max),
            variant: "outlined",
          }
        : undefined;

    return [previousValueMaker, valueMaker].filter(
      (value): value is MarkerData => value !== undefined
    );
  }, [value, previousValue, min, max, formatValue]);

  return <>{children({ ranges: rangeData, values })}</>;
}
