interface PrefixedNumberProps {
  value: number;
  precision: number;
}

export function PrefixedNumber({ value, precision = 0 }: PrefixedNumberProps) {
  return (
    <>
      {value >= 0 ? "+" : "-"}
      {Math.abs(value).toFixed(precision)}
    </>
  );
}
