import { ButtonHTMLAttributes, useMemo } from "react";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import ArrowIcon from "./assets/arrow.svg?react";
import styles from "./styles.module.sass";

interface HideButtonProps extends ButtonHTMLAttributes<any> {
  dohide: boolean;
}

export function HideButton({ dohide, ...props }: HideButtonProps) {
  const text = useMemo(() => {
    return dohide ? "Show" : "Hide";
  }, [dohide]);

  return (
    <div className={styles.HideButton} data-dohide={dohide}>
      <HoverTextButton {...props}>
        <div className={styles.content}>
          {text}{" "}
          <div className={styles.icon}>
            <ArrowIcon />
          </div>
        </div>
      </HoverTextButton>
    </div>
  );
}
