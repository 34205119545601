import { ScheduleViewSlot } from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { Interval } from "luxon";
import { SlotTime } from "./fragments/SlotTime";
import styles from "./styles.module.sass";

interface Props {
  slot: ScheduleViewSlot;
}

function toInterval(slot: ScheduleViewSlot): Interval {
  return Interval.fromDateTimes(slot.startTime, slot.endTime);
}

export function ReservedSlot({ slot }: Props) {
  return (
    <div className={classNames(styles.reserved, styles.slot)}>
      <div className={styles.title}>Reserved</div>
      <div className={styles.subtitle}>
        <SlotTime timeWindow={toInterval(slot)} />
      </div>
    </div>
  );
}
