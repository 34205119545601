import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface PopupDialogProps {
  children: ReactNode;
}

export function PopupDialog({ children }: PopupDialogProps) {
  return <div className={styles.PopupDialog}>{children}</div>;
}
