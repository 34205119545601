import { Assessment, AssessmentTypeName, Patient } from "@cur8/rich-entity";
import { URIType } from "@cur8/uri";
import { useNav } from "@pomle/react-router-paths";
import { useCallback, useMemo } from "react";
import BackIcon from "render/assets/nav/back.svg?react";
import { useImmutableScan } from "render/hooks/api/useImmutableScan";
import { paths } from "render/routes/paths";
import { Tooltip } from "render/ui/presentation/Tooltip";
import { useAssessmentNav } from "../../hooks/useAssessmentNav";
import { PatientSummary } from "./PatientSummary";
import styles from "./styles.module.sass";

interface AssessmentHeaderProps {
  patient: Patient;
  assessment: Assessment;
}

export function AssessmentHeader({
  patient,
  assessment,
}: AssessmentHeaderProps) {
  const patientNav = useNav(paths.patient.detail);
  const { unhandledQueue } = useAssessmentNav();
  const scan = useImmutableScan(
    patient.patientId,
    assessment.scanId,
    assessment.scanVersion
  );
  let title = "Unsupported Assessment";
  switch (assessment.itemTypeName) {
    case AssessmentTypeName.Pad:
      title = "PAD Assessment";
      break;
    case AssessmentTypeName.PulseWave:
      title = "Pulse Wave Assessment";
      break;
    case AssessmentTypeName.Thermal:
      title = "Thermal Assessment";
      break;
  }

  /**
   * Name-link router
   * If referrer is AdminUI, go back in history
   *  - else -
   * Go to PatientDetailPage
   */
  const goBack = useCallback(() => {
    const ref = document.referrer;
    if (ref.includes("admin.") && ref.includes("cur8.co")) {
      // Coming from adminUI
      window.history.go(-1);
    } else {
      patientNav.go({ patientId: patient.patientId });
    }
  }, [patient.patientId, patientNav]);

  const info = useMemo(() => {
    if (!scan) {
      return {
        scanId: "",
        version: "",
        recording: "",
      };
    }
    const sURI = scan.sourceUris.length > 0 ? scan.sourceUris[0] : undefined;
    const recordingId =
      sURI?.type === URIType.Recording ? sURI.recordingId : undefined;
    return {
      scanId: scan.id,
      version: scan.version,
      recording: recordingId,
    };
  }, [scan]);

  return (
    <header className={styles.AssessmentHeader}>
      <div className={styles.patientCol} onClick={goBack}>
        <BackIcon />
        <PatientSummary patient={patient} />
      </div>
      <div className={styles.title}>
        <h1>{title}</h1>
        {unhandledQueue && (
          <Tooltip>
            <div className={styles.tooltip}>Queue actived</div>
            <div className={styles.tooltipContent}>
              Next unassessed (<i>New</i>) assessment will open on <i>Save</i>.
            </div>
          </Tooltip>
        )}
      </div>

      <div className={styles.scanInfo}>
        Scan: {info.scanId}
        <br />
        Version: {info.version}
        <br />
        Recording: {info.recording}
      </div>
    </header>
  );
}
