import { APITypesV1 } from "@cur8/api-client";
import { clamp } from "lib/math";
import { PointerEvent, ReactNode, useCallback, useMemo, useState } from "react";
import { useRangeContext } from "../../../../../context/RangeContext";
import { VerticalLine } from "./components/VerticalLine";
import styles from "./style.module.sass";

interface RulerProps {
  children: ReactNode;
  onSelectionRange: (range: APITypesV1.Range, complete?: boolean) => void;
  rrInterval?: APITypesV1.Range;
  rrIntervalResize?: (
    pos: number,
    dragLeft: boolean,
    complete?: boolean
  ) => void;
  selectionRange?: APITypesV1.Range;
}

enum Cursor {
  crosshair = "crosshair",
  grab = "grab",
  grabbing = "grabbing",
  resizeLeft = "resizeLeft",
  resizeRight = "resizeRight",
}

export function Ruler({
  children,
  selectionRange,
  onSelectionRange,
  rrInterval,
  rrIntervalResize,
}: RulerProps) {
  const {
    setWindowRange,
    signalRange,
    singleTimeToScalar,
    timeToWindowScalar,
    windowLength,
    windowScalarToTime,
  } = useRangeContext();
  const [indicatorPos, setIndicatorPos] = useState<number>();
  const [startPos, setStartPos] = useState<number>();
  const [cursor, setCursor] = useState<Cursor>(Cursor.crosshair);

  const EDGE_MARGIN = 0.01;
  const isAtLeftEdge = useCallback(
    (pos: number) => {
      if (!rrInterval) {
        return false;
      }
      return Math.abs(pos - rrInterval.from) < EDGE_MARGIN;
    },
    [rrInterval]
  );

  const isAtRightEdge = useCallback(
    (pos: number) => {
      if (!rrInterval) {
        return false;
      }
      return Math.abs(pos - rrInterval.to) < EDGE_MARGIN;
    },
    [rrInterval]
  );

  const selection = useMemo(() => {
    if (!selectionRange) {
      return undefined;
    }
    return timeToWindowScalar(selectionRange);
  }, [selectionRange, timeToWindowScalar]);

  const rrSelection = useMemo(() => {
    if (!rrInterval) {
      return;
    }
    return timeToWindowScalar(rrInterval);
  }, [rrInterval, timeToWindowScalar]);

  const handlePointer = useCallback(
    (event: PointerEvent<HTMLDivElement>) => {
      const bounds = event.currentTarget.getBoundingClientRect();
      const pos = windowScalarToTime(
        clamp((event.clientX - bounds.left) / bounds.width, 0, 1)
      );
      const pan = event.ctrlKey;

      if (event.type === "pointerdown") {
        setIndicatorPos(undefined);
        setStartPos(pos);
        if (pan) {
          setCursor(Cursor.grabbing);
        } else if (Cursor.crosshair === cursor) {
          onSelectionRange({ from: pos, to: pos });
        }
      } else if (event.type === "pointermove" && !!event.buttons) {
        // Move + mouse btn pressed
        if (pan && startPos) {
          setCursor(Cursor.grabbing);
          setWindowRange((cur) => {
            const deltaTime = (event.movementX / bounds.width) * windowLength;
            const from = cur.from - deltaTime;
            const to = cur.to - deltaTime;
            if (from < signalRange.from || to > signalRange.to) {
              return cur;
            }
            return {
              from: cur.from - deltaTime,
              to: cur.to - deltaTime,
            };
          });
        } else if (
          rrIntervalResize &&
          (Cursor.resizeLeft === cursor || Cursor.resizeRight === cursor)
        ) {
          // dragging RR-interval
          rrIntervalResize(pos, Cursor.resizeLeft === cursor);
        } else {
          onSelectionRange({ from: startPos!, to: pos });
        }
      } else if (event.type === "pointermove") {
        // just moving around
        if (pan) {
          setCursor(Cursor.grab);
          setIndicatorPos(undefined);
        } else if (isAtLeftEdge(pos) || isAtRightEdge(pos)) {
          setCursor(isAtLeftEdge(pos) ? Cursor.resizeLeft : Cursor.resizeRight);
          setIndicatorPos(undefined);
        } else {
          setCursor(Cursor.crosshair);
          setIndicatorPos(singleTimeToScalar(pos));
        }
      } else if (event.type === "pointerup") {
        if (
          rrIntervalResize &&
          (Cursor.resizeLeft === cursor || Cursor.resizeRight === cursor)
        ) {
          // dragging RR-interval
          rrIntervalResize(pos, Cursor.resizeLeft === cursor, true);
        }
        if (Cursor.crosshair === cursor) {
          onSelectionRange({ from: startPos!, to: pos }, true);
          setIndicatorPos(singleTimeToScalar(pos));
        }
      }
    },
    [
      cursor,
      isAtLeftEdge,
      isAtRightEdge,
      onSelectionRange,
      rrIntervalResize,
      setWindowRange,
      signalRange.from,
      signalRange.to,
      singleTimeToScalar,
      startPos,
      windowLength,
      windowScalarToTime,
    ]
  );

  return (
    <div className={styles.Ruler} data-cursor={cursor}>
      <div
        className={styles.content}
        onPointerDown={handlePointer}
        onPointerMove={handlePointer}
        onPointerUp={handlePointer}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        onPointerLeave={() => setIndicatorPos(undefined)}
      >
        <div data-testid="rulerChildren">
          {children}
          <VerticalLine position={indicatorPos} />
        </div>
      </div>

      {rrSelection && (
        <div
          className={styles.rrSelection}
          style={{
            left: `${rrSelection.from * 100}%`,
            right: `${(1 - rrSelection.to) * 100}%`,
          }}
        />
      )}
      {selection && (
        <div
          className={styles.selection}
          style={{
            left: `${selection.from * 100}%`,
            right: `${(1 - selection.to) * 100}%`,
          }}
        />
      )}
    </div>
  );
}
