import { DateTime } from "luxon";
import { useLiveTime } from "render/hooks/useLiveTime";
import { PassedTimeBetween } from "../PassedTimeBetween";

interface PassedTimeProps {
  date: DateTime;
}

export function PassedTime({ date }: PassedTimeProps) {
  const now = useLiveTime("minute");

  return <PassedTimeBetween start={date} end={now} />;
}
