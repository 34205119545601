import { APITypesV1 } from "@cur8/api-client";
import {
  Assessment,
  isPulseWaveAssessment,
  PulseWaveAssessment,
  Sex,
} from "@cur8/rich-entity";
import { CreatePadAssessmentRequestBox } from "render/pages/AssessmentPage/context/AssessmentContext";
import {
  CreatePulseWaveAssessmentRequestBox,
  CreateThermalAssessmentRequestBox,
} from "render/pages/AssessmentPage/context/AssessmentContext/lib";
import { calculateLVETI } from "./calculations";
import { isCreatePWVAssessment, PulsewaveType, Selections } from "./types";

/**
 * Find the start- and end-indexes in the timestamp array
 * that fits the time window
 */
export function findTimeIndexes(
  timestamps: number[],
  win: APITypesV1.Range
): APITypesV1.Range {
  // Find the first index >= target
  const findStartIndex = (array: number[], target: number): number => {
    let low = 0,
      high = array.length - 1;
    while (low < high) {
      const mid = Math.floor((low + high) / 2);
      if (array[mid] < target) {
        low = mid + 1;
      } else {
        high = mid;
      }
    }
    return array[low] >= target ? low : array.length;
  };

  // Find the last index <= target
  const findEndIndex = (array: number[], target: number): number => {
    let low = 0,
      high = array.length - 1;
    while (low < high) {
      const mid = Math.ceil((low + high) / 2);
      if (array[mid] > target) {
        high = mid - 1;
      } else {
        low = mid;
      }
    }
    return array[high] <= target ? high : -1;
  };

  const from = findStartIndex(timestamps, win.from);
  const to = findEndIndex(timestamps, win.to);

  if (from <= to && from < timestamps.length && to >= 0) {
    return { from, to };
  } else {
    // Fallback to full range
    return { from: 0, to: timestamps.length - 1 };
  }
}

/**
 * Make LVETI-calculations and return Selections
 */
export function assessmentToSelection(
  selections: APITypesV1.LVETIRangeData[],
  sex: Sex
): Selections {
  const sels = {} as Selections;
  for (let i = 0; i <= 2; i++) {
    sels[i] = calculateLVETI(selections[i].ssn, selections[i].rrInterval, sex);
  }
  return sels;
}

/**
 * Get selection from assessment (can be unsaved)
 */
export function getLvetiSelection(
  createAssessment:
    | CreatePadAssessmentRequestBox
    | CreatePulseWaveAssessmentRequestBox
    | CreateThermalAssessmentRequestBox,
  selected: Assessment
) {
  if (
    createAssessment &&
    isCreatePWVAssessment(createAssessment) &&
    createAssessment.lveti?.selections
  ) {
    // Unsaved changes in Create assessment
    return createAssessment.lveti.selections;
  }
  if (
    selected &&
    isPulseWaveAssessment(selected) &&
    selected.lveti?.selections
  ) {
    // Latest saved assessment
    return selected.lveti.selections;
  }
  return undefined;
}

/**
 * Get PulseWave Assessment Data from either unsaved
 * createAssessment or from latest saved.
 */
export function getPWADrange(
  create: CreatePulseWaveAssessmentRequestBox,
  selected: PulseWaveAssessment,
  pwt: PulsewaveType
): APITypesV1.PulseWaveAssessmentData["range"] | undefined {
  if (
    create &&
    isCreatePWVAssessment(create) &&
    create[pwt] &&
    pwt !== PulsewaveType.lveti &&
    create[pwt]?.range
  ) {
    // Unsaved changes in Create assessment
    return create[pwt]?.range;
  }
  if (
    selected &&
    isPulseWaveAssessment(selected) &&
    selected[pwt] &&
    pwt !== PulsewaveType.lveti &&
    selected[pwt]?.range
  ) {
    // Latest saved assessment
    return selected[pwt]?.range;
  }
  return undefined;
}

/**
 * Ranges for window (zoom dataplot)
 * Checks in unsaved data first, then saved assessment
 */
export function getRangesForWindow(
  create: CreatePulseWaveAssessmentRequestBox,
  selected: PulseWaveAssessment,
  pulsewaveType: PulsewaveType,
  lvetiIdx = 0
) {
  switch (pulsewaveType) {
    case PulsewaveType.lveti:
      // The RR-interval is wider than SSN
      return either(
        create,
        selected,
        (pw) => pw.lveti?.selections[lvetiIdx].rrInterval
      );

    case PulsewaveType.footAsymmetry:
      return either(create, selected, (pw) => pw.footAsymmetry?.range);
    case PulsewaveType.handAsymmetry:
      return either(create, selected, (pw) => pw.handAsymmetry?.range);
    case PulsewaveType.pulseWaveVelocity:
      return either(create, selected, (pw) => pw.pulseWaveVelocity?.range);
  }
}

function either<T, R>(a: T, b: T, get: (p: T) => R | undefined) {
  return get(a) ?? get(b);
}
