import { ButtonHTMLAttributes, ReactNode } from "react";
import { AsyncHandle } from "render/hooks/useAsyncHandle";
import { AsyncButton } from "../AsyncButton";

interface ActionButtonProps
  extends Omit<ButtonHTMLAttributes<any>, "type" | "onClick"> {
  children: ReactNode;
  handle: AsyncHandle;
}

export function ActionButton({
  children,
  handle,
  ...props
}: ActionButtonProps) {
  return (
    <AsyncButton
      {...props}
      type="button"
      handle={handle}
      onClick={handle.callback}
    >
      {children}
    </AsyncButton>
  );
}
