import { fromAPI } from "@cur8/rich-entity";
import { UserURI } from "@cur8/uri";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { useUser } from "render/hooks/useUser";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import { usePatientCreatePrivateJournalNote } from "../../../hooks/usePatientCreatePrivateJournalNote";
import PrivateJournalNoteAuthor from "../PrivateJournalNoteAuthor/PrivateJournalNoteAuthor";
import styles from "./styles.module.sass";
import { PrivateJournalNote } from "@cur8/rich-entity/dist/types/PrivateJournalNote";

interface WritePrivateJournalNoteProps {
  patientId: string;
  onNoteAdded: (note: PrivateJournalNote) => void;
  setSelectedNote: (note: PrivateJournalNote) => void;
}

export default function WritePrivateJournalNote({
  patientId,
  onNoteAdded,
  setSelectedNote,
}: WritePrivateJournalNoteProps) {
  const user = useUser();
  const [privateJournalNoteContent, setPrivateJournalNoteContent] =
    useState<string>("");

  const createPrivateJournalNote = usePatientCreatePrivateJournalNote();

  const doSendPrivateJournalNoteHandle = useAsyncHandle(async () => {
    try {
      const newNote = await createPrivateJournalNote(patientId, {
        note: privateJournalNoteContent,
      });
      const formattedNote = fromAPI.toPrivateJournalNote(newNote);
      setSelectedNote(formattedNote);
      onNoteAdded(formattedNote);
    } catch (error) {
      console.error("Error saving the note:", error);
    }
  });

  const userUri = useMemo(
    () => new UserURI(user?.tenantId || "", user?.uniqueId || ""),
    [user]
  );

  return (
    <div className={styles.WritePrivateJournalNote}>
      <header>
        <h1>Note</h1>
        <p>
          Doctor notes are for Clinic Staff only and never visible to members
        </p>
      </header>
      <div className={styles.textareaContainer}>
        <div className={styles.authorSection}>
          <PrivateJournalNoteAuthor
            date={DateTime.now()}
            userUri={userUri}
            showTimestamp={false}
          />
        </div>
        <textarea
          placeholder="Add note here..."
          value={privateJournalNoteContent}
          onChange={(e) => setPrivateJournalNoteContent(e.target.value)}
          rows={10}
        />
      </div>
      <footer>
        <div className={styles.footerButtons}>
          <HoverTextButton
            active
            disabled={
              privateJournalNoteContent.trim().length === 0 ||
              doSendPrivateJournalNoteHandle.busy
            }
            onClick={doSendPrivateJournalNoteHandle.callback}
          >
            <span>Save & Sign</span>
          </HoverTextButton>
        </div>
      </footer>
    </div>
  );
}
