import { Patient } from "@cur8/rich-entity";
import { Lesion } from "lib/lesion";
import { LesionMacroClassification } from "./components/LesionMacroClassification";
import { LesionMacroDetails } from "./components/LesionMacroDetails";
import { LesionMacroEvolution } from "./components/LesionMacroEvolution";
import styles from "./styles.module.sass";

interface LesionMacroPanelProps {
  patient: Patient;
  active: boolean;
  selected: Lesion | undefined;
}

export function LesionMacroPanel({ selected }: LesionMacroPanelProps) {
  return (
    <div className={styles.LesionMacroPanel}>
      <div className={styles.content}>
        <section>
          <LesionMacroDetails lesion={selected} />
        </section>
        <section>
          <LesionMacroClassification selected={selected} />
        </section>
        <section>
          <LesionMacroEvolution selected={selected} />
        </section>
      </div>
    </div>
  );
}
