import { APITypesV2 } from "@cur8/api-client";
import { Patient } from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { convertQuestionnaire } from "lib/questionnaire/convert";
import { AlcoholFrequency } from "render/fragments/metric/patient-details/AlcoholFrequency";
import { ExerciseFrequency } from "render/fragments/metric/patient-details/ExerciseFrequency";
import { SmokingFrequency } from "render/fragments/metric/patient-details/SmokingFrequency";
import { SnusFrequency } from "render/fragments/metric/patient-details/SnusFrequency";
import { PatientAge } from "render/fragments/patient/PatientAge";
import { LocaleLanguage } from "render/ui/format/LocaleLanguage";
import { SexDefinition } from "render/ui/format/SexDefinition";
import styles from "./styles.module.sass";

function isMetricZero(value?: string) {
  return !value || value === "0";
}

interface DetailsProps {
  onboarding: APITypesV2.QuestionnaireResponse | undefined;
  patient: Patient | undefined;
}

export function DetailsV1({ patient, onboarding }: DetailsProps) {
  const answers = convertQuestionnaire(onboarding);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.metricTitle}>Age</div>
        <div className={styles.data}>
          <div>
            {patient && (
              <>
                <PatientAge patient={patient} /> years old
              </>
            )}
          </div>
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.metricTitle}>Sex</div>
        <div className={styles.data}>
          {patient && <SexDefinition sex={patient.sex} />}
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.metricTitle}>Language</div>
        <div className={styles.data}>
          {patient?.preferredLanguage ? (
            <LocaleLanguage locale={patient.preferredLanguage} />
          ) : (
            <>&mdash;</>
          )}
        </div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: !answers?.v1.medications,
        })}
      >
        <div className={styles.metricTitle}>Medications</div>
        <div className={styles.data}>{answers?.v1.medications}</div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: !answers?.allergies,
        })}
      >
        <div className={styles.metricTitle}>Allergies</div>
        <div className={styles.data}>{answers?.allergies}</div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: !answers?.diet,
        })}
      >
        <div className={styles.metricTitle}>Diet</div>
        <div className={styles.data}>{answers?.diet}</div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: isMetricZero(answers?.v1.exerciseHoursPerWeek),
        })}
      >
        <div className={styles.metricTitle}>Exercise</div>
        <div className={styles.data}>
          {answers?.v1.exerciseHoursPerWeek && (
            <ExerciseFrequency
              value={Number(answers.v1.exerciseHoursPerWeek)}
            />
          )}
        </div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: isMetricZero(answers?.alcohol),
        })}
      >
        <div className={styles.metricTitle}>Alcohol</div>
        <div className={styles.data}>
          {answers?.alcohol && <AlcoholFrequency value={answers.alcohol} />}
        </div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: isMetricZero(answers?.smokingDailyCigarettes),
        })}
      >
        <div className={styles.metricTitle}>Smoking</div>
        <div className={styles.data}>
          <Smoking
            dailyCigarettes={answers?.smokingDailyCigarettes}
            isSmoker={answers?.isSmoker}
          />
        </div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: isMetricZero(answers?.v1.snusCansPerWeek),
        })}
      >
        <div className={styles.metricTitle}>Snus</div>
        <div className={styles.data}>
          {answers?.v1.snusCansPerWeek && (
            <SnusFrequency value={answers.v1.snusCansPerWeek} />
          )}
        </div>
      </div>
    </>
  );
}

type SmokingProps = {
  dailyCigarettes: string | undefined;
  isSmoker?: boolean | undefined;
};

function Smoking({ dailyCigarettes, isSmoker }: SmokingProps) {
  if (isSmoker == null) {
    return null;
  }
  if (isSmoker === false) {
    return <>No</>;
  }
  if (dailyCigarettes) {
    return <SmokingFrequency value={Number(dailyCigarettes) || 0} />;
  }
  return <>Yes</>;
}
