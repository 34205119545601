import { ReactNode } from "react";
import { Entry } from "render/ui/presentation/MeasurementTimeline";
import { MainTitle, MetricResultHeader, Titles } from "../MetricResultHeader";
import {
  AuxTitle,
  Diff,
  DiffValue,
} from "../MetricResultHeader/MetricResultHeader";
import { RangeChart, RangeChartDataComposer } from "../RangeChart";
import { ChartRange } from "../RangeChart/types";

interface MetricRangeProps {
  title: ReactNode;
  subtitle?: ReactNode;
  unit?: ReactNode;
  ranges?: ChartRange[];
  entries?: Entry[];
  formatValue?: (value?: number) => string | number | undefined;
}

export function MetricRange({
  title,
  subtitle,
  unit,
  ranges,
  entries,
  formatValue = (n?: number) => n,
}: MetricRangeProps) {
  const latest = entries?.at(0);
  const value =
    latest?.repr !== undefined ? Number(latest?.repr) : latest?.value;

  const previous = entries?.at(1);
  const previousValue = previous?.repr
    ? Number(previous?.repr)
    : previous?.value;

  return (
    <div>
      <MetricResultHeader>
        <Titles>
          <MainTitle>{title}</MainTitle>
          {subtitle && <AuxTitle>{subtitle}</AuxTitle>}
        </Titles>
        <Diff>
          <DiffValue
            valuesDiff={
              value !== undefined && previousValue !== undefined
                ? value - previousValue
                : undefined
            }
            formatValue={formatValue}
            unit={unit?.toString()}
          />
        </Diff>
      </MetricResultHeader>
      {ranges && (
        <RangeChartDataComposer
          ranges={ranges}
          value={value}
          previousValue={previousValue}
          formatValue={formatValue}
        >
          {({ ranges, values }) => (
            <RangeChart ranges={ranges} values={values} />
          )}
        </RangeChartDataComposer>
      )}
    </div>
  );
}
