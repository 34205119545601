import { Assessment } from "@cur8/rich-entity";
import { Table } from "render/ui/layouts/Table";
import { AssessmentRow } from "./AssessmentRow";

interface AssessmentsTableProps {
  assessments: Assessment[];
}

export function AssessmentsTable({ assessments }: AssessmentsTableProps) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Type</th>
          <th>State</th>
          <th>Assessed by</th>
          <th>Date</th>
        </tr>
      </thead>

      <tbody>
        {assessments.map((ass) => (
          <AssessmentRow key={ass.itemId} assessment={ass} />
        ))}
      </tbody>
    </Table>
  );
}
