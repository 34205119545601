import { Site, fromAPI } from "@cur8/rich-entity";
import { useQueryParams } from "@pomle/react-router-paths";
import { silenceAbort } from "lib/error";
import { DateTime, Interval } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { query } from "render/routes/querys";
import { FramedPage } from "render/ui/layouts/FramedPage/FramedPage";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import { PeriodSelector } from "../SchedulePage/components/SchedulePageHeader/components/PeriodSelector";
import { SiteMonthlySchedule } from "./components/SiteMonthlySchedule";
import styles from "./styles.module.sass";

const MAX_VISIBLE_SITES = 7;

function TimeMonth({ date }: { date: DateTime }) {
  return <>{date.toFormat("LLLL")}</>;
}

function TimeYear({ date }: { date: DateTime }) {
  return <>{date.toFormat("yyyy")}</>;
}

export function SiteSelectPage() {
  const apiClient = useAPIClient();
  const [sites, setSites] = useState<Site[]>();
  const [params, setParams] = useQueryParams(query.schedule);

  const [offset, setOffset] = useState(0);
  const sliceStart = offset;
  const sliceEnd = offset + MAX_VISIBLE_SITES;

  const sourceDate = params.from.at(0);

  const range = useMemo(() => {
    const now = DateTime.now();
    const from = (sourceDate?.isValid ? sourceDate : now).startOf("month");
    const to = from.plus({ months: 2 });
    return Interval.fromDateTimes(from, to);
  }, [sourceDate]);

  useEffect(() => {
    const req = apiClient.sites.querySites();

    req.result
      .then((result) => result.items.map(fromAPI.toSite))
      .then(setSites)
      .catch(silenceAbort);

    return () => {
      setSites(undefined);
      req.abandon();
    };
  }, [apiClient]);

  const stepMonths = useCallback(
    (offset: number) => {
      const next = range.start.plus({ month: offset });
      setParams({ from: [next] });
    },
    [range, setParams]
  );

  return (
    <FramedPage>
      <div className={styles.SiteSelectPage}>
        <div className={styles.header}>
          <div className={styles.nav}>
            <div>
              <PeriodSelector
                navigateToNextPeriod={() => {
                  stepMonths(1);
                }}
                navigateToPreviousPeriod={() => {
                  stepMonths(-1);
                }}
              />
            </div>
            <div className={styles.time}>
              <>
                <TimeYear date={range.start} />
                &nbsp;
                <TimeMonth date={range.start} />
              </>
              &nbsp;-&nbsp;
              <>
                {range.start.month >= 11 && (
                  <>
                    <TimeYear date={range.end} />
                    &nbsp;
                  </>
                )}
                <TimeMonth date={range.end} />
              </>
            </div>
          </div>
        </div>

        <ul className={styles.list}>
          {sites?.slice(sliceStart, sliceEnd).map((site) => {
            return (
              <li key={site.siteId} className={styles.listItem}>
                <SiteMonthlySchedule site={site} date={range.start} />
              </li>
            );
          })}
        </ul>

        {sites?.length && sites.length > sliceEnd && (
          <div className={styles.actions}>
            <HoverTextButton
              onClick={() => {
                setOffset((offset) => offset + 1);
              }}
            >
              Show next site ({sites.length - sliceEnd})
            </HoverTextButton>
          </div>
        )}
      </div>
    </FramedPage>
  );
}
