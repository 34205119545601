import { AvatarPodium } from "lib/avatar/podium";
import { BodyAreas } from "lib/smpl";
import { createTimer } from "lib/timer";
import { createOpacityTween, createPositionTween } from "lib/tween";
import { useEffect, useMemo } from "react";
import { createCircumference } from "../../models";

const TWEEN_CONFIG = {
  stiffness: 3,
  mass: 20,
  friction: 2,
};

interface DiabetesLayerProps {
  active: boolean;
  podium: AvatarPodium;
  areas: BodyAreas;
}

export function DiabetesLayer({ active, podium, areas }: DiabetesLayerProps) {
  const models = useMemo(() => {
    const { waist } = areas;

    return [waist].map((box) => {
      const mesh = createCircumference(box);

      return {
        mesh,
        origin: mesh.position.clone(),
        position: createPositionTween(mesh, TWEEN_CONFIG),
        opacity: createOpacityTween(mesh.material, TWEEN_CONFIG),
      };
    });
  }, [areas]);

  useEffect(() => {
    return createTimer((deltaTime) => {
      models.forEach((model) => {
        model.position.update(deltaTime / 1000);
        model.opacity.update(deltaTime / 1000);
        model.mesh.rotation.z += deltaTime * 0.001;
      });
    });
  }, [models]);

  useEffect(() => {
    // Set offscreen initially
    models.forEach((model) => {
      model.mesh.position.z = model.origin.z + 3000;
      model.mesh.material.opacity = 0;
    });
  }, [models]);

  useEffect(() => {
    // Animate away all
    models.forEach((model) => {
      model.opacity.to({ opacity: 0 });
    });

    // Animate in selected
    if (active) {
      models.forEach((model) => {
        model.position.to(model.origin);
        model.opacity.to({ opacity: 1 });
      });
    }
  }, [models, active]);

  useEffect(() => {
    models.forEach((model) => {
      podium.add(model.mesh);
    });

    return () => {
      models.forEach((model) => {
        podium.remove(model.mesh);
        model.mesh.geometry.dispose();
      });
    };
  }, [podium, models, areas]);

  return null;
}
