import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface ContextHeaderProps {
  context: ReactNode;
  children: ReactNode;
}

export function ContextHeader({ context, children }: ContextHeaderProps) {
  return (
    <div className={styles.ContextHeader}>
      <div className={styles.context}>{context}</div>

      <div className={styles.content}>{children}</div>
    </div>
  );
}
