import { RefObject, useEffect, useState } from "react";

export function useDOMElement<Element extends HTMLOrSVGElement>(
  ref: RefObject<Element>
) {
  const [element, setElement] = useState<Element>();

  useEffect(() => {
    setElement(ref.current ?? undefined);

    return () => {
      setElement(undefined);
    };
  }, [ref]);

  return element;
}
