import { TextButton } from "render/ui/trigger/TextButton";
import { useCalendarInteraction } from "../../../Calendar/hooks/useCalendarInteraction";
import { useCalendarState } from "../../../Calendar/hooks/useCalendarState";
import EyeCloseIcon from "./assets/eye-close.svg?react";
import EyeOpenIcon from "./assets/eye-open.svg?react";

import styles from "./styles.module.sass";

export function AppointmentCensor() {
  const { toggleSlotBlur } = useCalendarInteraction();
  const { areAppointmentsCensored } = useCalendarState();

  return (
    <div className={styles.button}>
      <TextButton
        onClick={() => {
          toggleSlotBlur(!areAppointmentsCensored);
        }}
      >
        {areAppointmentsCensored ? <EyeCloseIcon /> : <EyeOpenIcon />}
      </TextButton>
    </div>
  );
}
