import { ImmutableScan } from "@cur8/rich-entity";
import { parseAsJSON } from "lib/parse";
import { useImmutableScanBlob } from "render/hooks/api/useImmutableScanBlob";

type RPeaksSource = {
  signal: number[];
  timestamps: number[];
};

async function parse(response: Response) {
  const raw = await parseAsJSON<RPeaksSource>(response);
  const peaks: number[] = [];

  for (let i = 0; i < raw.signal.length; i++) {
    if (raw.signal[i] === 1) {
      peaks.push(raw.timestamps[i]);
    }
  }

  return peaks;
}

export function useRPeaks(scan: ImmutableScan | undefined) {
  return useImmutableScanBlob({
    scan,
    resultName: "bioamp_ecg_r_peaks",
    path: "data.json",
    parse,
  });
}
