import { APITypesV1 } from "@cur8/api-client";
import {
  AktiiaMonitorBloodPressureAssignmentData,
  Assignment,
} from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { PatientName } from "render/fragments/patient/PatientName/PatientName";
import { usePatient } from "render/hooks/api/usePatient";
import { useFormHandle } from "render/hooks/useFormHandle";
import { useUserInput } from "render/hooks/useUserInput";
import { paths } from "render/routes/paths";
import { Field } from "render/ui/form/Field";
import { Fieldset } from "render/ui/form/Fieldset";
import { Input } from "render/ui/form/Input";
import { RadioButtons } from "render/ui/form/RadioButtons";
import { Time } from "render/ui/format/Time";
import { FramedPage } from "render/ui/layouts/FramedPage/FramedPage";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { LineThrobber } from "render/ui/throbber/LineThrobber/LineThrobber";
import { BackButtonLink } from "render/ui/trigger/BackButtonLink";
import { ButtonSet } from "render/ui/trigger/ButtonSet";
import { SubmitButton } from "render/ui/trigger/SubmitButton";
import {
  Entries,
  createInitialAssignment,
  toCreateAssignment,
} from "./convert";
import { useQueryDeviceByAlias } from "./hooks/useQueryDeviceByAlias";
import styles from "./styles.module.sass";

const AssignmentType = "AktiiaMonitorBloodPressureAssignment";

const AssignmentTypes = [
  {
    value: AssignmentType,
    content: "Monitor blood pressure",
  },
];
const MeasuredArms = [
  {
    value: APITypesV1.WearableLocation.LeftWrist,
    content: "Left wrist",
  },
  {
    value: APITypesV1.WearableLocation.RightWrist,
    content: "Right wrist",
  },
];

interface AssignmentCreatePageProps {
  patientId: string;
}

export function AssignmentCreatePage({ patientId }: AssignmentCreatePageProps) {
  const nav = {
    assignment: useNav(paths.patient.assignment.bloodpressure),
    patient: useNav(paths.patient.detail),
  };
  const api = useAPIClient();

  const patient = usePatient(patientId);

  const [type, setType] = useState<string>(AssignmentType);

  const initialAssignment = useMemo(() => {
    const result = createInitialAssignment();
    result.patientId = patientId;

    return result;
  }, [patientId]);

  const [assignment, setAssignment] =
    useState<Assignment<AktiiaMonitorBloodPressureAssignmentData>>(
      initialAssignment
    );

  const setAssignmentData = useCallback(
    (data: Partial<AktiiaMonitorBloodPressureAssignmentData>) => {
      setAssignment((assignment) => {
        return {
          ...assignment,
          data: {
            ...assignment.data,
            ...data,
          },
        };
      });
    },
    []
  );

  const handleEntries = useCallback(
    (entries: Entries) => {
      setAssignmentData({ deviceSerialNumber: entries.serialNo });
      setEntries(entries);
    },
    [setAssignmentData]
  );

  const [entries, setEntries] = useState({
    serialNo: "",
    serialNoAlias: "",
  });

  const devices = useQueryDeviceByAlias(entries.serialNoAlias);

  useEffect(() => {
    if (devices.busy) {
      return;
    }
    const assignmentDevice = devices.matches?.find(
      (device) => device.alias === entries.serialNoAlias
    );

    handleEntries({
      serialNo: assignmentDevice?.serialNumber ?? "",
      serialNoAlias: entries.serialNoAlias,
    });
  }, [
    devices.busy,
    devices.matches,
    entries.serialNoAlias,
    setAssignmentData,
    handleEntries,
  ]);

  const userInput = useUserInput(entries, handleEntries);
  const isValid = useMemo(() => {
    return (
      assignment.data.wearableLocation != null &&
      assignment.data.deviceSerialNumber.length > 4
    );
  }, [assignment]);

  const handleSave = useCallback(async () => {
    const payload = toCreateAssignment(assignment);
    const result = await api.assignment.createAssignmentFor(
      assignment.patientId,
      payload
    ).result;

    nav.assignment.go({
      assignmentId: result.assignmentId,
      patientId: result.patientId,
    });
  }, [assignment, api, nav.assignment]);

  const formHandle = useFormHandle(handleSave);

  return (
    <FramedPage>
      <div className={styles.AssignmentCreatePage}>
        <BackButtonLink to={nav.patient.to({ patientId })}>
          {patient ? <PatientName patient={patient} /> : "Back"}
        </BackButtonLink>
        <PageHeader caption="Create assignment" />

        <form className={styles.form} onSubmit={formHandle.onSubmit}>
          <Fieldset legend="Assignment">
            <Field label="Assignment type">
              <RadioButtons
                required
                value={type}
                entries={AssignmentTypes}
                onChange={setType}
              />
            </Field>
            <Field label="Wear wristband on">
              <RadioButtons
                required
                value={assignment.data.wearableLocation}
                entries={MeasuredArms}
                onChange={(wearableLocation) =>
                  setAssignmentData({ wearableLocation })
                }
              />
            </Field>

            <Field label="Storage number">
              <Input>
                <input type="text" {...userInput.serialNoAlias} required />
              </Input>
              <small>
                Aktiia device storage number (3 digits) use it to look up the
                device faster
              </small>
              {devices.busy && <LineThrobber />}
            </Field>

            <Field label="Serial number">
              <Input>
                <input type="text" {...userInput.serialNo} required />
              </Input>
              <small>Aktiia device serial number</small>
            </Field>
          </Fieldset>

          <Field label="Return date">
            <div className={styles.returnDate}>
              Return Mon-Fri anytime between
              <Time date={assignment.data.returnAt} />-
              <Time date={assignment.data.returnAt.plus({ minutes: 30 })} />
            </div>
          </Field>

          <ButtonSet>
            <SubmitButton disabled={!isValid} handle={formHandle}>
              Create and send
            </SubmitButton>
          </ButtonSet>
        </form>
      </div>
    </FramedPage>
  );
}
