import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface FieldsetProps {
  legend?: ReactNode;
  children?: ReactNode;
  direction?: "column" | "row";
}

export function Fieldset({
  legend,
  children,
  direction = "column",
}: FieldsetProps) {
  return (
    <fieldset className={styles.Fieldset} data-dir={direction}>
      {legend && <legend>{legend}</legend>}

      {children}
    </fieldset>
  );
}
