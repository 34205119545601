import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface PageHeaderProps {
  caption: ReactNode;
  children?: ReactNode;
}

export function PageHeader({ caption, children }: PageHeaderProps) {
  return (
    <header className={styles.PageHeader}>
      <h2>{caption}</h2>

      <div>{children}</div>
    </header>
  );
}
