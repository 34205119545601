import { Slot, Visit, fromAPI } from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { silenceAbort } from "lib/error";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { PatientIdentifier } from "render/fragments/patient/PatientIdentifier";
import { usePatient } from "render/hooks/api/usePatient";
import { useQuestionnaire } from "render/hooks/api/useQuestionnaire";
import { PassedTime } from "render/ui/format/PassedTime";
import { FramedPage } from "render/ui/layouts/FramedPage/FramedPage";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import { Details } from "./components/Details";
import { DetailsV1 } from "./components/DetailsV1";
import { LLMBrief } from "./components/LLMBrief";
import { OnboardingQuestionnaire } from "./components/OnboardingQuestionnaire";
import { SlotDate } from "./fragments/SlotDate";
import { SlotTime } from "./fragments/SlotTime";
import { useCancelAppointmentPopup } from "./hooks/useCancelAppointmentPopup";
import styles from "./styles.module.sass";

interface Props {
  patientId: string;
  bookingSlotId: string;
}

function fetchVisit(
  apiClient: APIClient,
  pointer: { patientId: string; visitId: string }
) {
  const { patientId, visitId } = pointer;
  const req = apiClient.visit.fetchVisit({ patientId, visitId });

  return {
    abandon: req.abandon,
    result: req.result.then(fromAPI.toVisit),
  };
}

function fetchSlot(apiClient: APIClient, slotId: string) {
  const req = apiClient.bookingV2.getSlot({ slotId });

  return {
    abandon: req.abandon,
    result: req.result.then(fromAPI.toSlot),
  };
}

export function PatientAppointmentPage({ patientId, bookingSlotId }: Props) {
  const api = useAPIClient();
  const patient = usePatient(patientId);
  const [slot, setSlot] = useState<Slot>();
  const [visit, setVisit] = useState<Visit>();
  const visitId = bookingSlotId.includes("_")
    ? bookingSlotId.split("_")[1]
    : bookingSlotId;
  const { data: questionnaireResponse } = useQuestionnaire({
    patientId,
    // TODO: pass visitId once Atrium visits are created before the questionnaire is answered
    visitId: undefined,
  });
  const { emitDialog } = useCancelAppointmentPopup();

  const appointment = slot;

  useEffect(() => {
    if (!visitId) {
      return;
    }

    const visitReq = fetchVisit(api, {
      patientId,
      visitId,
    });

    visitReq.result.then(setVisit).catch(silenceAbort);

    return () => {
      visitReq.abandon();
    };
  }, [api, patientId, bookingSlotId, visitId]);

  useEffect(() => {
    const slotReq = fetchSlot(api, bookingSlotId);
    slotReq.result.then(setSlot).catch(silenceAbort);

    return () => {
      slotReq.abandon();
    };
  }, [api, bookingSlotId]);

  const handleCancelAppointment = useCallback(() => {
    if (appointment) {
      emitDialog(appointment);
    }
  }, [emitDialog, appointment]);

  return (
    <FramedPage>
      <div className={styles.PatientAppointmentDetails}>
        <div className={styles.pageHeader}>
          <div className={styles.checkIn}>
            <PageHeader caption="Appointment Details" />
            {visit && (
              <>
                <CheckinTime visit={visit} />
              </>
            )}
          </div>
          <HoverTextButton
            onClick={handleCancelAppointment}
            disabled={!appointment}
          >
            Cancel appointment
          </HoverTextButton>
        </div>
        <div className={styles.content}>
          <section className={styles.leftSection}>
            <div className={styles.header}>
              <h1 className={styles.patientName}>
                <PatientIdentifier patientId={patientId} patient={patient} />
              </h1>
              <div className={styles.date}>
                {appointment ? <SlotDate slot={appointment} /> : null}
              </div>
              <div>{appointment ? appointment.room?.site?.siteName : null}</div>
              <div className={styles.time}>
                {appointment ? <SlotTime slot={appointment} /> : null}
              </div>
            </div>

            {questionnaireResponse ? (
              <>
                <OnboardingQuestionnaire response={questionnaireResponse} />
                {patient ? (
                  <LLMBrief patient={patient} visitId={visitId} />
                ) : null}
              </>
            ) : null}
          </section>

          <section className={styles.rightSection}>
            {questionnaireResponse?.questionnaireType === "onboarding/1" ? (
              <DetailsV1 patient={patient} onboarding={questionnaireResponse} />
            ) : (
              <Details patient={patient} onboarding={questionnaireResponse} />
            )}
          </section>
        </div>
      </div>
    </FramedPage>
  );
}

function CheckinTime({ visit }: { visit: Visit }) {
  const timestamp = visit.checkinEndTimestamp || visit.checkinStartTimestamp;

  if (!timestamp) {
    return null;
  }

  return (
    <span className={styles.caption}>
      {visit.checkinEndTimestamp ? "Checked in" : "Check in started"}{" "}
      <span title={timestamp.toFormat("yyyy-LL-dd HH:mm:ss")}>
        <PassedTime date={timestamp} />
      </span>
    </span>
  );
}
