import { CSSProperties, PropsWithChildren } from "react";
import styles from "./styles.module.sass";

export type TypographyProps = {
  as?: VariantProps["tag"];
  variant:
    | "numeral-xl"
    | "numeral-l"
    | "numeral-m"
    | "numeral-s"
    | "display-s"
    | "title-m"
    | "title-s"
    | "body-m"
    | "body-s"
    | "body-xs"
    | "label-m"
    | "label-s"
    | "eyebrow-m";
  style?: Partial<CSSProperties>;
  className?: string;
};

const VARIANT_PROPS_MAP: Record<TypographyProps["variant"], VariantProps> = {
  "numeral-xl": {
    tag: "span",
    className: styles.numeralXL,
  },
  "numeral-l": {
    tag: "span",
    className: styles.numeralL,
  },
  "numeral-m": {
    tag: "span",
    className: styles.numeralM,
  },
  "numeral-s": {
    tag: "span",
    className: styles.numeralS,
  },
  "display-s": {
    tag: "h1",
    className: styles.displayS,
  },
  "title-m": {
    tag: "h2",
    className: styles.titleM,
  },
  "title-s": {
    tag: "h3",
    className: styles.titleS,
  },
  "body-m": {
    tag: "p",
    className: styles.bodyM,
  },
  "body-s": {
    tag: "p",
    className: styles.bodyS,
  },
  "body-xs": {
    tag: "p",
    className: styles.bodyXS,
  },
  "label-m": {
    tag: "p",
    className: styles.labelM,
  },
  "label-s": {
    tag: "p",
    className: styles.labelS,
  },
  "eyebrow-m": {
    tag: "p",
    className: styles.eyebrowM,
  },
};

type VariantProps = {
  className: string;
  tag: "h1" | "h2" | "h3" | "h4" | "p" | "span";
};

export function Typography({
  as,
  children,
  style = {},
  className = "",
  variant,
}: PropsWithChildren<TypographyProps>) {
  const { className: typoClassName, tag } = VARIANT_PROPS_MAP[variant];
  const Component = as ?? tag;

  return (
    <Component className={`${typoClassName} ${className}`} style={style}>
      {children}
    </Component>
  );
}
