import { Layer } from "../../../../types";
import { Avatar } from "./components/Avatar";
import styles from "./styles.module.sass";

interface AvatarSectionProps {
  layer: Layer;
}

export function AvatarSection({ layer }: AvatarSectionProps) {
  return (
    <div className={styles.AvatarSection}>
      <div className={styles.avatar}>
        <Avatar layer={layer} />
      </div>
    </div>
  );
}
