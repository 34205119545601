import { useNav } from "@pomle/react-router-paths";
import { PatientName } from "render/fragments/patient/PatientName";
import { usePatient } from "render/hooks/api/usePatient";
import { paths } from "render/routes/paths";
import { FramedPage } from "render/ui/layouts/FramedPage/FramedPage";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { BackButtonLink } from "render/ui/trigger/BackButtonLink";
import { AccountSection } from "./components/AccountSection";
import styles from "./styles.module.sass";

interface PatientEditPageProps {
  patientId: string;
}

export function PatientEditPage({ patientId }: PatientEditPageProps) {
  const patient = usePatient(patientId);

  const nav = {
    patient: useNav(paths.patient.detail),
  };

  return (
    <FramedPage>
      <div className={styles.PatientEditPage}>
        <BackButtonLink to={nav.patient.to({ patientId })}>
          {patient ? <PatientName patient={patient} /> : "Back"}
        </BackButtonLink>
        <PageHeader caption="Member Details" />

        <AccountSection patientId={patientId} />
      </div>
    </FramedPage>
  );
}
