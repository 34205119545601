import * as Risks from "@cur8/health-risks-calc";
import { Patient, Sex } from "@cur8/rich-entity";
import { useAge } from "render/hooks/patient/useAge";
import { usePatientHealthData } from "render/hooks/patient/usePatientHealthData";
import { PatientMetrics } from "render/hooks/patient/usePatientMetrics";
import { Table } from "render/ui/layouts/Table";
import { ResultRow } from "../ResultRow";

interface BodyTableProps {
  patient?: Patient;
  metrics: PatientMetrics;
  healthData: ReturnType<typeof usePatientHealthData>;
}

export function BodyTable({ patient, metrics, healthData }: BodyTableProps) {
  const body = metrics.body;
  const age = useAge(patient);
  const agg = healthData.aggregates;

  return (
    <Table>
      <thead>
        <tr>
          <th>Body</th>
          <th>Result</th>
          <th>Risk</th>
          <th>Units</th>
        </tr>
      </thead>
      <tbody>
        <ResultRow
          caption="Weight"
          unit="kg"
          value={body.weight?.at(0)?.unit.kilograms}
          format={(kg) => kg.toFixed(1)}
        />

        <ResultRow
          caption="Height"
          unit="cm"
          value={body.height?.at(0)?.unit.meters}
          format={(meters) => (meters * 100).toFixed(1)}
        />

        <ResultRow
          caption="BMI"
          value={agg.bmi?.score}
          format={(score) => score.toFixed(1)}
          summarize={(bmi) => {
            return {
              risk: Risks.BMI.rangesFor({ age: age ?? NaN }).findRisk({
                bmi,
              }),
            };
          }}
        />

        <ResultRow
          caption="Waist"
          unit="cm"
          value={body.waist?.at(0)?.unit.meters}
          format={(meters) => (meters * 100).toFixed(1)}
          summarize={(meters) => {
            return {
              risk: Risks.Waistline.rangesFor({
                sex: patient?.sex ?? Sex.Unknown,
              }).findRisk({
                cm: meters * 100,
              }),
            };
          }}
        />

        <ResultRow
          caption="Strength (L)"
          unit="kg"
          value={body.gripStrength.left?.at(0)?.unit.kilograms}
          format={(kg) => kg.toFixed(1)}
        />

        <ResultRow
          caption="Strength (R)"
          unit="kg"
          value={body.gripStrength.right?.at(0)?.unit.kilograms}
          format={(kg) => kg.toFixed(1)}
        />

        <ResultRow
          caption="Eye pressure (L)"
          unit="kg"
          value={body.eyePressure.left?.at(0)?.unit}
          format={(pressure) => pressure.mmHg.toFixed(1)}
          summarize={(unit) => {
            return {
              risk: Risks.EyePressure.rangesFor().findRisk(unit),
            };
          }}
        />
        <ResultRow
          caption="Eye pressure (R)"
          unit="kg"
          value={body.eyePressure.right?.at(0)?.unit}
          format={(pressure) => pressure.mmHg.toFixed(1)}
          summarize={(unit) => {
            return {
              risk: Risks.EyePressure.rangesFor().findRisk(unit),
            };
          }}
        />
      </tbody>
    </Table>
  );
}
