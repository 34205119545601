import { APITypesV1 } from "@cur8/api-client";
import { Sex } from "@cur8/rich-entity";
import { LVETISelection } from "./types";

/**
 * Calculate LVETI
 * @param range  Time range between peaks
 * @param rrInterval  The lenght of the RR-interval (seconds)
 * @param sex   Patient sex
 */
export function calculateLVETI(
  ssn: APITypesV1.Range,
  rrInterval: APITypesV1.Range,
  sex: Sex
): LVETISelection {
  const lvet = calculateTimeRangeToDistanceMS(ssn);
  const HR = 60 / (rrInterval.to - rrInterval.from);
  const lveti = Math.round(
    sex === Sex.Female ? 1.51 * HR + lvet : 1.38 * HR + lvet
  );
  return {
    hr: Math.round(HR),
    lvet: Math.round(lvet),
    lveti,
    ssn,
    rrInterval,
  };
}

/**
 * Calculate Pulse Wave Velocity
 * @param femoralDistance in cm
 * @param distanceMS
 * @returns Velocity in m/s with one decimal precision
 */
export function calculatePWV(femoralDistance: number, distanceMS: number) {
  return Math.round((femoralDistance / 100 / distanceMS) * 1000 * 10) / 10;
}

/**
 * Get distance (in ms) from a time range
 */
export function calculateTimeRangeToDistanceMS(range: APITypesV1.Range) {
  return Math.abs(range.to * 1000 - range.from * 1000);
}

/**
 * TODO: Replace with Risk Ranges
 */
export const LVETI_RANGE_NORMAL_FEMALE: APITypesV1.Range = {
  from: 361,
  to: 423,
  unit: "ms",
} as const;
export const LVETI_RANGE_NORMAL_MALE: APITypesV1.Range = {
  from: 340,
  to: 406,
  unit: "ms",
} as const;

export enum RangeLabel {
  below = "below",
  normal = "normal",
  above = "above",
}

/**
 * Delays over these threshold is abnormal
 */
export const MAX_ASYMMETRY_DELAY = 60; // ms
export const MAX_PULSEWAVEVELOCITY = 10; // m/s

/**
 * Check if LVETI is within normal ranges (or below/above)
 */
export function getLVETIrange(lveti: number, sex: Sex) {
  const range =
    sex === Sex.Female ? LVETI_RANGE_NORMAL_FEMALE : LVETI_RANGE_NORMAL_MALE;
  if (lveti < range.from) {
    return RangeLabel.below;
  } else if (lveti > range.to) {
    return RangeLabel.above;
  }
  return RangeLabel.normal;
}
