import { Country, getCountryFromVisit, getLatestVisit } from "lib/visit/visit";
import { useCallback, useMemo, useState } from "react";
import { useVisits } from "render/hooks/api/useVisits";
import Factory from "./assets/factory.png";
import MD from "./assets/MD.png";
import NEKO from "./assets/neko_logo_black.png";
import RTFM from "./assets/read_manual.png";
import UKCA from "./assets/UKCA.png";
import Warning from "./assets/warning.png";
import styles from "./styles.module.sass";

interface RegulatoryBoxProps {
  name: string;
  patientId: string;
}

export function RegulatoryBox({ name, patientId }: RegulatoryBoxProps) {
  const visits = useVisits(patientId);
  const country = useMemo(() => {
    if (!visits) {
      return undefined;
    }
    const latestVisit = getLatestVisit(visits);
    if (!latestVisit) {
      return Country.GB;
    }
    return getCountryFromVisit(latestVisit);
  }, [visits]);

  const [showBox, setShowBox] = useState<boolean>(false);
  const toggleBox = useCallback(() => {
    setShowBox((show) => !!!show);
  }, []);

  return (
    <div className={styles.RegulatoryContainer} data-show={showBox}>
      <div className={styles.infoBtn} onClick={toggleBox}>
        [About]
      </div>
      <div className={styles.ModalBg} onClick={toggleBox}>
        <div className={styles.mainbox}>
          <div className={styles.header}>
            {country === Country.GB && (
              <img src={MD} alt="Medical Device" className={styles.iconH120} />
            )}
            <div className={styles.title}>{name}</div>
          </div>
          {country === Country.GB && (
            <div className={styles.content}>
              <img src={Factory} alt="Factory" className={styles.iconH120} />
              <div className={styles.address}>
                Neko Health AB
                <br />
                Svärdvägen 19
                <br />
                182 33 Danderyd
                <br />
                Sweden
                <br />
              </div>
              <div className={styles.address}>
                UK Responsible Person
                <br />
                Neko Health UK Ltd
                <br />
                7 Savoy Court
                <br />
                London
                <br />
                United Kingdom
                <br />
                WC2R 0EX
                <br />
              </div>
              <img src={Warning} alt="Warning" className={styles.iconH90} />
              <img src={RTFM} alt="Manual" className={styles.iconH90} />
            </div>
          )}
          <div className={styles.footer}>
            {country === Country.GB ? (
              <img src={UKCA} alt="UKCA" className={styles.iconH90} />
            ) : (
              <div className={styles.iuo}>Investigational Use Only (IUO)</div>
            )}

            <img src={NEKO} alt="NEKO" className={styles.iconW200} />
          </div>
        </div>
      </div>
    </div>
  );
}
