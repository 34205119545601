import { APITypesV1 } from "@cur8/api-client";
import { SkinBookTracking } from "@cur8/rich-entity";
import { useMemo } from "react";

const STATUS_MAP: Record<APITypesV1.SkinBookStatus, string> = {
  [APITypesV1.SkinBookStatus.Booked]: "Booked",
  [APITypesV1.SkinBookStatus.Assessed]: "Assessed",
  [APITypesV1.SkinBookStatus.AssessedAndDermoscoped]:
    "Assessed and Dermoscoped",
  [APITypesV1.SkinBookStatus.BookDerma]: "Book (Derma)",
  [APITypesV1.SkinBookStatus.BookExcision]: "Book (Excision)",
  [APITypesV1.SkinBookStatus.BookFollowUp]: "Book (Follow-Up)",
  [APITypesV1.SkinBookStatus.CouldNotBook]: "Could Not Book",
  [APITypesV1.SkinBookStatus.NotAssessed]: "Not Assessed",
  [APITypesV1.SkinBookStatus.NotAssessedAndDermoscoped]:
    "Not Assessed and Dermoscoped",
  [APITypesV1.SkinBookStatus.ReferToAtrium]: "Refer to Atrium",
  [APITypesV1.SkinBookStatus.ReferToDermatologist]: "Refer to Dermatologist",
  [APITypesV1.SkinBookStatus.ReferralSent]: "Referral Sent",
  [APITypesV1.SkinBookStatus.ReferralSentDerma]: "Referral Sent (Derma)",
  [APITypesV1.SkinBookStatus.ReferralSentExcision]: "Referral Sent (Excision)",
};

interface SkinBookStatusProps {
  item: SkinBookTracking;
}

export function SkinBookStatus({ item }: SkinBookStatusProps) {
  const mappedStatus = useMemo(() => {
    return item.skinBookStatus ? STATUS_MAP[item.skinBookStatus] : "Unknown";
  }, [item.skinBookStatus]);

  return <span>{mappedStatus}</span>;
}
