import { Triglycerides } from "@cur8/health-risks-calc";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { MetricRange } from "render/ui/presentation/MetricRange";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";

interface TriglyceridesRangeProps {
  metrics: Metric<"bloodwork.triglycerides">[] | undefined;
}

export function TriglyceridesRange({ metrics }: TriglyceridesRangeProps) {
  const riskRange = useMemo(() => {
    return Triglycerides.rangesFor();
  }, []);

  const ranges = useMemo(() => {
    return toChartRanges(riskRange.entries);
  }, [riskRange]);

  const entries = useMemo(() => {
    if (!metrics) {
      return [];
    }

    return metrics.map((metric) => {
      return {
        risk: riskRange.findRisk(metric.unit),
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["mmol/L"],
      };
    });
  }, [riskRange, metrics]);

  return (
    <MetricRange
      title="Triglycerides"
      unit="mmol/L"
      entries={entries}
      ranges={ranges}
      formatValue={(value) => value?.toFixed(2)}
    />
  );
}
