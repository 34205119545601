import { Patient } from "@cur8/rich-entity";
import { BodyAreas } from "lib/smpl";
import { toCenter } from "lib/three";
import { useCallback, useMemo } from "react";
import { BloodOxygenMetric } from "render/fragments/avatar/overlay/BloodOxygenMetric";
import { HeartRateMetric } from "render/fragments/avatar/overlay/HeartRateMetric";
import { LeftAnklePressure } from "render/fragments/avatar/overlay/LeftAnklePressure";
import { LeftArmBloodPressure } from "render/fragments/avatar/overlay/LeftArmBloodPressure";
import { MaxAnklePressure } from "render/fragments/avatar/overlay/MaxAnklePressure";
import { MaxArmBloodpressure } from "render/fragments/avatar/overlay/MaxArmBloodpressure";
import { RightAnklePressure } from "render/fragments/avatar/overlay/RightAnklePressure";
import { RightArmBloodpressure } from "render/fragments/avatar/overlay/RightArmBloodpressure";
import { ScreenProjector } from "render/hooks/three/useProject";
import { useScreenAnchorsForObjects } from "render/hooks/three/useScreenAnchors";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import { Overlay } from "../../components/Overlay";
import styles from "./styles.module.sass";

const ACTIVE = new Set([Layer.Cardio, Layer.Circulation, Layer.ArterialHealth]);

interface CardioLayerProps {
  layer: Layer;
  patient: Patient;
  areas: BodyAreas;
  projector: ScreenProjector;
}

export function CardioLayer({
  layer,
  patient,
  areas,
  projector,
}: CardioLayerProps) {
  const {
    metrics: { cardio },
  } = usePatientData();

  const { ui } = useDashboardContext();

  const positions = useMemo(() => {
    const leftUpperArm = toCenter(areas.leftUpperArm);
    const leftAnkle = toCenter(areas.leftAnkle);
    const rightAnkle = toCenter(areas.rightAnkle);
    const rightUpperArm = toCenter(areas.rightUpperArm);
    const rightKnee = toCenter(areas.rightKnee);
    const leftKnee = toCenter(areas.leftKnee);

    leftAnkle.z += 100;
    rightAnkle.z += 100;
    rightKnee.x = -625;
    rightKnee.z += 150;
    leftKnee.x += 520;
    leftKnee.z += 170;

    const rightShin = rightKnee.clone();
    rightShin.z -= 380;

    return {
      leftUpperArm,
      leftAnkle,
      rightAnkle,
      rightUpperArm,
      rightKnee,
      leftKnee,
      rightShin,
    };
  }, [areas]);

  const active = ACTIVE.has(layer);

  const anchors = useScreenAnchorsForObjects(projector, positions, active);

  const onOverlayClick = useCallback(() => {
    if (layer === Layer.ArterialHealth) {
      ui.set({ layer: Layer.Circulation });
    }
  }, [ui, layer]);

  return (
    <div className={styles.CardioLayer}>
      {anchors && (
        <>
          {cardio.brachialPressure.left && cardio.brachialPressure.right && (
            <Overlay pos={anchors.leftUpperArm}>
              <div className={styles.item} data-active={layer === Layer.Cardio}>
                <MaxArmBloodpressure
                  patient={patient}
                  brachialPressure={cardio.brachialPressure}
                />
              </div>
            </Overlay>
          )}

          {cardio.anklePressure.left && cardio.anklePressure.right && (
            <Overlay pos={anchors.leftAnkle}>
              <div className={styles.item} data-active={layer === Layer.Cardio}>
                <MaxAnklePressure anklePressure={cardio.anklePressure} />
              </div>
            </Overlay>
          )}

          {cardio.brachialPressure.left && (
            <Overlay pos={anchors.leftUpperArm}>
              <div
                className={styles.item}
                data-active={
                  layer === Layer.ArterialHealth || layer === Layer.Circulation
                }
              >
                <button onClick={onOverlayClick}>
                  <LeftArmBloodPressure
                    patient={patient}
                    brachialPressure={cardio.brachialPressure.left}
                  />
                </button>
              </div>
            </Overlay>
          )}

          {cardio.brachialPressure.right && (
            <Overlay pos={anchors.rightUpperArm}>
              <div
                className={styles.item}
                data-active={
                  layer === Layer.ArterialHealth || layer === Layer.Circulation
                }
              >
                <button onClick={onOverlayClick}>
                  <RightArmBloodpressure
                    patient={patient}
                    brachialPressure={cardio.brachialPressure.right}
                  />
                </button>
              </div>
            </Overlay>
          )}

          {cardio.anklePressure.left && (
            <Overlay pos={anchors.leftAnkle}>
              <div
                className={styles.item}
                data-active={
                  layer === Layer.ArterialHealth || layer === Layer.Circulation
                }
              >
                <button onClick={onOverlayClick}>
                  <LeftAnklePressure
                    anklePressure={cardio.anklePressure.left}
                  />
                </button>
              </div>
            </Overlay>
          )}

          {cardio.anklePressure.right && (
            <Overlay pos={anchors.rightAnkle}>
              <div
                className={styles.item}
                data-active={
                  layer === Layer.ArterialHealth || layer === Layer.Circulation
                }
              >
                <button onClick={onOverlayClick}>
                  <RightAnklePressure
                    anklePressure={cardio.anklePressure.right}
                  />
                </button>
              </div>
            </Overlay>
          )}

          {!!cardio.heartRate && (
            <Overlay pos={anchors.rightKnee}>
              <div className={styles.item} data-active={layer === Layer.Cardio}>
                <HeartRateMetric heartRate={cardio.heartRate} />
              </div>
            </Overlay>
          )}

          {!!cardio.bloodOxygen && (
            <Overlay pos={anchors.rightShin}>
              <div className={styles.item} data-active={layer === Layer.Cardio}>
                <BloodOxygenMetric bloodOxygen={cardio.bloodOxygen} />
              </div>
            </Overlay>
          )}
        </>
      )}
    </div>
  );
}
