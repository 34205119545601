import { ReactNode, useRef } from "react";
import styles from "./styles.module.sass";

interface BadgedProps {
  content?: ReactNode;
  children: ReactNode;
}

export function Badged({ content, children }: BadgedProps) {
  const contentRef = useRef<ReactNode>(null);

  if (content) {
    contentRef.current = content;
  }
  return (
    <div className={styles.Badged}>
      <div
        className={styles.badge}
        style={{
          transform: `scale(${content ? 1 : 0})`,
        }}
      >
        {content}
      </div>
      {children}
    </div>
  );
}
