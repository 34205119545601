import { useCallback } from "react";
import { usePopupDialog } from "render/context/PopupDialogContext";
import { BookSkin, BookSkinProps } from "./BookSkin";

export function useBookSkinModal() {
  const { create } = usePopupDialog();

  const openBookSkinModal = useCallback(
    ({ onClose, ...rest }: BookSkinProps) => {
      const { emit, close } = create();

      const handleClose = () => {
        close();
        onClose();
      };

      emit(<BookSkin {...rest} onClose={handleClose} />);
    },
    [create]
  );

  return {
    openBookSkinModal,
  };
}
