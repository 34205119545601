import { useHistory } from "@pomle/react-router-paths";
import { useEffect } from "react";
import { paths } from "render/routes/paths";

export function LandingPage() {
  const history = useHistory();

  useEffect(() => {
    const url = paths.patient.overview.build({});
    history.replace(url);
  }, [history]);

  return <></>;
}
