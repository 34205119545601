import { MouseEvent, ReactNode, useState } from "react";
import styles from "./styles.module.sass";

interface BoundingBoxProps {
  children: ReactNode;
  color: string;
  onClick: (event: MouseEvent<Element>) => void;
  onSelectedEvent: (selected: boolean) => void;
  weak?: boolean;
  bold?: boolean;
}

export function BoundingBox({
  children,
  color,
  onClick,
  onSelectedEvent,
  bold,
  weak = false,
}: BoundingBoxProps) {
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <button
      className={styles.BoundingBox}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        outlineColor: color,
        outlineStyle: weak ? "dashed" : "solid",
        backgroundColor: isHover ? `${color}20` : `${color}00`,
        outlineWidth: bold ? 4 : 1,
        border: "none",
      }}
      onPointerDown={(event) => {
        event.stopPropagation();
        onSelectedEvent(true);
      }}
      onPointerUp={(event) => {
        event.stopPropagation();
        onSelectedEvent(false);
      }}
      onPointerCancel={(event) => {
        event.stopPropagation();
        onSelectedEvent(false);
      }}
      onPointerLeave={(event) => {
        event.stopPropagation();
        onSelectedEvent(false);
      }}
      onClick={(event) => {
        event.stopPropagation();
        onClick(event);
      }}
    >
      {children}
    </button>
  );
}
