import { APIAssessment, APITypesV1 } from "@cur8/api-client";
import { Assessment, fromAPI } from "@cur8/rich-entity";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "../useReporting";

function convertAndRemoveDuplicates(
  assessments: APIAssessment[]
): Assessment[] {
  const unique = new Set<string>();
  const filtered: Assessment[] = [];

  for (const assessment of assessments) {
    if (!unique.has(assessment.itemVersion)) {
      unique.add(assessment.itemVersion);
      filtered.push(fromAPI.toAssessment(assessment));
    }
  }

  return filtered;
}

export function usePatientAssessments(patientId: string) {
  const { handleError } = useReporting();

  const api = useAPIClient().assessment;
  const [assessments, setAssessments] = useState<Assessment[]>();
  const [unhandled, setUnhandled] = useState<Assessment[]>();

  const fetch = useCallback(() => {
    api
      .queryAssessments({
        patientId,
        isLatestVersion: true,
        order: APITypesV1.SortOrder.Desc,
        assessmentTypes: [
          "PadAssessmentItem",
          "PulseWaveAssessmentItem",
          "ThermalAssessmentItem",
        ],
      })
      .result.then((vs) => {
        const all = convertAndRemoveDuplicates(vs.items);
        setAssessments(all);
        setUnhandled(
          all.filter(
            (ass) => ass.assessmentState === APITypesV1.AssessmentState.New
          )
        );
      })
      .catch(handleError);
  }, [api, handleError, patientId]);

  useEffect(() => {
    if (!patientId) {
      return;
    }
    fetch();
  }, [fetch, patientId]);

  return {
    assessments,
    unhandled,
  };
}
