import { cycle } from "lib/algo";
import { useCallback } from "react";
import {
  EnlargeShrinkIcon,
  MagicWandIcon,
  SelectIcon,
} from "render/ui/symbol/HoverIcon";
import { FigureButton } from "render/ui/trigger/FigureButton";
import { LabeledFigure } from "render/ui/trigger/LabeledFigure";
import { Interaction, Layout } from "../../types";
import styles from "./styles.module.sass";

const INTERACTION_STATES: Interaction[] = [Interaction.Orient, Interaction.Tag];
const LAYOUT_STATES: Layout[] = [Layout.Fit, Layout.Max];

interface ControlsProps {
  layout: Layout;
  onLayoutChange: (layout: Layout) => void;
  interaction: Interaction;
  onInteractionChange: (interaction: Interaction) => void;
  showAIMagic: boolean;
  onAIMagicChange: (showAIMagic: boolean) => void;
}

export function Controls({
  layout,
  onLayoutChange,
  interaction,
  onInteractionChange,
  showAIMagic,
  onAIMagicChange,
}: ControlsProps) {
  const toggleInteraction = useCallback(() => {
    const nextInteraction = cycle(INTERACTION_STATES, interaction);
    onInteractionChange(nextInteraction);
  }, [interaction, onInteractionChange]);

  const toggleLayout = useCallback(() => {
    const nextLayout = cycle(LAYOUT_STATES, layout);
    onLayoutChange(nextLayout);
  }, [layout, onLayoutChange]);

  const toggleMagic = useCallback(() => {
    onAIMagicChange(!showAIMagic);
  }, [showAIMagic, onAIMagicChange]);

  return (
    <div className={styles.Controls}>
      <FigureButton onClickCapture={toggleInteraction}>
        <LabeledFigure label="Select">
          <SelectIcon active={interaction === Interaction.Tag} />
        </LabeledFigure>
      </FigureButton>

      <FigureButton onClickCapture={toggleLayout}>
        <LabeledFigure label={layout === Layout.Fit ? "Enlarge" : "Shrink"}>
          <EnlargeShrinkIcon active={layout === Layout.Fit} />
        </LabeledFigure>
      </FigureButton>

      <FigureButton onClickCapture={toggleMagic}>
        <LabeledFigure label="Magic">
          <MagicWandIcon active={showAIMagic} />
        </LabeledFigure>
      </FigureButton>
    </div>
  );
}
