import { Sticky } from "@pomle/react-viewstack";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layout, LayoutProps } from "../Layout";
import { SidePanel } from "../SidePanel";
import { ArterialHealthSection } from "./components/ArterialHealthSection";
import styles from "./styles.module.sass";

interface ArterialHealthLayoutProps extends LayoutProps {}

export function ArterialHealthLayout({ active }: ArterialHealthLayoutProps) {
  const { patient } = usePatientData();

  return (
    <Layout active={active}>
      <div className={styles.ArterialHealthLayout}>
        <div className={styles.arterialHealthPanel} data-active={active}>
          <Sticky delay={2000}>
            {active && (
              <SidePanel>
                {patient && <ArterialHealthSection patient={patient} />}
              </SidePanel>
            )}
          </Sticky>
        </div>
      </div>
    </Layout>
  );
}
