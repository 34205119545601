import { APITypesV1 } from "@cur8/api-client";
import { fromAPI, ScheduleViewSlot, VisitSummary } from "@cur8/rich-entity";
import { codecs, createQuery } from "@pomle/paths";
import { useNav, useQueryParams } from "@pomle/react-router-paths";
import { APIClient } from "lib/api/client";
import { DateTime, Interval } from "luxon";
import { useCallback, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { paths } from "render/routes/paths";
import { Timestamp } from "render/ui/format/Timestamp";
import { FramedPage } from "render/ui/layouts/FramedPage/FramedPage";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { Table } from "render/ui/layouts/Table";
import { LineThrobber } from "render/ui/throbber/LineThrobber";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { fetchRoomWithScheduleViewSlot } from "../SchedulePage/components/Calendar/hooks/useBookingSchedule";
import styles from "./styles.module.sass";

async function fetchSlots(
  apiClient: APIClient,
  {
    from,
    to,
  }: {
    from: string;
    to: string;
  }
) {
  const intervals = Interval.fromDateTimes(
    DateTime.fromISO(from).startOf("day"),
    DateTime.fromISO(to).endOf("day")
  ).splitBy({ day: 1 });

  const sites = await apiClient.sites
    .querySites()
    .result.then((result) => result.items.map(fromAPI.toSite));
  const slots: ScheduleViewSlot[] = [];

  for (let site of sites) {
    for (let interval of intervals) {
      const start = interval.start;
      const end = interval.end;
      const response = await fetchRoomWithScheduleViewSlot(
        apiClient,
        site.siteId,
        {
          start,
          end,
        }
      );
      response.forEach((room) => {
        if (room.slots) {
          slots.push(...room.slots);
        }
      });
    }
  }
  return slots;
}

function fetchVisitSummariesForPatient(
  apiClient: APIClient,
  patientId: string
) {
  return apiClient.visitSummary
    .querySummary(
      { patientId },
      { order: APITypesV1.SortOrder.Desc, pageSize: 1 }
    )
    .result.then((result) => result.items.map(fromAPI.toVisitSummary));
}

interface Data {
  lastVisit: ScheduleViewSlot;
  lastSummary?: VisitSummary;
  patientId: string;
  status: "requires-follow-up" | "requires-summary";
}

async function fetchData(
  apiClient: APIClient,
  { from, to }: { from: string; to: string }
) {
  const slots = await fetchSlots(apiClient, { from, to });
  console.log("slots", slots);

  const patientIds = Array.from(
    slots.reduce((acc, prev) => {
      if (prev.patientId) {
        acc.add(prev.patientId);
      }
      return acc;
    }, new Set<string>())
  );

  const slotsWithMissingSummaries: Data[] = [];

  for (let patientId of patientIds) {
    const summaries = await fetchVisitSummariesForPatient(apiClient, patientId);
    const summary = summaries.at(0);
    const lastPatientSlot = slots.findLast(
      (slot) => slot.patientId === patientId
    );

    if (lastPatientSlot) {
      if (!summary) {
        slotsWithMissingSummaries.push({
          lastVisit: lastPatientSlot,
          patientId,
          status: "requires-summary",
        });
        continue;
      }

      if (summary?.audit.created?.timeStamp == null) {
        throw new Error("Audit no time stamp");
      }

      const visitDoneForSlot =
        lastPatientSlot.startTime <= summary?.audit.created?.timeStamp;

      if (!visitDoneForSlot) {
        slotsWithMissingSummaries.push({
          lastVisit: lastPatientSlot,
          patientId,
          lastSummary: summary,
          status: "requires-follow-up",
        });
      }
    }
  }

  return slotsWithMissingSummaries;
}

const query = createQuery({
  from: codecs.string,
  to: codecs.string,
});

export function LindaToolsPage() {
  const [params, setParams] = useQueryParams(query);
  const from =
    params.from.at(0) ??
    DateTime.now().minus({ month: 1 }).startOf("month").toISODate();
  const to = params.to.at(0) ?? DateTime.now().toISODate();

  const apiClient = useAPIClient();
  const nav = {
    slot: useNav(paths.patient.appointment.view),
    patient: useNav(paths.patient.detail),
  };
  const fetchDataHandler = useAsyncHandle(
    useCallback(() => {
      setSlotsWithMissingSummaries(undefined);
      return fetchData(apiClient, { from, to })
        .then(setSlotsWithMissingSummaries)
        .catch(() => setError(new Error("Ups I broke")));
    }, [apiClient, from, to])
  );
  const [error, setError] = useState<Error>();
  const [slotsWithMissingSummaries, setSlotsWithMissingSummaries] =
    useState<Data[]>();

  const loading = fetchDataHandler.busy && !error;

  return (
    <FramedPage>
      <PageHeader caption="Linda Stuff" />

      <div className={styles.controls}>
        <label>
          From
          <input
            disabled={loading}
            type="date"
            value={from}
            onChange={(event) => {
              setParams({ from: [event.target.value] });
            }}
          />
        </label>
        <label>
          To
          <input
            disabled={loading}
            type="date"
            value={to}
            onChange={(event) => {
              setParams({ to: [event.target.value] });
            }}
          />
        </label>
        <ActionButton handle={fetchDataHandler}>Submit</ActionButton>
      </div>

      {loading && (
        <>
          <div>
            I am en expensive operation, It will take a while to process
          </div>
          <LineThrobber />
        </>
      )}

      {slotsWithMissingSummaries && (
        <Table>
          <thead>
            <tr>
              <th>Visit Date</th>
              <th>Patient</th>
              <th>Summary Status</th>
            </tr>
          </thead>
          <tbody>
            {slotsWithMissingSummaries
              ?.toSorted((a, b) =>
                a.lastVisit.startTime < b.lastVisit.startTime ? 1 : -1
              )
              .map(({ lastVisit, patientId, lastSummary, status }) => {
                return (
                  <tr key={lastVisit.id}>
                    <td>
                      <a
                        target="_blank"
                        href={nav.slot.to({
                          patientId: patientId,
                          bookingSlotId: lastVisit.id,
                        })}
                        rel="noreferrer"
                      >
                        <Timestamp date={lastVisit.startTime} />
                      </a>
                    </td>
                    <td>
                      <a
                        target="_blank"
                        href={nav.patient.to({
                          patientId: patientId,
                        })}
                        rel="noreferrer"
                      >
                        {patientId}
                      </a>
                    </td>
                    <td>
                      {status === "requires-follow-up" && (
                        <>Requires follow up</>
                      )}
                      {status === "requires-summary" && <>Requires summary</>}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      )}
    </FramedPage>
  );
}
