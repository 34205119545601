import styles from "./TypingIndicator.module.sass";

export function TypingIndicator() {
  return (
    <div className={styles.TypingIndicator}>
      <div className={styles.container}>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
      </div>
    </div>
  );
}
