import { IconProps } from ".";
import styles from "./styles.module.sass";

export function SelectIcon({ active, color }: IconProps) {
  return (
    <svg
      className={styles.Common}
      data-active={active ? "yes" : "no"}
      data-color={color}
      fill="none"
      height="40"
      width="40"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="19" />
      <path d="M23.43 20a.38.38 0 0 1-.38.38h-2.67v2.67a.38.38 0 0 1-.76 0v-2.67h-2.67a.38.38 0 0 1 0-.76h2.67v-2.67a.38.38 0 0 1 .76 0v2.67h2.67a.38.38 0 0 1 .38.38Zm-11.05-3.43a.38.38 0 0 0 .38-.38v-3.05a.38.38 0 0 1 .38-.38h3.05a.38.38 0 1 0 0-.76h-3.05A1.14 1.14 0 0 0 12 13.14v3.05a.38.38 0 0 0 .38.38ZM26.86 12H23.8a.38.38 0 1 0 0 .76h3.05a.38.38 0 0 1 .38.38v3.05a.38.38 0 1 0 .76 0v-3.05A1.14 1.14 0 0 0 26.86 12Zm.76 11.43a.38.38 0 0 0-.38.38v3.05a.38.38 0 0 1-.38.38H23.8a.38.38 0 1 0 0 .76h3.05A1.14 1.14 0 0 0 28 26.86V23.8a.38.38 0 0 0-.38-.38Zm-11.43 3.8h-3.05a.38.38 0 0 1-.38-.37V23.8a.38.38 0 1 0-.76 0v3.05A1.14 1.14 0 0 0 13.14 28h3.05a.38.38 0 1 0 0-.76Z" />
    </svg>
  );
}
