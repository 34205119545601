import { Patient } from "@cur8/rich-entity";
import { useAge } from "render/hooks/patient/useAge";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

interface PatientIdentityProps {
  patient?: Patient;
}

export function PatientIdentity({ patient }: PatientIdentityProps) {
  const age = useAge(patient);

  return (
    <div className={styles.PatientIdentity}>
      <div className={styles.name} data-ready={!!patient}>
        <Typography variant="display-s">
          {patient?.name?.displayName ?? "--"}
        </Typography>
      </div>

      <div className={styles.age} data-ready={!!age}>
        <Typography variant="body-m">{age} years</Typography>
      </div>
    </div>
  );
}
