import { toPolyline } from "lib/svg";
import { SVGAttributes, useMemo } from "react";
import { useRangeContext } from "../../../../../context/RangeContext";
import { GraphData } from "../../../../../lib/types";
import { findTimeIndexes } from "../../../../../lib/utils";
import { Grid } from "./components/Grid";
import { PlotLegend } from "./components/PlotLegend";
import styles from "./styles.module.sass";

function span(frac: number) {
  const lines: number[] = [];
  for (let i = 0; i < 1 + frac; i += frac) {
    lines.push(i);
  }
  return lines;
}

interface DataPlotProps extends Omit<SVGAttributes<unknown>, "points"> {
  data: GraphData[];
  grid?: {
    x: number;
  };
}

export function DataPlot({ data, grid, ...props }: DataPlotProps) {
  const { offset, windowLength, windowRange } = useRangeContext();

  const paths = useMemo(() => {
    let scaledArray: string[] = [];
    for (const entry of data) {
      const idxs = findTimeIndexes(entry.signal.timestamps, windowRange);
      const times = entry.signal.timestamps.slice(idxs.from, idxs.to);
      const points = entry.signal.signal.slice(idxs.from, idxs.to);
      const mx = Math.max(...points);
      const mn = Math.min(...points);
      const margin = 2 / 15;
      const scaled = points
        .map((v, index) => {
          return {
            x: ((times[index] - windowRange.from) / windowLength) * 100,
            y: (margin + ((1 - 2 * margin) * (v - mn)) / (mx - mn)) * 100,
          };
        })
        .filter((point) => !isNaN(point.x) && !isNaN(point.y));
      scaledArray = [...scaledArray, toPolyline(scaled)];
    }

    return scaledArray;
  }, [data, windowLength, windowRange]);

  const lines = useMemo(() => {
    if (!grid) {
      return {
        x: [],
        y: [],
      };
    }

    const time = {
      start: windowRange.from - offset,
      end: windowRange.to - offset,
    };
    const lines: number[] = [];

    for (let pos = time.start; pos < time.end; pos += grid.x) {
      lines.push((pos - time.start) / windowLength);
    }

    return { x: lines, y: span(1 / 15) }; // the span argument controls the visible grid size along the y-axis
  }, [grid, windowRange, offset, windowLength]);

  const labels = useMemo(() => {
    if (!grid) {
      return {
        x: [],
      };
    }

    const time = {
      start: windowRange.from - offset,
      end: windowRange.to - offset,
    };

    let gap = grid.x;
    while (windowLength / gap > 20) {
      gap *= 2;
    }

    const linesX: { pos: number; text: string }[] = [];
    for (let pos = time.start; pos < time.end; pos += gap) {
      linesX.push({
        pos: (pos - time.start) / windowLength,
        text: pos.toFixed(2),
      });
    }

    return {
      x: linesX,
    };
  }, [grid, windowRange, offset, windowLength]);

  return (
    <div className={styles.DataPlot}>
      <div className={styles.plot}>
        <div />
        <div className={styles.time}>
          {labels.x.map((label) => {
            return (
              <div
                key={label.pos}
                className={styles.label}
                style={{ left: `${label.pos * 100}%` }}
              >
                {label.text}
              </div>
            );
          })}
        </div>
        <div />
        <svg viewBox="0 0 100 100" preserveAspectRatio="none" {...props}>
          <Grid lines={lines} height="100%" width="100%" />
          {paths.map((path, index) => {
            return (
              <polyline
                vectorEffect="non-scaling-stroke"
                points={path}
                fill="none"
                stroke={data[index].color}
                key={index}
              />
            );
          })}
        </svg>
      </div>
      <PlotLegend data={data} />
    </div>
  );
}
