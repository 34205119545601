import { toPolyline } from "lib/svg";
import { SVGAttributes, useMemo } from "react";
import { Grid } from "../Grid";
import { PlotData } from "../types";
import { PlotLegend } from "./components/PlotLegend";
import styles from "./styles.module.sass";

function span(frac: number) {
  const lines: number[] = [];
  for (let i = 0; i < 1 + frac; i += frac) {
    lines.push(i);
  }
  return lines;
}

interface DataPlotProps extends Omit<SVGAttributes<unknown>, "points"> {
  data: PlotData[];
  grid?: {
    x: number;
  };
  lengthMs: number;
  range: [number, number];
}

export function DataPlot({
  data,
  range,
  grid,
  lengthMs: length,
  ...props
}: DataPlotProps) {
  const start = Math.min(...range);
  const end = Math.max(...range);

  const paths = useMemo(() => {
    let scaledArray: string[] = [];
    for (const entry of data) {
      const points = entry.signal.normalized.slice(
        entry.signal.normalized.length * start,
        entry.signal.normalized.length * end
      );
      const mx = Math.max(...points);
      const mn = Math.min(...points);
      const margin = 2 / 15;
      const scaled = points.map((v, index, slice) => {
        return {
          x: (index / slice.length) * 100,
          y: (margin + ((1 - 2 * margin) * (v - mn)) / (mx - mn)) * 100,
        };
      });
      scaledArray = [...scaledArray, toPolyline(scaled)];
    }

    return scaledArray;
  }, [data, start, end]);

  const lines = useMemo(() => {
    if (!grid) {
      return {
        x: [],
        y: [],
      };
    }

    const time = {
      start: length * start,
      end: length * end,
    };

    const timeLen = time.end - time.start;

    const lines: number[] = [];
    for (let pos = time.start; pos < time.end; pos += grid.x) {
      lines.push((pos - time.start) / timeLen);
    }

    return { x: lines, y: span(1 / 15) }; // the span argument controls the visible grid size along the y-axis
  }, [start, end, grid, length]);

  const labels = useMemo(() => {
    if (!grid) {
      return {
        x: [],
      };
    }

    const time = {
      start: length * start,
      end: length * end,
    };

    const timeLen = time.end - time.start;

    let gap = grid.x;
    while (timeLen / gap > 20) {
      gap *= 2;
    }

    const linesX: { pos: number; text: string }[] = [];
    for (let pos = time.start; pos < time.end; pos += gap) {
      linesX.push({
        pos: (pos - time.start) / timeLen,
        text: (pos / 1000).toFixed(2), // Division by 1000 to convert to seconds
      });
    }

    return {
      x: linesX,
    };
  }, [start, end, grid, length]);

  return (
    <div className={styles.DataPlot}>
      <div className={styles.plot}>
        <div />
        <div className={styles.time}>
          {labels.x.map((label) => {
            return (
              <div
                key={label.pos}
                className={styles.label}
                style={{ left: `${label.pos * 100}%` }}
              >
                {label.text}
              </div>
            );
          })}
        </div>
        <div />
        <svg viewBox="0 0 100 100" preserveAspectRatio="none" {...props}>
          <Grid lines={lines} height="100%" width="100%" />
          {paths.map((path, index) => {
            return (
              <polyline
                vectorEffect="non-scaling-stroke"
                points={path}
                fill="none"
                stroke={data[index].color}
                key={index}
              />
            );
          })}
        </svg>
      </div>
      <PlotLegend data={data} />
    </div>
  );
}
