import {
  Assignment,
  isAktiiaMonitorBloodPressureAssignment,
} from "@cur8/rich-entity";
import { Table } from "render/ui/layouts/Table";
import { AktiiaAssignmentTableRow } from "./components/AktiiaAssignmentTableRow";

interface AssignmentsTableProps {
  assignments: Assignment[];
}

export function AssignmentsTable({ assignments }: AssignmentsTableProps) {
  return (
    <Table>
      <thead>
        <tr>
          <th />
          <th>Examination</th>
          <th>Type</th>
          <th>Date</th>
          <th>Status</th>
          <th>Device number</th>
          <th>Assigned to</th>
          <th>Action</th>
        </tr>
      </thead>

      <tbody>
        {assignments.map((assignment) => {
          const key = assignment.id;
          if (isAktiiaMonitorBloodPressureAssignment(assignment)) {
            return (
              <AktiiaAssignmentTableRow assignment={assignment} key={key} />
            );
          }
          return null;
        })}
      </tbody>
    </Table>
  );
}
