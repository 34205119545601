import { Patient, PulseWaveAssessment } from "@cur8/rich-entity";
import { Echo1 } from "render/fragments/regulatory/Echo-1";
import { useImmutableScan } from "render/hooks/api/useImmutableScan";
import { AssessmentSidebox } from "./components/AssessmentSidebox";
import { useCardioSignalsTimestamps } from "./hooks/useCardioSignalsTimestamps";
import { RangeHandler } from "./RangeHandler";
import styles from "./styles.module.sass";

interface PulseWavePageProps {
  patient: Patient;
  assessment: PulseWaveAssessment;
}

export function PulseWavePage({ patient, assessment }: PulseWavePageProps) {
  const scan = useImmutableScan(
    patient.patientId,
    assessment.scanId,
    assessment.scanVersion
  );
  const signals = useCardioSignalsTimestamps(scan);

  return (
    <div className={styles.PulseWavePage}>
      <AssessmentSidebox sex={patient.sex} />
      <div className={styles.plotArea}>
        {signals.loading ? (
          <div className={styles.loading}>
            <h1>Loading cardio signals...</h1>
          </div>
        ) : (
          <div className={styles.plotComponent}>
            <RangeHandler
              key={
                assessment.itemVersion
              } /* Needed to trigger re-instantiation when version changes */
              assessment={assessment}
              patient={patient}
              signals={signals}
            />
          </div>
        )}
      </div>
      <Echo1 patientId={patient.patientId} />
    </div>
  );
}
