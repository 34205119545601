import { ButtonHTMLAttributes } from "react";
import classes from "./styles.module.sass";

export function TextButton({ children, ...props }: ButtonHTMLAttributes<any>) {
  return (
    <button className={classes.TextButton} type="button" {...props}>
      {children}
    </button>
  );
}
