import { APITypesV1 } from "@cur8/api-client";
import { DateTime } from "luxon";
import { ChangeEvent, useCallback, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { useReporting } from "render/hooks/useReporting";
import { Input } from "render/ui/form/Input";
import { PopupDialog } from "render/ui/layouts/PopupDialog";
import CalendarAdd from "render/ui/trigger/DropdownMenuButton/components/DropdownItem/assets/calendar-add.svg?react";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import { IconButton } from "render/ui/trigger/IconButton";
import styles from "./styles.module.sass";

export interface BookSkinProps {
  onClose: () => void;
  patientName: string;
  patientId: string;
  scanId: string;
  isRebook?: boolean;
}

export function BookSkin({
  patientId,
  patientName,
  scanId,
  onClose,
  isRebook = false,
}: BookSkinProps) {
  const api = useAPIClient();
  const { handleError } = useReporting();
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleConfirm = useAsyncHandle(
    useCallback(() => {
      const slotTime = DateTime.fromISO(date + "T" + time);

      return api.patient
        .sendSkinBookAppointment({
          appointmentStartTime: slotTime.toISO(),
          patientId,
          scanId,
          status: isRebook
            ? APITypesV1.SkinAppointmentStatus.Rebooked
            : APITypesV1.SkinAppointmentStatus.Completed,
        })
        .result.then(onClose)
        .catch(handleError);
    }, [
      api.patient,
      date,
      handleError,
      isRebook,
      onClose,
      patientId,
      scanId,
      time,
    ])
  );

  const handleTimeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value);
  }, []);

  const handleDateChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value);
  }, []);

  const isSubmitBlocked =
    handleConfirm.busy ||
    !time ||
    time.trim().length === 0 ||
    !date ||
    date.trim().length === 0;

  return (
    <PopupDialog>
      <div className={styles.BookSkin}>
        <h2>{isRebook ? "Rebook" : "Book"} Derma</h2>
        <div>
          {patientName} ({patientId})
        </div>
        <div className={styles.form}>
          <Input>
            <input
              type="time"
              onChange={handleTimeChange}
              disabled={handleConfirm.busy}
            />
          </Input>
          <Input>
            <input
              type="date"
              onChange={handleDateChange}
              disabled={handleConfirm.busy}
            />
          </Input>
        </div>
        <div className={styles.footer}>
          <HoverTextButton onClick={handleCancel}>Cancel</HoverTextButton>
          <IconButton
            label="Book member"
            variant="primary"
            disabled={isSubmitBlocked}
            onClick={handleConfirm.callback}
          >
            <CalendarAdd />
          </IconButton>
        </div>
      </div>
    </PopupDialog>
  );
}
