import { useContext } from "react";
import { Context } from "./AssessmentContext";

export function useAssessmentContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useAssessmentContext without AssessmentContext");
  }
  return context;
}
