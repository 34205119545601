import { ReactNode } from "react";
import * as THREE from "three";
import styles from "./styles.module.sass";

interface OverlayProps {
  children: ReactNode;
  pos: THREE.Vector3;
  zIndex?: number;
}

export function Overlay({ children, pos, zIndex = 0 }: OverlayProps) {
  return (
    <div
      className={styles.Overlay}
      style={{
        transform: `translate(${pos.x}px, ${pos.y}px)`,
        zIndex,
      }}
    >
      <div className={styles.content}>{children}</div>
    </div>
  );
}
