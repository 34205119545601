import { Risk, Value } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { mapLinear } from "lib/math";
import { BloodPressureProjection } from "lib/projections";
import { useCallback, useEffect, useMemo } from "react";
import { useAppInsights } from "render/context/AppInsightsContext";
import { useAge } from "render/hooks/patient/useAge";
import {
  AuxTitle,
  MainTitle,
  MetricResultHeader,
  Subtitle,
  Titles,
  Unit,
} from "render/ui/presentation/MetricResultHeader";
import { Description } from "render/ui/presentation/MetricResultHeader/MetricResultHeader";
import {
  AxisLabel,
  ProjectionGraph,
  type ProjectionGraphRange,
} from "render/ui/presentation/ProjectionGraph/ProjectionGraph";
import styles from "./styles.module.sass";

type Group = (typeof BloodPressureProjection)["groups"][number];

const GroupLabelLookup: Record<Group, string> = {
  "1": "Optimal",
  "2": "Normal",
  "3": "High",
  "4": "Very High",
};

const DEFAULT_LABELS: AxisLabel[] = [
  { value: 30, label: "30" },
  { value: 40, label: "40" },
  { value: 50, label: "50" },
  { value: 60, label: "60" },
  { value: 70, label: "70" },
  { value: 80, label: "Age 80" },
];

const DEFAULT_RANGES: ProjectionGraphRange[] = [
  { from: 100, to: 120, labelLeft: "100" },
  { from: 120, to: 140, labelLeft: "120" },
  {
    from: 140,
    to: 160,
    labelLeft: "140",
    highlight: "warning",
  },
  {
    from: 160,
    to: 180,
    labelLeft: "160",
    highlight: "warning",
  },
];

const xDomain = [
  Math.min(...DEFAULT_LABELS.map((label) => label.value)),
  Math.max(...DEFAULT_LABELS.map((label) => label.value)),
] as [number, number];

const yDomain = [
  Math.min(...DEFAULT_RANGES.map((range) => range.from)),
  Math.max(...DEFAULT_RANGES.map((range) => range.to)),
] as [number, number];

interface BloodPressureProjectionGraphProps {
  patient: Patient;
  currentValue: Value<"mmHg">["mmHg"];
}

export function BloodPressureProjectionGraph({
  patient,
  currentValue,
}: BloodPressureProjectionGraphProps) {
  const patientAge = useAge(patient);
  const patientId = patient.patientId;
  const appInsights = useAppInsights();

  const projectionResult = useMemo(
    () =>
      BloodPressureProjection.project({
        age: patientAge,
        systolicBloodPressure: { mmHg: currentValue },
      }),
    [patientAge, currentValue]
  );

  useEffect(() => {
    appInsights.trackEvent({
      name: "doc-ui-dashboard-projections",
      properties: {
        patientId,
        type: "blood-pressure",
        params: {
          age: patientAge,
          systolicBloodPressure: { mmHg: currentValue },
        },
        result: projectionResult,
      },
    });
  }, [appInsights, projectionResult, patientId, patientAge, currentValue]);

  const projectionLines = useMemo(() => {
    function getDataPointsForGroup(group: Group) {
      return BloodPressureProjection.data.map((d) => ({
        x: d.ageBin.from,
        y: d[group],
      }));
    }

    const indexOfGroupMatchingProjectedRisk =
      BloodPressureProjection.groups.findIndex(
        (group) =>
          BloodPressureProjection.GroupToRiskMap[group] ===
          projectionResult.risk
      );

    if (indexOfGroupMatchingProjectedRisk === -1) {
      return [];
    }

    // When picking projection lines to display,
    // we want to show 3 lines, centered around the projected risk group
    const numOfLines = 3;
    let start = indexOfGroupMatchingProjectedRisk - 1;
    let end = start + numOfLines;

    if (end > BloodPressureProjection.groups.length) {
      end = BloodPressureProjection.groups.length;
      start = Math.max(0, end - numOfLines);
    }
    if (start < 0) {
      start = 0;
      end = Math.min(start + numOfLines, BloodPressureProjection.groups.length);
    }

    const lines = BloodPressureProjection.groups
      .slice(start, end)
      .map((group) => ({
        risk: BloodPressureProjection.GroupToRiskMap[group],
        label: GroupLabelLookup[group],
        points: getDataPointsForGroup(group),
      }));

    return lines;
  }, [projectionResult.risk]);

  const timelineSeek = mapLinear(patientAge, xDomain[0], xDomain[1], 0, 1, {
    clamp: true,
  });

  const getProjectionLineHighlight = useCallback(
    (lineRisk: Risk) => {
      if (lineRisk === projectionResult.risk) {
        return lineRisk >= Risk.HighRisk ? "warning" : "normal";
      } else {
        return "none";
      }
    },
    [projectionResult.risk]
  );

  const getProjectionLineStartLabel = useCallback(
    (lineRisk: Risk) => {
      if (lineRisk === projectionResult.risk) {
        return "You are here";
      } else {
        return "";
      }
    },
    [projectionResult.risk]
  );

  return (
    <div className={styles.BloodPressureProjectionGraph}>
      <MetricResultHeader>
        <Titles>
          <MainTitle>Projected</MainTitle>
          <Subtitle>blood pressure</Subtitle>
          <AuxTitle>[Systolic]</AuxTitle>
        </Titles>
        <Unit>mmHg</Unit>
      </MetricResultHeader>
      <Description>Based on a long-running blood pressure study*</Description>
      <div className={styles.graph}>
        <ProjectionGraph
          xDomain={xDomain}
          yDomain={yDomain}
          xLabels={DEFAULT_LABELS}
          yRanges={DEFAULT_RANGES}
          timelineSeek={timelineSeek}
        >
          {/** Group lines */}
          {projectionLines.map((line) => {
            return (
              <ProjectionGraph.Line
                key={line.risk}
                points={line.points}
                highlight={getProjectionLineHighlight(line.risk)}
                startLabel={getProjectionLineStartLabel(line.risk)}
                endLabel={line.label}
              />
            );
          })}

          {projectionResult.riskThreshold && (
            <ProjectionGraph.RiskThresholdMarker
              age={projectionResult.riskThreshold.age}
              threshold={projectionResult.riskThreshold?.value?.mmHg}
            />
          )}
        </ProjectionGraph>
      </div>
    </div>
  );
}
