import { Patient } from "@cur8/rich-entity";
import { PatientName } from "render/fragments/patient/PatientName";
import CrossIcon from "./assets/cross-icon.svg?react";
import styles from "./styles.module.sass";

interface Props {
  patient?: Patient;
  onClose?: () => void;
}

export function AssigningPatient({ patient, onClose }: Props) {
  return (
    <div className={styles.AssigningPatient}>
      Book an appointment for{" "}
      <strong>{patient ? <PatientName patient={patient} /> : null}</strong>
      {onClose && (
        <button className={styles.button} onClick={onClose}>
          <CrossIcon />
        </button>
      )}
    </div>
  );
}
