import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAPIClient } from "render/context/APIContext";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import styles from "./Chat.module.sass";
import CogIcon from "./assets/cog.svg?react";
import { Bubble } from "./components/Bubble";
import { SettingsDialog } from "./components/SettingsDialog";
import { TypingIndicator } from "./components/TypingIndicator";
import { prePrompts } from "./prePrompts";
import { Language, Message, Model, Settings } from "./types";

import {
  firstInstruction,
  firstPrompt,
  repeatInstruction,
  tools,
} from "../../prompts";

interface ChatProps {
  currentSummaryText: string;
  onSummaryTextUpdated: (newSummary: string) => void;
  active: boolean;
  onReset: () => void;
}

type ToolResponse = {
  summary: string;
  comment: string;
};

export function Chat({
  currentSummaryText,
  onSummaryTextUpdated,
  active,
  onReset,
}: ChatProps) {
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [waiting, setWaiting] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [settings, setSettings] = useState<Settings>({
    language: Language.English,
    model: Model.GPT35Turbo16k,
    temp: 0.21,
  });

  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { OAIChat } = useAPIClient().nlp;

  const addUserInput = useCallback(
    async (text: string) => {
      setWaiting(true);
      setInputText("");
      const newMessages: Message[] = [
        ...messages,
        { role: "system", content: repeatInstruction(currentSummaryText) },
        { role: "user", content: text },
      ];
      setMessages(newMessages);

      try {
        let answer = await OAIChat({
          messages: newMessages,
          tools,
          tool_choice: {
            type: "function",
            function: {
              name: "onResponse",
            },
          },
          model: settings.model,
          temperature: settings.temp,
          max_tokens: 2000,
        }).result;

        const calls = answer.choices[0].message.tool_calls;

        if (calls && calls.length > 0) {
          const data: ToolResponse = JSON.parse(calls[0].function.arguments);
          setMessages((p) => {
            return [...p, { role: "assistant", content: data.comment }];
          });
          onSummaryTextUpdated(data.summary);
        }
      } catch (e) {
        const msg = `Error calling llm: ${e}`;
        console.error(msg);
        setMessages((p) => {
          return [...p, { role: "assistant", content: msg }];
        });
      }
      setWaiting(false);
    },
    [messages, settings, OAIChat, onSummaryTextUpdated, currentSummaryText]
  );

  const onStartOver = useCallback(() => {
    setMessages([
      { role: "system", content: firstInstruction },
      {
        role: "assistant",
        content: firstPrompt,
      },
    ]);
    onReset();
  }, [setMessages, onReset]);

  const handleInputKeyPress = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (inputText && e.key === "Enter") {
      addUserInput(inputText);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [waiting]);

  useEffect(() => {
    if (!active) {
      onStartOver();
    }
  }, [active, onStartOver]);

  // Ensure we are always seeing the end of the text input
  useEffect(() => {
    if (inputRef && inputRef.current) {
      const element = inputRef.current;
      element.scrollLeft = element.scrollWidth;
    }
  }, [inputText]);

  return (
    <div className={styles.Chat}>
      <div className={styles.topRight}>
        <CogIcon
          className={styles.settings}
          onClick={() => setShowSettings(true)}
        />
      </div>
      {showSettings && (
        <SettingsDialog
          onClose={(newSettings) => {
            setSettings(newSettings);
            setShowSettings(false);
          }}
          settings={settings}
        />
      )}

      <div className={styles.scrollArea} ref={scrollAreaRef}>
        {messages
          .filter((m) => {
            return m.role !== "system";
          })
          .map((m, index) => {
            return <Bubble message={m} key={index} />;
          })}
        {waiting && <TypingIndicator />}
      </div>
      <div className={styles.input}>
        <div className={styles.buttonRow}>
          {prePrompts.map((p, idx) => {
            return (
              <HoverTextButton onClick={() => addUserInput(p.prompt)} key={idx}>
                {p.title}
              </HoverTextButton>
            );
          })}
          <HoverTextButton onClick={onStartOver}>Start over</HoverTextButton>
        </div>
        <input
          ref={inputRef}
          value={inputText}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyPress}
          disabled={waiting}
          placeholder={waiting ? "" : "Type here..."}
        />
      </div>
    </div>
  );
}
