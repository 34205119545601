import { Interval } from "luxon";
import { ViewRangeMode } from "../../Calendar/hooks/useCalendarState";

interface Props {
  selectedInterval: Interval;
  viewRangeMode: ViewRangeMode;
}
export function WeekTitle({ selectedInterval, viewRangeMode }: Props) {
  const sameMonth = selectedInterval.start.month === selectedInterval.end.month;
  const sameYear = selectedInterval.start.year === selectedInterval.end.year;

  if (viewRangeMode === ViewRangeMode.Daily) {
    return (
      <>{`${selectedInterval.start.monthLong} ${selectedInterval.start.day}, ${selectedInterval.start.year}`}</>
    );
  }

  if (!sameYear) {
    return (
      <>{`${selectedInterval.start.monthLong} ${selectedInterval.start.day}, ${selectedInterval.start.year} - ${selectedInterval.end.day} ${selectedInterval.end.monthLong}, ${selectedInterval.end.year}`}</>
    );
  }

  if (!sameMonth) {
    return (
      <>{`${selectedInterval.start.monthLong} ${selectedInterval.start.day} - ${selectedInterval.end.day} ${selectedInterval.end.monthLong}, ${selectedInterval.end.year}`}</>
    );
  }

  return (
    <>{`${selectedInterval.start.monthLong} ${selectedInterval.start.day}-${selectedInterval.end.day}, ${selectedInterval.end.year}`}</>
  );
}
