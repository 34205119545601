import { ReactNode } from "react";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import styles from "./styles.module.sass";

interface RightDrawerProps {
  title: string;
  onClose?: () => void;
  children: ReactNode;
  active: boolean;
}

export function RightDrawer({
  title,
  onClose,
  children,
  active,
}: RightDrawerProps) {
  return (
    <div data-active={active} className={styles.RightDrawer}>
      <h1>{title}</h1>
      <div className={styles.children}>{children}</div>
      <div className={styles.footer}>
        <HoverTextButton onClick={onClose}>Close</HoverTextButton>
      </div>
    </div>
  );
}
