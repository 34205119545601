import { BoundingBoxAnnotation } from "@cur8/rich-entity";
import { useQueryParams } from "@pomle/react-router-paths";
import { useCallback, useMemo } from "react";
import { useAnnotationCRUD } from "render/hooks/api/useAnnotationCRUD";
import { TagIconButton, TrashIconButton } from "render/ui/trigger/IconButton";
import { selectionQuery } from "../../../../query";
import { ChangeClassification } from "./components/ChangeClassification";
import { ChangeState } from "./components/ChangeState";
import { ImageHistory } from "./components/ImageHistory";
import { Notes } from "./components/Notes";
import { ArtefactHistoryEntry } from "./history";
import { useArtefactHistory } from "./hooks/useArtefactHistory";
import styles from "./styles.module.sass";

const EMPTY: ArtefactHistoryEntry[] = [];

interface PhysicalArtefactViewProps {
  annotation: BoundingBoxAnnotation;
  onDelete: (annotation: BoundingBoxAnnotation) => void;
  onClose: () => void;
}

export function PhysicalArtefactView({
  annotation: sourceAnnotation,
  onDelete,
  onClose,
}: PhysicalArtefactViewProps) {
  const { remove: removeAnnotation } = useAnnotationCRUD();

  const { entries: artefactHistory, fetch: fetchAnnotations } =
    useArtefactHistory(sourceAnnotation);

  const handleDelete = useCallback(async () => {
    const patientId = sourceAnnotation.patientId;
    const annotationId = sourceAnnotation.id;
    await removeAnnotation(patientId, annotationId);
    onDelete(sourceAnnotation);
  }, [removeAnnotation, sourceAnnotation, onDelete]);

  const [params, setParams] = useQueryParams(selectionQuery);

  const selectedEntry = useMemo(() => {
    const [imageAnnotationId] = params.selectedImage;

    return artefactHistory?.find((entry) => {
      return entry.image.id === imageAnnotationId;
    });
  }, [artefactHistory, params]);

  const setSelectedEntry = useCallback(
    (entry: ArtefactHistoryEntry | undefined) => {
      setParams({
        selectedImage: entry ? [entry.image.id] : [],
      });
    },
    [setParams]
  );

  return (
    <div className={styles.PhysicalArtefactView}>
      <header>
        <div className={styles.control}>
          <h2>Selected Area</h2>
          <TrashIconButton onClick={handleDelete} />
        </div>

        <div className={styles.control}>
          <TagIconButton label="Save" onClick={onClose} />
        </div>
      </header>

      <main className={styles.recordings}>
        <div className={styles.images}>
          <div className={styles.tools}>
            <ChangeState annotation={sourceAnnotation} />

            <ChangeClassification annotation={sourceAnnotation} />
          </div>

          <div>
            <ImageHistory
              entries={artefactHistory ?? EMPTY}
              selectedEntry={selectedEntry}
              onSelectEntry={setSelectedEntry}
              onUpdate={fetchAnnotations}
            />
          </div>
        </div>

        <div className={styles.notes}>
          <Notes
            entries={artefactHistory ?? EMPTY}
            selectedEntry={selectedEntry}
            onSelectEntry={setSelectedEntry}
          />
        </div>
      </main>
    </div>
  );
}
