import { DashboardCamera } from "lib/avatar/camera";
import { AvatarPodium } from "lib/avatar/podium";
import { createContext, ReactNode, useContext } from "react";
import { useCamera } from "./hooks/useCamera";
import { usePodium } from "./hooks/usePodium";
import { useUI } from "./hooks/useUI";

type DashboardContextValue = {
  camera: DashboardCamera;
  podium: AvatarPodium;
  ui: ReturnType<typeof useUI>;
};

const Context = createContext<DashboardContextValue | null>(null);

interface DashboardContextProps {
  children: ReactNode;
}

export function DashboardContext({ children }: DashboardContextProps) {
  const ui = useUI();
  const camera = useCamera();
  const podium = usePodium();

  return (
    <Context.Provider value={{ ui, camera, podium }}>
      {children}
    </Context.Provider>
  );
}

export function useDashboardContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useDashboardContext without DashboardContext");
  }
  return context;
}
