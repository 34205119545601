import { Sticky } from "@pomle/react-viewstack";
import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface FoldUpCardProps {
  active: boolean;
  children: ReactNode;
}

export function FoldUpCard({ active, children }: FoldUpCardProps) {
  return (
    <div className={styles.Overlay}>
      <div className={styles.content} data-active={active}>
        <div className={styles.card}>
          <Sticky>{children}</Sticky>
        </div>
      </div>
    </div>
  );
}
