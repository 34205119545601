import { createProjector } from "lib/avatar/projector";
import { useCallback, useMemo } from "react";
import * as THREE from "three";

function toScreen(pos: THREE.Vector3, size: { w: number; h: number }) {
  const wh = size.w / 2;
  const hh = size.h / 2;

  return new THREE.Vector3(pos.x * wh + wh, -(pos.y * hh) + hh, pos.z);
}

export interface ScreenProjector {
  getOffsets<Key extends string>(
    sources: Record<Key, THREE.Vector3>
  ): Record<Key, THREE.Vector3>;
  getOffsets(sources: THREE.Vector3[]): THREE.Vector3[];
  getOffsets<Key extends string>(
    sources: Record<Key, THREE.Vector3> | THREE.Vector3[]
  ): Record<Key, THREE.Vector3> | THREE.Vector3[];
}

export function useScreenProject(
  camera: THREE.PerspectiveCamera,
  parent: THREE.Object3D,
  canvas: HTMLCanvasElement
): ScreenProjector {
  const { getPositions } = useMemo(() => {
    return createProjector(camera, parent);
  }, [camera, parent]);

  const getOffsets = useCallback(
    function getOffsets<Key extends string>(
      sources: Record<Key, THREE.Vector3> | THREE.Vector3[]
    ) {
      const size = {
        w: canvas.offsetWidth,
        h: canvas.offsetHeight,
      };

      if (Array.isArray(sources)) {
        return getPositions(sources).map((p) => toScreen(p, size));
      }

      const entries = Object.entries(sources) as [Key, THREE.Vector3][];

      const source = entries.map(([_, value]) => value);
      const drain = getPositions(source);

      const output: Record<string, THREE.Vector3> = {};
      for (let i = 0; i < entries.length; i++) {
        const [key] = entries[i];
        output[key] = toScreen(drain[i], size);
      }

      return output as Record<Key, THREE.Vector3>;
    },
    [getPositions, canvas]
  );

  return { getOffsets } as ScreenProjector;
}
