import { ImmutableScan } from "@cur8/rich-entity";
import { useFemoralPlot } from "render/hooks/api/scan/cardio/useFemoralPlot";
import { usePlotBlob } from "render/hooks/api/scan/cardio/usePlotBlob";
import { useSSNPlot } from "render/hooks/api/scan/cardio/useSSNPlot";
import { StudySymbol } from "render/ui/symbol/StudySymbol";
import { Graph } from "./components/Graph";
import styles from "./styles.module.sass";

interface PWVGraphsProps {
  scan: ImmutableScan;
}

export function PWVGraphs({ scan }: PWVGraphsProps) {
  const piezoFoot = {
    left: usePlotBlob(scan, "piezo_foot_left"),
    right: usePlotBlob(scan, "piezo_foot_right"),
  };

  const piezoHand = {
    left: usePlotBlob(scan, "piezo_hand_left"),
    right: usePlotBlob(scan, "piezo_hand_right"),
  };

  const femoral = useFemoralPlot(scan);
  const ssn = useSSNPlot(scan);

  return (
    <div className={styles.PWVGraphs}>
      <section>
        <div className={styles.graph}>
          <label>
            Hip <StudySymbol />
          </label>
          {femoral && <Graph plot={femoral} />}
        </div>

        <div className={styles.graph}>
          <label>
            Aorta <StudySymbol />
          </label>
          {ssn && <Graph plot={ssn} />}
        </div>
      </section>

      <section>
        <div className={styles.graph}>
          <label>
            Right Hand <StudySymbol />
          </label>
          {piezoHand.right && <Graph plot={piezoHand.right} />}
        </div>

        <div className={styles.graph}>
          <label>
            Left Hand <StudySymbol />
          </label>
          {piezoHand.left && <Graph plot={piezoHand.left} />}
        </div>
      </section>

      <section>
        <div className={styles.graph}>
          <label>
            Right Foot <StudySymbol />
          </label>
          {piezoFoot.right && <Graph plot={piezoFoot.right} />}
        </div>

        <div className={styles.graph}>
          <label>
            Left Foot <StudySymbol />
          </label>
          {piezoFoot.left && <Graph plot={piezoFoot.left} />}
        </div>
      </section>
    </div>
  );
}
