import { useRef } from "react";
import { CalendarEvent } from "render/pages/SchedulePage/reducer/slotManagementUtils";
import { Timeslot } from "../../../Timeslot";
import styles from "./styles.module.sass";

interface Props {
  calendarEvent: CalendarEvent;
  isInteractive?: boolean;
  isSelected?: boolean;
}

export function PossibleSlot({
  calendarEvent,
  isSelected,
  isInteractive = false,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const thisRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={thisRef}
      className={styles.PossibleSlot}
      onClick={(event) => {
        if (!isInteractive || !ref.current) {
          return;
        }
        if (event.target !== thisRef.current) {
          return;
        }
        const child = ref.current.firstChild;
        if (child) {
          child.dispatchEvent(new MouseEvent("click", { bubbles: true }));
        }
      }}
      data-selected={isSelected}
      style={{ cursor: isInteractive ? "pointer" : "initial" }}
    >
      {isInteractive && (
        <div ref={ref} className={styles.slot}>
          <Timeslot slot={calendarEvent} isCensored={false} />
        </div>
      )}
    </div>
  );
}
