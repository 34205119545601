import { ButtonHTMLAttributes } from "react";
import classes from "./styles.module.sass";

interface IconButtonProps extends ButtonHTMLAttributes<any> {
  variant?: "danger" | "primary";
  label?: string;
}

export function IconButton({
  children,
  variant,
  label,
  ...props
}: IconButtonProps) {
  return (
    <button
      className={classes.IconButton}
      type="button"
      data-variant={variant}
      {...props}
    >
      {children}
      {label && <div className={classes.label}>{label}</div>}
    </button>
  );
}
