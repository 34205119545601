import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface InfoPanelProps {
  align: "left" | "right";
  caption: ReactNode;
  children?: ReactNode;
  onClick(): void;
}

export function InfoPanel({
  caption,
  children,
  align,
  onClick,
}: InfoPanelProps) {
  return (
    <div className={styles.InfoPanel} data-align={align}>
      <button onClick={onClick} type="button">
        <header className={styles.caption}>{caption}</header>
      </button>

      <hr />

      <div className={styles.content}>{children}</div>
    </div>
  );
}
