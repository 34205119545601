import { Patient } from "@cur8/rich-entity";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Typography } from "render/ui/presentation/Typography";
import { PWVGraphs } from "./components/PWVGraphs";
import styles from "./styles.module.sass";

interface PulseWavesPanelProps {
  patient: Patient;
}

export function PulseWavesPanel({ patient }: PulseWavesPanelProps) {
  const { scans } = usePatientData();

  const cardioScan = scans?.cardio.at(0);

  return (
    <div className={styles.PulseWavesPanel}>
      <Typography as="h2" variant="display-s">
        Arterial stiffness
      </Typography>

      <hr />

      <Typography as="h3" variant="title-s">
        Pulse waves
      </Typography>

      {cardioScan && <PWVGraphs scan={cardioScan} />}
    </div>
  );
}
