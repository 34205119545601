import { ChangeEvent, useCallback, useMemo } from "react";

type InputProps = {
  value: string;
  onChange: (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
};

export function useUserInput<T extends Record<string, string>>(
  values: T,
  onChange: (value: T) => void
): Record<keyof T, InputProps> {
  const merge = useCallback(
    (key: keyof T, value: string) => {
      const nextValues = { ...values, ...{ [key]: value } };
      onChange(nextValues);
    },
    [values, onChange]
  );

  return useMemo(() => {
    const userInput = {} as Record<keyof T, InputProps>;

    for (let key in values) {
      userInput[key] = {
        value: values[key],
        onChange: (
          event: ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
          >
        ) => {
          const value = event.target.value;
          merge(key, value);
        },
      };
    }

    return userInput;
  }, [merge, values]);
}
