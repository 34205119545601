import { useMemo } from "react";
import { DownIcon, UpIcon } from "render/ui/symbol/HoverIcon";
import { ButtonSet } from "render/ui/trigger/ButtonSet";
import { FigureButton } from "render/ui/trigger/FigureButton";

interface ImageStepProps<T> {
  items: T[];
  selected?: T;
  onChange: (selected: T) => void;
}

export function ImageStep<T>({ items, selected, onChange }: ImageStepProps<T>) {
  const activeIndex = useMemo(() => {
    if (selected) {
      const index = items.indexOf(selected);
      if (index > -1) {
        return index;
      }
    }

    return 0;
  }, [items, selected]);

  const prevItem = items[activeIndex - 1];
  const nextItem = items[activeIndex + 1];

  return (
    <ButtonSet>
      <FigureButton disabled={!prevItem} onClick={() => onChange(prevItem)}>
        <UpIcon color="black" />
      </FigureButton>
      <FigureButton disabled={!nextItem} onClick={() => onChange(nextItem)}>
        <DownIcon color="black" />
      </FigureButton>
    </ButtonSet>
  );
}
