import { Lesion } from "lib/lesion";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { Layer } from "render/pages/DashboardPage/types";
import { TrackedLesionImage } from "./components/TrackedLesionImage";
import styles from "./styles.module.sass";

const CELL_SIZE = 220;

interface TrackedLesionsImagePoolProps {
  active: boolean;
  lesions: Lesion[] | undefined;
}

export function TrackedLesionsImagePool({
  active,
  lesions,
}: TrackedLesionsImagePoolProps) {
  const { ui } = useDashboardContext();

  return (
    <div className={styles.TrackedLesionsImagePool} data-active={active}>
      <div className={styles.lesions}>
        {lesions?.map((lesion, index) => {
          const physId = lesion.physicalId;
          const delay = 0.1 + index * 0.1;

          return (
            <button
              className={styles.lesion}
              key={physId}
              style={{
                height: CELL_SIZE,
                width: CELL_SIZE,
              }}
              onClick={() => {
                ui.set({ layer: Layer.LesionMacro, physId });
              }}
              data-physical-id={physId}
            >
              <TrackedLesionImage
                active={active}
                delay={delay}
                lesion={lesion}
              />
            </button>
          );
        })}
      </div>
    </div>
  );
}
