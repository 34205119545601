import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface ReferenceValueProps {
  value: ReactNode;
}

export function ReferenceValue({ value }: ReferenceValueProps) {
  return (
    <div className={styles.ReferenceValue}>
      Previous: <b>{value}</b>
    </div>
  );
}
