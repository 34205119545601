import styles from "./styles.module.sass";

interface DataSummaryProps {
  label: string;
  systolic: number | string;
  diastolic: number | string;
  risk?: string;
}

export function DataSummary({
  diastolic,
  label,
  systolic,
  risk,
}: DataSummaryProps) {
  return (
    <div className={styles.DataSummary}>
      <div className={styles.header}>
        {risk && <div style={{ background: risk }} className={styles.bubble} />}
        {label}
      </div>
      <div className={styles.row}>
        <div className={styles.values}>
          <span>{systolic}</span> / <span>{diastolic}</span>
        </div>
        <div className={styles.unit}>mmHg</div>
      </div>
    </div>
  );
}
