import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layout, LayoutProps } from "../Layout";
import { SidePanel } from "../SidePanel";
import { PulseWavesPanel } from "./components/PulseWavesPanel";
import styles from "./styles.module.sass";

interface ArterialStiffnessLayoutProps extends LayoutProps {}

export function ArterialStiffnessLayout({
  active,
}: ArterialStiffnessLayoutProps) {
  const { patient } = usePatientData();

  return (
    <Layout active={active}>
      <div className={styles.ArterialStiffnessLayout}>
        <div className={styles.pulseWavesPanel} data-active={active}>
          <SidePanel>
            {patient && <PulseWavesPanel patient={patient} />}
          </SidePanel>
        </div>
      </div>
    </Layout>
  );
}
