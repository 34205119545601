import { Slot } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { useCallback } from "react";
import { usePopupDialog } from "render/context/PopupDialogContext";
import { paths } from "render/routes/paths";
import { CancelAppointmentPopup } from "../components/CancelAppointmentPopup";

export function useCancelAppointmentPopup() {
  const { create } = usePopupDialog();

  const nav = {
    scheduleSelect: useNav(paths.scheduleSiteSelect),
    patientDetails: useNav(paths.patient.detail),
  };

  const emitDialog = useCallback(
    (slot: Slot) => {
      const { emit, close } = create();

      emit(
        <CancelAppointmentPopup
          slot={slot}
          onDone={() => {
            close();
            if (slot.patientId) {
              nav.patientDetails.go({ patientId: slot.patientId });
              return;
            }
            nav.scheduleSelect.go({});
          }}
          onCancel={close}
        />
      );
    },
    [create, nav.scheduleSelect, nav.patientDetails]
  );

  return {
    emitDialog,
  };
}
