import { RefObject, useEffect, useState } from "react";

export function useDOMElementSize(ref: RefObject<HTMLElement>) {
  const [size, setSize] = useState<{ width: number; height: number }>();

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      const element = entries.at(0)?.target;
      if (element instanceof HTMLElement) {
        setSize({
          width: element.offsetWidth,
          height: element.offsetHeight,
        });
      }
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return size;
}
