import { Reader } from "@stripe/terminal-js";
import { useCallback, useEffect, useState } from "react";
import {
  availableLocations,
  useStripeTerminal,
} from "render/hooks/useStripeTerminal";
import { Fieldset } from "render/ui/form/Fieldset";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import styles from "./styles.module.sass";

export function StripeTerminals() {
  const {
    fetchReaders,
    location,
    preferredTerminalId,
    setLocationById,
    setPreferredTerminal,
  } = useStripeTerminal();
  const [readers, setReaders] = useState<Reader[]>([]);

  const selectLocation = useCallback(
    (id: string | null) => {
      if (id && id !== location?.stripeId) {
        setLocationById(id);
      } else {
        setLocationById(undefined);
      }
    },
    [location?.stripeId, setLocationById]
  );

  const selectTerminal = useCallback(
    (id: string | null) => {
      setPreferredTerminal(id);
    },
    [setPreferredTerminal]
  );

  useEffect(() => {
    if (!location) {
      return;
    }

    fetchReaders().then((rs) => {
      if (rs) {
        setReaders(rs);
      }
    });
  }, [fetchReaders, location]);

  return (
    <div className={styles.StripeTerminals}>
      <h2>Stripe Terminals</h2>
      <Fieldset direction="row" legend="Selected Location">
        <div className={styles.field}>
          {availableLocations.map(
            ({ stripeId, label, address, country }, key) => (
              <HoverTextButton
                key={key}
                onClick={() => selectLocation(stripeId)}
                active={stripeId === location?.stripeId}
              >
                <h2 title={stripeId}>{label}</h2>
                {address.split(",").map((row, key) => (
                  <span key={key}>{row}</span>
                ))}
                <span>{country}</span>
              </HoverTextButton>
            )
          )}
          <HoverTextButton
            onClick={() => selectLocation(null)}
            active={!location}
          >
            <h2>No preferred</h2>
          </HoverTextButton>
        </div>
      </Fieldset>

      <Fieldset direction="row" legend="Preferred Stripe Terminals">
        <div className={styles.field}>
          {!location && (
            <div className={styles.empty}>Select a location first</div>
          )}
          {location && readers.length > 0 && (
            <>
              {readers.map((reader, key) => (
                <HoverTextButton
                  key={key}
                  onClick={() => selectTerminal(reader.id)}
                  active={preferredTerminalId === reader.id}
                  disabled={reader.status !== "online"}
                >
                  <h2 title={reader.id}>{reader.label}</h2>
                </HoverTextButton>
              ))}
              <HoverTextButton
                onClick={() => selectTerminal(null)}
                active={!preferredTerminalId}
              >
                <h2>No preferred</h2>
              </HoverTextButton>
            </>
          )}
          {location && readers.length <= 0 && (
            <div className={styles.empty}>No terminals found</div>
          )}
        </div>
      </Fieldset>
    </div>
  );
}
