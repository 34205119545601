import { Risk, Triglycerides } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { useMemo } from "react";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import {
  Diff,
  DiffValue,
  MainTitle,
  MetricResultHeader,
  Subtitle,
  Titles,
} from "render/ui/presentation/MetricResultHeader";
import {
  RangeChart,
  RangeChartDataComposer,
} from "render/ui/presentation/RangeChart";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";
import { ChartRange } from "render/ui/presentation/RangeChart/types";

interface TriglyceridesItemProps {
  patient: Patient;
}

const getTitlesForValueChange = (value?: number, previousValue?: number) => {
  if (value === undefined || previousValue === undefined) {
    return {
      title: "Triglycerides",
      subtitle: "",
    };
  }

  if (value === previousValue) {
    return {
      title: "Maintained",
      subtitle: "Triglycerides",
    };
  }

  if (value > previousValue) {
    return {
      title: "Increased",
      subtitle: "Triglycerides",
    };
  }

  return {
    title: "Decreased",
    subtitle: "Triglycerides",
  };
};

function capToMaxRanges(ranges: ChartRange[], currentNumber?: number) {
  if (!currentNumber) {
    return ranges.slice(0, 3);
  }

  const veryHighRange = ranges.find((range) => range.risk === Risk.HighRisk);
  const isValueWithinVeryHigh = veryHighRange
    ? currentNumber >= veryHighRange.from
    : false;

  const sortedRanges = ranges.toSorted((a, b) => a.to - b.to);

  const activeRange = sortedRanges.find((range) => {
    return currentNumber >= range.from && currentNumber < range.to;
  });

  const index = activeRange ? sortedRanges.indexOf(activeRange) : 0;
  // only show high risk if the value is within the very high range
  const len = isValueWithinVeryHigh
    ? sortedRanges.length
    : sortedRanges.length - 1;
  let start = index - 1;
  let end = start + 3;

  if (end > len) {
    end = len;
    start = Math.max(0, end - 3);
  }
  if (start < 0) {
    start = 0;
    end = Math.min(start + 3, len);
  }

  return sortedRanges.slice(start, end);
}

export function TriglyceridesItem({ patient }: TriglyceridesItemProps) {
  const { metrics } = usePatientData();

  const riskRange = useMemo(() => {
    return Triglycerides.rangesFor();
  }, []);

  const entries = useMemo(() => {
    if (!metrics) {
      return [];
    }

    return metrics.bloodwork.triglycerides?.map((metric) => {
      const unit = metric.unit;
      return {
        risk: riskRange.findRisk(unit),
        timestamp: metric.measurement.timestampStart,
        value: unit["mmol/L"],
      };
    });
  }, [metrics, riskRange]);

  const latest = entries?.at(0);
  const value = latest?.value;
  const previous = entries?.at(1);
  const previousValue = previous?.value;

  const ranges = useMemo(() => {
    return capToMaxRanges(toChartRanges(riskRange.entries), value);
  }, [riskRange.entries, value]);

  const { title, subtitle } = getTitlesForValueChange(value, previousValue);

  return (
    <div>
      <MetricResultHeader>
        <Titles>
          <MainTitle>{title}</MainTitle>
          <Subtitle>{subtitle}</Subtitle>
        </Titles>
        <Diff>
          <DiffValue
            valuesDiff={
              previousValue !== undefined && value !== undefined
                ? value - previousValue
                : undefined
            }
            formatValue={(value) => value?.toFixed(2)}
            unit="mmol/L"
          />
        </Diff>
      </MetricResultHeader>
      <RangeChartDataComposer
        ranges={ranges}
        value={value}
        previousValue={previousValue}
      >
        {({ ranges, values }) => <RangeChart ranges={ranges} values={values} />}
      </RangeChartDataComposer>
    </div>
  );
}
