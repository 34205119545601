import { useCallback, useEffect, useState } from "react";
import { useFormHandle } from "render/hooks/useFormHandle";
import { useUserInput } from "render/hooks/useUserInput";
import { Field } from "render/ui/form/Field";
import { Fieldset } from "render/ui/form/Fieldset";
import { Input } from "render/ui/form/Input";
import { BodyMetricWeight } from "render/ui/format/BodyMetricWeight";
import { ButtonSet } from "render/ui/trigger/ButtonSet";
import { SubmitButton } from "render/ui/trigger/SubmitButton";
import { ReferenceValue } from "../../fragments/ReferenceValue";
import { Entries, toMetrics } from "./conversion";
import {
  GripStrengthMetrics,
  useGripStrengthMetrics,
} from "./hooks/useGripStrengthMetrics";
import styles from "./styles.module.sass";

const INITIAL: Entries = {
  left: "",
  right: "",
};

interface GripStrengthSectionProps {
  patientId: string;
}

export function GripStrengthSection({ patientId }: GripStrengthSectionProps) {
  const { fetchGripStrengthMetrics, updateGripStrengthMetrics } =
    useGripStrengthMetrics();

  const [latest, setLatest] = useState<GripStrengthMetrics>();

  const updateLatest = useCallback(() => {
    fetchGripStrengthMetrics(patientId).then(setLatest);
  }, [fetchGripStrengthMetrics, patientId]);

  useEffect(() => {
    updateLatest();

    return () => {
      setLatest(undefined);
    };
  }, [updateLatest]);

  const [entries, setEntries] = useState<Entries>(INITIAL);

  const userInput = useUserInput(entries, setEntries);

  const handleSave = useCallback(async () => {
    await updateGripStrengthMetrics(patientId, toMetrics(entries));
    setEntries(INITIAL);

    setTimeout(updateLatest, 1000);
  }, [entries, patientId, updateGripStrengthMetrics, updateLatest]);

  const formHandle = useFormHandle(handleSave);

  return (
    <form className={styles.GripStrengthSection} onSubmit={formHandle.onSubmit}>
      <Fieldset direction="row" legend="Grip Strength">
        <Field label="Left Hand">
          <Input hint="kg">
            <input
              type="number"
              min={15}
              max={85}
              step="any"
              autoComplete="off"
              {...userInput.left}
            />
          </Input>

          <ReferenceValue
            value={<BodyMetricWeight weight={latest?.gripStrengthLeft?.unit} />}
          />
        </Field>

        <Field label="Right Hand">
          <Input hint="kg">
            <input
              type="number"
              min={15}
              max={85}
              step="any"
              autoComplete="off"
              {...userInput.right}
            />
          </Input>

          <ReferenceValue
            value={
              <BodyMetricWeight weight={latest?.gripStrengthRight?.unit} />
            }
          />
        </Field>
      </Fieldset>

      <ButtonSet>
        <SubmitButton handle={formHandle}>Save</SubmitButton>
      </ButtonSet>
    </form>
  );
}
